import React from 'react'
import { IconProps } from '../../types/icon'

const ZoIcon: React.FC<IconProps> =({className}: IconProps) => {
    return (
        <svg className={className} width="70" height="70" viewBox="0 0 51 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_2335_8284)">
                <path d="M38.9284 14.5478C39.38 14.9744 39.8284 15.4073 40.2892 15.8246C40.5384 16.0519 40.5602 16.3321 40.5664 16.6373C40.5726 16.9238 40.4636 17.2414 40.7221 17.4843C40.9868 17.7054 40.9183 18.023 40.9619 18.3095C41.3542 20.9533 42.3382 23.2763 44.5367 24.9547C44.9976 25.3066 45.3027 25.263 45.5176 24.7212C45.5487 24.6963 45.5799 24.6745 45.611 24.6496C47.5977 25.4966 48.1022 27.7698 46.4923 29.1648C45.7262 29.8281 45.7107 30.6159 45.6359 31.3913C45.2436 35.6108 43.5714 39.2416 40.803 42.4179C37.5925 46.1017 33.5413 48.3282 28.7582 49.0351C22.9226 49.8977 17.5728 48.5649 12.9112 44.8343C8.74156 41.4962 6.30955 37.1522 5.57465 31.8864C5.46255 31.0768 5.2508 30.6346 4.5377 30.2111C2.40462 28.9375 2.43265 25.7924 4.52524 24.4409C4.75256 24.2946 4.97677 24.1482 5.20409 24.005C5.42518 23.7372 5.73658 23.7808 6.02929 23.7683C7.6797 23.6936 9.3301 23.7372 10.9805 23.7341C15.284 23.7216 19.4972 23.077 23.6419 21.9373C24.2429 21.7722 24.8502 21.6352 25.4387 21.4328C27.0642 21.4422 28.6741 21.7473 30.3058 21.6446C33.0586 21.4702 35.5062 20.6356 37.3652 18.4808C35.3847 20.7322 32.7565 21.464 29.8979 21.6165C28.9575 21.6664 27.9922 21.738 27.0922 21.3207C26.8867 20.754 27.4254 20.7851 27.6776 20.6792C29.026 20.1094 30.3806 19.5551 31.6916 18.898C33.9305 17.777 36.0293 16.4411 38.0036 14.9059C38.2714 14.6973 38.5143 14.3921 38.9284 14.5416V14.5478Z" fill="white" />
                <path d="M25.5694 21.5667C23.617 22.3857 21.5462 22.75 19.491 23.1798C14.7702 24.1638 9.984 24.005 5.20405 24.0019C4.97673 23.977 4.72138 24.0641 4.547 23.8368C4.63419 23.7216 4.75875 23.6313 4.88953 23.6469C5.89535 23.759 6.19118 23.105 6.51191 22.3172C9.49199 15.0149 14.9601 10.7488 22.689 9.52189C28.3814 8.61572 33.5506 10.1478 38.1624 13.5763C38.4582 13.7974 38.8101 13.9904 38.9284 14.389C38.9284 14.442 38.9284 14.4949 38.9284 14.5478C37.742 15.3855 36.568 16.2481 35.3629 17.0608C32.7721 18.8046 29.9103 20.0191 27.0392 21.2055C26.8368 21.4764 26.5566 21.4079 26.295 21.3643C24.5294 21.056 22.7949 20.6357 21.1133 20.0098C20.9608 19.9537 20.8082 19.8914 20.6805 19.7855C20.4594 19.6049 20.4501 19.4306 20.7054 19.2749C20.9172 19.1441 21.1538 19.0942 21.3905 19.0288C23.1187 18.5555 24.8127 17.9919 26.4258 17.2009C26.9738 16.9331 27.4939 16.6186 27.9547 16.2138C26.1019 17.5248 24.137 18.294 19.5377 19.4835C21.2753 20.1966 23.0066 20.7073 24.7691 21.1277C25.0774 21.2024 25.4387 21.1775 25.5694 21.5699V21.5667Z" fill="#D6EBF4" />
                <path d="M38.9284 14.389C37.4368 13.3676 36.0012 12.2622 34.3353 11.5086C32.0963 10.4965 29.7889 9.87685 27.3288 9.63396C23.3429 9.2416 19.6342 10.0637 16.1279 11.8729C12.5779 13.7039 9.81583 16.3788 7.88517 19.9038C7.36202 20.8567 6.87936 21.8345 6.55862 22.8559C6.30638 23.6624 5.91714 23.9676 5.11685 23.843C4.93312 23.815 4.73694 23.843 4.54699 23.843C4.17643 23.7839 4.25116 23.5659 4.31655 23.3012C5.27877 19.5053 6.86379 16.0145 9.33005 12.941C10.9587 10.9107 12.8706 9.20112 15.1625 7.98044C17.3984 6.78779 19.7961 6.04355 22.3527 5.9221C22.8821 5.89719 23.6574 6.01241 23.8007 5.55154C23.9408 5.10001 23.4768 4.4959 23.0253 4.12222C22.7015 3.85131 22.3278 3.63956 21.8482 3.31259C23.8256 3.56171 25.5912 3.91047 26.8524 5.56399C27.2012 4.79796 27.7399 4.2717 28.3035 3.77035C28.6709 3.44649 28.9201 3.45583 29.0913 4.00389C29.6519 5.79754 29.6768 5.71347 31.458 5.93767C35.2165 6.411 38.3741 7.9929 40.5508 11.219C41.2452 12.2466 41.2234 12.4116 40.2923 13.2586C39.8532 13.6572 39.3799 14.0184 38.9222 14.3952L38.9284 14.389Z" fill="#C2E4F1" />
                <path d="M40.7251 16.3383C40.7781 15.4259 40.6161 14.5073 40.8404 13.5949C40.9493 13.1559 41.0334 13.0033 41.4289 13.3738C43.6896 15.4882 45.4428 17.914 45.8788 21.056C46.0438 22.2424 46.1092 23.4818 45.611 24.6495C45.1034 24.7772 44.9134 24.4222 44.8076 24.0548C44.0602 21.4639 42.7897 19.1534 41.1082 17.0546C40.9369 16.8397 40.8933 16.5563 40.7283 16.3383H40.7251Z" fill="#C2E4F1" />
                <path d="M40.7252 16.3383C42.2977 17.942 43.3845 19.8634 44.2813 21.8968C44.574 22.5601 44.8543 23.2389 44.9788 23.9645C45.0411 24.3195 45.2871 24.5219 45.6079 24.6464C45.6203 24.9765 45.6421 25.3502 45.337 25.537C44.9788 25.7581 44.7266 25.3813 44.4993 25.2038C41.9676 23.2576 40.831 20.617 40.7189 17.4812C40.7189 17.0982 40.7189 16.7183 40.722 16.3352L40.7252 16.3383Z" fill="#D6EBF4" />
                <path d="M25.5694 21.5667C23.5703 21.0716 21.5742 20.5609 19.6653 19.7762C19.4442 19.6859 19.2263 19.5893 18.8682 19.4367C20.2352 19.0475 21.4714 18.7299 22.689 18.3437C24.5761 17.7458 26.4787 17.1666 28.0201 15.8276C28.154 15.7093 28.2942 15.6283 28.4468 15.7685C28.671 15.9709 28.4748 16.1079 28.344 16.2262C27.8582 16.6653 27.307 17.0109 26.7247 17.3099C25.0245 18.1787 23.2153 18.7485 21.3874 19.2686C21.1351 19.3402 20.8829 19.4149 20.4719 19.5364C22.7015 20.3616 24.8221 20.9782 27.0455 21.2055C29.2813 21.5636 31.486 21.439 33.6284 20.6948C35.0608 20.1965 36.3127 19.4025 37.2655 18.188C37.3963 18.0199 37.496 17.6524 37.8011 17.9233C38.0814 18.1693 37.7544 18.3624 37.633 18.5243C36.3718 20.1747 34.6498 21.0809 32.6693 21.5574C30.3089 22.1241 27.9423 21.9155 25.5757 21.5698L25.5694 21.5667Z" fill="#22B3E5" />
                <path d="M22.4461 42.0753C20.1013 42.0909 18.0492 40.7488 17.2053 38.678C17.006 38.186 16.897 37.6971 17.498 37.4417C18.0648 37.202 18.3232 37.6068 18.5319 38.0583C19.3508 39.8551 20.7646 40.6958 22.7264 40.6367C22.8883 40.6304 23.0502 40.6211 23.2122 40.6211C23.6575 40.6211 23.9471 40.8297 23.9813 41.2782C24.0187 41.7733 23.7291 42.0286 23.2558 42.0722C22.988 42.0971 22.7139 42.0753 22.4461 42.0753Z" fill="#12133C" />
                <path d="M30.9442 31.9269C30.9442 32.8486 30.9286 33.7673 30.9504 34.689C30.9597 35.072 30.8476 35.2121 30.449 35.2028C29.4214 35.1779 28.3907 35.181 27.3631 35.2028C26.977 35.2121 26.8524 35.0907 26.8431 34.6983C26.8337 34.2748 27.0174 34.2063 27.3724 34.2219C27.9143 34.2437 28.4654 34.1627 28.9948 34.2437C29.8667 34.3745 30.0193 33.9977 29.9819 33.2223C29.9197 31.8989 29.9726 30.5692 29.9601 29.2458C29.9601 28.9095 29.9695 28.6977 30.4179 28.7008C30.8507 28.7008 30.9691 28.8316 30.9535 29.252C30.9224 30.1457 30.9442 31.0394 30.9442 31.93V31.9269Z" fill="#12133D" />
                <path d="M18.1582 26.5304C18.2547 25.1416 19.2232 24.2946 20.5746 24.3226C21.8607 24.3475 22.8852 25.3315 22.907 26.5647C22.9132 26.8885 22.8416 27.1906 22.471 27.2373C22.088 27.2871 21.9635 27.0193 21.9323 26.683C21.8732 26.0726 21.624 25.5837 21.0106 25.3689C20.1013 25.045 19.329 25.537 19.1391 26.5678C19.0799 26.8978 19.0643 27.2746 18.6159 27.2404C18.1239 27.2061 18.1551 26.7982 18.1582 26.5335V26.5304Z" fill="#13143E" />
                <path d="M37.0507 24.3195C38.3648 24.3164 39.3924 25.2443 39.4422 26.4837C39.4547 26.8169 39.4734 27.1906 39.0343 27.2373C38.6108 27.2809 38.4956 26.957 38.4675 26.5989C38.4115 25.8858 38.044 25.4343 37.3341 25.3128C36.431 25.1602 35.8238 25.6149 35.6961 26.5615C35.6432 26.9508 35.5902 27.3182 35.1076 27.2248C34.6529 27.1345 34.681 26.7235 34.7214 26.3622C34.8616 25.1384 35.7958 24.3226 37.0538 24.3195H37.0507Z" fill="#12143D" />
            </g>
            <defs>
                <filter id="filter0_d_2335_8284" x="0.215759" y="0.581512" width="49.9652" height="51.4373" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="1.36554" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.137255 0 0 0 0 0.121569 0 0 0 0 0.12549 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2335_8284" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2335_8284" result="shape" />
                </filter>
            </defs>
        </svg>

    )
}

export default React.memo(ZoIcon)