import React, { useState } from 'react'
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import yellowBling from '../../asset/gamification/xp-yellow.svg'
import * as sparkles from '../../asset/gamification/sparkles.json'
import Lottie from 'react-lottie';
import LinearProgress from '@mui/joy/LinearProgress';
import { useCountUp } from 'use-count-up';
import LevelUpPopUp from './LevelUpPopUp';

interface QuestPopUpProps {
    isOpen: boolean,
    close: () => void,
    gamification: QuestProgressGameProps
}

export default function QuestProgressPopUp({ isOpen, close, gamification }: QuestPopUpProps) {
    const [collect, setCollect] = useState(false)
    const [isOpenLevel, setIsOpenLevel] = useState(false)
    
    const { value } = useCountUp({
        isCounting: true,
        duration: 2,
        easing: 'linear',
        start: 0,
        end: gamification.quest_info ? (gamification.quest_info.progress / gamification.quest_info.required_count) * 100 : 0,
        onComplete: () => ({
            shouldRepeat: false,
            delay: 2,
        }),
    });
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: sparkles,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const handleCollectXp = () => {
        setCollect(true)

        setTimeout(() => {
            close()
            setIsOpenLevel(true)
        }, 1500);
    }
    const handleCloseLevel = () => {
        setIsOpenLevel(false)
    }
    if(!gamification.quest_info) return <div></div>;
    return (
        <>
            <Transition appear show={isOpen}>
                <Dialog as="div" className="relative z-[99999] focus:outline-none lexend-deca-normal" onClose={() => null}>
                    <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4">
                            <TransitionChild
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 transform-[scale(95%)]"
                                enterTo="opacity-100 transform-[scale(100%)]"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 transform-[scale(100%)]"
                                leaveTo="opacity-0 transform-[scale(95%)]"
                            >
                                <DialogPanel className="w-full max-w-[40%] rounded-xl bg-white p-6 border border-[#cccccc]">
                                    <h5 className='uppercase text-center font-bold text-2xl mb-8'>{gamification.quest_info.is_completed ? 'Quest Cleared' : 'Ongoing Quest'}</h5>
                                    <img src={gamification.quest_info.icon} width={200} className='block m-auto mb-8' />
                                    <div className='flex flex-col rounded-2xl bg-[#f0f0f0] p-4 mb-8'>
                                        <h6 className='uppercase font-bold text-xl'>{gamification.quest_info.name}</h6>
                                        <p className='mb-4'>{gamification.quest_info.description}</p>
                                        {gamification.xp_earned_breakdown.map((data) =>
                                            <div className='flex justify-between'>
                                                <p>{data.type}</p>
                                                <span className='text-[#FBA91F]'>{data.xp_awarded} XP</span>
                                            </div>
                                        )}
                                        <hr className='my-2' />
                                        <div className='flex justify-between mb-4'>
                                            <p className='uppercase'>Total</p>
                                            <span className='text-[#FBA91F]'>{gamification.xp_earned}</span>
                                        </div>
                                        <LinearProgress
                                            determinate
                                            color='success'
                                            value={Number(value!)}
                                            sx={{
                                                '--LinearProgress-radius': '20px',
                                                '--LinearProgress-thickness': '24px',
                                                color: '#29B3E6',
                                            }}
                                        ></LinearProgress>
                                        <div className='flex justify-between mt-4'>
                                            <p className='uppercase'>{(gamification.quest_info.progress / gamification.quest_info.required_count) * 100}%</p>
                                            <div><span className='text-[#29B3E6]'>{gamification.quest_info.progress}</span>/<span className=''>{gamification.quest_info.required_count}</span></div>
                                        </div>
                                    </div>
                                    <button className='relative bg-[#29B3E6] text-white rounded-full p-4 w-full text-center' onClick={handleCollectXp}>
                                        <div className='flex gap-2 justify-center items-center font-bold'>
                                            <span>Collect</span>
                                            <img src={yellowBling} width={30} height={30} />
                                            <span>{gamification.xp_earned} XP</span>
                                        </div>
                                        {collect && <div className='absolute transform translate-x-1/2 -translate-y-1/2 top-[50%] right-[50%] z-[999]'>
                                            <Lottie
                                                options={defaultOptions}
                                                height={150}
                                                width={150}
                                            />
                                        </div>}
                                    </button>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>

                </Dialog>
            </Transition>
            <LevelUpPopUp gamification={gamification} isOpenLevel={isOpenLevel} closeLvl={handleCloseLevel} />
        </>
    )
}
