import React, { useEffect, useState } from 'react'
import DragandDropList from '../components/DragandDropList'
import axios, { CancelTokenSource } from 'axios';
import { useNavigate } from 'react-router-dom'
import EmergencyDragandDrop from '../components/EmergencyDragandDrop'
import { toast } from 'react-toastify'
import DoYouNeedHelpWidget from '../components/DoYouNeedHelpWidget'
import ClickableList from '../components/ClickableList'
import {
    safetyplan, safetyplanmessage, how, why, who, when, back, stressdefault, stressoractive, redfagsactive,
    redflagsdefault, contactactive, contactdefault, copingactive, copingdefault, motivationactive, motivationdefault, distractionactive,
    distractiondefault, redflag
} from '../asset/index'
import ContactList from '../components/ContactList';
import { FadeLoader } from 'react-spinners';
import Blur from '../components/Blur';
import Error from '../components/Error';
import { TierLists } from '../types/tier';
import cryptojs from 'crypto-js'
import { useSafetyStore } from '../store/safety';
import { track } from '@amplitude/analytics-browser';
interface UserData {
    uuid: string;
    current_org_id: number;
    gamification: {
        credits: number;
        avatar: string;
        current_xp: number;
        xp_cap: number;
        current_level: number;
    };
    user_info: {
        firstname: string;
        lastname: string;
    };
    org_roles: {
        role: string;
    }[];
}

interface EmergencyContact {
    id: number;
    name: string;
    relationship: string;
    contact1: string;
    contact2: string;
}

function SafetyPlan() {
    const navigate = useNavigate();
    const [isSetup, setIsSetup] = useState<number>();
    const [isActivated, setIsActivated] = useState<number>(0);
    const [userData, setUserData] = useState<UserData | null>(null);
    const [step, setStep] = useState<number>(0);
    const [stressSafetyPlans, setStressSafetyPlans] = useState<string[]>([]);
    const [redflagSafetyPlans, setRedflagSafetyPlans] = useState<string[]>([]);
    const [copingSafetyPlans, setCopingSafetyPlans] = useState<string[]>([]);
    const [motivationSafetyPlans, setMotivationSafetyPlans] = useState<string[]>([]);
    const [distractionSafetyPlans, setDistractionSafetyPlans] = useState<string[]>([]);
    const [emergencyContacts, setEmergencyContacts] = useState<EmergencyContact[]>([]);
    const [distractionSelected, setDistractionSelected] = useState<string[]>([]);
    const [motivationSelected, setMotivationSelected] = useState<string[]>([]);
    const [copingSelected, setCopingSelected] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isError, setIsError] = useState<boolean>(false);
    const [tierLists, setTierLists] = useState<TierLists>();
    const setSafetyActivated = useSafetyStore((state) => state.setSafetyActivated);
    const apiToken = sessionStorage.getItem('api_token');
    const getSafetyPlan = async (source: CancelTokenSource) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/safety-plan`, { headers: { Authorization: `Bearer ${apiToken}` }, cancelToken: source.token });
            setIsActivated(res.data.response.activate_status)
            setIsSetup(res.data.response.setup_status)
            setIsLoading(false)
        } catch (error) {
            if (axios.isCancel(error)) {
            }
            else {
                toast.error('Error Safety Plan');
            }
        }
    }
    useEffect(() => {
        const unsubscribe = useSafetyStore.subscribe((state) => {
            setIsActivated(state.safetyActivated)
        })
        return () => unsubscribe()
    }, [])
    useEffect(() => {
        track('screenView', { screen_name: 'SafetyPlanScreen' })

        let source = axios.CancelToken.source();
        // const params = new URLSearchParams(window.location.search);
        // const activated = params.get('activated');
        // if (activated === '1') {
        //     setActivated(1);
        // }
        getSafetyPlan(source)
        fetchSafetyPlan(source);
        const tier = localStorage.getItem('tier')
        if (tier && process.env.REACT_APP_TIER_SECRET) {
            try {
                const decryptData = cryptojs.AES.decrypt(tier, process.env.REACT_APP_TIER_SECRET)
                const tierData = JSON.parse(decryptData.toString(cryptojs.enc.Utf8))
                setTierLists(tierData)
            } catch (error) {
            }
        }
        return () => {
            source.cancel();
        }
    }, []);
    useEffect(() => {
        localStorage.setItem('isActivated', JSON.stringify(isActivated))
    }, [isActivated])

    useEffect(() => {
        // Check if the API token is present in session storage
        const storedUserData = sessionStorage.getItem('user');

        if (apiToken && storedUserData) {
            setUserData(JSON.parse(storedUserData));
        } else {
            // If the API token or user data is not present, redirect to the login page
            navigate('/login');
        }
    }, [navigate]);

    const handleNextClick = () => {
        setStep(step + 1);
    }

    const handleBackClick = () => {
        setStep(step - 1);
    }

    const handleIAmOkayClick = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/safety-plan-activation`,
                {
                    activate_status: 0
                },
                { headers: { Authorization: `Bearer ${apiToken}` } });
            if (response.status === 200) {
                setSafetyActivated(0)
                toast.success(response.data.message);
                navigate('/home');
            } else {
                toast.error('Error updating safety plan');
            }
        } catch (error) {
            toast.error('Error updating safety plan');
        }
    }

    const fetchSafetyPlan = async (source: CancelTokenSource) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/safety-plan`, { headers: { Authorization: `Bearer ${apiToken}` }, cancelToken: source.token });
            if (response.status === 200) {
                if (response.data.response.length < 1) {
                    setIsError(true)
                }
                else {
                    setStressSafetyPlans(response.data.response.triggers);
                    setRedflagSafetyPlans(response.data.response.flags)
                    setCopingSafetyPlans(response.data.response.strategies);
                    setMotivationSafetyPlans(response.data.response.motivations);
                    setDistractionSafetyPlans(response.data.response.places);
                    setEmergencyContacts(response.data.response.contacts);
                }
            } else {
                setIsError(true)
                toast.error('Error Safety Plan')
            }
        } catch (error) {
            if (axios.isCancel(error)) {
            }
            else {
                toast.error('Error Safety Plan');
                setIsError(true)
            }
        }
    }

    const updateSafetyPlan = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/safety-plan`,
                {
                    triggers: stressSafetyPlans,
                    flags: redflagSafetyPlans,
                    strategies: copingSafetyPlans,
                    motivations: motivationSafetyPlans,
                    places: distractionSafetyPlans,
                    contacts: emergencyContacts
                },
                { headers: { Authorization: `Bearer ${apiToken}` } });
            if (response.status === 200) {
                toast.success('Success');
                navigate('/home');
            } else {
                toast.error('Error updating safety plan');
            }
        } catch (error) {
            toast.error('Error updating safety plan');
        }
    }

    return (
        <div className='h-screen w-screen flex pr-4'>
            <div className='pl-16 flex flex-col w-full h-full pt-4'>
                {isLoading ? <div className='flex items-center justify-center h-screen'>
                    <FadeLoader color='gray' loading={isLoading} height={12} width={5} radius={5} />
                </div> :
                    isError ? <Error /> :
                        isSetup === 0 ?
                            <>
                                <div className='w-full h-full flex flex-col items-center text-[#29b6e8] font-bold relative'>
                                    <div className='ml-5 absolute top-0 left-0'>
                                        <div className='w-fit px-3 py-1 rounded-full flex border border-[#29b6e8]'>
                                            <img src={safetyplan} alt='safety plan' className='w-5 mr-2' />
                                            <span className='font-bold text-[#29b6e8] text-lg'>Safety Plan</span>
                                        </div>
                                    </div>
                                    {step === 0 ?
                                        <>
                                            <span className='mt-10 text-xl'>Your safety and well-being are</span>
                                            <span className='text-xl'>important to us</span>
                                            <img src={safetyplanmessage} alt='safety plan message' className='w-32 h-32 mt-20 mb-20' />
                                            <span>This Safety Plan is here to help you during challenging times.</span>
                                            <span className='mt-4'>Please take a moment to complete the following sections, so we can</span>
                                            <span>provide the right support when you need it most.</span>
                                            <span className='text-black mt-4'>Your information will be kept confidential.</span>
                                            <button className='bg-[#29b6e8] rounded-full px-32 py-2 text-white mt-20' onClick={() => handleNextClick()}>Next</button>
                                        </>
                                        : step === 1 ?
                                            <>
                                                <button className='absolute top-16 left-10 flex items-center justify-center' onClick={() => handleBackClick()}><img src={back} alt='back' className='w-3 mr-2' />Back</button>
                                                <span className='text-3xl mt-10'>Why?</span>
                                                <img src={why} alt='why' className='w-40 h-40 mt-8 mb-8' />
                                                <span>Safety Plans are special guides to support you during</span>
                                                <span>times when you feel really upset or are thinking of</span>
                                                <span>huring yourself.</span>
                                                <span className='text-black mt-6'>They help you know what you can do to feel better and</span>
                                                <span className='text-black'>stay safe.</span>
                                                <button className='bg-[#29b6e8] rounded-full px-32 py-2 text-white mt-20' onClick={() => handleNextClick()}>Next</button>
                                            </>
                                            : step === 2 ?
                                                <>
                                                    <button className='absolute top-16 left-10 flex items-center justify-center' onClick={() => handleBackClick()}><img src={back} alt='back' className='w-3 mr-2' />Back</button>
                                                    <span className='text-3xl mt-10'>Who?</span>
                                                    <img src={who} alt='who' className='w-40 h-40 mt-8 mb-8' />
                                                    <span>Anyone who feels very stressed or worried can use safety</span>
                                                    <span>plans to make the tough times a bit easier.</span>
                                                    <span className='mt-6'>Adults like parents, teachers, or mental health professionals</span>
                                                    <span>like counsellors can also join in on the team effort to help</span>
                                                    <span>when things get difficult.</span>
                                                    <button className='bg-[#29b6e8] rounded-full px-32 py-2 text-white mt-20' onClick={() => handleNextClick()}>Next</button>
                                                </>
                                                : step === 3 ?
                                                    <>
                                                        <button className='absolute top-16 left-10 flex items-center justify-center' onClick={() => handleBackClick()}><img src={back} alt='back' className='w-3 mr-2' />Back</button>
                                                        <span className='text-3xl mt-10'>When?</span>
                                                        <img src={when} alt='when' className='w-40 h-40 mt-8 mb-8' />
                                                        <span>You can use your safety plan on days that feel heavy,</span>
                                                        <span>like you're carrying a lot of worries or bad thoughts and</span>
                                                        <span>need a little extra help to feel better.</span>
                                                        <span className='text-black mt-6'>It's okay to use them whenever you need, day or night.</span>
                                                        <button className='bg-[#29b6e8] rounded-full px-32 py-2 text-white mt-20' onClick={() => handleNextClick()}>Next</button>
                                                    </>
                                                    : step === 4 ?
                                                        <>
                                                            <button className='absolute top-16 left-10 flex items-center justify-center' onClick={() => handleBackClick()}><img src={back} alt='back' className='w-3 mr-2' />Back</button>
                                                            <span className='text-3xl mt-10'>How?</span>
                                                            <img src={how} alt='how' className='w-40 h-40 mt-8 mb-8' />
                                                            <span>Using your safety plan is like having a personal compass to</span>
                                                            <span>navigate through hard times. When things feel</span>
                                                            <span>overwhelming, talk to someone you trust, follow the steps</span>
                                                            <span>you've outlined, and lean on activites or people that bring</span>
                                                            <span>comfort.</span>
                                                            <span className='mt-6'>It's your go-to guide for finding your way back to a better</span>
                                                            <span>state of mind.</span>
                                                            <button className='bg-[#29b6e8] rounded-full px-32 py-2 text-white mt-20' onClick={() => handleNextClick()}>Next</button>
                                                        </>
                                                        : step === 5 ?
                                                            <>
                                                                <div className='w-full flex justify-center gap-12 mt-2'>
                                                                    <img src={stressoractive} alt='stress active' className='w-8 h-8' />
                                                                    <img src={redflagsdefault} alt='red flag default' className='w-8 h-8' />
                                                                    <img src={copingdefault} alt='coping default' className='w-8 h-8' />
                                                                    <img src={motivationdefault} alt='motivation default' className='w-8 h-8' />
                                                                    <img src={distractiondefault} alt='default' className='w-8 h-8' />
                                                                    <img src={contactdefault} alt='default' className='w-8 h-8' />
                                                                </div>
                                                                <img src={stressdefault} alt='stress' className='w-14 h-14 mt-12' />
                                                                <span className='text-[#161d45] font-bold mt-6'>Stressors & Triggers</span>
                                                                <span>What contributes to my feelings of distress or thoughts of suicide</span>
                                                                <div className='w-full px-[23%]'><DragandDropList safetyPlan={stressSafetyPlans} setSafetyPlan={setStressSafetyPlans} /></div>
                                                                <div className='absolute bottom-4 flex justify-between w-full px-80 h-fit items-center'>
                                                                    <button className='text-white bg-[#29b6e8] rounded-full px-4 py-1' onClick={() => handleBackClick()}>Back</button>
                                                                    {/* <button className='text-white rounded-full bg-[#161d45] px-20 py-3' onClick={() => updateSafetyPlan()}>Save</button> */}
                                                                    <button className='text-white bg-[#29b6e8] rounded-full px-4 py-1' onClick={() => handleNextClick()}>Next</button>
                                                                </div>
                                                            </>
                                                            : step === 6 ?
                                                                <>
                                                                    <div className='w-full flex justify-center gap-12 mt-2'>
                                                                        <img src={stressdefault} alt='stress active' className='w-8 h-8' />
                                                                        <img src={redfagsactive} alt='red flag default' className='w-8 h-8' />
                                                                        <img src={copingdefault} alt='coping default' className='w-8 h-8' />
                                                                        <img src={motivationdefault} alt='motivation default' className='w-8 h-8' />
                                                                        <img src={distractiondefault} alt='default' className='w-8 h-8' />
                                                                        <img src={contactdefault} alt='default' className='w-8 h-8' />
                                                                    </div>
                                                                    <img src={redflag} alt='red flag' className='w-14 h-14 mt-12' />
                                                                    <span className='text-[#161d45] font-bold mt-6'>Red Flags</span>
                                                                    <span>What do you notice in you when you are struggling, first signs of worrisome</span>
                                                                    <div className='w-full px-[23%]'><DragandDropList safetyPlan={redflagSafetyPlans} setSafetyPlan={setRedflagSafetyPlans} /></div>
                                                                    <div className='absolute bottom-4 flex justify-between w-full px-80 h-fit items-center'>
                                                                        <button className='text-white bg-[#29b6e8] rounded-full px-4 py-1' onClick={() => handleBackClick()}>Back</button>
                                                                        {/* <button className='text-white rounded-full bg-[#161d45] px-20 py-3' onClick={() => updateSafetyPlan()}>Save</button> */}
                                                                        <button className='text-white bg-[#29b6e8] rounded-full px-4 py-1' onClick={() => handleNextClick()}>Next</button>
                                                                    </div>
                                                                </>
                                                                : step === 7 ?
                                                                    <>
                                                                        <div className='w-full flex justify-center gap-12 mt-2'>
                                                                            <img src={stressdefault} alt='stress active' className='w-8 h-8' />
                                                                            <img src={redflagsdefault} alt='red flag default' className='w-8 h-8' />
                                                                            <img src={copingactive} alt='coping default' className='w-8 h-8' />
                                                                            <img src={motivationdefault} alt='motivation default' className='w-8 h-8' />
                                                                            <img src={distractiondefault} alt='default' className='w-8 h-8' />
                                                                            <img src={contactdefault} alt='default' className='w-8 h-8' />
                                                                        </div>
                                                                        <img src={copingdefault} alt='red flag' className='w-14 h-14 mt-12' />
                                                                        <span className='text-[#161d45] font-bold mt-6'>Coping Strategies</span>
                                                                        <span>Things that worked for me in the past: people, places, activities</span>
                                                                        <div className='w-full px-[23%]'><DragandDropList safetyPlan={copingSafetyPlans} setSafetyPlan={setCopingSafetyPlans} /></div>
                                                                        <div className='absolute bottom-4 flex justify-between w-full px-80 h-fit items-center'>
                                                                            <button className='text-white bg-[#29b6e8] rounded-full px-4 py-1' onClick={() => handleBackClick()}>Back</button>
                                                                            {/* <button className='text-white rounded-full bg-[#161d45] px-20 py-3' onClick={() => updateSafetyPlan()}>Save</button> */}
                                                                            <button className='text-white bg-[#29b6e8] rounded-full px-4 py-1' onClick={() => handleNextClick()}>Next</button>
                                                                        </div>
                                                                    </>
                                                                    : step === 8 ?
                                                                        <>
                                                                            <div className='w-full flex justify-center gap-12 mt-2'>
                                                                                <img src={stressdefault} alt='stress active' className='w-8 h-8' />
                                                                                <img src={redflagsdefault} alt='red flag default' className='w-8 h-8' />
                                                                                <img src={copingdefault} alt='coping default' className='w-8 h-8' />
                                                                                <img src={motivationactive} alt='motivation default' className='w-8 h-8' />
                                                                                <img src={distractiondefault} alt='default' className='w-8 h-8' />
                                                                                <img src={contactdefault} alt='default' className='w-8 h-8' />
                                                                            </div>
                                                                            <img src={motivationdefault} alt='red flag' className='w-14 h-14 mt-12' />
                                                                            <span className='text-[#161d45] font-bold mt-6'>Motivations to Live...</span>
                                                                            <span>My Reasons worth living for</span>
                                                                            <div className='w-full px-[23%]'><DragandDropList safetyPlan={motivationSafetyPlans} setSafetyPlan={setMotivationSafetyPlans} /></div>
                                                                            <div className='absolute bottom-4 flex justify-between w-full px-80 h-fit items-center'>
                                                                                <button className='text-white bg-[#29b6e8] rounded-full px-4 py-1' onClick={() => handleBackClick()}>Back</button>
                                                                                {/* <button className='text-white rounded-full bg-[#161d45] px-20 py-3' onClick={() => updateSafetyPlan()}>Save</button> */}
                                                                                <button className='text-white bg-[#29b6e8] rounded-full px-4 py-1' onClick={() => handleNextClick()}>Next</button>
                                                                            </div>
                                                                        </>
                                                                        : step === 9 ?
                                                                            <>
                                                                                <div className='w-full flex justify-center gap-12 mt-2'>
                                                                                    <img src={stressdefault} alt='stress active' className='w-8 h-8' />
                                                                                    <img src={redflagsdefault} alt='red flag default' className='w-8 h-8' />
                                                                                    <img src={copingdefault} alt='coping default' className='w-8 h-8' />
                                                                                    <img src={motivationdefault} alt='motivation default' className='w-8 h-8' />
                                                                                    <img src={distractionactive} alt='default' className='w-8 h-8' />
                                                                                    <img src={contactdefault} alt='default' className='w-8 h-8' />
                                                                                </div>
                                                                                <img src={distractiondefault} alt='red flag' className='w-14 h-14 mt-12' />
                                                                                <span className='text-[#161d45] font-bold mt-6'>Place of Distraction...</span>
                                                                                <span>What are some social settings for a positive distraction?</span>
                                                                                <div className='w-full px-[23%]'><DragandDropList safetyPlan={distractionSafetyPlans} setSafetyPlan={setDistractionSafetyPlans} /></div>
                                                                                <div className='absolute bottom-4 flex justify-between w-full px-80 h-fit items-center'>
                                                                                    <button className='text-white bg-[#29b6e8] rounded-full px-4 py-1' onClick={() => handleBackClick()}>Back</button>
                                                                                    {/* <button className='text-white rounded-full bg-[#161d45] px-20 py-3' onClick={() => updateSafetyPlan()}>Save</button> */}
                                                                                    <button className='text-white bg-[#29b6e8] rounded-full px-4 py-1' onClick={() => handleNextClick()}>Next</button>
                                                                                </div>
                                                                            </>
                                                                            : step === 10 ?
                                                                                <>
                                                                                    <div className='w-full flex justify-center gap-12 mt-2'>
                                                                                        <img src={stressdefault} alt='stress active' className='w-8 h-8' />
                                                                                        <img src={redflagsdefault} alt='red flag default' className='w-8 h-8' />
                                                                                        <img src={copingdefault} alt='coping default' className='w-8 h-8' />
                                                                                        <img src={motivationdefault} alt='motivation default' className='w-8 h-8' />
                                                                                        <img src={distractiondefault} alt='default' className='w-8 h-8' />
                                                                                        <img src={contactactive} alt='default' className='w-8 h-8' />
                                                                                    </div>
                                                                                    <img src={contactdefault} alt='red flag' className='w-14 h-14 mt-12' />
                                                                                    <span className='text-[#161d45] font-bold mt-6'>Emergency Contacts</span>
                                                                                    <span>Who can I talk to when in crisis</span>
                                                                                    <div className='w-full px-[26%]'><EmergencyDragandDrop safetyPlan={emergencyContacts} setSafetyPlan={setEmergencyContacts} /></div>
                                                                                    <div className='absolute bottom-4 flex justify-between w-full px-80 h-fit items-center'>
                                                                                        <button className='text-white bg-[#29b6e8] rounded-full px-4 py-1' onClick={() => handleBackClick()}>Back</button>
                                                                                        <button className='text-white rounded-full bg-[#161d45] px-20 py-3' onClick={() => updateSafetyPlan()}>Save</button>
                                                                                        <button className='text-white bg-[#29b6e8] rounded-full px-4 py-1 bg-transparent cursor-default'>Next</button>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <></>
                                    }
                                    {tierLists?.safety_plan ? <></> : <Blur />}
                                </div>
                            </>
                            :
                            isActivated === 1 ?
                                <>
                                    <div className='w-full h-full flex font-bold'>
                                        <div className='w-[75%] h-full'>
                                            <div className='ml-5'>
                                                <div className='w-fit px-3 py-1 rounded-full flex border border-[#29b6e8]'>
                                                    <img src={safetyplan} alt='safety plan' className='w-5 mr-2' />
                                                    <span className='font-bold text-[#29b6e8] text-lg'>Safety Plan</span>
                                                </div>
                                            </div>
                                            {step === 0 ?
                                                <>
                                                    <div className='w-full flex flex-col items-center'>
                                                        <span>I will keep myself safe by...</span>
                                                        <div className='w-full flex justify-center mt-8 gap-12'>
                                                            <img src={distractionactive} alt='distraction' className='w-8 h-8' />
                                                            <img src={copingdefault} alt='coping' className='w-8 h-8' />
                                                            <img src={motivationdefault} alt='motivation' className='w-8 h-8' />
                                                            <img src={contactdefault} alt='contact' className='w-8 h-8' />
                                                        </div>
                                                        <img src={distractiondefault} alt='motivation' className='w-14 h-14 mt-8 mb-2' />
                                                        <span className='text-[#29b6e8] font-bold text-xl'>I will keep myself safe by...</span>
                                                        <span className='text-[#999999] text-sm'>I can choose more than one</span>
                                                        <ClickableList
                                                            safetyPlan={distractionSafetyPlans}
                                                            handleBackClick={undefined} handleNextClick={handleNextClick}
                                                            uniqueIdentifier={'distraction'}
                                                            selectedItems={distractionSelected}
                                                            setSelectedItems={setDistractionSelected}
                                                            handleIAmOkayClick={handleIAmOkayClick}
                                                        />
                                                    </div>
                                                </>
                                                : step === 1 ?
                                                    <>
                                                        <div className='w-full flex flex-col items-center'>
                                                            <span>My Coping Strategies</span>
                                                            <div className='w-full flex justify-center mt-8 gap-12'>
                                                                <img src={distractiondefault} alt='distraction' className='w-8 h-8' />
                                                                <img src={copingactive} alt='coping' className='w-8 h-8' />
                                                                <img src={motivationdefault} alt='motivation' className='w-8 h-8' />
                                                                <img src={contactdefault} alt='contact' className='w-8 h-8' />
                                                            </div>
                                                            <img src={copingdefault} alt='coping' className='w-14 h-14 mt-8 mb-2' />
                                                            <span className='text-[#29b6e8] font-bold text-xl'>I'll ease my mind by...</span>
                                                            <span className='text-[#999999] text-sm'>I can choose more than one</span>
                                                            <ClickableList
                                                                safetyPlan={copingSafetyPlans}
                                                                handleBackClick={handleBackClick}
                                                                handleNextClick={handleNextClick}
                                                                uniqueIdentifier={'coping'}
                                                                selectedItems={copingSelected}
                                                                setSelectedItems={setCopingSelected}
                                                                handleIAmOkayClick={handleIAmOkayClick}
                                                            />
                                                        </div>
                                                    </>
                                                    : step === 2 ?
                                                        <>
                                                            <div className='w-full flex flex-col items-center'>
                                                                <span>My Motivation to Live</span>
                                                                <div className='w-full flex justify-center mt-8 gap-12'>
                                                                    <img src={distractiondefault} alt='distraction' className='w-8 h-8' />
                                                                    <img src={contactdefault} alt='coping' className='w-8 h-8' />
                                                                    <img src={motivationactive} alt='motivation' className='w-8 h-8' />
                                                                    <img src={contactdefault} alt='contact' className='w-8 h-8' />
                                                                </div>
                                                                <img src={motivationdefault} alt='coping' className='w-14 h-14 mt-8 mb-2' />
                                                                <span className='text-[#29b6e8] font-bold text-xl'>My reasons for living are...</span>
                                                                <span className='text-[#999999] text-sm'>I can choose more than one</span>
                                                                <ClickableList
                                                                    safetyPlan={motivationSafetyPlans}
                                                                    handleBackClick={handleBackClick}
                                                                    handleNextClick={handleNextClick}
                                                                    uniqueIdentifier={'motivation'}
                                                                    selectedItems={motivationSelected}
                                                                    setSelectedItems={setMotivationSelected}
                                                                    handleIAmOkayClick={handleIAmOkayClick}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className='w-full flex flex-col items-center'>
                                                                <span>My Emergency Contact</span>
                                                                <div className='w-full flex justify-center mt-8 gap-12'>
                                                                    <img src={distractiondefault} alt='distraction' className='w-8 h-8' />
                                                                    <img src={contactdefault} alt='coping' className='w-8 h-8' />
                                                                    <img src={motivationdefault} alt='motivation' className='w-8 h-8' />
                                                                    <img src={contactactive} alt='contact' className='w-8 h-8' />
                                                                </div>
                                                                <img src={contactdefault} alt='contact' className='w-10 h-10 mt-8 mb-2' />
                                                                <span className='text-[#29b6e8] font-bold text-xl'>I'm still feeling upset and should talk to someone.</span>
                                                                <span className='text-[#29b6e8] font-bold text-xl'>I'll call...</span>
                                                                <ContactList safetyPlan={emergencyContacts} handleBackClick={handleBackClick} handleNextClick={undefined} handleIAmOkayClick={handleIAmOkayClick} />
                                                            </div>
                                                        </>
                                            }
                                        </div>
                                        <div className='w-[25%] p-4 h-fit rounded-2xl flex flex-col items-center border border-[#cccccc]'>
                                            <DoYouNeedHelpWidget />
                                        </div>
                                    </div>

                                    {/* <div className='w-[25%] h-fit rounded-2xl flex flex-col items-center border border-[#cccccc]'>
                                        <DoYouNeedHelpWidget />
                                    </div> */}
                                    {tierLists?.safety_plan ? <></> : <Blur />}
                                </>
                                : ""
                }
            </div>
        </div>
    )
}

export default SafetyPlan