import React from 'react'
import { SafetyContact } from '../../pages/INeedHelp'
import PhoneIcon from '../../asset/ineedhelp/PhoneIcon'

export default function SafetyContacts({ safetyContacts }: { safetyContacts: SafetyContact[] }) {
    return (
        <>
            <section className='max-h-[1000px] overflow-auto w-full'>
            {safetyContacts.length > 0 ? <h5 className='text-[#999999] font-bold mb-4 text-3xl'>Safety Contacts</h5> : ""}
                {safetyContacts.length > 0 ? safetyContacts.map((contact: SafetyContact) => (
                    <a key={contact.contact1} className='cursor-pointer flex items-center w-full p-8 border border-[#E9E9E9] rounded-xl mb-8' href={`tel:${contact.contact1}`}>
                        <div className='flex flex-col gap-4 w-full' key={contact.contact1}>
                            <h5 className='font-bold'>{contact.name}</h5>
                            <div className="flex items-center gap-4 w-fit bg-[#F2F2F2] text-[#999999] text-xs font-medium py-2 px-4 rounded-full mt-2">
                                <PhoneIcon color='#999999' />
                                <span>{contact.contact1}</span>
                            </div>
                        </div>
                        <p className='text-[#999999]'>{contact.relationship}</p>
                       
                    </a>
                )
                ) : ""}
            </section>


        </>
    )
}
