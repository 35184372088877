import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ForgetPassword from './pages/ForgetPassword';
import ChangePassword from './pages/ChangePassword';
import Gender from './pages/Gender';
import SideBar from './components/SideBar';
import Journal from './pages/Journal';
import LearningSpace from './pages/LearningSpace';
import Insights from './pages/Insights';
import Surveys from './pages/Surveys';
import SmartGoals from './pages/SmartGoals';
import Setting from './pages/Setting';
import SafetyPlan from './pages/SafetyPlan';
import { ToastContainer } from 'react-toastify';
import INeedHelp from './pages/INeedHelp';
import SafetyPlanRevised from './pages/SafetyPlanRevised';
import { useDisabledOrgStore } from './store/disableSection';
import Survey from './pages/Survey';
import Chat from './pages/Chat';
import Feeds from './pages/Feeds';
import axios from 'axios';
import DisabledSection from './pages/DisabledSection';
import { PulseLoader } from 'react-spinners';
import { decryptData, encryptData } from './helpers/encryptData';
import PinPopUp from './components/setting/PinPopUp';
import { useAuthStore } from './store/auth';
import { useInitApi } from './store/initApi';
import { usePrivacyStore } from './store/privacy';
import { useHomeStore } from './store/home';
import WellnessStream from './pages/WellnessStream';
import { isMobile } from 'react-device-detect';

const Unauthorized = () => {
  return (
    <div>
      <h1>Unauthorized</h1>
      <p>You do not have permission to view this page.</p>
    </div>
  );
};
function App() {
  const apiToken = sessionStorage.getItem('api_token');
  const auth = useAuthStore((state) => state.auth);
  const setAuth = useAuthStore((state) => state.setAuth);
  const { setGenderPage } = useAuthStore();
  const home = useHomeStore((state) => state.home);
  const setHome = useHomeStore((state) => state.setHome);
  const [loading, setLoading] = useState(true); // 1. Initialize loading state
  const disabledOrg = useDisabledOrgStore((state) => state.disabledOrg)
  const initApi = useInitApi((state) => state.initApi);
  const setInitApi = useInitApi((state) => state.setInitApi);
  const setIsPrivacy = usePrivacyStore(state => state.setIsPrivacy)
  const isShowLocked = usePrivacyStore(state => state.isShowLocked)
  const setIsShowLocked = usePrivacyStore(state => state.setIsShowLocked)
  const setAutoLockDuration = usePrivacyStore(state => state.setAutoLockDuration)

  useEffect(() => {
    if (apiToken) {

      // Combine both fetches into a single promise
      Promise.all([fetchApi(apiToken), fetchHome(apiToken)])
        .then(() => {
          // Both API calls are successful
        })
        .catch(error => {
          // Handle any errors from either API call
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          // Set loading to false after both fetches are done
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [apiToken]);


  const storedUserData = sessionStorage.getItem('user');
  const parsedUserData = storedUserData ? JSON.parse(storedUserData) : null;

  const ProtectedRoute = ({ children, redirectPath = '/login', hideFromWebView = false, requiredRole }: any) => {
    const { autoLockDuration, isPrivacy, setIsShowLocked } = usePrivacyStore()

    useEffect(() => {
      // Store the current time in localStorage when the component mounts
      if (!isPrivacy) return;
      const currentTime = new Date().getTime();
      localStorage.setItem('storedTime', currentTime.toString());
      const handleVisibilityChange = () => {
        const storedTime = localStorage.getItem('storedTime');
        const currentTime = new Date().getTime();
        if (document.visibilityState === 'visible' && storedTime) {
          const timeDifference = currentTime - parseInt(storedTime, 10);
          localStorage.setItem('storedTime', currentTime.toString());
          if (timeDifference >= autoLockDuration * 60 * 1000) {
            setIsShowLocked(true);
          }
        }
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);

      // Cleanup the event listener on component unmount
      return () => {
        document.removeEventListener("visibilitychange", handleVisibilityChange);
      };
    }, [isPrivacy, autoLockDuration]);

    if (isShowLocked && !hideFromWebView) {
      return <PinPopUp isOpenPin={isShowLocked} />
    }
    if (loading) { // 3. Conditionally render loading state
      return <div className='h-[100dvh] flex justify-center items-center'><PulseLoader /></div>;
    }
    if (!apiToken) {
      return <Navigate to={redirectPath} replace />;
    }
    if (requiredRole && parsedUserData.org_roles[0].role !== requiredRole) {
      return <Unauthorized />;
    }
    return children;
  };

  const LoginRoute = ({ auth, setAuth, apiToken, children }: any) => {
    if (apiToken) {
      try {
        setAuth(true);
        // return <Navigate to="/home" replace />
      } catch (error) {
        setAuth(false);
        return children;
      }
    }
    return children;
  };
  const fetchApi = async (apiToken: string) => {
    try {
      const fromLogin = localStorage.getItem('from_login')
      const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/init`, {}, { headers: { Authorization: `Bearer ${apiToken}` } });
      if (response.status === 200 && process.env.REACT_APP_ENCRYPT_SECRET) {
        setGenderPage(response.data.response.user.user_info.gender === '' ? true : false);
        setIsPrivacy(response.data.response.user.user_info.privacy_mode)
        setAutoLockDuration(response.data.response.user.user_info.auto_lock_duration)
        //Show lock if Privacy mode, already go thru on boarding and auto lock duration is 0
        if (response.data.response.user.user_info.privacy_mode === 1 && response.data.response.user.user_info.no_pin_record === 0 && response.data.response.user.user_info.auto_lock_duration === 0 && !fromLogin) {
          setIsShowLocked(true)
        }
        localStorage.removeItem('from_login')
        setInitApi(response.data.response)
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }
  const fetchHome = async (apiToken: string) => {
    try {
      setLoading(true)
      const response = await axios.get(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/home`, {
        headers: { Authorization: `Bearer ${apiToken}` }
      });
      if (response.status === 200) {
        setHome(response.data.response.disable_section);
      }
    } catch (error) {
      console.error(error);
      setLoading(false)
    }
  };
  useEffect(() => {
    const unsubscribe = useHomeStore.subscribe((state) => {
      if (!state.home.disable_module) {
        // setTimeout(() => {
        //   window.location.href = "/home";  // Use a direct location change to avoid state issues
        // }, 0);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    useDisabledOrgStore.subscribe((state) => {
      if (state.disabledOrg.login === 'deleted_org') {
        // setTimeout(() => {
        //   window.location.href = "/login";  // Use a direct location change to avoid state issues
        // }, 0);
      }
    });
  }, []);

  return (
    <Router>
      <ToastContainer position='top-center' />
      <Routes>
        <Route path="/" element={apiToken ? <Navigate to="/home" /> : <Navigate to="/login" />} />
        <Route path="/home" element={
          <ProtectedRoute >
            {home?.disable_module !== null && home.disable_module !== 'chat_ai' ? <DisabledSection disabledModule={home?.disable_module} /> : <><SideBar /><Home /></>}
          </ProtectedRoute>
        } />
        <Route path="/journal" element={
          <ProtectedRoute requiredRole="Student">
            {home?.disable_module !== null && home.disable_module !== 'chat_ai' ? <DisabledSection disabledModule={home?.disable_module} /> : <><SideBar /><Journal /></>}
          </ProtectedRoute>
        } />
        <Route path="/learning-space" element={
          <ProtectedRoute requiredRole="Student">
            {home?.disable_module !== null && home.disable_module !== 'chat_ai' ? <DisabledSection disabledModule={home?.disable_module} /> : <><SideBar /><LearningSpace /></>}
          </ProtectedRoute>
        } />
        <Route path="/insights" element={
          <ProtectedRoute requiredRole="Student">
            {home?.disable_module !== null && home.disable_module !== 'chat_ai' ? <DisabledSection disabledModule={home?.disable_module} /> : <><SideBar /><Insights /></>}
          </ProtectedRoute>
        } />
        <Route path="/surveys" element={
          <ProtectedRoute requiredRole="Student">
            {home?.disable_module !== null && home.disable_module !== 'chat_ai' ? <DisabledSection disabledModule={home?.disable_module} /> : <><SideBar /><Surveys /></>}
          </ProtectedRoute>
        } />
        <Route path="/smart-goals" element={
          <ProtectedRoute requiredRole="Student">
            {home?.disable_module !== null && home.disable_module !== 'chat_ai' ? <DisabledSection disabledModule={home?.disable_module} /> : <><SideBar /><SmartGoals /></>}
          </ProtectedRoute>
        } />
        <Route path="/i-need-help" element={
          <ProtectedRoute requiredRole="Student">
            {disabledOrg.login === 'deleted_org' ? <DisabledSection disabledOrg={disabledOrg} /> : <><SideBar /><INeedHelp /></>}
          </ProtectedRoute>
        } />
        <Route path="/safety-plan" element={
          <ProtectedRoute requiredRole="Student">
            {home?.disable_module === 'deleted_org' ? <DisabledSection disabledModule={home?.disable_module} /> : <><SideBar /><SafetyPlan /></>}
          </ProtectedRoute>
        } />
        <Route path="/safety-plan-revised" element={
          <ProtectedRoute requiredRole="Student">
            {home?.disable_module === 'deleted_org' ? <DisabledSection disabledModule={home?.disable_module} /> : <><SideBar /><SafetyPlanRevised /></>}
          </ProtectedRoute>
        } />
        <Route path="/setting" element={
          <ProtectedRoute requiredRole="Student">
            {home?.disable_module !== null && home.disable_module !== 'chat_ai' ? <DisabledSection disabledModule={home?.disable_module} /> : <><SideBar /><Setting /></>}
          </ProtectedRoute>
        } />
        <Route path="/feeds" element={
          <ProtectedRoute requiredRole="Student">
            {home?.disable_module !== null && home.disable_module !== 'chat_ai' ? <DisabledSection disabledModule={home?.disable_module} /> : <><SideBar /><Feeds /></>}
          </ProtectedRoute>
        } />
        <Route path="/survey/:page_url" element={
          <ProtectedRoute requiredRole="Student">
            {home?.disable_module !== null && home.disable_module !== 'chat_ai' ? <DisabledSection disabledModule={home?.disable_module} /> : <><SideBar /><Survey /></>}
          </ProtectedRoute>
        } />
        <Route path="/chat" element={
          <ProtectedRoute>
            {home?.disable_module === 'all_feature' ? <DisabledSection disabledModule={home?.disable_module} /> : <><SideBar /><Chat /></>}
          </ProtectedRoute>
        } />
        <Route path="/wellness-streams" element={
          <ProtectedRoute hideFromWebView={isMobile ? true : false}>
            {home?.disable_module === 'all_feature' ? <DisabledSection disabledModule={home?.disable_module} /> : isMobile ? <WellnessStream /> : <><SideBar /><WellnessStream /></>}
          </ProtectedRoute>
        } />
        <Route path="/login" element={
          <LoginRoute auth={auth} setAuth={setAuth} apiToken={apiToken}>
            {disabledOrg.login === 'deleted_org' ? <DisabledSection disabledOrg={disabledOrg} /> : <Login />}
          </LoginRoute>
        } />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/gender" element={<Gender />} />
        <Route path="*" element={<Navigate to="/" />} />
        {/* <Route path="/unauthorized" element={<Unauthorized />} /> */}
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;
