import React from 'react'
import { IconProps } from '../../types/icon'

const BrowserIcon = ({ className, onClick }: IconProps) => {
    return (
        <svg className={className} onClick={onClick} width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M6.75367 14.4909C7.49486 17.9334 9.07561 20.1113 10.5376 20.1113C11.9996 20.1113 13.5804 17.9334 14.3216 14.4909H6.75367Z" fill="#0E113C" />
            <path d="M14.7529 10.2755C14.7531 9.3356 14.6904 8.39676 14.5653 7.46525H6.50985C6.38476 8.39676 6.32209 9.3356 6.32226 10.2755C6.32209 11.2153 6.38476 12.1542 6.50985 13.0857H14.5653C14.6904 12.1542 14.7531 11.2153 14.7529 10.2755Z" fill="#0E113C" />
            <path d="M14.3216 6.0603C13.5804 2.61778 11.9996 0.439859 10.5376 0.439859C9.07561 0.439859 7.49486 2.61778 6.75367 6.0603H14.3216Z" fill="#0E113C" />
            <path d="M5.31483 14.491H0.888735C1.55377 16.0045 2.56424 17.3411 3.83909 18.3935C5.11394 19.4459 6.6177 20.1849 8.22974 20.5513C6.89699 19.3787 5.85159 17.2106 5.31483 14.491Z" fill="#0E113C" />
            <path d="M0.383546 13.0857H5.09067C4.97477 12.1534 4.91681 11.2149 4.91714 10.2755C4.91705 9.33603 4.97524 8.39749 5.09137 7.46525H0.384249C-0.128103 9.3037 -0.128805 11.2472 0.383546 13.0857Z" fill="#0E113C" />
            <path d="M8.22974 6.00815e-05C6.61744 0.366192 5.11341 1.10512 3.8383 2.15757C2.56319 3.21001 1.5525 4.54667 0.887329 6.0603H5.31343C5.85159 3.34071 6.89699 1.17263 8.22974 6.00815e-05Z" fill="#0E113C" />
            <path d="M15.7604 6.0603H20.1865C19.5213 4.54667 18.5107 3.21001 17.2356 2.15757C15.9604 1.10512 14.4564 0.366192 12.8441 6.00815e-05C14.1783 1.17263 15.2237 3.34071 15.7604 6.0603Z" fill="#0E113C" />
            <path d="M12.8455 20.5513C14.4578 20.1851 15.9618 19.4462 17.237 18.3938C18.5121 17.3413 19.5228 16.0047 20.1879 14.491H15.7618C15.2237 17.2106 14.1783 19.3787 12.8455 20.5513Z" fill="#0E113C" />
            <path d="M16.158 10.2755C16.1581 11.2149 16.0999 12.1535 15.9838 13.0857H20.6909C21.2033 11.2472 21.2033 9.3037 20.6909 7.46525H15.9838C16.0999 8.39749 16.1581 9.33603 16.158 10.2755Z" fill="#0E113C" />
        </svg >

    )
}

export default React.memo(BrowserIcon)