import React from 'react'
import { IconProps } from '../../types/icon'

function SafetyContactIcon({ className }: IconProps) {
    return (
        <svg className={className} width="100" height="100" viewBox="0 0 77 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.6249 15.8941C35.6249 7.11602 42.7409 0 51.519 0H66.0902C71.8307 0 76.4842 4.65358 76.4842 10.3941C76.4842 16.1345 71.8307 20.7881 66.0902 20.7881H35.6249V15.8941Z" fill="#FAA81E" />
            <path d="M40.9313 30.5167C40.9313 39.2947 33.8153 46.4108 25.0372 46.4108L10.466 46.4108C4.72549 46.4108 0.0719189 41.7572 0.0719195 36.0167C0.07192 30.2762 4.7255 25.6226 10.466 25.6226L40.9313 25.6227L40.9313 30.5167Z" fill="#A272B1" />
            <path d="M46.7683 44.0357C46.7683 36.2695 53.0641 29.9738 60.8303 29.9738L64.4982 29.9738C70.2387 29.9738 74.8923 34.6273 74.8923 40.3678C74.8923 46.1083 70.2387 50.7619 64.4982 50.7619H46.7683V44.0357Z" fill="#29B6E8" />
            <g clipPath="url(#clip0_2088_7810)">
                <path d="M54.1877 9.19996C54.1877 9.72765 54.1978 10.2563 54.1826 10.784C54.1765 11.0044 54.2475 11.0838 54.495 11.0791C55.1359 11.0651 55.7768 11.066 56.4178 11.0791C56.6581 11.0838 56.7362 11.0156 56.7413 10.7896C56.7464 10.5458 56.6328 10.5084 56.4107 10.5159C56.074 10.5281 55.7302 10.4823 55.3996 10.5271C54.856 10.6018 54.7617 10.387 54.785 9.94059C54.8246 9.18128 54.7911 8.41916 54.7992 7.65798C54.8012 7.46558 54.7931 7.34416 54.5142 7.3451C54.2445 7.3451 54.1715 7.41982 54.1806 7.66171C54.1998 8.17353 54.1867 8.68627 54.1867 9.19902L54.1877 9.19996Z" fill="white" />
                <path d="M62.1573 6.10417C62.0955 5.3075 61.4931 4.8209 60.6503 4.83584C59.8502 4.84985 59.2113 5.41584 59.1981 6.12192C59.195 6.30684 59.2397 6.48056 59.4699 6.50765C59.7092 6.53566 59.7863 6.38343 59.8066 6.18916C59.8431 5.83986 59.9992 5.55874 60.3806 5.43452C60.9475 5.24866 61.4282 5.53072 61.5458 6.12192C61.5833 6.31058 61.5925 6.52726 61.8714 6.50858C62.1786 6.48803 62.1584 6.25547 62.1573 6.10417Z" fill="white" />
                <path d="M50.3836 4.8346C49.5642 4.83273 48.9243 5.36603 48.8939 6.07584C48.8858 6.26824 48.8746 6.48118 49.1484 6.50827C49.4121 6.53349 49.4841 6.34763 49.5013 6.14309C49.5358 5.73401 49.765 5.47437 50.2072 5.40525C50.77 5.31746 51.1483 5.57804 51.2284 6.12254C51.2619 6.34576 51.2954 6.55683 51.5955 6.50266C51.8775 6.4513 51.8613 6.215 51.8359 6.0086C51.7497 5.30625 51.1666 4.83647 50.3836 4.8346Z" fill="white" />
                <path d="M54.1897 14.1725C54.7546 15.8583 56.6926 16.8091 58.5292 16.2991C60.3658 15.7892 61.4084 14.0091 60.8668 12.3167L54.1907 14.1725H54.1897Z" fill="white" />
            </g>
            <mask id="mask0_2088_7810" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="13" y="28" width="15" height="15">
                <path d="M27.5066 28.5234H13.8687V42.3227H27.5066V28.5234Z" fill="white" />
            </mask>
            <g mask="url(#mask0_2088_7810)">
                <path d="M25.4908 31.8274C24.3615 31.055 23.4768 30.1036 22.8788 28.9426C22.7902 28.9245 22.7446 28.9443 22.7784 29.0346C23.0723 30.4142 23.9232 31.4552 25.184 32.2472C25.2598 32.2949 25.3319 32.3458 25.405 32.3959C26.076 32.709 26.7334 33.0532 27.5066 33.1428C26.8228 32.7156 26.0988 32.3425 25.4908 31.8282" fill="#A272B1" />
                <path d="M22.1247 37.1286C22.1758 37.509 22.0571 37.7957 21.6025 37.896C21.3788 37.9453 21.2747 38.1129 21.3185 38.2739C21.3724 38.4695 21.6052 38.384 21.744 38.3561C22.6122 38.1803 22.9144 37.2929 22.4817 36.7761C22.1329 36.7046 22.0681 36.8665 22.1247 37.1278" fill="white" />
                <path d="M22.1247 37.1283C22.1567 36.9394 22.258 36.8071 22.4817 36.7767C22.4872 36.7578 22.489 36.7381 22.4881 36.7192C22.3722 36.628 22.2635 36.5285 22.1394 36.4464C21.9924 36.3494 21.8207 36.2919 21.6947 36.4636C21.5934 36.6025 21.6564 36.7298 21.8052 36.821C21.9212 36.8917 22.0408 36.9615 22.0517 37.1053C22.0764 37.1119 22.1001 37.1193 22.1238 37.1283" fill="white" />
                <path d="M13.9974 39.2227C16.8852 38.9105 19.1548 37.7142 20.5955 35.4005C21.9293 33.2601 22.184 30.9242 21.9978 28.5242C21.901 29.5316 21.7969 30.5381 21.5714 31.5306C21.1834 33.2388 20.527 34.8451 19.3137 36.2303C17.9232 37.8185 16.0854 38.7445 13.9043 39.1512C13.8915 39.2211 13.8979 39.2704 13.9965 39.2227" fill="#A272B1" />
                <path d="M18.8736 29.8751C17.7561 31.3475 16.4469 32.6769 15.103 33.9816C15.5166 34.1813 15.9265 34.1312 16.3355 33.9693C16.1977 33.8387 15.9996 33.9652 15.8115 33.8346C16.9281 32.5972 18.0738 31.3606 18.9603 29.9581C19.0042 29.8685 18.9548 29.8579 18.8727 29.8743" fill="#A272B1" />
            </g>
            <path d="M16.7463 33.2115C15.5768 32.8697 14.6282 33.5771 14.2886 34.7586C14.7113 34.1769 15.207 33.877 15.606 33.9312C15.4362 34.688 16.0944 35.5852 16.9061 35.3937C17.0677 35.3559 17.2174 35.2689 17.3306 35.1415C17.6227 34.8128 17.6684 34.3223 17.4885 33.9025C17.4548 33.8244 17.41 33.7193 17.3516 33.6576C17.1964 33.4383 16.9818 33.2805 16.7454 33.2115" fill="white" />
            <path d="M25.5701 33.4835C24.4006 33.1417 23.4521 33.8491 23.1124 35.0307C23.5351 34.4489 24.0309 34.149 24.4298 34.2033C24.26 34.96 24.9183 35.8572 25.7299 35.6658C25.8915 35.628 26.0412 35.5409 26.1544 35.4135C26.4466 35.0849 26.4922 34.5944 26.3124 34.1745C26.2786 34.0965 26.2339 33.9913 26.1754 33.9297C26.0202 33.7103 25.8057 33.5525 25.5692 33.4835" fill="white" />
            <path d="M16.8129 43.1564C17.9015 43.1564 18.784 42.3622 18.784 41.3825C18.784 40.4028 17.9015 39.6086 16.8129 39.6086C15.7243 39.6086 14.8418 40.4028 14.8418 41.3825C14.8418 42.3622 15.7243 43.1564 16.8129 43.1564Z" fill="white" />
            <path d="M14.8747 30.7155C14.8054 30.7155 14.736 30.6941 14.6784 30.6514C14.5497 30.5536 14.5333 30.3811 14.6419 30.266C14.8364 30.059 15.2609 29.6958 15.9237 29.589C17.0248 29.4123 17.8182 30.1058 17.9049 30.1855C18.0227 30.2931 18.0208 30.4665 17.9012 30.5725C17.7816 30.6785 17.589 30.6768 17.4712 30.5692C17.4073 30.5109 16.823 30.0006 16.0296 30.128C15.5549 30.2044 15.2463 30.4681 15.1057 30.6193C15.0455 30.6834 14.9596 30.7163 14.8729 30.7163L14.8747 30.7155Z" fill="white" />
            <path d="M23.6849 30.7155C23.6155 30.7155 23.5461 30.6941 23.4886 30.6514C23.3599 30.5536 23.3435 30.3811 23.4521 30.266C23.6466 30.059 24.0711 29.6958 24.7339 29.589C25.8322 29.4123 26.6283 30.1058 26.7151 30.1855C26.8328 30.2931 26.831 30.4665 26.7114 30.5725C26.5918 30.6785 26.3992 30.6768 26.2814 30.5692C26.2175 30.5109 25.6332 29.9998 24.8398 30.128C24.3651 30.2044 24.0574 30.4681 23.9159 30.6193C23.8556 30.6834 23.7698 30.7163 23.6831 30.7163L23.6849 30.7155Z" fill="white" />
            <g clipPath="url(#clip1_2088_7810)">
                <path d="M62.3566 47.0119C63.7915 47.2188 65.1736 46.6427 65.8829 45.5583C66.051 45.3007 66.1638 45.0368 65.8186 44.8443C65.4942 44.6623 65.2971 44.8659 65.1285 45.1017C64.4595 46.0364 63.5162 46.3859 62.3179 46.186C62.2193 46.1698 62.1211 46.1512 62.0208 46.1361C61.7484 46.0985 61.5516 46.1906 61.4893 46.4384C61.4199 46.7122 61.5752 46.8785 61.8602 46.9422C62.0216 46.9786 62.1913 46.9904 62.3564 47.0132L62.3566 47.0119Z" fill="white" />
                <path d="M58.0906 40.6281C58.0052 41.142 57.9294 41.6572 57.8303 42.1692C57.7892 42.3828 57.845 42.47 58.0894 42.4986C58.7218 42.5717 59.3517 42.6599 59.9798 42.7593C60.2156 42.797 60.3037 42.7412 60.3445 42.5211C60.3893 42.285 60.2841 42.232 60.0639 42.2106C59.7303 42.1774 59.4007 42.0858 59.0687 42.0849C58.5228 42.084 58.464 41.8617 58.5595 41.4313C58.7207 40.6983 58.8123 39.9519 58.9423 39.212C58.9747 39.0255 58.986 38.9065 58.7136 38.8689C58.4481 38.8323 58.3645 38.8951 58.3336 39.1319C58.2701 39.6322 58.1735 40.1292 58.0906 40.6281Z" fill="white" />
                <path d="M64.8583 37.5546C66.1231 37.4047 67.1217 38.154 67.4543 39.2436C67.0156 38.674 66.492 38.3441 66.062 38.3399C66.2244 39.0316 65.4917 39.7396 64.6254 39.465C64.4529 39.4105 64.2944 39.3142 64.1763 39.1864C63.8705 38.8583 63.8368 38.4177 64.0409 38.0702C64.0798 38.005 64.1294 37.9183 64.195 37.8709C64.3674 37.6972 64.6028 37.5848 64.8583 37.5546Z" fill="white" />
                <path d="M55.0982 36.0727C56.363 35.9227 57.3616 36.672 57.6942 37.7616C57.2555 37.192 56.7319 36.8621 56.3019 36.858C56.4642 37.5496 55.7316 38.2576 54.8652 37.983C54.6927 37.9285 54.5342 37.8322 54.4161 37.7044C54.1104 37.3763 54.0766 36.9357 54.2807 36.5882C54.3197 36.523 54.3693 36.4363 54.4349 36.3889C54.6073 36.2152 54.8427 36.1028 55.0982 36.0727Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_2088_7810">
                    <rect width="13.266" height="11.6027" fill="white" transform="matrix(-1 0 0 1 62.1569 4.83459)" />
                </clipPath>
                <clipPath id="clip1_2088_7810">
                    <rect width="13.701" height="10.033" fill="white" transform="matrix(-0.990623 -0.136623 -0.163911 0.986475 67.8224 37.6468)" />
                </clipPath>
            </defs>
        </svg>

    )
}
export default React.memo(SafetyContactIcon)