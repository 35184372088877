import React from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, LabelList, ResponsiveContainer } from 'recharts';

const WellnessChartV2 = ({ chartData, color, customStyle }: any) => {
    return <div className={`w-full h-[400px] ${customStyle ? customStyle : `rounded-b-lg`} bg-[#f0f0f0] mb-8 border border-[#cccccc] `}>
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                layout="vertical"
                data={chartData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                barSize={80}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" domain={[0, 10]} tickLine={false} ticks={[0, 2, 4, 6, 8, 10]} xAxisId="top" orientation="top" />
                <XAxis type="number" domain={[0, 10]} tickLine={false} ticks={[0, 2, 4, 6, 8, 10]} xAxisId="bottom" />
                <YAxis
                    type="category"
                    dataKey="name"
                    tickLine={false}
                    tick={{ fontSize: 14, fontWeight: 'bold' }}
                    orientation="right"
                />
                <Tooltip />
                <Bar dataKey="value" fill={color} xAxisId="bottom">
                    <LabelList dataKey="value" position="insideRight" fill="black" />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    </div>
}

export default WellnessChartV2