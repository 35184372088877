import { Button, Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import React from 'react';
import { Domains, SurveyProps, WellnessChartProps } from '../types/insights';
import WellnessChartV2 from './WellnessChartV2';

interface WellnessRevampModalProps {
    isOpen: boolean;
    close: () => void;
    header: string;
    domain: WellnessChartProps;
    detail: {
        header: string;
        color: string;
        icon: any;
    };
    wellnessSurvey?: SurveyProps
    children: React.ReactNode;
}

export default function WellnessRevampModal({ isOpen, close, header, domain, detail,wellnessSurvey, children }: WellnessRevampModalProps) {
    const domainsArr = domain.questions;
    const keys = Object.keys(domainsArr);

    const transformQuestions = (questions: Domains) => {
        return Object.keys(questions).map((key) => {
            const question = questions[key];
            return [
                { name: 'This Week', value: question.value[2] },
                { name: 'Last Week', value: question.value[1] },
                { name: '2 Weeks Ago', value: question.value[0] },
            ];
        });
    };

    const transformedData = transformQuestions(domainsArr);
    
    const handleDoSurvey = () => {
        if(wellnessSurvey)
        window.open(`/survey/${wellnessSurvey.page_url}`, "_blank")
    }
    return (
        <Transition appear show={isOpen}>
            <Dialog as="div" className="relative z-[99999] focus:outline-none lexend-deca-normal" onClose={close}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <TransitionChild
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 transform-[scale(95%)]"
                            enterTo="opacity-100 transform-[scale(100%)]"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 transform-[scale(100%)]"
                            leaveTo="opacity-0 transform-[scale(95%)]"
                        >
                            <DialogPanel className="w-full max-w-[70%] rounded-xl bg-white p-6 border border-[#cccccc]">
                                <DialogTitle as="h3" className={`text-xl font-bold mb-8 text-[${detail.color}]`}>
                                    {header}
                                </DialogTitle>
                                {children}
                                <p className="mt-2 text-black font-bold">
                                    About "{header}"
                                </p>
                                <p>{domain.description}</p>
                                <div className='w-full flex justify-between items-center mt-8'>
                                    <h5 className='font-bold'>Key Questions</h5>
                                    <div className='ml-auto p-4 rounded-xl border border-[#cccccc] bg-[#0c133b] text-white cursor-pointer' onClick={handleDoSurvey}>
                                        <p>Take Survey</p>
                                    </div>
                                </div>
                                <div className='xl:flex gap-8'>
                                    {keys.map((key, index) => (
                                        <div key={key} className='flex-1 mt-8'>
                                            <p className='text-[#727577] mb-4'>{domainsArr[key].title}</p>
                                            <WellnessChartV2 chartData={transformedData[index]} domain={domain} color={detail.color} customStyle="rounded-lg"/>
                                        </div>
                                    ))}
                                </div>

                                <div className="mt-4">
                                    <Button
                                        className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
                                        onClick={close}
                                    >
                                        Close
                                    </Button>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}
