import React, { ChangeEvent, useEffect } from 'react';
import Switch, { switchClasses } from '@mui/joy/Switch';
import { Theme, Typography } from '@mui/joy';
import { usePrivacyStore } from '../../store/privacy';

interface PreferencesProps {
  handlePrivacySwitch: (value: boolean) => void
}
export default function PrivacySwitcher({ handlePrivacySwitch }: PreferencesProps) {
  const [checked, setChecked] = React.useState<boolean>(false);
  const isPrivacy = usePrivacyStore(state => state.isPrivacy)
  const handlePrivacyOn = async (event: ChangeEvent<HTMLInputElement>) => {
    handlePrivacySwitch(event.target.checked)
  }
  useEffect(() => {
    if (isPrivacy === 1) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [])

  useEffect(() => {
    usePrivacyStore.subscribe(state => {
      if(state.isPrivacyModeCompleted && !state.isPrivacy){
        setChecked(false)
      }
      if(state.isPrivacyModeCompleted && state.isPrivacy){
        setChecked(true)
      }
    })
  },[checked])
  return (
    <Switch
      checked={checked}
      onChange={handlePrivacyOn}
      sx={(theme: Theme) => ({
        '--Switch-thumbShadow': '0 3px 7px 0 rgba(0 0 0 / 0.12)',
        '--Switch-thumbSize': '27px',
        '--Switch-trackWidth': '64px',
        '--Switch-trackHeight': '31px',
        '--Switch-trackBackground': theme.vars.palette.background.level3,
        [`& .${switchClasses.thumb}`]: {
          transition: 'width 0.2s, left 0.2s',
        },
        '&:hover': {
          '--Switch-trackBackground': theme.vars.palette.background.level3,
        },
        '&:active': {
          '--Switch-thumbWidth': '32px',
        },
        [`&.${switchClasses.checked}`]: {
          '--Switch-trackBackground': 'rgb(48 209 88)',
          '&:hover': {
            '--Switch-trackBackground': 'rgb(48 209 88)',
          },
        },
      })}
      slotProps={{
        track: {
          children: (
            <React.Fragment>
              <Typography component="span" level="inherit" sx={{ ml: '10px' }}>
                On
              </Typography>
              <Typography component="span" level="inherit" sx={{ mr: '8px' }}>
                Off
              </Typography>
            </React.Fragment>
          ),
        },
      }}
    />
  );
}
