import React, { useEffect, useState } from 'react';
import SidePanel from '../components/SidePanel';
import { useNavigate } from 'react-router-dom';
import axios, { CancelTokenSource } from 'axios';
import survey from '../asset/sidebar/active/survey.svg';
import search from '../asset/Search.svg';
import { FadeLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Blur from '../components/Blur';
import { TierLists } from '../types/tier';
import cryptojs from 'crypto-js'
import { track } from '@amplitude/analytics-browser';
interface UserData {
  uuid: string;
  current_org_id: number;
  gamification: {
    credits: number;
    avatar: string;
    current_xp: number;
    xp_cap: number;
    current_level: number;
  };
  user_info: {
    firstname: string;
    lastname: string;
  };
  org_roles: {
    role: string;
  }[];
}

interface Survey {
  url: string;
  image: string;
  title: string;
  page_url: string;
}

function Surveys() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserData | null>(null);
  const [surveyData, setSurveyData] = useState<Survey[]>([]);
  const [filteredData, setFilteredData] = useState<Survey[]>([]);
  const [surveyLoading, setSurveyLoading] = useState<boolean>(true);
  const [tierLists, setTierLists] = useState<TierLists>()
  const apiToken = sessionStorage.getItem('api_token');

  useEffect(() => {
    const storedUserData = sessionStorage.getItem('user');

    if (!apiToken || !storedUserData) {
      navigate('/login');
      return;
    }

    setUserData(JSON.parse(storedUserData));
  }, [apiToken, navigate]);

  useEffect(() => {
    track('screenView', { screen_name: 'SurveysScreen' })
    let source = axios.CancelToken.source();
    fetchSurvey(source);
    const tier = localStorage.getItem('tier')
    if (tier && process.env.REACT_APP_TIER_SECRET) {
      try {
        const decryptData = cryptojs.AES.decrypt(tier, process.env.REACT_APP_TIER_SECRET)
        const tierData = JSON.parse(decryptData.toString(cryptojs.enc.Utf8))
        setTierLists(tierData)
      } catch (error) {
      }
    }
    return () => {
      source.cancel();
    }
  }, []);

  const fetchSurvey = async (source: CancelTokenSource) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/get-surveys`, { headers: { Authorization: `Bearer ${apiToken}` }, cancelToken: source.token });
      if (response.status === 200) {
        setFilteredData(response.data);
        setSurveyData(response.data);
        setSurveyLoading(false);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
      }
      else {
        toast.error('Error Survey');
      }
    }
  };
  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value.trim().toLowerCase();
    // If the search input is empty, display all surveys
    if (!searchTerm) {
      setFilteredData(surveyData);
      return;
    }

    // Filter the surveyData array based on the title
    const filteredSurveys = surveyData.filter(survey =>
      survey.title.toLowerCase().includes(searchTerm)
    );

    // Update the surveyData state with the filtered data
    setFilteredData(filteredSurveys);
  };


  return (
    <div className='relative h-screen w-screen'>
      <div className='pl-20 flex w-full h-full p-5'>
        <div className='bg-white h-fit w-[75%] px-10 flex flex-col items-center'>
          <div className='flex flex-col mt-8 w-full h-full'>
            <div className='w-fit px-3 py-1 bg-[#fde0de] rounded-full flex'>
              <img src={survey} alt='surveys' className='w-5 mr-2' />
              <span className='font-bold text-[#ed3426] text-lg'>Surveys</span>
            </div>
            <div className='xl:hidden flex justify-center mt-8'>
              <div className='relative w-full md:w-[60%]'>
                <img src={search} alt='search' className='absolute top-4 sm:top-3 md:top-4 left-4 w-5 ml-2' />
                <input type="search" name="search-surveys" className='w-full py-4 sm:py-2 md:py-4 pr-4 pl-12 rounded-full bg-[#F1F0F0]' onInput={handleFilter} />
              </div>
            </div>
            <div className='w-full h-full flex justify-center items-center'>
              <FadeLoader color='gray' loading={surveyLoading} height={12} width={5} radius={100} />
            </div>
            <div className='flex gap-4 flex-wrap w-full max-w-screen-lg mt-5 items-center'>
              {filteredData.map((survey, index) => (
                <button
                  key={index}
                  className='bg-white w-full h-fit flex flex-col items-center md:w-[calc(50%-1rem)] lg:w-[calc(33.33%-1rem)] md:mt-8 xl:mt-0'
                  onClick={() => navigate(`/survey/${survey.page_url}`)}
                >
                  <img src={survey.image} alt={survey.title} className='object-cover w-full h-44 rounded-2xl border border-[#cccccc]' />
                  <span className='font-bold text-[#161d46] mt-2 h-12'>{survey.title}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
        <aside className='bg-white h-auto sm:w-[30%] w-[25%]'>
          {userData && tierLists ? <SidePanel userData={userData} tierLists={tierLists} /> : ""}
        </aside>
      </div>
      {tierLists?.surveys ? <></> : <Blur />}
    </div>
  );
}

export default Surveys;
