import React from 'react'
import { IconProps } from '../types/icon'

const SearchIcon: React.FC<IconProps> = ({className}) => {
    return (
        <svg className={className} width="20" height="18" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0239 14.4868C12.7515 14.3565 12.5144 14.1627 12.3325 13.9217C11.6262 13.191 10.9199 12.4578 10.2136 11.7224C9.90995 11.4067 9.60557 11.0917 9.30753 10.7711C9.29569 10.7527 9.27988 10.7372 9.26131 10.7256C9.24275 10.714 9.22184 10.7066 9.2001 10.704C9.17836 10.7014 9.15637 10.7038 9.13561 10.7107C9.11486 10.7177 9.09589 10.729 9.08009 10.7442C8.53066 11.1202 7.91671 11.3923 7.26905 11.5467C5.99324 11.8699 4.64513 11.7453 3.45024 11.1935C2.5127 10.7724 1.70388 10.11 1.10622 9.27387C0.490517 8.42277 0.116254 7.4211 0.0230108 6.37479C-0.0702322 5.32848 0.121011 4.27641 0.576512 3.32984C1.08173 2.27218 1.89397 1.39123 2.90725 0.802015C3.62527 0.372953 4.42992 0.109441 5.26265 0.0307341C6.40452 -0.0867648 7.55574 0.134251 8.57297 0.66614C9.5902 1.19803 10.4285 2.01728 10.9835 3.02206C11.5386 4.02684 11.7858 5.17277 11.6945 6.31703C11.6032 7.46129 11.1774 8.55347 10.4701 9.4575C10.3789 9.57545 10.3697 9.63688 10.4819 9.75271C11.5498 10.8503 12.6129 11.952 13.6709 13.058C13.8414 13.2026 13.9553 13.4027 13.9928 13.6231C14.0186 13.8124 13.9745 14.0045 13.8688 14.1636C13.7631 14.3228 13.6031 14.4379 13.4186 14.4875L13.0239 14.4868ZM5.84599 10.1538C6.69596 10.1566 7.52763 9.90729 8.23587 9.43733C8.94411 8.96737 9.49709 8.29796 9.82482 7.5137C10.1525 6.72944 10.2404 5.86554 10.0771 5.03139C9.9138 4.19724 9.50673 3.43019 8.90749 2.82738C8.30824 2.22458 7.54372 1.81308 6.71055 1.64486C5.87738 1.47663 5.01305 1.55939 4.22686 1.88246C3.44068 2.20554 2.76789 2.75441 2.29375 3.45986C1.8196 4.16531 1.5654 4.99563 1.56316 5.8456C1.56093 6.4101 1.67009 6.96939 1.8844 7.49163C2.0987 8.01386 2.41396 8.48879 2.81207 8.889C3.21018 9.28921 3.68335 9.60687 4.20445 9.82392C4.72555 10.041 5.28425 10.153 5.84874 10.1538H5.84599Z" fill="#999999" />
        </svg>
    )
}

export default React.memo(SearchIcon)
