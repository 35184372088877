import React from 'react'

const RightArrowIcon = () => {
    return (
        <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2946_1432)">
                <path d="M6.55958 5.51942L2.4445 1.40416C2.3312 1.29112 2.26886 1.13998 2.26886 0.978829C2.26886 0.817585 2.3312 0.666536 2.4445 0.553317L2.80509 0.19291C2.91822 0.0795121 3.06945 0.0170893 3.2306 0.0170894C3.39176 0.0170894 3.5428 0.0795122 3.65602 0.192911L8.55567 5.09247C8.66934 5.20605 8.73158 5.35781 8.73114 5.51915C8.73158 5.6812 8.66943 5.83278 8.55567 5.94645L3.66058 10.8413C3.54736 10.9547 3.39632 11.0171 3.23507 11.0171C3.07392 11.0171 2.92287 10.9547 2.80956 10.8413L2.44906 10.4809C2.21449 10.2463 2.21449 9.86441 2.44906 9.62993L6.55958 5.51942Z" fill="#F6A41E" />
            </g>
            <defs>
                <clipPath id="clip0_2946_1432">
                    <rect width="11" height="11" fill="white" transform="matrix(-1 -8.73322e-08 -8.73322e-08 1 11 0.0170898)" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default React.memo(RightArrowIcon)
