import React, { ChangeEvent } from 'react';

interface Props {
  type: string;
  placeholder: string;
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  pattern?: string;
}

const TextInput: React.FC<Props> = ({ type, placeholder, name, onChange, pattern }) => {
  return (
    <input
      className='bg-[#f2f3f2] pl-3 outline-none h-[40px] w-full rounded-[5px]'
      type={type}
      placeholder={placeholder}
      name={name}
      onChange={onChange}
      pattern={pattern}
      required
    />
  );
}

export default TextInput;
