const checkIsYoutube = (url: string) => {
    const parsedUrl = new URL(url);
    const hostname = parsedUrl.hostname;

    // Check if the hostname matches one of YouTube's valid URLs
    const validYouTubeDomains = [
        'www.youtube.com',
        'youtube.com',
        'youtu.be',
        'm.youtube.com',
    ];

    return validYouTubeDomains.includes(hostname);
}

export default checkIsYoutube