import { create } from "zustand"
import { InitProps } from "../types/init"

type InitApiProps = {
    initApi: InitProps | null,
    setInitApi: (value: InitProps) => void
}

export const useInitApi = create<InitApiProps>((set) => ({
    initApi: null,
    setInitApi: (newValue) => set(() => ({ initApi: newValue })),
}))