import React, { useRef, useState } from 'react';

interface DragandDropListProps {
  safetyPlan: string[];
  setSafetyPlan: React.Dispatch<React.SetStateAction<string[]>>;
}

const DragandDropList: React.FC<DragandDropListProps> = ({ safetyPlan, setSafetyPlan }) => {
  const dragSafetyPlan = useRef<number>(0);
  const draggedOverSafetyPlan = useRef<number>(0);
  const [newSafetyPlanItem, setNewSafetyPlanItem] = useState<string>('');

  const handleSort = () => {
    const safetyPlanClone = [...safetyPlan];
    const temp = safetyPlanClone[dragSafetyPlan.current];
    safetyPlanClone[dragSafetyPlan.current] = safetyPlanClone[draggedOverSafetyPlan.current];
    safetyPlanClone[draggedOverSafetyPlan.current] = temp;
    setSafetyPlan(safetyPlanClone);
  };

  const handleDeleteSafetyPlanItem = (itemToDelete: string) => {
    const updatedSafetyPlan = safetyPlan.filter(item => item !== itemToDelete);
    setSafetyPlan(updatedSafetyPlan);
  };

  const handleAddSafetyPlanItem = () => {
    if (!newSafetyPlanItem.trim()) return;
    const newSafetyPlanItemObj = newSafetyPlanItem.trim();
    setSafetyPlan([...safetyPlan, newSafetyPlanItemObj]);
    setNewSafetyPlanItem('');
  };

  return (
    <main className="flex flex-col items-center space-y-4 mt-4 w-full">
      <div className="flex w-full">
        <input
          className="w-full h-8 outline-none rounded-md bg-[#f2f2f2] pl-4 py-5"
          placeholder="Add your own"
          value={newSafetyPlanItem}
          onChange={(e) => setNewSafetyPlanItem(e.target.value)}
        />
        <button className="ml-2 bg-[#29b6e8] text-white px-3 py-1 rounded" onClick={handleAddSafetyPlanItem}>Add</button>
      </div>
      <div className="overflow-y-auto max-h-72 w-full space-y-3">
        {safetyPlan.map((item, index) => (
          <div
            key={index}
            className="relative border rounded p-2 w-full flex justify-between text-sm"
            draggable
            onDragStart={() => (dragSafetyPlan.current = index)}
            onDragEnter={() => (draggedOverSafetyPlan.current = index)}
            onDragEnd={handleSort}
            onDragOver={(e) => e.preventDefault()}
          >
            <p>{item}</p>
            <button
              onClick={() => handleDeleteSafetyPlanItem(item)}
              className="text-[#29b6e8]"
            >
              X
            </button>
          </div>
        ))}
      </div>
    </main>
  );
}

export default DragandDropList;
