import { create } from "zustand"
import { DisableSection } from "./disableSection"

type HomeProps = {
    home: DisableSection,
    setHome: (value: {}) => void
}

export const useHomeStore = create<HomeProps>((set) => ({
    home: {},
    setHome: (newValue) => set(() => ({ home: newValue })),
}))