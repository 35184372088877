import React, { useState } from 'react'
import RightArrowIcon from '../../asset/home/RightArrowIcon';
import ActivateSafetyPlanIcon from '../../asset/ineedhelp/ActivateSafetyPlanIcon.svg'
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function FloatingActivateSafety({ isActivated, onSetAcivate }: any) {
    const apiToken = sessionStorage.getItem('api_token');
    const navigate = useNavigate();
    const [disable, setDisable] = useState(false);
    const handleActivateSafetyPlan = async () => {
        try {
            setDisable(true)
            const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/safety-plan-activation`,
                {
                    activate_status: 1
                },
                { headers: { Authorization: `Bearer ${apiToken}` } });
            if (response.status === 200) {
                onSetAcivate(1)

                toast.success(response.data.message);
                navigate("/safety-plan")

            } else {
            }
        } catch (error) {
            setDisable(false)
        }

    }
    const handleDeactivate = () => {
        setDisable(true)
        navigate('/safety-plan')
    }
    return (
        <div className={`fixed bottom-8 right-8 flex items-center gap-4 py-4 px-8 bg-[#FBD5D1] rounded-2xl z-[999] ${disable ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={!disable && isActivated ? handleDeactivate : !disable && !isActivated ? handleActivateSafetyPlan : () => null}>
            <img src={ActivateSafetyPlanIcon} alt="" />
            <p className='text-[#ED3425] font-bold'>{!isActivated ? 'Activate Safety Plan' : 'Deactivate Safety Plan'}</p>
            <RightArrowIcon color='#ED3425' />
        </div>
    )
}
export default React.memo(FloatingActivateSafety)