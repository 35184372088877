import React from 'react'
import { IconProps } from '../../types/icon'

export default function RightArrowIcon({ className, color }: IconProps) {
    return (
        <svg className={className} width="12" height="40" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 2L5.12767 5.3731L10.11 9.45254C11.0873 10.2527 11.0873 11.7473 10.11 12.5475L5.12767 16.6269L0.999998 20" stroke={color ?? 'black'} strokeWidth="3" strokeLinejoin="round" />
        </svg>
    )
}
