import React from 'react'
import { motion } from 'framer-motion'
import { AnimationProps } from '../types/animation'


export default function FadeIn({ className, children }: AnimationProps) {
    return (
        <motion.div
            className={className}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >
            {children}
        </motion.div>
    )
}
