import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TextInput from '../components/TextInput';
import background from '../asset/BG.png';
import zo from '../asset/login/zo.svg';
import zoalalogo from '../asset/login/zoala-logo-standard.svg';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useGoogleLogin } from '@react-oauth/google';
import { useDisabledOrgStore } from '../store/disableSection'
import Loader from '../components/icons/Loader';
import { useAuthStore } from '../store/auth';
import * as amplitude from '@amplitude/analytics-browser';

interface FormData {
  email: string;
  password: string;
}

function Login() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    email: '',
    password: '',
  });
  const [isDisabled, setIsDisabled] = useState(false)
  const setDisabledOrg = useDisabledOrgStore((state) => state.setDisabledOrg)
  const { genderPage, setGenderPage } = useAuthStore()

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        setIsDisabled(true)
        const res = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/login/google`, {
          token: tokenResponse.access_token
        })
        if (res.status === 200 && res.data.response.login === 'yes') {
          sessionStorage.setItem('api_token', res.data.response.user.api_token);
          sessionStorage.setItem('user', JSON.stringify(res.data.response.user));
          localStorage.setItem('zoala_version', process.env.REACT_APP_VERSION ?? '1.0')
          localStorage.setItem('from_login', "1")
          setAuth(res.data.session)
          setIsDisabled(false)
          amplitude.setUserId(res.data.response.user.uuid);
          amplitude.setGroup('orgId', res.data.response.user.current_org_id ?? '');
          amplitude.setGroup('orgRole', res.data.response.user.org_roles[0].role ?? '');
          amplitude.track('login', { method: 'Google' })

          if (res.data.response.user.user_info.gender === '') {
            setGenderPage(true)
            navigate('/gender');
          } else {
            navigate('/home');
          }
        }
        else if (res.data.response.login === 'deleted_org') {
          setDisabledOrg(res.data.response)
        }

      } catch (error) {
        toast.error('Login Failed!')
        setIsDisabled(false)

      }
    },
    onError: () => {
      toast.error('Login Failed!')
      setIsDisabled(false)

    },
  });

  const handleInputChange = (name: string, value: string) => {
    // Update the form data state
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsDisabled(true)
      const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/login`, {
        email: formData.email,
        password: formData.password,
      });
      if (response.status === 200 && response.data.response.login === 'yes') {
        setAuth(response.data.session)
        sessionStorage.setItem('api_token', response.data.response.user.api_token);
        sessionStorage.setItem('user', JSON.stringify(response.data.response.user));
        localStorage.setItem('zoala_version', process.env.REACT_APP_VERSION ?? '1.0')
        localStorage.setItem('from_login', "1")
        amplitude.setUserId(response.data.response.user.uuid);
        amplitude.setGroup('orgId', response.data.response.user.current_org_id ?? '');
        amplitude.setGroup('orgRole', response.data.response.user.org_roles[0].role ?? '');
        amplitude.track('login', { method: 'Email' })
        if (response.data.response.user.user_info.gender === '') {
          setGenderPage(true)
          navigate('/gender');
        } else {
          navigate('/home');
        }
      }
      else if (response.data.response.login === 'deleted_org') {
        setDisabledOrg(response.data.response)
      }
      setIsDisabled(false)
    } catch (error) {
      setIsDisabled(false)
      toast.error('Login Failed!')
    }
  }
  const setAuth = useAuthStore((state) => state.setAuth);
  useEffect(() => {
    const unsubscribe = useAuthStore.subscribe((state) => {
      if (state.auth && state.genderPage) {
        navigate('/gender');
      } else {
        navigate('/home');
      }
    })
    return () => unsubscribe()
  }, [])

  useEffect(() => {
    amplitude.track('screenView', { screen_name: 'LoginScreen' })
  }, [])

  return (
    <>
      <div className='flex justify-center items-center flex-col h-screen overflow-hidden'
        style={{
          backgroundImage: `url(${background})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}>
        <img src={zoalalogo} className='w-40 h-40' alt='zoala logo' />
        <div className='flex justify-center items-center h-screen w-screen pb-10 relative'>
          <div className='bg-white  w-96 rounded-3xl shadow-2xl shadow-black z-10 p-8 mb-24'>
            <div className='w-full h-full flex flex-col items-center'>
              <img src={zo} alt='zoala' className='mb-5 w-14 h-14' />
              <span className='font-bold text-2xl'>Mental Wellness,<span className='text-[#00ace5]'>F</span><span className='text-[#fba91f]'>i</span><span className='text-[#ee3224]'>r</span><span className='text-[#a271b0]'>s</span><span className='text-[#add038]'>t</span>.</span>
              <form onSubmit={handleLogin} className='flex flex-col w-full h-full mt-10 gap-3'>
                <TextInput type={'email'} placeholder={'Email'} name={'email'} onChange={(e) => handleInputChange('email', e.target.value)}></TextInput>
                <TextInput type={'password'} placeholder={'Password'} name={'password'} onChange={(e) => handleInputChange('password', e.target.value)}></TextInput>
                <Link to="/forget-password" className='text-[#00ace5] text-sm'>Forgot password?</Link>
                <span className='text-sm text-[#999999] text-center'>By continuing, you agree to Zoala's <a className='underline' href='https://www.zoala.co/privacy-policy-terms-of-use-for-counsellors-educators/'>Condition of use</a> and <a className='underline' href='https://www.zoala.co/privacy-policy-terms-of-use-for-counsellors-educators/'>Privacy Policy</a></span>
                <div className='flex items-center flex-col w-full mt-6 gap-2'>
                  <button disabled={isDisabled} type='submit' className='flex justify-center bg-[#00ace5] border border-slate-200 hover:border-slate-400 hover:shadow  w-full rounded-full px-4 py-2 text-white'>{isDisabled ? <Loader /> : 'Log in'}</button>
                  <button onClick={() => googleLogin()} disabled={isDisabled} type='button' className="w-full rounded-full px-4 py-2 border flex justify-center gap-2 border-slate-200  text-slate-700  hover:border-slate-400 hover:text-slate-900  hover:shadow transition duration-150">
                    <img className="w-6 h-6" src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo" />
                    <span>Login with Google</span>
                  </button>
                  <span>Not a member? <Link to="/signup" className='text-[#00ace5] underline'>Register</Link></span>
                </div>
              </form>
            </div>
          </div>
          <div className='bg-gradient-to-t from-[#04ACE8] to-[#5BC7EE] absolute h-[85%] mt-20 rounded-3xl top-0 w-[460px]'></div>
        </div>
      </div>
      {/* } */}
    </>
  )
}

export default Login;
