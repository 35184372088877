import React from 'react'
import { IconProps } from '../../types/icon'

export default function RightArrowIcon({ color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" viewBox="0 0 6 9" fill="none">
            <path d="M0.632716 1.01077e-05C0.716339 -0.000481027 0.799236 0.0158039 0.876652 0.0479323C0.954068 0.0800606 1.02448 0.1274 1.08385 0.187235L4.89628 4.06085C5.01463 4.18181 5.08106 4.34544 5.08106 4.516C5.08106 4.68656 5.01463 4.85019 4.89628 4.97115L1.08385 8.84476C0.962298 8.95053 0.805941 9.00579 0.646024 8.99952C0.486108 8.99324 0.334412 8.92589 0.22125 8.81091C0.108088 8.69593 0.0417943 8.5418 0.0356174 8.37932C0.0294405 8.21683 0.0838352 8.05797 0.187932 7.93446L3.54923 4.51923L0.187932 1.10399C0.0987074 1.01408 0.0377271 0.899299 0.0127013 0.774173C-0.0123241 0.649046 -0.000270567 0.51919 0.0473373 0.401024C0.0949457 0.282857 0.17597 0.181689 0.280166 0.110309C0.384362 0.0389295 0.50705 0.000545108 0.632716 1.01077e-05Z" fill={color} />
        </svg>
    )
}
