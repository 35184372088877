import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TextInput from '../components/TextInput';
import background from '../asset/BG.png';
import zoalalogo from '../asset/login/zoala-logo-standard.svg';
import greentick from '../asset/login/passwordgreentick.svg';
import greycross from '../asset/login/passwordgreycross.svg';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Loader from '../components/icons/Loader';
import { track } from '@amplitude/analytics-browser';

interface UserData {
  current_org_id: number;
  login_email: string;
  gamification: {
    credits: number;
    avatar: string;
    current_xp: number;
    xp_cap: number;
    current_level: number;
  };
  user_info: {
    firstname: string;
    lastname: string;
    birth_date: string;
    gender: string
    profile_pic: string;
  };
  org_roles: {
    class_name: string;
    org_name: string;
    role: string;
  }[];
}

function ChangePassword() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserData | null>(null);
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  const [step, setStep] = useState<number>(1);
  const [otp, setOtp] = useState<string[]>(['', '', '', '', '', '']);
  const [isLengthValid, setIsLengthValid] = useState<boolean>(false);
  const [isSpecialCharValid, setIsSpecialCharValid] = useState<boolean>(false);
  const [isNumberValid, setIsNumberValid] = useState<boolean>(false);
  const [isUppercaseValid, setIsUppercaseValid] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState(false)
  const [formData, setFormData] = useState({
    password: '',
    confirmpassword: '',
  });
  const apiToken = sessionStorage.getItem('api_token');

  useEffect(() => {
    const storedUserData = sessionStorage.getItem('user');
    if (!apiToken || !storedUserData) {
      navigate('/login');
      return;
    }

    setUserData(JSON.parse(storedUserData));
  }, [apiToken, navigate]);

  useEffect(() => {
    track('screenView', { screen_name: 'ChangePasswordScreen' })
  }, [])

  const checkPasswordRequirements = (password: string) => {
    // Check password requirements and update state accordingly
    setIsLengthValid(password.length >= 8);
    setIsSpecialCharValid(/[$&+,:;=?@#|'<>.^*()%!-]/.test(password));
    setIsNumberValid(/\d/.test(password));
    setIsUppercaseValid(/[A-Z]/.test(password));

    setFormData({
      ...formData,
      'password': password,
    });
  };

  const checkPasswordSame = (password: string) => {
    if (password === formData.password) {
      setFormData({
        ...formData,
        'confirmpassword': password,
      });
    }
  }

  const getPasswordStatusIcon = (isValid: boolean) => {
    return isValid ? greentick : greycross;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;

    // Check if the input is numeric and update the corresponding digit
    if (/^[0-9]*$/.test(value) && index >= 0 && index < 6) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      nextInput && (nextInput as HTMLInputElement).focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    // Handle backspace to move to the previous input when the current input is empty
    if (e.key === 'Backspace' && index > 0 && !otp[index]) {
      const newOtp = [...otp];
      newOtp[index - 1] = '';
      setOtp(newOtp);

      const nextInput = document.getElementById(`otp-input-${index - 1}`);
      nextInput && (nextInput as HTMLInputElement).focus();
    }
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsDisabled(true)
      const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/forgot-password/verify-code`, {
        email: userData?.login_email,
        code: otp.map(entry => parseInt(entry, 10)).join('')
      });
      if (response.status === 200) {
        setStep(step + 1);
      }
      setIsDisabled(false)
    } catch (error) {
      setIsDisabled(false)
      toast.error('Unexpected error, Please try again');
    }
  };

  const handleBackClick = () => {
    // Handle logic for transitioning to the next step or view
    setStep(step - 1);
  };

  const handleBackToSetting = () => {
    navigate(-1);
  }

  const changePasswordSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formData.confirmpassword === '') {
      toast.warning('Password is not the same');
      return;
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/forgot-password/change-password`, {
        email: userData?.login_email,
        code: otp.map(entry => parseInt(entry, 10)).join(''),
        new_password: formData.confirmpassword
      });
      if (response.status === 200) {
        toast.success('Password change successfully');
        navigate('/setting');
      } else {
        toast.error('Unexpected error, Please try again');
      }
    } catch (error) {
      toast.error('Unexpected error, Please try again');
    }
  }
  useEffect(() => {
    if (step === 2)
      document.getElementById(`otp-input-0`)?.focus();
  }, [step])

  return (
    <>
      <div className='flex justify-center items-center flex-col h-screen overflow-hidden'
        style={{
          backgroundImage: `url(${background})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}>
        <img src={zoalalogo} className='w-40 h-40' alt='zoala logo' />
        <div className='flex justify-center items-center h-screen w-screen pb-10 relative'>
          <div className='bg-white h-[87%] w-96 rounded-3xl shadow-2xl shadow-black z-10 p-8 mb-24'>
            <div className='w-full h-full flex flex-col items-center relative'>
              <span className='font-bold text-[#00ace5]'>Password Change</span>
              {step === 1 ? (
                <>
                  <div className='flex flex-col items-center w-full px-10 text-center text-[#6d6d6d] gap-5 mt-4'>
                    <span className=''>Password reset code will be sent to your email</span>
                    <span className='text-[#00ade7] font-bold'>{userData?.login_email}</span>
                    <span>If you can’t find it, check your spam/junk folder too.</span>
                    <span>The code will last for 5 minutes.</span>
                  </div>
                  <button className='flex justify-center text-[#00ade7]' onClick={handleBackClick}>Resend code</button>
                  <form onSubmit={handleFormSubmit} className='w-full'>
                    <div className='rounded bg-[#f2f2f2] w-full flex p-2 mt-4 justify-center items-center gap-2'>
                      {otp.map((digit, index) => (
                        <input
                          id={`otp-input-${index}`}
                          className='w-[16%] bg-transparent text-center font-bold text-2xl outline-none border-b-2 border-[#00ace5] text-[#00ace5]'
                          key={index}
                          type="text"
                          maxLength={1}
                          value={digit}
                          onChange={(e) => handleChange(e, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          required
                        />
                      ))}
                    </div>
                    <button disabled={isDisabled} type='submit' className='flex justify-center bg-[#00ace5] w-full rounded-full p-3 text-white mt-8'>{isDisabled ? <Loader /> : 'Continue'}</button>
                    <div className='w-full flex justify-center items-center mt-4'>
                      <button className='text-[#00ade7]' onClick={handleBackToSetting}>Back</button>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <form onSubmit={changePasswordSubmit} className='mt-5 w-full h-full flex flex-col items-center gap-3 relative'>
                    <TextInput type={'password'} placeholder={'New Password'} name={'password'} pattern={passwordRegex.source} onChange={(e) => checkPasswordRequirements(e.target.value)}></TextInput>
                    <TextInput type={'password'} placeholder={'Confirm Password'} name={'confirm password'} onChange={(e) => checkPasswordSame(e.target.value)}></TextInput>
                    <div className='flex flex-col text-xs gap-1 mt-2 w-full px-12'>
                      <div className='flex w-full gap-2'>
                        <img src={getPasswordStatusIcon(isLengthValid)} alt='password checker' />
                        <span className={`${isLengthValid ? 'text-[#7d7d7d]' : 'text-red-600'} italic`}>Must be at least 8 characters long</span>
                      </div>
                      <div className='flex w-full gap-2'>
                        <img src={getPasswordStatusIcon(isSpecialCharValid)} alt='password checker' />
                        <span className={`${isSpecialCharValid ? 'text-[#7d7d7d]' : 'text-red-600'} italic`}>Include at least 1 special character</span>
                      </div>
                      <div className='flex w-full gap-2'>
                        <img src={getPasswordStatusIcon(isNumberValid)} alt='password checker' />
                        <span className={`${isNumberValid ? 'text-[#7d7d7d]' : 'text-red-600'} italic`}>Contain at least 1 number</span>
                      </div>
                      <div className='flex w-full gap-2'>
                        <img src={getPasswordStatusIcon(isUppercaseValid)} alt='password checker' />
                        <span className={`${isUppercaseValid ? 'text-[#7d7d7d]' : 'text-red-600'} italic`}>Have at least 1 uppercase letter</span>
                      </div>
                    </div>
                    <button type='submit' className='bg-[#00ace5] w-full rounded-full p-3 text-white absolute bottom-0'>Done</button>
                  </form>
                </>
              )}
            </div>
          </div>
          <div className='bg-gradient-to-t from-[#04ACE8] to-[#5BC7EE] absolute h-[85%] mt-20 rounded-3xl top-0 w-[460px]'></div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
