import React, { useEffect, useState } from 'react';
import { safetyplan, stressdefault, stressoractive, redfagsactive, redflagsdefault, contactactive, contactdefault, copingactive, copingdefault, motivationactive, motivationdefault, distractionactive, distractiondefault, redflag } from '../asset/index';
import DoYouNeedHelpWidget from '../components/DoYouNeedHelpWidget';
import DragandDropList from '../components/DragandDropList';
import { useNavigate } from 'react-router-dom';
import axios, { CancelTokenSource } from 'axios';
import { toast } from 'react-toastify';
import SafetyPlansButton from '../components/SafetyPlansButton';
import SafetyPlansNav from '../components/SafetyPlansNav';
import EmergencyDragandDrop from '../components/EmergencyDragandDrop';
import Blur from '../components/Blur';
import { TierLists } from '../types/tier';
import cryptojs from 'crypto-js'
import { track } from '@amplitude/analytics-browser';
interface SafetyPlan {
    triggers: string[];
    flags: string[];
    strategies: string[];
    motivations: string[];
    places: string[];
    contacts: EmergencyContact[];
}

interface EmergencyContact {
    id: number;
    name: string;
    relationship: string;
    contact1: string;
    contact2: string;
}

function SafetyPlanRevised() {
    const navigate = useNavigate();
    const [step, setStep] = useState<number>(0);
    const [stressSafetyPlans, setStressSafetyPlans] = useState<string[]>([]);
    const [redflagSafetyPlans, setRedflagSafetyPlans] = useState<string[]>([]);
    const [copingSafetyPlans, setCopingSafetyPlans] = useState<string[]>([]);
    const [motivationSafetyPlans, setMotivationSafetyPlans] = useState<string[]>([]);
    const [distractionSafetyPlans, setDistractionSafetyPlans] = useState<string[]>([]);
    const [emergencyContacts, setEmergencyContacts] = useState<EmergencyContact[]>([]);
    const [tierLists, setTierLists] = useState<TierLists>();
    const apiToken: string | null = sessionStorage.getItem('api_token');

    useEffect(() => {
        // Check if the API token is present in session storage
        const storedUserData = sessionStorage.getItem('user');

        if (apiToken && storedUserData) {
        } else {
            // If the API token or user data is not present, redirect to the login page
            navigate('/login');
        }
    }, [navigate]);

    useEffect(() => {
        track('screenView', { screen_name: 'SafetyPlanRevisedScreen' })
        let source = axios.CancelToken.source();
        fetchSafetyPlan(source);
        const tier = localStorage.getItem('tier')
        if (tier && process.env.REACT_APP_TIER_SECRET) {
            try {
                const decryptData = cryptojs.AES.decrypt(tier, process.env.REACT_APP_TIER_SECRET)
                const tierData = JSON.parse(decryptData.toString(cryptojs.enc.Utf8))
                setTierLists(tierData)
            } catch (error) {
            }
        }
        return () => {
            source.cancel();
        }
    }, []);

    const handleNextClick = () => {
        setStep(step + 1);
    }

    const navigateClick = (step: number) => {
        setStep(step);
    }

    const handleBackClick = () => {
        setStep(step - 1);
    }

    const fetchSafetyPlan = async (source: CancelTokenSource) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/safety-plan`, { headers: { Authorization: `Bearer ${apiToken}` }, cancelToken: source.token });
            if (response.status === 200) {
                const responseData = response.data.response;
                setStressSafetyPlans(responseData.triggers);
                setRedflagSafetyPlans(responseData.flags);
                setCopingSafetyPlans(responseData.strategies);
                setMotivationSafetyPlans(responseData.motivations);
                setDistractionSafetyPlans(responseData.places);
                setEmergencyContacts(responseData.contacts);
            }
        } catch (error) {
            if (axios.isCancel(error)) {
            }
            else {
                toast.error('Error Safety Plan');
            }
        }
    }

    const updateSafetyPlan = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/safety-plan`,
                {
                    triggers: stressSafetyPlans,
                    flags: redflagSafetyPlans,
                    strategies: copingSafetyPlans,
                    motivations: motivationSafetyPlans,
                    places: distractionSafetyPlans,
                    contacts: emergencyContacts
                },
                { headers: { Authorization: `Bearer ${apiToken}` } });
            if (response.status === 200) {
                toast.success(response.data.message);
            } else {
                toast.error('Error updating safety plan');
            }
        } catch (error) {
            toast.error('Error updating safety plan');
        }
    }

    return (
        <div className='relative h-screen w-screen flex pr-4'>
            <div className='pl-16 flex flex-col w-[75%] h-full pt-4'>
                <div className='w-full h-full flex flex-col items-center text-[#29b6e8] font-bold relative'>
                    <div className='ml-5 absolute top-0 left-0'>
                        <div className='w-fit px-3 py-1 rounded-full flex border border-[#29b6e8]'>
                            <img src={safetyplan} alt='safety plan' className='w-5 mr-2' />
                            <span className='font-bold text-[#29b6e8] text-lg'>Safety Plan</span>
                        </div>
                    </div>
                    {step === 0 ?
                        <>
                            <div className='w-full mt-10'>
                                <SafetyPlansNav stressor={stressoractive}
                                    redflags={redflagsdefault}
                                    coping={copingdefault}
                                    motivation={motivationdefault}
                                    distraction={distractiondefault}
                                    contact={contactdefault}
                                    navigateClick={navigateClick}
                                />
                            </div>
                            <img src={stressdefault} alt='stress' className='w-14 h-14 mt-12' />
                            <span className='text-[#161d45] font-bold mt-6'>Stressors & Triggers</span>
                            <span>What contributes to my feelings of distress or thoughts of suicide</span>
                            <div className='w-full px-[20%]'>
                                <DragandDropList safetyPlan={stressSafetyPlans} setSafetyPlan={setStressSafetyPlans} />
                            </div>
                            <SafetyPlansButton handleBackClick={undefined} updateSafetyPlan={updateSafetyPlan} handleNextClick={handleNextClick} />
                        </>
                        : step === 1 ?
                            <>
                                <div className='w-full mt-10'>
                                    <SafetyPlansNav stressor={stressdefault}
                                        redflags={redfagsactive}
                                        coping={copingdefault}
                                        motivation={motivationdefault}
                                        distraction={distractiondefault}
                                        contact={contactdefault}
                                        navigateClick={navigateClick}
                                    />
                                </div>
                                <img src={redflag} alt='stress' className='w-14 h-14 mt-12' />
                                <span className='text-[#161d45] font-bold mt-6'>Red Flags</span>
                                <span>What do you notice in you when you are struggling, first signs of worrisome</span>
                                <div className='w-full px-[20%]'>
                                    <DragandDropList safetyPlan={redflagSafetyPlans} setSafetyPlan={setRedflagSafetyPlans} />
                                </div>
                                <SafetyPlansButton handleBackClick={handleBackClick} updateSafetyPlan={updateSafetyPlan} handleNextClick={handleNextClick} />
                            </>
                            : step === 2 ?
                                <>
                                    <div className='w-full mt-10'>
                                        <SafetyPlansNav stressor={stressdefault}
                                            redflags={redflagsdefault}
                                            coping={copingactive}
                                            motivation={motivationdefault}
                                            distraction={distractiondefault}
                                            contact={contactdefault}
                                            navigateClick={navigateClick}
                                        />
                                    </div>
                                    <img src={copingdefault} alt='stress' className='w-14 h-14 mt-12' />
                                    <span className='text-[#161d45] font-bold mt-6'>Coping Strategies</span>
                                    <span>Things that worked for me in the past: people, places, activities</span>
                                    <div className='w-full px-[20%]'>
                                        <DragandDropList safetyPlan={copingSafetyPlans} setSafetyPlan={setCopingSafetyPlans} />
                                    </div>
                                    <SafetyPlansButton handleBackClick={handleBackClick} updateSafetyPlan={updateSafetyPlan} handleNextClick={handleNextClick} />
                                </>
                                : step === 3 ?
                                    <>
                                        <div className='w-full mt-10'>
                                            <SafetyPlansNav stressor={stressdefault}
                                                redflags={redflagsdefault}
                                                coping={copingdefault}
                                                motivation={motivationactive}
                                                distraction={distractiondefault}
                                                contact={contactdefault}
                                                navigateClick={navigateClick}
                                            />
                                        </div>
                                        <img src={motivationdefault} alt='stress' className='w-14 h-14 mt-12' />
                                        <span className='text-[#161d45] font-bold mt-6'>Motivations to Live...</span>
                                        <span>My Reasons worth living for</span>
                                        <div className='w-full px-[20%]'>
                                            <DragandDropList safetyPlan={motivationSafetyPlans} setSafetyPlan={setMotivationSafetyPlans} />
                                        </div>
                                        <SafetyPlansButton handleBackClick={handleBackClick} updateSafetyPlan={updateSafetyPlan} handleNextClick={handleNextClick} />
                                    </>
                                    : step === 4 ?
                                        <>
                                            <div className='w-full mt-10'>
                                                <SafetyPlansNav stressor={stressdefault}
                                                    redflags={redflagsdefault}
                                                    coping={copingdefault}
                                                    motivation={motivationdefault}
                                                    distraction={distractionactive}
                                                    contact={contactdefault}
                                                    navigateClick={navigateClick}
                                                />
                                            </div>
                                            <img src={distractiondefault} alt='stress' className='w-14 h-14 mt-12' />
                                            <span className='text-[#161d45] font-bold mt-6'>Place of Distraction...</span>
                                            <span>What are some social settings for a positive distraction</span>
                                            <div className='w-full px-[20%]'>
                                                <DragandDropList safetyPlan={distractionSafetyPlans} setSafetyPlan={setDistractionSafetyPlans} />
                                            </div>
                                            <SafetyPlansButton handleBackClick={handleBackClick} updateSafetyPlan={updateSafetyPlan} handleNextClick={handleNextClick} />
                                        </>
                                        :
                                        <>
                                            <div className='w-full mt-10'>
                                                <SafetyPlansNav stressor={stressdefault}
                                                    redflags={redflagsdefault}
                                                    coping={copingdefault}
                                                    motivation={motivationdefault}
                                                    distraction={distractiondefault}
                                                    contact={contactactive}
                                                    navigateClick={navigateClick}
                                                />
                                            </div>
                                            <img src={contactdefault} alt='stress' className='w-14 h-14 mt-12' />
                                            <span className='text-[#161d45] font-bold mt-6'>Emergency Contacts</span>
                                            <span>Who can I talk to when in crisis</span>
                                            <div className='w-full px-[20%]'>
                                                <EmergencyDragandDrop safetyPlan={emergencyContacts} setSafetyPlan={setEmergencyContacts} />
                                            </div>
                                            <SafetyPlansButton handleBackClick={handleBackClick} updateSafetyPlan={updateSafetyPlan} handleNextClick={undefined} />
                                        </>
                    }
                </div>
            </div>
            <div className='w-[25%] h-fit rounded-2xl flex flex-col items-center border border-[#cccccc] mt-4'>
                <DoYouNeedHelpWidget />
            </div>
            {tierLists?.safety_plan ? <></> : <Blur />}
        </div>
    );
}

export default SafetyPlanRevised;
