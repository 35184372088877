import React, { useEffect, useState, memo } from 'react';
import background from '../asset/BG.png';
import { Link, useNavigate } from 'react-router-dom';
import { DisabledOrg, useDisabledOrgStore } from '../store/disableSection';
import logout from '../asset/sidebar/default/logout.svg';
import DangerIcon from '../asset/ineedhelp/DangerIcon';
import customer_support from '../asset/disabled_module/customer_support.svg';
import retry from '../asset/disabled_module/retry.svg';
import axios from 'axios';
import { useAuthStore } from '../store/auth';
import { useHomeStore } from '../store/home';

interface DisabledModule {
    disabledModule?: undefined | 'all_feature' | 'chat_ai' | 'deleted_org';
    disabledOrg?: DisabledOrg
}

const DisabledSection: React.FC<DisabledModule> = ({ disabledModule, disabledOrg }) => {
    const navigate = useNavigate();
    const setAuth = useAuthStore((state) => state.setAuth);
    const home = useHomeStore((state) => state.home);
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [countdown, setCountdown] = useState(30);
    const setHome = useHomeStore((state) => state.setHome);
    const setDisabledOrg = useDisabledOrgStore((state) => state.setDisabledOrg);
    const handleLogout = (): void => {
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('api_token');
        localStorage.removeItem('tier');
        localStorage.removeItem('zoala_version');
        localStorage.removeItem('isActivated');
        localStorage.removeItem('storedTime');
        localStorage.removeItem('shownBoardingPrivacy');

        setAuth(false);
        setDisabledOrg({})
        navigate('/login');
    };

    const handleClick = async () => {
        setDisabledBtn(true);
        const apiToken = sessionStorage.getItem('api_token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/home`, { headers: { Authorization: `Bearer ${apiToken}` } });
            if (response.status === 200) {
                if (!response.data.response.disable_section.disable_module) {
                    setHome(response.data.response.disable_section);
                }
                else {
                    setDisabledBtn(true);
                    setCountdown(30);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };
    const handleToCustomerSupport = () => {
        window.open('https://zoalaco.atlassian.net/servicedesk/customer/portal/7/group/15/create/67', '_blank');
    };

    useEffect(() => {
        if (disabledBtn) {
            const timer = setInterval(() => {
                setCountdown((prev) => {
                    if (prev === 1) {
                        clearInterval(timer);
                        setDisabledBtn(false);
                    }
                    return prev - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [disabledBtn]);

    return (
        <div
            className='flex justify-center items-center flex-col h-screen overflow-hidden'
            style={{
                backgroundImage: `url(${background})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
            }}
        >
            <div className='flex justify-center items-center h-screen w-screen pb-10 relative'>
                <div className='bg-white  w-96 rounded-3xl shadow-2xl shadow-black z-10 p-8 mb-24 absolute top-8'>
                    {disabledModule !== 'chat_ai' && <button className='absolute top-4 left-4' onClick={() => handleLogout()}>
                        <img src={logout} className='w-6' alt='logout' />
                    </button>}
                    <StaticPart disabledModule={disabledModule} disabledOrg={disabledOrg?.login} home={disabledOrg && disabledOrg.login === 'deleted_org' ? disabledOrg : home} />
                    <div className='w-full h-full flex flex-col items-center'>
                        {disabledModule === 'deleted_org' || disabledOrg?.login === 'deleted_org' ? (
                            <div className='flex gap-2 bg-[#29B6E8] rounded-full p-2 text-white mt-4 cursor-pointer' onClick={handleToCustomerSupport}>
                                <img src={customer_support} alt='Customer Support' />
                                <p>Contact Support</p>
                            </div>
                        ) : <CountdownPart
                            disabledBtn={disabledBtn}
                            countdown={countdown}
                            handleClick={handleClick}
                        />}
                    </div>
                </div>
                <div className='bg-gradient-to-t from-[#04ACE8] to-[#5BC7EE] absolute h-[85%] mt-20 rounded-3xl top-0 w-[460px]'></div>
            </div>
        </div>
    );
};

const StaticPart = memo(({ disabledModule, home }: any, disabledOrg: DisabledOrg) => (
    <>
        {disabledModule == 'deleted_org' || disabledOrg.login == 'deleted_org' ? "" : (
            <div className='absolute top-4 right-4 flex gap-4 self-end items-center bg-[#ED3425] rounded-full px-4 py-1'>
                <DangerIcon />
                <Link className='cursor-pointer' to='/i-need-help'>
                    <p className='text-white font-semibold text-lg'>I need help!</p>
                </Link>
            </div>
        )}
        <div className='w-full h-full flex flex-col items-center mt-20'>
            <img src={home.disable_icon} className='w-20 h-20' alt='zoala logo' />
            <h5 className='text-[#29B6E8] font-bold text-xl my-4'>{home.disable_title}</h5>
            <p className='text-[#6D6D6D] font-bold mb-4'>{home.disable_subject}</p>
            <p className='text-[#666666] text-justify' style={{ textAlignLast: 'center' }}>
                {home.disable_content}
            </p>
            <p className={`${disabledModule === 'deleted_org' ? 'text-[#1C1E42]' : 'text-[#29B6E8]'} text-center font-bold mt-12`}>
                {home.disable_caption}
            </p>
        </div>
    </>
));

const CountdownPart = ({
    disabledBtn,
    countdown,
    handleClick,
}: any) => {
    return <>

        <img src={retry} className='cursor-pointer mt-8' onClick={!disabledBtn ? handleClick : () => null} />

        {disabledBtn && (
            <p className='mt-8'>
                Retry in <span className='text-[#29B6E8]'>{countdown}</span> seconds
            </p>
        )}

    </>
}


export default DisabledSection;
