import React, { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'


interface Title {
    className?: string
    feed: WellnessStreamProps
}
export default function Title({ className, feed }: Title) {
    const [expandRead, setExpandRead] = useState(false)
    const [isOverflowing, setIsOverflowing] = useState(false);
    const pRef = useRef<HTMLParagraphElement>(null); // Ref for the <p> element

    const handleExpandRead = () => {
        setExpandRead(!expandRead)
    }
    useEffect(() => {
        const pElement = pRef.current;

        // Check if the content is overflowing
        if (pElement && pElement.scrollWidth > pElement.clientWidth) {
            setIsOverflowing(true);
        }
    }, []);
    return (
        <div className={`absolute ${className} text-white bg-[#5d5d5d73] max-w-[50%] p-2 rounded-xl z-[99999]`} onTouchStart={(e) => e.stopPropagation()}>
            <h5 className='font-bold'>{feed.author}</h5>
            <p ref={pRef} className={`text-gray-200 ${expandRead ? '' : 'text-ellipsis whitespace-nowrap overflow-hidden cursor-pointer'}`} onClick={handleExpandRead}>{feed.brief}</p>
            {isOverflowing && !expandRead && (
                <button className='text-gray-200' onClick={handleExpandRead}>
                    Read more
                </button>
            )}
            {expandRead && (
                <button className='text-gray-200' onClick={handleExpandRead}>
                    Show less
                </button>
            )}
            <p className='text-gray-300'>{feed.time}</p>
        </div>
    )
}
