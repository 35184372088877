import React from 'react'
import InfoIcon from '../asset/InfoIcon'
import { Tooltip } from 'react-tooltip'
import DailyVibesChart from './DailyVibesChart'
import { InsightsDailyVibesProps } from '../types/insights'

function InsightsDailyVibes({ children }: InsightsDailyVibesProps) {
  return (
    <div className='relative rounded-2xl border border-[#cccccc] h-full w-full lg:w-1/2 flex flex-col py-3'>
      <div className='flex flex-col mb-2 py-3 p-5'>
        <div className='w-full flex justify-between mb-4'>
          <div className='flex gap-2 items-center'>
            <h5 className='text-2xl font-bold text-[#161d45]'>Vibe Check</h5>
            <a
              data-tooltip-id="dailyVibes-tooltip"
              data-tooltip-content="Vibe Check records your feelings by capturing your check-ins."
              data-tooltip-place="top"
            >
              <InfoIcon />
            </a>
            <Tooltip id="dailyVibes-tooltip" style={{ maxWidth: "30%" }} />
          </div>
          {/* <button className='text-xs font-bold text-[#666666]'>View all &gt;</button> */}
        </div>
        <button className='rounded-full bg-[#29B6E8] text-white font-bold px-4 py-2 w-fit' disabled={true}>Last 7 days</button>
      </div>
      <DailyVibesChart />
      {children}
    </div>
  )
}

export default InsightsDailyVibes