import { useEffect, useState } from 'react'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import React from 'react'
import MentalHealthSupport from './MentalHealthSupport'
import YouthCounselling from './YouthCounselling'
import { HotlineProps } from '../../types/ineedhelp'

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

interface Category {
    id: number;
    content: React.ReactNode;
}

interface CategoriesState {
    [key: string]: Category[];
}

interface TabsProps {
    hotlineContacts: HotlineProps[];
    selectedContact?: HotlineProps; // optional prop
  }

const Tabs: React.FC<TabsProps> = (hotlineContacts, selectedContact) => {
    const [categories, setCategories] = useState<CategoriesState>({
        "Mental Health Support": [],
        "Youth Counselling": [],
    });


    // Update categories when hotlineContacts prop changes
    useEffect(() => {
        setCategories({
            "Mental Health Support": [
                {
                    id: 1,
                    content: <MentalHealthSupport hotlineContacts={hotlineContacts.hotlineContacts} {...(selectedContact?.hotlines_type === 1 ? { selectedContact } : {})} />
                },
            ],
            "Youth Counselling": [
                {
                    id: 1,
                    content: <YouthCounselling />
                },
            ],
        });
    }, [hotlineContacts]);


    return (
        <div className="w-full px-2 sm:px-0">
            <TabGroup>
                <TabList className="flex space-x-1 rounded-full bg-[#F2F2F2]">
                    {Object.keys(categories).map((category) => (
                        <Tab
                            key={category}
                            className={({ selected }) =>
                                classNames(
                                    `w-full py-2.5 text-sm font-medium leading-5 rounded-full ${selected ? 'bg-[#29B3E6]' : 'bg-[#F2F2F2]'}`,
                                    'ring-white/60 focus:outline-none focus:ring-0',
                                    selected
                                        ? 'text-white shadow'
                                        : 'text-[#999999] font-bold hover:bg-white/[0.12] hover:text-[#29B3E6]'
                                )
                            }
                        >
                            {category}
                        </Tab>
                    ))}
                </TabList>
                <TabPanels className="mt-2">
                    {Object.values(categories).map((contents, idx) => (
                        <TabPanel
                            key={idx}
                            className={classNames(
                                'rounded-xl bg-white p-3',
                                'ring-white/60 ring-offset-2 focus:outline-none focus:ring-2'
                            )}
                        >
                            {contents.map((content) => <div key={content.id}>{content.content}</div>)}

                        </TabPanel>
                    ))}
                </TabPanels>
            </TabGroup>
        </div>
    )
}

export default React.memo(Tabs)