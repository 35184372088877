import React from 'react'
import { IconProps } from '../../types/icon'

const WhatsAppIcon = ({ className }: IconProps) => {
    return (
        <svg className={className} width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3607 41.0656C31.515 41.0656 40.5574 32.0232 40.5574 20.8688C40.5574 9.7145 31.515 0.672119 20.3607 0.672119C9.20631 0.672119 0.163933 9.7145 0.163933 20.8688C0.163933 32.0232 9.20631 41.0656 20.3607 41.0656Z" fill="#29A71A" />
            <path d="M29.2656 11.9641C27.1643 9.84181 24.3738 8.54029 21.3974 8.29429C18.421 8.04829 15.4546 8.87399 13.0334 10.6225C10.6122 12.3709 8.8955 14.9271 8.19295 17.8298C7.4904 20.7326 7.84827 23.7908 9.202 26.4529L7.87314 32.9044C7.85935 32.9686 7.85896 33.035 7.872 33.0993C7.88503 33.1637 7.9112 33.2247 7.94888 33.2785C8.00408 33.3601 8.08287 33.423 8.17473 33.4587C8.26659 33.4943 8.36715 33.5012 8.46298 33.4782L14.7859 31.9795C17.4406 33.2989 20.4772 33.6338 23.3556 32.9245C26.2339 32.2151 28.7673 30.5076 30.5048 28.1058C32.2424 25.7039 33.0715 22.7635 32.8446 19.8077C32.6177 16.852 31.3494 14.0726 29.2656 11.9641ZM27.2941 27.6992C25.8402 29.149 23.9681 30.106 21.9415 30.4354C19.9148 30.7648 17.8359 30.4499 15.9977 29.5352L15.1164 29.0992L11.24 30.0172L11.2515 29.969L12.0548 26.0674L11.6233 25.2159C10.6841 23.3713 10.3527 21.2767 10.6768 19.2322C11.0008 17.1878 11.9636 15.2983 13.4272 13.8346C15.2663 11.9961 17.7603 10.9633 20.3607 10.9633C22.9611 10.9633 25.4551 11.9961 27.2941 13.8346C27.3098 13.8525 27.3267 13.8694 27.3446 13.8851C29.1609 15.7283 30.1748 18.2148 30.1654 20.8025C30.1559 23.3902 29.1238 25.8693 27.2941 27.6992Z" fill="white" />
            <path d="M26.9499 24.8372C26.4748 25.5854 25.7243 26.5012 24.781 26.7284C23.1286 27.1277 20.5925 26.7421 17.4368 23.7998L17.3977 23.7654C14.623 21.1926 13.9023 19.0513 14.0768 17.353C14.1732 16.389 14.9764 15.5169 15.6535 14.9477C15.7605 14.8564 15.8875 14.7913 16.0241 14.7578C16.1608 14.7243 16.3034 14.7232 16.4406 14.7546C16.5778 14.7861 16.7057 14.8492 16.8141 14.9389C16.9225 15.0286 17.0084 15.1425 17.065 15.2713L18.0863 17.5664C18.1526 17.7152 18.1772 17.8793 18.1574 18.041C18.1376 18.2028 18.0741 18.356 17.9738 18.4844L17.4574 19.1546C17.3466 19.293 17.2798 19.4613 17.2654 19.638C17.2511 19.8147 17.29 19.9917 17.3771 20.1461C17.6663 20.6533 18.3594 21.3992 19.1282 22.09C19.9912 22.8703 20.9482 23.5841 21.5541 23.8274C21.7163 23.8936 21.8945 23.9098 22.0659 23.8738C22.2373 23.8378 22.394 23.7513 22.5158 23.6254L23.1148 23.0218C23.2304 22.9078 23.3741 22.8266 23.5314 22.7862C23.6886 22.7459 23.8537 22.748 24.0099 22.7923L26.4358 23.4808C26.5696 23.5219 26.6923 23.593 26.7944 23.6887C26.8965 23.7844 26.9754 23.9022 27.025 24.0331C27.0746 24.164 27.0936 24.3045 27.0806 24.4439C27.0676 24.5832 27.0229 24.7178 26.9499 24.8372Z" fill="white" />
        </svg>
    )
}
export default React.memo(WhatsAppIcon)
