import React from 'react'
import { IconProps } from '../../types/icon'

function CounsellorsIcon({ className }: IconProps) {
    return (
        <svg className={className} width="100" height="100" viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M85.2 30.5714C85.2 21.4193 77.7807 14 68.6286 14H47.5C39.4919 14 33 20.4919 33 28.5V28.5C33 36.5081 39.4919 43 47.5 43H85.2V30.5714Z" fill="#29B6E8" />
            <circle cx="46.6714" cy="29.3286" r="2.9" fill="white" />
            <circle cx="59.1" cy="29.3286" r="2.9" fill="white" />
            <circle cx="71.5286" cy="29.3286" r="2.9" fill="white" />
            <path d="M16 55.4762C16 49.6904 20.6904 45 26.4762 45H39.8333C44.896 45 49 49.1041 49 54.1667V54.1667C49 59.2293 44.896 63.3333 39.8333 63.3333H16V55.4762Z" fill="#29B6E8" />
            <circle cx="1.83333" cy="1.83333" r="1.83333" transform="matrix(-1 0 0 1 42.1905 52.8571)" fill="white" />
            <circle cx="1.83333" cy="1.83333" r="1.83333" transform="matrix(-1 0 0 1 34.3333 52.8571)" fill="white" />
            <circle cx="1.83333" cy="1.83333" r="1.83333" transform="matrix(-1 0 0 1 26.4762 52.8571)" fill="white" />
            <path d="M28.908 85.5441C32.748 85.5441 35.8609 82.4312 35.8609 78.5913C35.8609 74.7513 32.748 71.6384 28.908 71.6384C25.0681 71.6384 21.9552 74.7513 21.9552 78.5913C21.9552 82.4312 25.0681 85.5441 28.908 85.5441Z" fill="#12133D" />
            <path d="M28.9109 87.9266C25.4875 87.9266 22.2042 89.2863 19.783 91.7067C17.3619 94.127 16.0011 97.4099 16 100.833C16.0021 101.732 16.36 102.592 16.9954 103.227C17.6308 103.862 18.4918 104.219 19.3899 104.22H38.4354C39.3329 104.218 40.1931 103.86 40.8277 103.226C41.4623 102.591 41.8197 101.731 41.8217 100.833C41.8169 97.4111 40.4549 94.1303 38.0346 91.7108C35.6142 89.2912 32.3333 87.9303 28.9109 87.9266Z" fill="#12133D" />
            <path d="M79.9062 73.7142C86.1786 73.7142 91.2633 68.6295 91.2633 62.3571C91.2633 56.0848 86.1786 51.0001 79.9062 51.0001C73.6339 51.0001 68.5492 56.0848 68.5492 62.3571C68.5492 68.6295 73.6339 73.7142 79.9062 73.7142Z" fill="#12133D" />
            <path d="M79.911 77.6058C74.319 77.6058 68.9558 79.8269 65.0011 83.7804C61.0463 87.7339 58.8236 93.0964 58.8217 98.6884C58.8251 100.155 59.4098 101.561 60.4477 102.598C61.4856 103.635 62.892 104.218 64.359 104.22H95.4686C96.9347 104.216 98.3397 103.633 99.3763 102.596C100.413 101.559 100.997 100.154 101 98.6884C100.992 93.0982 98.7674 87.7393 94.8139 83.7871C90.8604 79.835 85.5011 77.6119 79.911 77.6058Z" fill="#12133D" />
        </svg>
    )
}
export default React.memo(CounsellorsIcon)