import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Achievement } from '../../../types/achievement'
import { LinearProgress } from '@mui/joy'
import * as sparkles from '../../../asset/gamification/sparkles.json'
import Lottie from 'react-lottie'
import Loader from '../../icons/Loader'
import { PulseLoader } from 'react-spinners'
import { useInitApi } from '../../../store/initApi'

export default function Achievements() {
    const apiToken = sessionStorage.getItem('api_token')
    const { initApi } = useInitApi()
    const [achievements, setAchievements] = useState<Achievement[]>([])
    const [claimedNumber, setClaimedNumber] = useState<number | null>(null)
    const [isDisabled, setIsDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: sparkles,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const fetchAchievements = async () => {
        try {
            setIsLoading(true)
            if (!initApi) return;
            const res = await axios.get(initApi.url.features.gamification.get_achievements, { headers: { Authorization: `Bearer ${apiToken}` } })
            if (res.status === 200) {
                setAchievements(res.data.response.achievement)
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }

    const claimXp = async (achievement_id: number, index: number) => {
        try {
            setIsDisabled(true)
            if (!initApi) return;
            const res = await axios.get(`${initApi.url.features.gamification.claim_achievement}?claim=1&id=${achievement_id}`, { headers: { Authorization: `Bearer ${apiToken}` } })
            if (res.status === 200) {
                setClaimedNumber(index)
            }
            setIsDisabled(false)
        } catch (error) {
            setIsDisabled(false)
        }
    }
    useEffect(() => {
        fetchAchievements()
    }, [claimedNumber])

    if (isLoading) return <div className='h-screen flex items-center justify-center'><PulseLoader /> </div>

    return (
        <div className='w-full my-8'>
            <div className='w-full flex items-center gap-4 p-4 mt-8 bg-[#cff1fa] rounded-t-3xl border-2 border-[#F3EDF5] border-b-transparent'>
                <div className='font-bold'>Achievements</div>

            </div>
            <div className='w-full rounded-b-3xl border-2 border-[#F3EDF5] border-t-transparent px-4'>
                {achievements?.map((achievement, index) =>
                    <div key={achievement.id} className='flex items-center gap-4'>
                        <div className='relative'>
                            <img src={achievement.icon} alt="" className='w-20 h-20' />
                            <div className='absolute bottom-0 right-[-1rem] rounded-full p-2 font-bold text-[#7c7c7c] bg-white'>LV.{achievement.level}</div>
                        </div>
                        <div className='flex flex-col p-4'>
                            <p className='font-bold'>{achievement.name}</p>
                            <span className='text-[#7c7c7c] mb-4'>{achievement.description}</span>
                            <div className='flex gap-4'>
                                {achievement.progress === achievement.required_count ?
                                    <div className={`flex justify-center relative w-full p-4 bg-[#cff1fa] text-[#1eb9eb] rounded-xl text-center font-bold ${isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={isDisabled ? () => null : () => claimXp(achievement.id, index)}>
                                        {isDisabled ? <Loader color='#1eb9eb' /> : <p>Claim {achievement.reward.xp} XP</p>}
                                        {claimedNumber === index ? <div className='absolute transform translate-x-1/2 -translate-y-1/2 top-[50%] right-[50%]'>
                                            <Lottie
                                                options={defaultOptions}
                                                height={250}
                                                width={250}
                                            />
                                        </div> : ""}
                                    </div> :
                                    <LinearProgress
                                        determinate
                                        color='success'
                                        value={(achievement.progress / achievement.required_count) * 100}
                                        sx={{
                                            '--LinearProgress-radius': '16px',
                                            '--LinearProgress-thickness': '20px',
                                            color: '#29B3E6',
                                        }}
                                    />}
                                {achievement.progress === achievement.required_count ? "" :
                                    <div className='font-bold'>
                                        <span className='text-[#29B3E6]'>{achievement.progress}</span>
                                        <span className='text-[#7c7c7c]'>/{achievement.required_count}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {/* <p className='font-bold text-[#b3b3b3]'>Coming Soon</p>
            <p className='font-bold text-[#b3b3b3]'>Achievements</p>
            <div className='w-full h-[100px] bg-[#d9d9d9] rounded-2xl p-8'></div> */}
        </div>
    )
}
