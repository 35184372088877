import { create } from 'zustand'

interface ProfileStore {
    leaderboard: LeaderboardProps | null
    setLeaderboard: (value: LeaderboardProps) => void
}

export const useProfileStore = create<ProfileStore>((set) => ({
    leaderboard: null,
    setLeaderboard: (newValue) => set(() => ({ leaderboard: newValue })),
}))
