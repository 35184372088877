import React from 'react'
import { CounsellorProps } from '../../types/ineedhelp'

export default function Counsellors({ counsellors, teachers }: { counsellors: CounsellorProps[], teachers: CounsellorProps[] }) {
    return (
        <>
            <section className='max-h-[1000px] overflow-auto w-full'>
            {counsellors.length > 0 ? <h5 className='text-[#999999] font-bold mb-4 text-3xl'>Counsellors and Teachers</h5> : ""}
                {counsellors.length > 0 ? counsellors.map((contact: CounsellorProps) => (
                    <a className='cursor-pointer flex items-center w-full p-8 border border-[#E9E9E9] rounded-xl mb-8' href={`tel:${contact.phone_number}`}>
                        <div className='flex flex-col gap-4 w-full' key={contact.phone_number}>
                            <h5 className='font-bold'>{contact.first_name}</h5>
                            {contact.phone_number && <div className="flex items-center gap-4 w-fit bg-[#F2F2F2] text-[#999999] text-xs font-medium py-2 px-4 rounded-full mt-2">
                                <span>{contact.phone_number}</span>
                            </div>
                            }
                        </div>
                        <p className='text-[#999999]'>{contact.role_name}</p>
                    </a>
                )
                ) : ""}
                {teachers.length > 0 ? teachers.map((contact: CounsellorProps) => (
                    <a className='cursor-pointer flex items-center w-full p-8 border border-[#E9E9E9] rounded-xl mb-8' href={`tel:${contact.phone_number}`}>
                        <div className='flex flex-col gap-4 w-full' key={contact.phone_number}>
                            <h5 className='font-bold'>{contact.first_name}</h5>
                            {contact.phone_number && <div className="flex items-center gap-4 w-fit bg-[#F2F2F2] text-[#999999] text-xs font-medium py-2 px-4 rounded-full mt-2">
                                <span>{contact.phone_number}</span>
                            </div>
                            }
                        </div>
                        <p className='text-[#999999]'>{contact.role_name}</p>
                    </a>
                )
                ) : ""}
            </section >


        </>
    )
}
