import React from 'react'
import xpBling from '../../asset/gamification/bling.svg'
import { Gamification } from '../../types/aichat'
import levelUpArrow from '../../asset/gamification/level-up.svg'
interface ChatGainXpPopUpProps {
    game: Gamification
}

export default function ChatGainXpPopUp({ game }: ChatGainXpPopUpProps) {
    return (
        <div>
            <strong>Quest completed</strong>
            <br />
            <div className='flex justify-between items-center'>
                <p>{game.quest_info.name}</p>
                <div className='flex gap-2 items-center'>
                    <img src={xpBling} alt="" width={35} height={35} />
                    <span>{game.xp_earned} XP</span>
                </div>
            </div>
            <br />
            {game.level_up_flag &&
                <>
                    <strong>Level up</strong>
                    <br />
                    <div className='flex justify-between items-center'>
                        <p className='max-w-[100px]'>Level {game.current_level + 1}</p>
                        <img src={levelUpArrow} alt="" width={35} height={35} />
                    </div>
                </>}
        </div>

    )
}
