import axios, { AxiosRequestConfig } from 'axios';

interface ApiProviderProps {
    method: 'get' | 'post' | 'put' | 'delete' | 'patch';
    url: string;
    data?: object; // Optional, since not all methods need data
    apiToken?: string | null;
    config?: AxiosRequestConfig; // Optional Axios config
}

const ApiProvider = async ({ method, url, data, apiToken, config }: ApiProviderProps) => {
    // Merging the config with optional Authorization header if apiToken is present
    const mergedConfig = {
        ...config,
        ...(apiToken && { headers: { Authorization: `Bearer ${apiToken}` } }),
    };

    try {
        let res;

        // Determine parameters based on the method
        if (method === 'get' || method === 'delete') {
            res = await axios[method](url, mergedConfig);
        } else {
            res = await axios[method](url, data, mergedConfig);
        }

        return res.data;
    } catch (error) {
        console.error('API request failed:', error);
        throw error;
    }
};

export default ApiProvider;
