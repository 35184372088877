import React from 'react'
import play from '../../asset/wellness_stream/play.png'
import { IconProps } from '../../types/icon'

export function PlayerIcon({ onClick }: IconProps) {
  return (
    <>
      <div onClick={onClick} className='player-icon absolute transform translate-x-1/2 -translate-y-1/2 top-[50%] right-[50%]'>
        <img src={play} width='64' height='64' alt='Play' />
      </div>

      <style>{`
        .player-icon {
          width: 64px;
          height: 64px;
        }
      `}</style>
    </>
  )
}
