import React from 'react'
import AlertIcon from '../asset/home/AlertIcon'

type ErrorProps = {
    mainText?: string
}

export default function Error({ mainText }: ErrorProps) {
    return (
        <div className='flex items-center justify-center h-screen gap-2'>
            <AlertIcon />
            <h1 className='text-xl font-extrabold'>{mainText ?? "Error 404"}</h1>
        </div>
    )
}
