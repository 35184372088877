import React, { useEffect, useState } from 'react'
import { accountpasswordicon } from '../asset';
import preferences from '../asset/setting/preferences.svg'
import leaderboardPic from '../asset/setting/leaderboard.svg'
import { useLocation, useNavigate } from 'react-router-dom';
import { UserDataProps } from '../types/common';
import Account from '../components/setting/Account';
import Profile from '../components/setting/Profile';
import Preferences from '../components/setting/Preferences';
import Leaderboard from './Leaderboard';
import axios, { CancelTokenSource } from 'axios';
import { useProfileStore } from '../store/profile';
import { PulseLoader } from 'react-spinners';
import { useInitApi } from '../store/initApi';
import { track } from '@amplitude/analytics-browser';

function Setting() {
  const location = useLocation()
  const { toPreferencesTab } = location.state || {}
  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserDataProps | null>(null);
  const [selectedMenu, setSelectedMenu] = useState('profile')
  const apiToken = sessionStorage.getItem('api_token');
  const [isLoading, setIsLoading] = useState(false)
  const { initApi } = useInitApi()
  const { setLeaderboard } = useProfileStore()

  const fetchLeaderboard = async (source: CancelTokenSource) => {
    try {
      setIsLoading(true)
      if (!initApi) return;
      const res = await axios.get(initApi.url.features.gamification.get_leaderboard, { cancelToken: source.token, headers: { Authorization: `Bearer ${apiToken}` } })
      if (res.status === 200) {
        setLeaderboard(res.data.response)
      }
      setIsLoading(false)
    } catch (error) {
      if (axios.isCancel(error)) {
      }
      setIsLoading(false)
    }
  }
  useEffect(() => {
    track('screenView', { screen_name: 'SettingScreen' })
    let source = axios.CancelToken.source();
    fetchLeaderboard(source);
    return () => {
      source.cancel();
    }
  }, [])
  useEffect(() => {
    const storedUserData = sessionStorage.getItem('user');
    if (!apiToken || !storedUserData) {
      navigate('/login');
      return;
    }

    setUserData(JSON.parse(storedUserData));
  }, [apiToken, navigate]);

  const handleSelectedMenu = (menu: string) => {
    setSelectedMenu(menu)
  }

  useEffect(() => {
    const onBoardingPrivacy = localStorage.getItem('onBoardingPrivacy')
    if (onBoardingPrivacy) {
      handleSelectedMenu('preferences')
      localStorage.removeItem('onBoardingPrivacy')
    }
    if (toPreferencesTab) {
      setSelectedMenu('preferences')
    }
  }, [])

  if (isLoading) return <div className='h-screen flex items-center justify-center'><PulseLoader /> </div>
  return (
    <div className='h-screen w-screen'>
      <div className='pl-16 flex w-full h-full'>
        <div className='w-fit px-6 h-full flex flex-col items-center pt-20 border-r-gray-200 border'>
          <div className='w-full h-fit flex justify-center items-center mb-8'>
            <img className='rounded-full w-14 h-14' src={userData?.gamification.avatar} alt='profile' />
            <div className='flex flex-col ml-4'>
              <span className='text-[#161d46] font-bold text-lg'>{userData?.user_info.firstname} {userData?.user_info.lastname}</span>
              <span className='text-[#2ab1e4]'>{userData?.login_email}</span>
            </div>


          </div>
          <div className='flex flex-col xl:flex-row gap-2 items-center mb-4'>
            <div className='bg-[#e3d4e7] p-2 rounded-full text-[#a273b3]'>@{userData?.gamification.handler}</div>
            <div className='bg-[#bbe9f9] p-2 rounded-full text-[#2399c1] w-full text-center'>Level {userData?.gamification.current_level}</div>
          </div>
          <div className={`w-full py-4 px-2 flex items-center gap-3 cursor-pointer ${selectedMenu === 'profile' ? 'bg-gray-300 rounded-xl' : ''}`} onClick={() => handleSelectedMenu('profile')}>
            <img src={accountpasswordicon} alt='icon' />
            <span className='text-[#151D45] font-bold'>Profile</span>
          </div>
          <div className={`w-full py-4 px-2 flex items-center gap-3 cursor-pointer ${selectedMenu === 'account' ? 'bg-gray-300 rounded-xl' : ''}`} onClick={() => handleSelectedMenu('account')}>
            <img src={accountpasswordicon} alt='icon' />
            <span className='text-[#151D45] font-bold'>Account & Password</span>
          </div>
          <div className={`w-full py-4 px-2 flex items-center gap-3 cursor-pointer ${selectedMenu === 'preferences' ? 'bg-gray-300 rounded-xl' : ''}`} onClick={() => handleSelectedMenu('preferences')}>
            <img src={preferences} alt='icon' />
            <span className='text-[#151D45] font-bold'>Preferences</span>
          </div>
          <div className={`w-full py-4 px-2 flex items-center gap-3 cursor-pointer ${selectedMenu === 'leaderboard' ? 'bg-gray-300 rounded-xl' : ''}`} onClick={() => handleSelectedMenu('leaderboard')}>
            <img src={leaderboardPic} alt='icon' />
            <span className='text-[#151D45] font-bold'>Leaderboard</span>
          </div>
        </div>
        <div className='w-[80%] h-full overflow-y-auto'>
          {selectedMenu === 'profile' && userData && <Profile userData={userData} />}
          {selectedMenu === 'account' && userData && <Account userData={userData} />}
          {selectedMenu === 'preferences' && userData && <Preferences />}
          {selectedMenu === 'leaderboard' && userData && <Leaderboard userData={userData} />}
        </div>
      </div>



    </div>
  )
}

export default Setting