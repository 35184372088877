import React, { useEffect, useState } from 'react';
import background from '../asset/BG.png';
import { useNavigate } from 'react-router-dom';
import { maleactive, maledefault, femaleactive, femaledefault, otheractive, otherdefault, logincongratulation, zoalalogo } from '../asset';
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { track } from '@amplitude/analytics-browser';

function Gender() {
    const navigate = useNavigate();
    const [step, setStep] = useState<number>(1);
    const [gender, setGender] = useState<string | null>(null);
    const [userData, setUserData] = useState<any>();
    const apiToken = sessionStorage.getItem('api_token');

    useEffect(() => {
        // Check if the API token is present in session storage
        const storedUserData = sessionStorage.getItem('user');

        if (apiToken && storedUserData) {
            setUserData(JSON.parse(storedUserData));
        } else {
            // If the API token or user data is not present, redirect to the login page
            navigate('/login');
        }
    }, [apiToken, navigate]);
    useEffect(() => {
        track('screenView', { screen_name: 'GenderScreen' })
    }, [])
    const getMaleActive = () => {
        return gender === 'm' ? maleactive : maledefault;
    };

    const getFemaleActive = () => {
        return gender === 'f' ? femaleactive : femaledefault;
    };

    const getNActive = () => {
        return gender === 'x' ? otheractive : otherdefault;
    };

    const handleGenderClick = (selectedGender: string) => {
        setGender(selectedGender);
    };

    const handleNextClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (gender === null) {
            toast.warning('Select a gender');
            return;
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/user/profile/update`, {
                firstname: userData.user_info.firstname,
                lastname: userData.user_info.lastname,
                dob: userData.user_info.birth_date,
                gender: gender
            }, { headers: { Authorization: `Bearer ${apiToken}` } });
            if (response.status === 200) {
                toast.success(response.data.message);
                setStep(step + 1);
            } else {
                toast.error('Unexpected error, Please try again');
            }
        } catch (error) {
            toast.error('Unexpected error, Please try again');
        }
    };

    const handleContinueClick = () => {
        navigate('/home');
    };

    return (
        <>
            <div className='flex justify-center items-center flex-col h-screen overflow-hidden'
                style={{
                    backgroundImage: `url(${background})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                }}>
                <img src={zoalalogo} className='w-40 h-40' alt='zoala logo' />
                <div className='flex justify-center items-center h-screen w-screen pb-10 relative'>
                    <div className='bg-white h-[80%] w-96 rounded-3xl shadow-2xl shadow-black z-10 p-8 mb-24'>
                        <div className='w-full h-full flex flex-col items-center relative'>
                            {step === 1 ? (
                                <>
                                    <div className='w-full flex justify-evenly items-center mt-4'>
                                        <span className='font-bold text-[#00ade7] text-xl'>1</span>
                                        <div className='bg-[#a4a4a5] w-12 h-[1px]'></div>
                                        <span className='font-bold text-[#a4a4a5] text-xl'>2</span>
                                    </div>
                                    <span className='font-bold text-[#00ade7] text-xl mt-4'>Select your gender</span>
                                    <div className='w-full flex flex-col justify-center items-center mt-14 gap-4'>
                                        <button onClick={() => handleGenderClick('m')} className={`border border-[#00ade7] rounded-full flex items-center ${gender === 'm' ? 'text-white bg-[#00ade7]' : 'text-[#00ade7]'} pl-5 py-3 pr-10`}><img className='w-6 h-6 mr-5' src={getMaleActive()} alt='male' />Male</button>
                                        <button onClick={() => handleGenderClick('f')} className={`border border-[#00ade7] rounded-full flex items-center ${gender === 'f' ? 'text-white bg-[#00ade7]' : 'text-[#00ade7]'} pl-5 py-3 pr-10`}><img className='w-6 h-6 mr-5' src={getFemaleActive()} alt='female' />Female</button>
                                        <button onClick={() => handleGenderClick('x')} className={`border border-[#00ade7] rounded-full flex items-center ${gender === 'x' ? 'text-white bg-[#00ade7]' : 'text-[#00ade7]'} pl-5 py-3 pr-10`}><img className='w-6 h-6 mr-5' src={getNActive()} alt='other' />Prefer not to say</button>
                                    </div>
                                    <button onClick={handleNextClick} className='text-[#00ade7] absolute bottom-0 right-0'>Next</button>
                                </>
                            ) : (
                                <>
                                    <div className='w-full flex justify-evenly items-center mt-4'>
                                        <span className='font-bold text-[#00ade7] text-xl'>1</span>
                                        <div className='bg-[#00ade7] w-12 h-[1px]'></div>
                                        <span className='font-bold text-[#00ade7] text-xl'>2</span>
                                    </div>
                                    <div className='flex flex-col mt-10 gap-4 justify-center items-center h-full w-full'>
                                        <img className='w-24 h-24' src={logincongratulation} alt='congratilation' />
                                        <span className='text-[#00ade7] font-bold text-xl'>Congratulation!</span>
                                        <span className='text-center text-sm text-[#6d6d6d]'>You’re now part of Zoala. Thank you for joining us on this exciting journey!</span>
                                        <button onClick={handleContinueClick} className='bg-[#00ade7] w-full rounded-full p-3 text-white mt-8'>Continue</button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className='bg-gradient-to-t from-[#04ACE8] to-[#5BC7EE] absolute h-[85%] mt-20 rounded-3xl top-0 w-[460px]'></div>
                </div>
            </div>
        </>
    );
}

export default Gender;