import { create } from "zustand"
import { ContactProps } from "../types/safety"

type SafetyProps = {
    safetyActivated: number,
    setSafetyActivated: (value: number) => void
    contacts: ContactProps[],
    setContacts: (value: ContactProps[]) => void
}


export const useSafetyStore = create<SafetyProps>((set) => ({
    safetyActivated: 0,
    setSafetyActivated: (newValue) => set(() => ({ safetyActivated: newValue })),
    contacts: [],
    setContacts: (newValue) => set(() => ({ contacts: newValue })),
}))
