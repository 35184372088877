import React, { useEffect } from 'react'
import CountdownTimer from '../components/CountdownTimer'
import crown from '../asset/gamification/crown.svg'
import { UserDataProps } from '../types/common'
import { useProfileStore } from '../store/profile'
import { track } from '@amplitude/analytics-browser'
interface UserProps {
    userData: UserDataProps
}
export default function Leaderboard({ userData }: UserProps) {
    const { leaderboard } = useProfileStore()

    const convertToPlaced = (number: number) => {
        if (number < 1) return 'N/A';
        if (number > 0 && number < 100) {
            const value = number.toString().split('')
            if (value.length === 1) {
                return value[0] === "1" ? `${value[0]}st` :
                    value[0] === "2" ? `${value[0]}nd` :
                        value[0] === "3" ? `${value[0]}rd` :
                            `${number}th`
            }
            else if (value.length === 2) {
                return value[1] === "1" ? `${number}st` :
                    value[1] === "2" ? `${number}nd` :
                        value[1] === "3" ? `${number}rd` :
                            `${number}th`
            }
            else {
                return `${number}th`
            }
        }
    }
    useEffect(() => {
        track('screenView', { screen_name: 'LeaderBoardScreen' })

    }, [])
    return (
        <div>
            {leaderboard &&
                <div>
                    <div className='leaderBoardBg p-10'>
                        <h1 className='text-[#161d46] font-bold text-2xl mb-4'>Leaderboard</h1>
                        <div className='block m-auto bg-[#29B3E6] rounded-full p-4 w-full max-w-[500px] text-center text-white font-bold'>Current Season</div>
                        <CountdownTimer startDate={leaderboard.season.start_date} endDate={leaderboard.season.end_date} />
                        <div>
                            <div className='flex gap-4 items-center justify-center'>
                                <div className='flex flex-col gap-2 items-center'>
                                    <p className='font-bold'>2nd</p>
                                    <img src={leaderboard.leaderboard["2"].avatar} alt="" className='w-20 h-20 rounded-full' />
                                    <span>{leaderboard.leaderboard["2"].total_season_xp}</span>
                                    <p>{leaderboard.leaderboard["2"].handler}</p>
                                </div>
                                <div className='flex flex-col gap-2 items-center'>
                                    <img src={crown} className='w-10 h-10' />
                                    <img src={leaderboard.leaderboard["1"].avatar} alt="" className='w-28 h-28 rounded-full' />
                                    <span>{leaderboard.leaderboard["1"].total_season_xp}</span>
                                    <p>{leaderboard.leaderboard["1"].handler}</p>
                                </div>
                                <div className='flex flex-col gap-2 items-center'>
                                    <p className='font-bold'>3rd</p>
                                    <img src={leaderboard.leaderboard["3"].avatar} alt="" className='w-20 h-20 rounded-full' />
                                    <span>{leaderboard.leaderboard["3"].total_season_xp}</span>
                                    <p>{leaderboard.leaderboard["3"].handler}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='block items-center'>
                        {Object.keys(leaderboard.leaderboard).map((list, index) =>
                            parseInt(list) > 3 ?
                                <>
                                    <div className={`${leaderboard.leaderboard[list].uuid === userData.uuid ? 'bg-[#fcefc0]' : ''}`}>
                                        <div className={`flex items-center p-8 max-w-[500px] m-auto`}>
                                            <p className='flex-1 px-4'>{convertToPlaced(index + 1)}</p>
                                            <img src={leaderboard.leaderboard[list].avatar} alt="" className='flex-1 w-20 h-20 rounded-full' />
                                            <p className='flex-[5] text-center font-bold break-all px-4'>{leaderboard.leaderboard[list].handler}</p>
                                            <span className='flex-[2] text-2xl text-end'>{leaderboard.leaderboard[list].total_season_xp} <span className='text-base'>XP</span></span>
                                        </div>
                                    </div>
                                    <hr />
                                </>

                                : ""
                        )}
                    </div>
                </div>
            }
        </div>
    )
}
