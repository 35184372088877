import { Button, Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import axios from 'axios'
import Loader from '../icons/Loader'
import eyeOn from '../../asset/eyeOn.svg'
import eyeOff from '../../asset/eyeOff.svg'
import CloseIcon from '../../asset/ineedhelp/CloseIcon'
import { Link } from 'react-router-dom'
import { useInitApi } from '../../store/initApi'
import { usePrivacyStore } from '../../store/privacy'

interface PasswordPopUp {
    isOpenPassword: boolean,
    closeIsOpenPassword: () => void
}
interface PasswordValidateProps {
    status?: boolean
    message: string,
    attempts?: number | string,
    remaining_time?: number
}

export default function PasswordPopUp({ isOpenPassword, closeIsOpenPassword }: PasswordPopUp) {
    const apiToken = sessionStorage.getItem('api_token')
    const passwordRef = useRef<HTMLInputElement>(null)
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState<PasswordValidateProps | null>(null)
    const [isDisabled, setIsDisabled] = useState(false)
    const [passwordVisible, setPasswordVisible] = useState(false)
    const initApi = useInitApi(state => state.initApi)
    const setIsPrivacyModeCompleted = usePrivacyStore(state => state.setIsPrivacyModeCompleted)
    const isPrivacy = usePrivacyStore(state => state.isPrivacy)
    const setIsPrivacy = usePrivacyStore(state => state.setIsPrivacy)
    const setIsShowLocked = usePrivacyStore(state => state.setIsShowLocked)

    const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.trim() !== '') {
            setPasswordError(null)
        }
        setPassword(event.target.value.trim())
    }

    const passwordValidate = async () => {
        try {
            if (passwordRef.current?.value.trim() === '') {
                setPasswordError({
                    status: false,
                    message: 'Password cannot be empty!'
                })
                return;
            }
            if (!initApi || isPrivacy !== 1) return;
            setIsDisabled(true)

            const privacyMode = isPrivacy === 1 ? 'disable' : 'enable'
            const res = await axios.post(initApi.url.features.privacy_pin.post_privacy_mode, {
                type: privacyMode,
            }, { headers: { Authorization: `Bearer ${apiToken}` } })
            if (!res.data.session) {
                setPasswordError(res.data)
            } else {
                setIsPrivacyModeCompleted(true)
                setIsPrivacy(0)
                toast.success(res.data.message)
                setPasswordError(null)
                setIsShowLocked(false)
                localStorage.removeItem('storedTime');
                closeIsOpenPassword()
            }
            setIsDisabled(false)

        } catch (error) {
            setPasswordError({
                status: false,
                message: 'Error while validating password'
            })
            setIsDisabled(false)

        }
    }
    useEffect(() => {
        if (!isOpenPassword) {
            setPassword('')
            setPasswordVisible(false)
        }
    }, [isOpenPassword])
    return (
        <Transition appear show={isOpenPassword}>
            <Dialog as="div" className="relative z-[99999] focus:outline-none lexend-deca-normal" onClose={closeIsOpenPassword}>
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <TransitionChild
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 transform-[scale(95%)]"
                            enterTo="opacity-100 transform-[scale(100%)]"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 transform-[scale(100%)]"
                            leaveTo="opacity-0 transform-[scale(95%)]"
                        >
                            <DialogPanel className="relative flex justify-center w-full max-w-[70%] h-[500px] rounded-xl bg-white p-6 border border-[#cccccc]">
                                <CloseIcon onClick={closeIsOpenPassword} className='absolute top-5 right-5 cursor-pointer' />

                                <div className='flex flex-col justify-between h-full w-1/2'>

                                    <div className='flex flex-col justify-between h-full'>
                                        <h1 className='text-2xl font-bold text-center'>Enter your password</h1>
                                        <div>
                                            <div className='relative flex justify-center pb-8'>
                                                <input type={passwordVisible ? 'text' : 'password'} ref={passwordRef} value={password} className='w-full border-gray-500 border-b-2 focus:outline-none text-2xl' onChange={handleChangePassword} disabled={isDisabled} />
                                                <img src={passwordVisible ? eyeOff : eyeOn} alt="" width={25} height={25} className='absolute top-1 right-0 cursor-pointer' onClick={() => setPasswordVisible(!passwordVisible)} />
                                                <Link to="/forget-password" state={{ fromDisablePrivacyMode: true }} className='absolute bottom-0 right-0 text-[#00ace5] text-sm'>Forgot password?</Link>
                                            </div>
                                            {!passwordError?.status && <p className='text-red-600 text-center'>{passwordError?.message}</p>}
                                            {!passwordError?.status && passwordError?.attempts && <p className='text-red-600 text-center'>Attempt remaining: {4 - Number(passwordError?.attempts)}</p>}
                                        </div>
                                        <div className="mt-4 flex flex-col">
                                            <Button
                                                className=" text-center rounded-md bg-[#29B3E6] text-white py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10 data-[hover]:bg-[#36819d]"
                                                onClick={passwordValidate} disabled={isDisabled}
                                            >
                                                {isDisabled ? <Loader className='m-auto' /> : 'Confirm'}

                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>

            </Dialog>
        </Transition>
    )
}
