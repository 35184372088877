import React from 'react';

interface SafetyPlansButtonProps {
  handleBackClick?: () => void;
  updateSafetyPlan: () => void;
  handleNextClick?: () => void;
}

const SafetyPlansButton: React.FC<SafetyPlansButtonProps> = ({ handleBackClick, updateSafetyPlan, handleNextClick }) => {
  return (
    <div className='absolute bottom-4 flex justify-between w-full px-[20%] h-fit items-center'>
      <button
        className={`rounded-full px-4 py-1 ${handleBackClick === undefined ? 'bg-transparent cursor-default text-transparent' : 'bg-[#29b6e8] text-white'}`}
        onClick={handleBackClick}
        disabled={handleBackClick === undefined}
      >Back</button>
      <button className='text-white rounded-full bg-[#161d45] px-20 py-3' onClick={updateSafetyPlan}>Save</button>
      <button
        className={`rounded-full px-4 py-1 ${handleNextClick === undefined ? 'bg-transparent cursor-default text-transparent' : 'bg-[#29b6e8] text-white'}`}
        onClick={handleNextClick}
        disabled={handleNextClick === undefined}
      >Next</button>
    </div>
  );
}

export default SafetyPlansButton;
