import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import {
  smartgoal, datasafe, pastgoal, specific, measurable,
  relevant, achievable, timebound, congratulation, back, questionmarkgoal, obstacles, overcoming, firststep, summaryobstacles, summaryfirst, summaryovercoming, blackarrow, whitecross
} from '../asset';
import { toast } from 'react-toastify';
import Datepicker from 'react-tailwindcss-datepicker';
import axios from 'axios';
import Blur from '../components/Blur';
import { TierLists } from '../types/tier';
import cryptojs from 'crypto-js'
import Loader from '../components/icons/Loader';
import QuestProgressPopUp from '../components/gamification/QuestProgressPopUp';
import { track } from '@amplitude/analytics-browser';
interface GoalData {
  goals: string;
  specificGoal: string;
  measureableGoal: string;
  achievableGoal: string;
  relevantGoal: string;
  timeDate: any;
  obstaclesGoal: string;
  overcomeGoal: string;
  firststepGoal: string;
}

interface PastGoalData {
  goal_name: string;
  specific_desc: string;
  measurable_desc: string;
  achievable_desc: string;
  relevant_desc: string;
  end_date: string;
  id: number;
}

interface PastGoalObstacle {
  first_step: string | null;
  obstacles_desc: string;
  overcome_desc: string;
}

function SmartGoals() {
  const navigate = useNavigate();
  const [remindMe, setRemindMe] = useState<boolean>(false);
  const [goalsObstaclesToggle, setGoalsObstaclesToggle] = useState<string>('goals');
  const [pastGoalsObstaclesToggle, setPastGoalsObstaclesToggle] = useState<string>('goals');
  const [goalID, setGoalID] = useState<number | null>(null);
  const [showPastGoals, setShowPastGoals] = useState<boolean>(false);
  const [goalData, setGoalData] = useState<GoalData>({
    goals: '',
    specificGoal: '',
    measureableGoal: '',
    achievableGoal: '',
    relevantGoal: '',
    timeDate: { startDate: '' },
    obstaclesGoal: '',
    overcomeGoal: '',
    firststepGoal: ''
  });
  const [pastGoal, setPastGoal] = useState<PastGoalData[]>([]);
  const [step, setStep] = useState<number>(0);
  const [selectedPastGoal, setSelectedPastGoal] = useState<PastGoalData>();
  const [selectedPastGoalObstacle, setSelectedPastGoalObstacle] = useState<PastGoalObstacle | null>(null);
  const [isDisabled, setIsDisabled] = useState(false)
  const [game, setGame] = useState<QuestProgressGameProps>()
  const [isOpen, setIsOpen] = useState(false)
  const [tierLists, setTierLists] = useState<TierLists>();
  const apiToken = sessionStorage.getItem('api_token');

  const submitSmartGoals = async () => {
    if (goalData.timeDate === '') {
      toast.warning('Enter a date');
      return;
    }
    try {
      setIsDisabled(true);
      const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/post-create-smart-goal`,
        {
          goal_name: goalData.goals,
          specific_desc: goalData.specificGoal,
          measurable_desc: goalData.measureableGoal,
          achievable_desc: goalData.achievableGoal,
          relevant_desc: goalData.relevantGoal,
          end_date: goalData.timeDate.startDate,
          reminder: remindMe ? 1 : 0
        },
        { headers: { Authorization: `Bearer ${apiToken}` } });
      if (response.status === 201) {
        if (response.data.data.gamification) {
          setGame(response.data.data.gamification)
        }
        setGoalID(response.data.data.goal.id);
        toast.success(response.data.message);
        setStep(step + 1);
      }
      setIsDisabled(false);
    } catch (error) {
      setIsDisabled(false);
      toast.error('Error fetching data');
    }
  }
  useEffect(() => {
    if (game && game.achievement_info) {
      setIsOpen(true);
    }
  }, [game])

  const submitSmartGoalObstacles = async () => {
    if (goalData.overcomeGoal === '') {
      toast.warning('You have to write something before proceeding');
      return;
    }
    try {
      setIsDisabled(true);
      const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/post-create-smart-goal-obstacles`,
        {
          id: goalID,
          obstacles_desc: goalData.goals,
          overcome_desc: goalData.specificGoal,
          first_step: goalData.measureableGoal
        },
        { headers: { Authorization: `Bearer ${apiToken}` } });
      if (response.status === 201) {
        toast.success(response.data.message);
        setStep(step + 1);
      }
      setIsDisabled(false);
    } catch (error) {
      setIsDisabled(false);
      toast.error('Error fetching data');
    }
  }

  useEffect(() => {
    track('screenView', { screen_name: 'SmartGoalsScreen' })
    getSmartGoals();
    const tier = localStorage.getItem('tier')
    if (tier && process.env.REACT_APP_TIER_SECRET) {
      try {
        const decryptData = cryptojs.AES.decrypt(tier, process.env.REACT_APP_TIER_SECRET)
        const tierData = JSON.parse(decryptData.toString(cryptojs.enc.Utf8))
        setTierLists(tierData)
      } catch (error) {
      }
    }
  }, []);

  const getSmartGoals = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/get-smart-goals`, null,
        { headers: { Authorization: `Bearer ${apiToken}` } });
      if (response.status === 200) {
        setPastGoal(response.data.data);
      } else {
        toast.error('Error fetching data')
      }
    } catch (error) {
      toast.error('Error fetching data');
    }
  }

  const getSmartGoalsObstacles = async (goal_id: number) => {
    try {
      setIsDisabled(true);
      const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/get-smart-goal-obstacles`, { id: goal_id },
        { headers: { Authorization: `Bearer ${apiToken}` } });
      if (response.status === 200) {
        setSelectedPastGoalObstacle(response.data.data);
      }
      setIsDisabled(false);
    } catch (error) {
      setIsDisabled(false);
      toast.error('Error fetching data');
    }
  }

  const deleteSmartGoals = async (goal_id: number) => {
    try {
      setIsDisabled(true);
      const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/post-delete-smart-goal`, { smart_goal_id: goal_id },
        { headers: { Authorization: `Bearer ${apiToken}` } });
      if (response.status === 200) {
        toast.success('Smart goal deleted successfully');
        setSelectedPastGoalObstacle(null);
        getSmartGoals();
      }
      setIsDisabled(false);
    } catch (error) {
      setIsDisabled(false);
      toast.error('Error fetching data');
    }
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRemindMe(event.target.checked);
  };

  const handleDateChange = (newValue: any) => {
    setGoalData({ ...goalData, timeDate: newValue });
  };

  const handleBackClick = () => {
    setStep(step - 1);
  }

  const handleNextClick = (text: string) => {
    if (!text) {
      toast.warning('You have to write something before proceeding');
      return;
    }
    setStep(step + 1);
  }

  const handleContinueOrSummary = (step: number) => {
    setStep(step)
  }

  const handleToggleClick = (name: string) => {
    setGoalsObstaclesToggle(name);
  }

  const handlePastToggleClick = (name: string) => {
    setPastGoalsObstaclesToggle(name);
  }

  const handlePastGoalCLick = () => {
    setShowPastGoals(true);
  }
  const handleCloseClick = () => {
    setShowPastGoals(false);
  }
  const handleSelectedPastGoalBackClick = () => {
    setSelectedPastGoalObstacle(null);
  }
  const handleSelectedPastGoal = (goal: PastGoalData) => {
    setIsDisabled(true);
    setSelectedPastGoal(goal);
    getSmartGoalsObstacles(goal.id);
  }
  useEffect(() => {
    // Check if the API token is present in session storage
    const storedUserData = sessionStorage.getItem('user');

    if (apiToken && storedUserData) {
      //setUserData(JSON.parse(storedUserData));
    } else {
      // If the API token or user data is not present, redirect to the login page
      navigate('/login');
    }
  }, [navigate]);
  return (
    <div className='relative h-screen w-screen bg-white'>
      <div className='pl-16 w-full h-full'>
        <div className='w-full h-full flex flex-col items-center relative text-[#29b6e8] font-bold'>
          <div className='ml-5 absolute top-4 left-0'>
            <div className='w-fit px-3 py-1 rounded-full flex bg-[#dbedd5]'>
              <img src={smartgoal} alt='safety plan' className='w-5 mr-2' />
              <span className='font-bold text-[#add038] text-lg'>S.M.A.R.T Goals</span>
            </div>
          </div>
          {showPastGoals && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="absolute inset-0 bg-[#f4f4f4] opacity-40"></div>
              <div className="bg-white p-6 rounded-2xl z-50 relative h-[80%] w-[40%] border border-[#dedede]">
                {!selectedPastGoalObstacle ?
                  <>
                    <button onClick={() => handleCloseClick()} className="absolute top-0 right-0 m-6 text-gray-600 hover:text-gray-800 text-2xl">
                      X
                    </button>
                    <span className='text-black text-xl'>My Goals</span>
                    <div className='w-full grid grid-cols-1 h-[90%] overflow-y-scroll mt-6 gap-6'>
                      {pastGoal.map((goal, index) => (
                        <div key={index} className={`w-full h-20 border border-[#cccccc] rounded-xl flex items-center px-8 relative ${isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                          onClick={() => !isDisabled && handleSelectedPastGoal(goal)}
                        >
                          <img src={smartgoal} alt='smart goal' className='w-8' />
                          <div className='ml-6 flex flex-col'>
                            <span className='text-black'>{goal.goal_name}</span>
                            <span className='text-[#999999] text-sm'>{goal.specific_desc}</span>
                          </div>
                          <img src={blackarrow} alt='black arrow' className='absolute right-8' />
                        </div>
                      ))}
                    </div>
                  </>
                  :
                  <div className='w-full h-full'>
                    <div className='w-full flex justify-between mb-4'>
                      <button className='flex items-center' onClick={() => handleSelectedPastGoalBackClick()}>
                        <img src={back} alt='back' className='mr-2' />
                        <span className='font-bold text-[#00ace5]'>Back</span>
                      </button>
                      <button disabled={isDisabled} onClick={() => deleteSmartGoals(selectedPastGoal!.id)} className='text-white font-bold bg-[#ed3426] rounded-full px-4 py-1 flex items-center justify-center'> {isDisabled ? <Loader /> : <>
                        <img src={whitecross} alt='white cross' className='mr-2' /><p>Delete Goal</p></>}</button>
                    </div>
                    <span className='text-black text-xl'>{selectedPastGoal?.goal_name}</span>
                    <div className='w-full h-full flex flex-col items-center mt-4'>
                      {selectedPastGoalObstacle.first_step !== null && (
                        <div className='w-fit h-auto rounded-full border border-[#e3e2e2]'>
                          <button className={`py-1 px-6 rounded-full ${pastGoalsObstaclesToggle === 'goals' ? 'text-white bg-[#29b6e8]' : 'text-[#929292]'}`} onClick={() => handlePastToggleClick('goals')}>S.M.A.R.T Goals</button>
                          <button className={`py-1 px-6 rounded-full ${pastGoalsObstaclesToggle === 'obstacles' ? 'text-white bg-[#29b6e8]' : 'text-[#929292]'}`} onClick={() => handlePastToggleClick('obstacles')}>Obstacles</button>
                        </div>
                      )}
                      {pastGoalsObstaclesToggle === 'goals' ?
                        <>
                          <div className='w-full h-[75%] overflow-y-scroll'>
                            <div className='w-full grid grid-cols-2 mt-6 gap-2'>
                              <div className='w-full h-fit bg-[#c3e7f7] rounded-2xl p-3'>
                                <div className='w-full flex items-center gap-3'>
                                  <div className='w-10 h-10 rounded-full bg-[#29b6e8] text-white flex items-center justify-center text-xl'>S</div>
                                  <span className='text-lg'>Specific</span>
                                </div>
                                <div className='rounded-xl w-full h-20 bg-white mt-2 p-3'>
                                  <span className='text-[#878787]'>{selectedPastGoal?.specific_desc}</span>
                                </div>
                              </div>
                              <div className='w-full h-fit bg-[#ffefd4] rounded-2xl p-3'>
                                <div className='w-full flex items-center gap-3'>
                                  <div className='w-10 h-10 rounded-full bg-[#faa81d] text-white flex items-center justify-center text-xl'>M</div>
                                  <span className='text-lg text-[#faa81d]'>Measurable</span>
                                </div>
                                <div className='rounded-xl w-full h-20 bg-white mt-2 p-3'>
                                  <span className='text-[#878787]'>{selectedPastGoal?.measurable_desc}</span>
                                </div>
                              </div>
                              <div className='w-full h-fit bg-[#fde0de] rounded-2xl p-3'>
                                <div className='w-full flex items-center gap-3'>
                                  <div className='w-10 h-10 rounded-full bg-[#ed3426] text-white flex items-center justify-center text-xl'>A</div>
                                  <span className='text-lg text-[#ed3426]'>Achievable</span>
                                </div>
                                <div className='rounded-xl w-full h-20 bg-white mt-2 p-3'>
                                  <span className='text-[#878787]'>{selectedPastGoal?.achievable_desc}</span>
                                </div>
                              </div>
                              <div className='w-full h-fit bg-[#ede6f0] rounded-2xl p-3'>
                                <div className='w-full flex items-center gap-3'>
                                  <div className='w-10 h-10 rounded-full bg-[#a272b1] text-white flex items-center justify-center text-xl'>R</div>
                                  <span className='text-lg text-[#a272b1]'>Relevancy</span>
                                </div>
                                <div className='rounded-xl w-full h-20 bg-white mt-2 p-3'>
                                  <span className='text-[#878787]'>{selectedPastGoal?.relevant_desc}</span>
                                </div>
                              </div>
                            </div>
                            <div className='w-full flex justify-center mt-2'>
                              <div className='w-[50%] h-fit bg-[#dbedd5] rounded-2xl p-3'>
                                <div className='w-full flex items-center gap-3'>
                                  <div className='w-10 h-10 rounded-full bg-[#add038] text-white flex items-center justify-center text-xl'>T</div>
                                  <span className='text-lg text-[#add038]'>Time Bound</span>
                                </div>
                                <div className='rounded-xl w-full h-20 bg-white mt-2 p-3'>
                                  <span className='text-[#878787]'>{selectedPastGoal?.end_date}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                        :
                        <>
                          <div className='w-full h-[75%] overflow-y-scroll mt-4 px-10'>
                            <div className='w-full rounded-2xl bg-[#dbedd5] flex flex-col items-center p-3'>
                              <img src={summaryobstacles} alt='summary obstacles' className='w-[10%]' />
                              <span className='text-[#add038] text-xl'>Obstacles</span>
                              <span className='text-black font-normal'>What are your obstacles?</span>
                              <div className='bg-white w-full h-[12vh] rounded-lg mt-4 p-3 flex'>
                                <span className='flex-1 break-all text-[#878787]'>{selectedPastGoalObstacle.obstacles_desc}</span>
                              </div>
                            </div>
                            <div className='w-full rounded-2xl bg-[#ffefd4] flex flex-col items-center p-3 mt-2'>
                              <img src={summaryovercoming} alt='summary obstacles' className='w-[10%]' />
                              <span className='text-[#faa81d] text-xl'>Overcoming Obstacles</span>
                              <span className='text-black font-normal'>How will you overcome the obstacles?</span>
                              <div className='bg-white w-full h-[12vh] rounded-lg mt-4 p-3 flex'>
                                <span className='flex-1 break-all text-[#878787]'>{selectedPastGoalObstacle.overcome_desc}</span>
                              </div>
                            </div>
                            <div className='w-full rounded-2xl bg-[#ede6f0] flex flex-col items-center p-3 mt-2'>
                              <img src={summaryfirst} alt='summary obstacles' className='w-[10%]' />
                              <span className='text-[#a272b1] text-xl'>First Step</span>
                              <span className='text-black font-normal'>The very first step you'll take!</span>
                              <div className='bg-white w-full h-[12vh] rounded-lg mt-4 p-3 flex'>
                                <span className='flex-1 break-all text-[#878787]'>{selectedPastGoalObstacle.first_step}</span>
                              </div>
                            </div>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                }
              </div>
            </div>
          )}
          {step === 0 ?
            <>
              <button className='absolute right-[18%] top-[9%] flex justify-center items-center font-bold text-[#161d45]' onClick={handlePastGoalCLick}>
                <img src={pastgoal} alt='past goal' className='w-6 mr-2' /> Past Goals &gt;
              </button>
              <img src={datasafe} alt='data safe' className='w-8 h-8 mt-8' />
              <span className='text-[#808080] font-bold mt-2'>Your data is safe and only seen by you!</span>
              <div className='h-full w-[30%] bg-gradient-to-t from-[#04ACE8] to-[#5BC7EE] rounded-2xl mt-4 mb-8 flex flex-col items-center'>
                <div className='image-background mt-6 flex flex-col items-center justify-center font-bold text-[#12143e] text-sm'>
                  <span className='text-3xl text-[#29b6e8]'>Why Set Goals?</span>
                  <span className='mt-2'>Goals are magical plans</span>
                  <span>for a better, happier you!</span>
                  <span className='mt-8'>Use the S.M.A.R.T</span>
                  <span>framework to set clear,</span>
                  <span>achievable goals</span>
                </div>
                <span className='text-white font-bold my-4 text-2xl'>Take the first step</span>
                <input placeholder='Type your goal'
                  className='w-[70%] h-[10%] rounded-md text-center outline-none'
                  value={goalData.goals}
                  maxLength={255}
                  onChange={(event) => setGoalData({ ...goalData, goals: event.target.value })} />
                <button className='mt-12 rounded-full bg-white px-12 py-2 font-bold text-[#29b6e8]' onClick={() => handleNextClick(goalData.goals)}>Make it S.M.A.R.T</button>
              </div>
            </>
            : step === 1 ?
              <>
                <button className='w-full absolute top-[20%] left-[28%] flex items-center' onClick={() => handleBackClick()}>
                  <img src={back} alt='back' className='mr-2' />
                  <span className='font-bold text-[#00ace5]'>Back</span>
                </button>
                <span className='font-bold mt-8 text-[#12143e] text-xl'>Goal Setup</span>
                <div className='w-full flex items-center justify-center mt-6 gap-8'>
                  <div className='w-12 h-12 rounded-full bg-[#12143e] text-white font-bold flex items-center justify-center'>1</div>
                  <div className='w-12 h-12 rounded-full bg-[#f0f0f0] font-bold flex items-center justify-center'>2</div>
                  <div className='w-12 h-12 rounded-full bg-[#f0f0f0] font-bold flex items-center justify-center'>3</div>
                  <div className='w-12 h-12 rounded-full bg-[#f0f0f0] font-bold flex items-center justify-center'>4</div>
                  <div className='w-12 h-12 rounded-full bg-[#f0f0f0] font-bold flex items-center justify-center'>5</div>
                </div>
                <img src={specific} alt='specific' className='w-[35%] h-[35%]' />
                <span className='text-[#29b6e8] font-bold text-xl'>Specific</span>
                <span>Have a clear and specific goal,</span>
                <span>Instead of. "I want to do better in school,"</span>
                <span>"I want to get an A grade in math."</span>
                <textarea placeholder='What exact goal you want to accomplish'
                  value={goalData.specificGoal}
                  maxLength={1000}
                  className='rounded-xl bg-[#f0f0f0] w-[40%] h-28 resize-none font-normal p-2 outline-none text-black mt-4'
                  onChange={(event) => setGoalData({ ...goalData, specificGoal: event.target.value })}>
                </textarea>
                <button className='rounded-full py-2 px-32 bg-[#29b6e8] text-white mt-6' onClick={() => handleNextClick(goalData.specificGoal)}>Next</button>
              </>
              : step === 2 ?
                <>
                  <button className='w-full absolute top-[20%] left-[28%] flex items-center' onClick={() => handleBackClick()}>
                    <img src={back} alt='back' className='mr-2' />
                    <span className='font-bold text-[#00ace5]'>Back</span>
                  </button>
                  <span className='font-bold mt-8 text-[#12143e] text-xl'>Goal Setup</span>
                  <div className='w-full flex items-center justify-center mt-6 gap-8'>
                    <div className='w-12 h-12 rounded-full bg-[#12143e] text-white font-bold flex items-center justify-center'>1</div>
                    <div className='w-12 h-12 rounded-full bg-[#12143e] text-white font-bold flex items-center justify-center'>2</div>
                    <div className='w-12 h-12 rounded-full bg-[#f0f0f0] font-bold flex items-center justify-center'>3</div>
                    <div className='w-12 h-12 rounded-full bg-[#f0f0f0] font-bold flex items-center justify-center'>4</div>
                    <div className='w-12 h-12 rounded-full bg-[#f0f0f0] font-bold flex items-center justify-center'>5</div>
                  </div>
                  <img src={measurable} alt='specific' className='w-[35%] h-[35%] mt-4' />
                  <span className='text-[#faa81d] font-bold text-xl'>Measurable</span>
                  <span className='text-[#faa81d]'>You can measure your progress, just like a ruler to see how much</span>
                  <span className='text-[#faa81d]'>you've achieved. For math, it's seeing your test scores go up each time</span>
                  <textarea placeholder='What exact goal you want to accomplish'
                    value={goalData.measureableGoal}
                    maxLength={1000}
                    className='rounded-xl bg-[#f0f0f0] w-[40%] h-28 resize-none font-normal p-2 outline-none text-black mt-4'
                    onChange={(event) => setGoalData({ ...goalData, measureableGoal: event.target.value })}>
                  </textarea>
                  <button className='rounded-full py-2 px-32 bg-[#29b6e8] text-white mt-6' onClick={() => handleNextClick(goalData.measureableGoal)}>Next</button>
                </>
                : step === 3 ?
                  <>
                    <button className='w-full absolute top-[20%] left-[28%] flex items-center' onClick={() => handleBackClick()}>
                      <img src={back} alt='back' className='mr-2' />
                      <span className='font-bold text-[#00ace5]'>Back</span>
                    </button>
                    <span className='font-bold mt-8 text-[#12143e] text-xl'>Goal Setup</span>
                    <div className='w-full flex items-center justify-center mt-6 gap-8'>
                      <div className='w-12 h-12 rounded-full bg-[#12143e] text-white font-bold flex items-center justify-center'>1</div>
                      <div className='w-12 h-12 rounded-full bg-[#12143e] text-white font-bold flex items-center justify-center'>2</div>
                      <div className='w-12 h-12 rounded-full bg-[#12143e] text-white font-bold flex items-center justify-center'>3</div>
                      <div className='w-12 h-12 rounded-full bg-[#f0f0f0] font-bold flex items-center justify-center'>4</div>
                      <div className='w-12 h-12 rounded-full bg-[#f0f0f0] font-bold flex items-center justify-center'>5</div>
                    </div>
                    <img src={achievable} alt='specific' className='w-[35%] h-[35%] mt-4' />
                    <span className='text-[#ed3426] font-bold text-xl'>Achievable</span>
                    <span className='text-[#ed3426]'>It means setting a goal you can actually accomplish.</span>
                    <span className='text-[#ed3426]'>"I want to be able to swim across the pool by myself without floaties"</span>
                    <textarea placeholder='What will I do to achieve this goal?'
                      className='rounded-xl bg-[#f0f0f0] w-[40%] h-28 resize-none font-normal p-2 outline-none text-black mt-4'
                      value={goalData.achievableGoal}
                      maxLength={1000}
                      onChange={(event) => setGoalData({ ...goalData, achievableGoal: event.target.value })}>
                    </textarea>
                    <button className='rounded-full py-2 px-32 bg-[#29b6e8] text-white mt-6' onClick={() => handleNextClick(goalData.achievableGoal)}>Next</button>
                  </>
                  : step === 4 ?
                    <>
                      <button className='w-full absolute top-[20%] left-[28%] flex items-center' onClick={() => handleBackClick()}>
                        <img src={back} alt='back' className='mr-2' />
                        <span className='font-bold text-[#00ace5]'>Back</span>
                      </button>
                      <span className='font-bold mt-8 text-[#12143e] text-xl'>Goal Setup</span>
                      <div className='w-full flex items-center justify-center mt-6 gap-8'>
                        <div className='w-12 h-12 rounded-full bg-[#12143e] text-white font-bold flex items-center justify-center'>1</div>
                        <div className='w-12 h-12 rounded-full bg-[#12143e] text-white font-bold flex items-center justify-center'>2</div>
                        <div className='w-12 h-12 rounded-full bg-[#12143e] text-white font-bold flex items-center justify-center'>3</div>
                        <div className='w-12 h-12 rounded-full bg-[#12143e] text-white font-bold flex items-center justify-center'>4</div>
                        <div className='w-12 h-12 rounded-full bg-[#f0f0f0] font-bold flex items-center justify-center'>5</div>
                      </div>
                      <img src={relevant} alt='specific' className='w-[35%] h-[35%] mt-4' />
                      <span className='text-[#a071af] font-bold text-xl'>Relevant</span>
                      <span className='text-[#a071af]'>Your goal should be a goal that you love and feel good about, like</span>
                      <span className='text-[#a071af]'>getting better at drawing to create amazing pictures!</span>
                      <textarea placeholder='Is this goal worthwhile?'
                        className='rounded-xl bg-[#f0f0f0] w-[40%] h-28 resize-none font-normal p-2 outline-none text-black mt-4'
                        value={goalData.relevantGoal}
                        maxLength={1000}
                        onChange={(event) => setGoalData({ ...goalData, relevantGoal: event.target.value })}>
                      </textarea>
                      <button className='rounded-full py-2 px-32 bg-[#29b6e8] text-white mt-6' onClick={() => handleNextClick(goalData.relevantGoal)}>Next</button>
                    </>
                    : step === 5 ?
                      <>
                        <button className='w-full absolute top-[20%] left-[28%] flex items-center' onClick={() => handleBackClick()}>
                          <img src={back} alt='back' className='mr-2' />
                          <span className='font-bold text-[#00ace5]'>Back</span>
                        </button>
                        <span className='font-bold mt-8 text-[#12143e] text-xl'>Goal Setup</span>
                        <div className='w-full flex items-center justify-center mt-6 gap-8'>
                          <div className='w-12 h-12 rounded-full bg-[#12143e] text-white font-bold flex items-center justify-center'>1</div>
                          <div className='w-12 h-12 rounded-full bg-[#12143e] text-white font-bold flex items-center justify-center'>2</div>
                          <div className='w-12 h-12 rounded-full bg-[#12143e] text-white font-bold flex items-center justify-center'>3</div>
                          <div className='w-12 h-12 rounded-full bg-[#12143e] text-white font-bold flex items-center justify-center'>4</div>
                          <div className='w-12 h-12 rounded-full bg-[#12143e] text-white font-bold flex items-center justify-center'>5</div>
                        </div>
                        <img src={timebound} alt='specific' className='w-[35%] h-[35%] mt-4' />
                        <span className='text-[#add038] font-bold text-xl'>Time Bound</span>
                        <span className='text-[#add038]'>It means setting a time limit for your goal. It's like having a race with a</span>
                        <span className='text-[#add038]'>finish line. So, you can say, "I want to learn to ride a bike without</span>
                        <span className='text-[#add038]'>training wheels before my birthday."</span>
                        <div className='flex mt-4 items-center justify-center'>
                          <span className='mr-2 w-32 text-black text-center'>End date</span>
                          <Datepicker useRange={false} asSingle={true} value={goalData.timeDate} onChange={handleDateChange} placeholder={"Choose a Date"} displayFormat={"YYYY-MM-DD"} />
                          {/* <input type='checkbox' id='checkbox1' value='remindMe' className='ml-4 mr-4' checked={remindMe} onChange={handleCheckboxChange}/>
                  <label htmlFor='checkbox1' className='w-40 text-black'>Remind Me?</label> */}
                        </div>
                        <button disabled={isDisabled} className='rounded-full py-2 px-32 bg-[#29b6e8] text-white mt-6' onClick={() => submitSmartGoals()}>{isDisabled ? <Loader /> : 'Next'}</button>
                      </>
                      : step === 6 ?
                        <>
                          <img src={congratulation} alt='congratilation' className='w-[20%] mt-8' />
                          <span className='text-3xl mt-4'>Congratulations</span>
                          <span className='text-black mt-1'>You're on your way to achieving your S.M.A.R.T Goal</span>
                          <div className='bg-[#f0f0f0] rounded-2xl py-6 px-32 mt-4'>
                            <span>{goalData.goals}</span>
                          </div>
                          <img src={questionmarkgoal} alt='Goals?' className='w-[20%]' />
                          <span>The next steps are optional but highly recommended</span>
                          <span>They'll set you up for success</span>
                          <span>If you're ready, let's keep going.</span>
                          <div className='w-full flex justify-center items-center gap-8'>
                            <button className='rounded-full py-2 px-10 bg-[#29b6e8] text-white mt-6' onClick={() => handleContinueOrSummary(10)}>Goal Summary</button>
                            <button className='rounded-full py-2 px-10 bg-[#29b6e8] text-white mt-6' onClick={() => handleContinueOrSummary(7)}>Continue</button>
                          </div>
                        </>
                        : step === 7 ?
                          <>
                            <button className='w-full absolute top-[20%] left-[28%] flex items-center' onClick={() => handleBackClick()}>
                              <img src={back} alt='back' className='mr-2' />
                              <span className='font-bold text-[#00ace5]'>Back</span>
                            </button>
                            <span className='font-bold mt-8 text-[#12143e] text-xl'>Goal Setup</span>
                            <div className='w-full flex items-center justify-center mt-6 gap-8'>
                              <div className='w-12 h-12 rounded-full bg-[#12143e] text-white font-bold flex items-center justify-center'>1</div>
                              <div className='w-12 h-12 rounded-full bg-[#f0f0f0] text-black font-bold flex items-center justify-center'>2</div>
                              <div className='w-12 h-12 rounded-full bg-[#f0f0f0] text-black font-bold flex items-center justify-center'>3</div>
                            </div>
                            <span className='mt-4 text-[#12143e]'>Now, let's tackle potential obstacles head-on!</span>
                            <img src={obstacles} alt='obstacles' className='w-[19%]' />
                            <span className='text-[#a9cb35]'>Type down the things that might go wrong in your</span>
                            <span className='text-[#a9cb35]'>journey to reaching your goals</span>
                            <span className='text-[#666666] mt-4'>It's okay to think about challenges; we'll conquer them together</span>
                            <textarea className='bg-[#f0f0f0] outline-none resize-none rounded-2xl w-[40%] h-[15%] mt-4 p-4 text-black'
                              placeholder='What challenges or obstacles do you foresee in reaching your goals?'
                              value={goalData.obstaclesGoal}
                              maxLength={1000}
                              onChange={(event) => setGoalData({ ...goalData, obstaclesGoal: event.target.value })}
                            ></textarea>
                            <button className='rounded-full py-2 px-32 bg-[#29b6e8] text-white mt-6' onClick={() => handleNextClick(goalData.obstaclesGoal)}>Next</button>
                          </>
                          : step === 8 ?
                            <>
                              <button className='w-full absolute top-[20%] left-[28%] flex items-center' onClick={() => handleBackClick()}>
                                <img src={back} alt='back' className='mr-2' />
                                <span className='font-bold text-[#00ace5]'>Back</span>
                              </button>
                              <span className='font-bold mt-8 text-[#12143e] text-xl'>Goal Setup</span>
                              <div className='w-full flex items-center justify-center mt-6 gap-8'>
                                <div className='w-12 h-12 rounded-full bg-[#12143e] text-white font-bold flex items-center justify-center'>1</div>
                                <div className='w-12 h-12 rounded-full bg-[#12143e] text-white font-bold flex items-center justify-center'>2</div>
                                <div className='w-12 h-12 rounded-full bg-[#f0f0f0] text-black font-bold flex items-center justify-center'>3</div>
                              </div>
                              <span className='mt-4 text-[#12143e]'>Let's plan to overcome them!</span>
                              <img src={firststep} alt='first step' className='w-[19%]' />
                              <span className='text-[#faa81d]'>List down the things you can do to tackle each</span>
                              <span className='text-[#faa81d]'>obstacle you've identified</span>
                              <span className='text-[#666666] mt-4'>Remember, you're stronger than any challenge you face!</span>
                              <textarea className='bg-[#f0f0f0] outline-none resize-none rounded-2xl w-[40%] h-[15%] mt-4 p-4 text-black'
                                placeholder='How will you overcome the obstacles you listed earlier?'
                                value={goalData.firststepGoal}
                                maxLength={1000}
                                onChange={(event) => setGoalData({ ...goalData, firststepGoal: event.target.value })}
                              ></textarea>
                              <button className='rounded-full py-2 px-32 bg-[#29b6e8] text-white mt-6' onClick={() => handleNextClick(goalData.firststepGoal)}>Next</button>
                            </>
                            : step === 9 ?
                              <>
                                <button className='w-full absolute top-[20%] left-[28%] flex items-center' onClick={() => handleBackClick()}>
                                  <img src={back} alt='back' className='mr-2' />
                                  <span className='font-bold text-[#00ace5]'>Back</span>
                                </button>
                                <span className='font-bold mt-8 text-[#12143e] text-xl'>Goal Setup</span>
                                <div className='w-full flex items-center justify-center mt-6 gap-8'>
                                  <div className='w-12 h-12 rounded-full bg-[#12143e] text-white font-bold flex items-center justify-center'>1</div>
                                  <div className='w-12 h-12 rounded-full bg-[#12143e] text-white font-bold flex items-center justify-center'>2</div>
                                  <div className='w-12 h-12 rounded-full bg-[#12143e] text-white font-bold flex items-center justify-center'>3</div>
                                </div>
                                <span className='mt-4 text-[#12143e]'>Now, let's take action!</span>
                                <img src={overcoming} alt='first step' className='w-[19%]' />
                                <span className='text-[#faa81d]'>Write down the very first step you'll take towards</span>
                                <span className='text-[#faa81d]'>your SMART goal.</span>
                                <span className='text-[#666666] mt-4'>{goalData.obstaclesGoal}</span>
                                <textarea className='bg-[#f0f0f0] outline-none resize-none rounded-2xl w-[40%] h-[15%] mt-4 p-4 text-black'
                                  placeholder='How will you overcome the obstacles you listed earlier?'
                                  value={goalData.overcomeGoal}
                                  maxLength={1000}
                                  onChange={(event) => setGoalData({ ...goalData, overcomeGoal: event.target.value })}
                                ></textarea>
                                <button className='rounded-full py-2 px-32 bg-[#29b6e8] text-white mt-6' onClick={() => submitSmartGoalObstacles()}>Next</button>
                              </>
                              :
                              <>
                                <img src={congratulation} alt='congratilation' className='w-[20%] mt-8' />
                                <span className='text-3xl mt-4'>Congratulations</span>
                                <span className='text-black mt-1'>You've started a goal!</span>
                                <span className='mt-4 text-2xl'>{goalData.goals}</span>
                                <div className='w-full h-full mt-6 px-[18%]'>
                                  <div className='w-fit h-auto rounded-full border border-[#e3e2e2]'>
                                    <button className={`py-1 px-6 rounded-full ${goalsObstaclesToggle === 'goals' ? 'text-white bg-[#29b6e8]' : 'text-[#929292]'}`} onClick={() => handleToggleClick('goals')}>S.M.A.R.T Goals</button>
                                    <button className={`py-1 px-6 rounded-full ${goalsObstaclesToggle === 'obstacles' ? 'text-white bg-[#29b6e8]' : 'text-[#929292]'}`} onClick={() => handleToggleClick('obstacles')}>Obstacles</button>
                                  </div>
                                  {goalsObstaclesToggle === 'goals' ?
                                    <>
                                      <div className='w-full grid-cols-3 grid mt-6 gap-4'>
                                        <div className='w-full h-fit bg-[#c3e7f7] rounded-2xl p-3'>
                                          <div className='w-full flex items-center gap-3'>
                                            <div className='w-10 h-10 rounded-full bg-[#29b6e8] text-white flex items-center justify-center text-xl'>S</div>
                                            <span className='text-lg'>Specific</span>
                                          </div>
                                          <div className='rounded-xl w-full h-20 bg-white mt-2 p-3'>
                                            <span className='text-[#878787]'>{goalData.specificGoal}</span>
                                          </div>
                                        </div>
                                        <div className='w-full h-fit bg-[#ffefd4] rounded-2xl p-3'>
                                          <div className='w-full flex items-center gap-3'>
                                            <div className='w-10 h-10 rounded-full bg-[#faa81d] text-white flex items-center justify-center text-xl'>M</div>
                                            <span className='text-lg text-[#faa81d]'>Measurable</span>
                                          </div>
                                          <div className='rounded-xl w-full h-20 bg-white mt-2 p-3'>
                                            <span className='text-[#878787]'>{goalData.measureableGoal}</span>
                                          </div>
                                        </div>
                                        <div className='w-full h-fit bg-[#fde0de] rounded-2xl p-3'>
                                          <div className='w-full flex items-center gap-3'>
                                            <div className='w-10 h-10 rounded-full bg-[#ed3426] text-white flex items-center justify-center text-xl'>A</div>
                                            <span className='text-lg text-[#ed3426]'>Achievable</span>
                                          </div>
                                          <div className='rounded-xl w-full h-20 bg-white mt-2 p-3'>
                                            <span className='text-[#878787]'>{goalData.achievableGoal}</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='w-full gap-4 mt-4 flex justify-center'>
                                        <div className='w-[32.5%] h-fit bg-[#ede6f0] rounded-2xl p-3'>
                                          <div className='w-full flex items-center gap-3'>
                                            <div className='w-10 h-10 rounded-full bg-[#a272b1] text-white flex items-center justify-center text-xl'>R</div>
                                            <span className='text-lg text-[#a272b1]'>Relevancy</span>
                                          </div>
                                          <div className='rounded-xl w-full h-20 bg-white mt-2 p-3'>
                                            <span className='text-[#878787]'>{goalData.relevantGoal}</span>
                                          </div>
                                        </div>
                                        <div className='w-[32.5%] h-fit bg-[#dbedd5] rounded-2xl p-3'>
                                          <div className='w-full flex items-center gap-3'>
                                            <div className='w-10 h-10 rounded-full bg-[#add038] text-white flex items-center justify-center text-xl'>T</div>
                                            <span className='text-lg text-[#add038]'>Time Bound</span>
                                          </div>
                                          <div className='rounded-xl w-full h-20 bg-white mt-2 p-3'>
                                            <span className='text-[#878787]'>{goalData.timeDate.startDate}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                    :
                                    <>
                                      <div className='w-full grid-cols-3 grid mt-6 gap-2'>
                                        <div className='w-full rounded-2xl bg-[#dbedd5] flex flex-col items-center p-3'>
                                          <img src={summaryobstacles} alt='summary obstacles' className='w-[20%]' />
                                          <span className='text-[#add038] text-xl'>Obstacles</span>
                                          <span className='text-black font-normal'>What are your obstacles?</span>
                                          <div className='bg-white w-full h-[24vh] rounded-lg mt-4 p-3 flex'>
                                            <span className='flex-1 break-all text-[#878787]'>{goalData.obstaclesGoal}</span>
                                          </div>
                                        </div>
                                        <div className='w-full rounded-2xl bg-[#ffefd4] flex flex-col items-center p-3'>
                                          <img src={summaryovercoming} alt='summary obstacles' className='w-[20%]' />
                                          <span className='text-[#faa81d] text-xl'>Overcoming Obstacles</span>
                                          <span className='text-black font-normal'>How will you overcome the obstacles?</span>
                                          <div className='bg-white w-full h-[24vh] rounded-lg mt-4 p-3 flex'>
                                            <span className='flex-1 break-all text-[#878787]'>{goalData.overcomeGoal}</span>
                                          </div>
                                        </div>
                                        <div className='w-full rounded-2xl bg-[#ede6f0] flex flex-col items-center p-3'>
                                          <img src={summaryfirst} alt='summary obstacles' className='w-[20%]' />
                                          <span className='text-[#a272b1] text-xl'>First Step</span>
                                          <span className='text-black font-normal'>The very first step you'll take!</span>
                                          <div className='bg-white w-full h-[24vh] rounded-lg mt-4 p-3 flex'>
                                            <span className='flex-1 break-all text-[#878787]'>{goalData.firststepGoal}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  }
                                  <div className='w-full flex items-center justify-center mt-4'>
                                    <Link to='/home' className='rounded-full py-2 px-32 bg-[#29b6e8] text-white mt-6'>Back to Home</Link>
                                  </div>
                                </div>
                              </>
          }
        </div>
      </div>
      {game && <QuestProgressPopUp gamification={game} isOpen={isOpen} close={() => setIsOpen(false)} />}
      {tierLists?.smart_goals ? <></> : <Blur />}
    </div>
  )
}

export default SmartGoals