import { create } from "zustand"

export interface DisableSection {
    disable_module?: undefined | "all_feature" | "chat_ai" | "deleted_org",
    disable_icon?: string
    disable_title?: string
    disable_subject?: string,
    disable_content?: string,
    disable_caption?: string,
}

export interface DisabledOrg extends DisableSection {
    login?: string | null,
}

type DisabledOrgProps = {
    disabledOrg: DisabledOrg,
    setDisabledOrg: (value: DisabledOrg) => void
}

export const useDisabledOrgStore = create<DisabledOrgProps>((set) => ({
    disabledOrg: {},
    setDisabledOrg: (newValue) => set(() => ({ disabledOrg: newValue })),
}))