import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import journal from '../asset/journal/Journal.svg';
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { tick, congratspop, mood1active, mood1inactive, mood2active, mood2inactive, mood3active, mood3inactive, mood4active, mood4inactive, mood5active, mood5inactive, } from '../asset';
import Loader from './icons/Loader';

interface Prompt {
  id: string;
  title_id: string;
  literature: string;
  end_encouragement_text: string;
  daily_submitted: number;
}

interface JournalWidgetProps {
  prompt: Prompt;
  apiToken: string;
  children?: React.ReactNode
}

function JournalWidget({ prompt, apiToken, children }: JournalWidgetProps) {
  const [journalInput, setJournalInput] = useState<string>('');
  const [journalSubmitted, setJournalSubmitted] = useState<boolean>(false);
  const [emotion, setEmotion] = useState<number>(0);
  const [showCongrats, setShowCongrats] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    if (prompt.daily_submitted === 1) {
      setJournalSubmitted(true);
    }
  }, []);

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    setJournalInput(event.target.value);
  };

  const submitJournal = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    try {
      setIsDisabled(true)
      const submitResponse: AxiosResponse<any> = await axios.post(
        `${process.env.REACT_APP_ENV_URL}/api/app/mobile/create-guidedjournal`,
        {
          prompt_id: prompt.id,
          journal: journalInput,
          title_id: prompt.title_id
        },
        {
          headers: {
            Authorization: `Bearer ${apiToken}`
          }
        }
      );
      if (submitResponse.status === 200) {
        setShowCongrats(true);
        toast.success(prompt.end_encouragement_text);
        setJournalSubmitted(true);
      }
      setIsDisabled(false)
    } catch (error) {
      setIsDisabled(false)
      toast.error('Error submitting');
    }
  };
  const getMood1Image = (): string => {
    if (emotion === 1) {
      return mood1active;
    } else {
      return mood1inactive;
    }
  }
  const getMood2Image = (): string => {
    if (emotion === 2) {
      return mood2active;
    } else {
      return mood2inactive;
    }
  }
  const getMood3Image = () => {
    if (emotion === 3) {
      return mood3active;
    } else {
      return mood3inactive;
    }
  }
  const getMood4Image = (): string => {
    if (emotion === 4) {
      return mood4active;
    } else {
      return mood4inactive;
    }
  }
  const getMood5Image = (): string => {
    if (emotion === 5) {
      return mood5active;
    } else {
      return mood5inactive;
    }
  }
  const handleEmotionClick = (emotion: number) => {
    setShowCongrats(false);
    setEmotion(0);
  };

  return (
    <div className='relative py-4 xl:p-16 w-full h-fit flex'>
      <div className=''>
        <img src={journal} alt='journal' className='w-24' />
      </div>
      <div className='flex flex-col w-full h-full justify-center mt-11 xl:ml-4'>
        {journalSubmitted ?
          <>
            <div className='w-full flex items-center'>
              <div className='rounded-full w-6 h-6 bg-[#a071af] flex items-center justify-center mr-2'>
                <img src={tick} alt='tick' className='w-3' />
              </div>
              <span className='font-bold text-[#a071af] text-2xl'>You have completed today's reflection!</span>
            </div>
          </>
          :
          <>
            <div className='px-6'>
              <p className='text-[#a071af] font-bold text-xl xl:ml-4'>Today's Reflection</p>
              <p className='text-[#a071af] font-semibold my-4'>Write about a time when you made a sacrifice for someone else.</p>
            </div>
            <form onSubmit={submitJournal} className="w-[90%] h-40 max-h-40 rounded-2xl bg-[#f0f0f0] px-3 py-2 transition-all duration-500 overflow-hidden relative mb-8">
              <span className='md:hidden text-[#666666] font-bold'>{prompt?.literature}</span>
              <textarea maxLength={1000} required onChange={handleInputChange} placeholder='Write down your thoughts...' className='bg-transparent w-full h-[60%] placeholder:text-[#666666] outline-none resize-none mt-2'></textarea>
              <button disabled={isDisabled} className='absolute bottom-2 right-3 bg-[#a071af] rounded-full text-white font-bold flex justify-center items-center w-10 h-10' type='submit'>{isDisabled ? <Loader/> : <img src={tick} alt='tick' /> }</button>
            </form>
          </>
        }
      </div>
      {showCongrats && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-[#f4f4f4] opacity-40"></div>
          <div className="bg-white p-8 rounded-2xl z-50 relative h-fit w-[27%] border border-[#dedede] flex flex-col items-center">
            <img src={congratspop} alt='congratulation' />
            <span className='font-bold text-xl mt-2'>Congrats for taking the step</span>
            <span className='font-bold text-xl'>towards building self-care</span>
            <span className='font-bold text-[#29b6e9] mt-10'>How do you feel after journaling?</span>
            <div className='w-full grid grid-cols-5 mt-4 ml-6 mb-10'>
              <div className='flex flex-col items-center justify-center w-12'>
                <img src={getMood1Image()} alt='mood-1' className='w-12 cursor-pointer' onClick={() => handleEmotionClick(1)} />
                <span className='mt-2 text-[#e83223]'>Worse</span>
              </div>
              <img src={getMood2Image()} alt='mood-2' className='w-12 cursor-pointer col-span-1' onClick={() => handleEmotionClick(2)} />
              <img src={getMood3Image()} alt='mood-3' className='w-12 cursor-pointer col-span-1' onClick={() => handleEmotionClick(3)} />
              <img src={getMood4Image()} alt='mood-4' className='w-12 cursor-pointer col-span-1' onClick={() => handleEmotionClick(4)} />
              <div className='flex flex-col items-center w-12'>
                <img src={getMood5Image()} alt='mood-5' className='w-12 cursor-pointer' onClick={() => handleEmotionClick(5)} />
                <span className='mt-2 text-[#a9cb35]'>Better</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {children}
    </div>
  );
}

export default JournalWidget;
