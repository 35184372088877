import React from 'react'
import { IconProps } from '../../types/icon'

const SeenBlueIcon: React.FC<IconProps> = ({ className }) => {
    return (
        <svg className={className} fill="#1EB8EB" width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">

            <g id="SVGRepo_bgCarrier" strokeWidth="0" />

            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />

            <g id="SVGRepo_iconCarrier">

                <path d="M20.169,5.243l-10,9a1,1,0,0,1-1.376-.036l-5-5A1,1,0,1,1,5.207,7.793l4.329,4.329,9.3-8.365a1,1,0,1,1,1.338,1.486ZM18.831,9.757,9.536,18.122,5.207,13.793a1,1,0,0,0-1.414,1.414l5,5a1,1,0,0,0,1.376.036l10-9a1,1,0,0,0-1.338-1.486Z" />

            </g>

        </svg>
    )
}
export default React.memo(SeenBlueIcon)