import React, { useEffect, useState } from 'react';
//import zoalacoin from '../asset/home/Zoala Coin.svg';
// import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
//import Calendar from './Calendar';
import DailyVibes from './DailyVibes';
import Blur from './Blur';
import { TierLists } from '../types/tier';
import cryptojs from 'crypto-js'
import { toast } from 'react-toastify';
interface UserData {
  current_org_id: number;
  gamification: {
    credits: number;
    avatar: string;
    current_xp: number;
    xp_cap: number;
    current_level: number;
  };
  user_info: {
    firstname: string;
    lastname: string;
  };
  org_roles: {
    role: string;
  }[];
}

interface Props {
  userData: UserData;
  tierLists: TierLists
}

const SidePanel: React.FC<Props> = ({ userData, tierLists }) => {

  return (
    <div className='rounded-xl w-full h-full bg-[#f5f5f5] p-4 flex flex-col items-center relative'>
      <div className='w-full flex justify-center items-center mt-6'>
        <img src={userData?.gamification.avatar} alt="avatar" className='w-[90px] h-[90px] rounded-full p-2' />
        <div className='flex flex-col ml-4'>
          <span className='text-[#161d46] font-bold md:text-xl xl:text-3xl'>{userData?.user_info.firstname} {userData?.user_info.lastname}</span>
          <span className='text-[#2ab1e4]'>{userData?.org_roles[0].role}</span>
        </div>
      </div>
      {userData.org_roles[0].role === 'Student' ? <DailyVibes userData={userData}>
        {tierLists?.daily_vibes ? <></> : <Blur />}
      </DailyVibes> : ""}
      {/* <div className='w-full h-48 rounded-2xl bg-white border mt-4'></div> */}
    </div>
  );
}

export default SidePanel;
