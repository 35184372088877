import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { Link } from 'react-router-dom';

interface CarouselWidgetProps {
  highlight: {
    url: string;
    image: string;
    title: string;
    description: string;
  }[];
  children?: React.ReactNode
}

function NextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: '50px',
        height: '50px',
        right: "-60px",
      }}
      onClick={onClick}
    >
      <SlArrowRight style={{ ...style, color: 'grey', fontSize: '30px' }} />
    </div>
  );
}

function PrevArrow(props: any) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: '50px',
        height: '50px',
        left: "-60px",
      }}
      onClick={onClick}
    >
      <SlArrowLeft style={{ ...style, color: 'grey', fontSize: '30px' }} />
    </div>
  );
}

const CarouselWidget: React.FC<CarouselWidgetProps> = ({ highlight, children }) => {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };
  return (
    <div className='relative w-full px-[10%] bg-white'>
      {highlight.length < 1 ? <></> : <span className='font-bold'>Try it out!</span>}
      <div className='mt-4'>
        {highlight.length < 1 ? <></> :
          highlight.length === 1 ?
            <div className='w-[50%]'>
              <Link to={`${highlight[0].url === 'wellness-stream' ? '/wellness-streams' : highlight[0].url}`} target={`${highlight[0].url === 'wellness-stream' ? '' : '_blank'}`} className='rounded-2xl relative'>
                <img src={highlight[0].image} className='w-full h-full rounded-2xl' />
                <div className='hidden lg:flex absolute bg-white bg-opacity-70 w-full h-20 bottom-0 p-3 flex-col'>
                  <span className='font-bold text-lg'>{highlight[0].title}</span>
                  <span className='hidden xl:block font-bold text-sm'>{highlight[0].description}</span>
                </div>
                <div className='flex flex-col lg:hidden'>
                  <span className='font-bold text-lg'>{highlight[0].title}</span>
                  <span className='hidden font-bold text-sm'>{highlight[0].description}</span>
                </div>
              </Link>
            </div>
            :
            <>
              <Slider {...settings}>
                {highlight.length > 1 && highlight.map((item, index) => (
                  <Link to={`${item.url === 'wellness-stream' ? '/wellness-streams' : item.url}`} target={`${item.url === 'wellness-stream' ? '' : '_blank'}`} key={index} className='rounded-2xl relative'>
                    <img src={item.image} className='w-full h-full rounded-2xl' />
                    <div className='hidden lg:flex absolute bg-white bg-opacity-70 w-full h-20 bottom-0 p-3 flex-col'>
                      <span className='font-bold text-lg'>{item.title}</span>
                      <span className='hidden xl:block font-bold text-sm'>{item.description}</span>
                    </div>
                    <div className='flex flex-col lg:hidden'>
                      <span className='font-bold text-lg'>{item.title}</span>
                      <span className='hidden font-bold text-sm'>{item.description}</span>
                    </div>
                  </Link>
                ))}
              </Slider>
            </>
        }
      </div>
      {children}
    </div>
  );
}

export default CarouselWidget;
