import React, { useEffect, useRef, useState } from 'react'
import axios, { CancelTokenSource } from 'axios';
import arrow from '../asset/arrow.svg'
import back from '../asset/journal/blue back icon.svg'
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Loader from './icons/Loader';
import { useTierStore } from '../store/tier';
import { decryptData } from '../helpers/encryptData';
import { TierLists } from '../types/tier';
import { useNavigate } from 'react-router-dom';
import ScaleUp from '../animations/ScaleUp';
import { Activity, DailyVibeData, DailyVibeProps, Emotion, Params, Relationship } from '../types/vibeCheck';
import FadeIn from '../animations/FadeIn';

const DailyVibes = ({ userData, children }: DailyVibeProps) => {
    const navigate = useNavigate();
    const [emotion, setEmotion] = useState<Emotion | null>(null);
    const [dailyVibeData, setDailyVibeData] = useState<DailyVibeData | null>(null);
    const [selectedRelationship, setSelectedRelationship] = useState<Relationship | null>(null);
    const [selectedActivity, setSelectedActivity] = useState<Activity | null>(null);
    const [emotionsData, setEmotionsData] = useState<Emotion[]>([]);
    const [relationshipData, setRelationshipData] = useState<Relationship[]>([]);
    const [activityData, setActivityData] = useState<Activity[]>([]);
    const [checkIn, setCheckIn] = useState<boolean>(false);
    const [checkInSuccess, setCheckInSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [step, setStep] = useState<number>(1);
    const [isDisabled, setIsDisabled] = useState(false);
    //Custom inputs
    const [customRelationship, setCustomRelationship] = useState("");
    const [customActivity, setCustomActivity] = useState("");
    const [isCustomRelationship, setIsCustomRelationship] = useState(false);
    const [isCustomActivity, setIsCustomActivity] = useState(false);
    const customRelationshipRef = useRef<HTMLInputElement>(null)
    const customActivityRef = useRef<HTMLInputElement>(null)

    const tier = useTierStore((state) => state.tier);
    const apiToken: string | null = sessionStorage.getItem('api_token');

    useEffect(() => {
        fetchEmotionAndActivities();
        let source = axios.CancelToken.source();
        getCheckInData(source);
        return () => {
            source.cancel();
        }
    }, []);

    useEffect(() => {
        let source = axios.CancelToken.source();
        if (checkInSuccess) {
            // Reset the check-in success state
            setCheckInSuccess(false);
            // Fetch the latest check-in data
            getCheckInData();
        }
        return () => {
            if (source)
                source.cancel();
        }
    }, [checkInSuccess]);

    const handleCustomRelationship = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value.trim()
        setCustomRelationship(input);
    }
    const handleCustomActivity = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value.trim()
        setCustomActivity(input);
    }

    const fetchEmotionAndActivities = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/journal/all-emotion-activities`);
            if (response.status === 200) {
                setEmotionsData(response.data.response.emotions);
                setRelationshipData(response.data.response.relationships);
                setActivityData(response.data.response.activities)
            }
        } catch (error) {
            // toast.error('Error fetching data')
        }
    }

    const getCheckInData = async (source?: CancelTokenSource) => {
        try {
            let config: any = { headers: { Authorization: `Bearer ${apiToken}` } };

            // If source is provided, add cancelToken to the request config
            if (source) {
                config = { ...config, cancelToken: source.token };
            }

            const response = await axios.get(
                `${process.env.REACT_APP_ENV_URL}/api/app/get-latest-check-in-data-today`,
                config
            );

            if (response.status === 200) {
                if (response.data.data !== null) {
                    setDailyVibeData({
                        emotion: {
                            name: response.data.data.Emotion,
                            id: 0,
                            icon: response.data.data.EmotionURL
                        },
                        what: {
                            id: 0,
                            name: response.data.data.What,
                            icon_url: response.data.data.WhatURL,
                        },
                        who: {
                            id: 0,
                            name: response.data.data.Who,
                            icon_url: response.data.data.WhoURL
                        }
                    });
                } else {
                    setDailyVibeData(null);
                }
                setLoading(false);
            }
        } catch (error) {
            if (axios.isCancel(error)) {
            }
            else {
                toast.error('Error Vibe Check');
            }
        }
    }

    const getBGColorFromEmotion = (emotion: Emotion) => {
        switch (emotion?.name) {
            case 'Happy':
                return 'bg-[#DCEDD5]';
            case 'Sad':
                return 'bg-[#C3E7F7]';
            case 'Surprised':
                return 'bg-[#FFEFD4]';
            case 'Disgust':
                return 'bg-[#B6BDD1]';
            case 'Angry':
                return 'bg-[#FDE0DE]';
            case 'Fearful':
                return 'bg-[#DCE0F0]'
            default:
                return 'bg-[#f2f2f2]';
        }
    }

    const getTextColorFromEmotion = (emotion: Emotion) => {
        switch (emotion?.name) {
            case 'Happy':
                return 'text-[#aed136]';
            case 'Sad':
                return 'text-[#26b8eb]';
            case 'Surprised':
                return 'text-[#fba91f]';
            case 'Disgust':
                return 'text-[#97a0c4]';
            case 'Angry':
                return 'text-[#ee3224]';
            case 'Fearful':
                return 'text-[#a271b0]'
            default:
                return 'text-black';
        }
    }
    const handleCloseClick = () => {
        setCheckIn(false);
        setEmotion(null);
        setSelectedActivity(null);
        setSelectedRelationship(null);
        setStep(1);
    }
    const handleRelationshipClick = (relationship: Relationship | null) => {
        setSelectedRelationship(relationship);
    }
    useEffect(() => {
        if (selectedRelationship?.name === 'Others') {
            if (customRelationshipRef.current) {
                setTimeout(() => {
                    customRelationshipRef.current?.focus();
                }, 0);
            }
            setIsCustomRelationship(true)
        } else {
            setIsCustomRelationship(false)
        }
    }, [selectedRelationship])
    useEffect(() => {
        if (selectedActivity?.name === 'Others') {
            if (customActivityRef.current) {
                setTimeout(() => {
                    customActivityRef.current?.focus();
                }, 0);
            }
            setIsCustomActivity(true)
        } else {
            setIsCustomActivity(false)
        }
    }, [selectedActivity])
    const handleActivityClick = (activity: Activity) => {
        setSelectedActivity(activity);
    }
    const handleEmotionClick = (emotion: Emotion | null) => {
        setEmotion(emotion)
        setCheckIn(true);
    }
    const handleNextClick = () => {
        if (step === 2 && selectedRelationship === null) {
            toast.warning('Select a relationship')
        }
        else if (step === 2 && selectedRelationship?.name === 'Others' && customRelationship === '') {
            toast.warning('The input field cannot be empty')
        }
        else {
            setStep(step + 1);
        }
    }
    const handleBackClick = () => {
        setStep(step - 1);
    }
    const handleCheckIn = async () => {
        try {
            setIsDisabled(true);
            if (selectedActivity?.name === 'Others' && customActivity === '') {
                toast.warning('The input field cannot be empty')
            }
            let params: Params = {
                org_id: userData.current_org_id,
                activity_id: selectedActivity?.id,
                relationship_id: selectedRelationship?.id,
                emotion_id: emotion?.id,
            }
            if (selectedRelationship?.name === 'Others' && customRelationship !== '') {
                params = {
                    ...params,
                    custom_relationship: customRelationship,
                }
            }
            if (selectedActivity?.name === 'Others' && customActivity !== '') {
                params = {
                    ...params,
                    custom_activity: customActivity,
                }
            }
            const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/mood_check_in`, params, { headers: { Authorization: `Bearer ${apiToken}` } });
            if (response.status === 200) {
                toast.success('Check In Successful');
                setCheckIn(false);
                setStep(1);
                setSelectedActivity(null);
                setSelectedRelationship(null);
                setCheckInSuccess(true);
                if (tier && process.env.REACT_APP_TIER_SECRET) {
                    const decryptedTier: TierLists = decryptData({ data: tier, secret: process.env.REACT_APP_TIER_SECRET })
                    if (decryptedTier.chat_ai) {
                        localStorage.setItem('chatState', JSON.stringify({ from: 'fromVibeCheck' }));
                        navigate('/chat')
                    }
                }

            }
            setIsDisabled(false);
        } catch (error) {
            // toast.error('Error fetching data')
            setIsDisabled(false);
        }

    }
    const handleCheckInAgain = () => {
        setDailyVibeData(null);
    }
    return (
        <>
            {loading ?
                ""
                :
                <div className='w-full relative h-full mt-4'>
                    {dailyVibeData && (<button className='w-full bg-[#faa81d] rounded-t-2xl py-2 text-white flex justify-center font-bold h-32' onClick={() => handleCheckInAgain()}>Check-in again</button>)}
                    <div className={`w-full h-fit rounded-2xl bg-white border absolute pb-4 ${dailyVibeData ? 'top-10' : 'top-0'}`}>
                        <div className='w-full px-3 mt-3 flex flex-col'>
                            {!dailyVibeData ?
                                <>
                                    <span className='font-bold text-[#161d46] text-lg'>Vibe Check</span>
                                    <span className='font-bold text-[#29b6e8] text-sm'>How are you feeling now?</span>

                                    <ScaleUp className='w-full flex flex-wrap items-center gap-2 mt-3 justify-center'

                                    >
                                        {emotionsData.map((emotion, index) => (
                                            <button type='button' key={index} className='md:w-[calc(33.33%-0.5rem)] xl:w-[calc(16%-0.5rem)] rounded-full hover:scale-125' onClick={() => handleEmotionClick(emotion)}>
                                                <img src={emotion.icon} key={emotion.id} alt={emotion.name} className='w-[100px]' />
                                            </button>
                                        ))}
                                    </ScaleUp>
                                </>
                                :
                                <>
                                    <span className='font-bold text-[#161d46] text-lg'>Vibe Check <span className='text-[#29b6e8]'>Logged!</span></span>
                                    <FadeIn className='w-full mt-2 flex flex-col lg:flex-row items-center'>
                                        <div className={`w-fit h-full p-2 xl:p-4 rounded-full ${getBGColorFromEmotion(dailyVibeData.emotion!)}`}>
                                            <img src={dailyVibeData.emotion!.icon} alt='emotion' className='w-20' />
                                        </div>
                                        <div className='md:ml-0 lg:ml-4 mt-4 lg:mt-0 rounded-lg bg-[#f2f2f2] flex-1 grid grid-rows-2 p-2 gap-2'>
                                            <div className='bg-white w-full flex gap-4 lg:gap-0 lg:justify-between items-center p-3'>
                                                {dailyVibeData.who?.name !== '' ? <span className='w-full lg:w-auto'>{dailyVibeData.who?.name}</span> : <div className='w-full flex items-center justify-center'><span className='text-xs text-center text-[#6c6f72] font-bold'>No relationship chosen</span></div>}
                                                <img src={dailyVibeData.who?.icon_url} alt='icon' className={`w-6 ${dailyVibeData.who?.icon_url ? '' : 'hidden'}`} />
                                            </div>
                                            <div className='bg-white w-full flex gap-4 lg:gap-0 lg:justify-between items-center p-3'>
                                                {dailyVibeData.what?.name !== null ? <span className='w-full lg:w-auto'>{dailyVibeData.what?.name}</span> : <div className='w-full flex items-center justify-center'><span className='text-xs text-center text-[#6c6f72] font-bold'>No activity chosen</span></div>}
                                                <img src={dailyVibeData.what?.icon_url} alt='icon' className={`w-6 ${dailyVibeData.what?.icon_url ? '' : 'hidden'}`} />
                                            </div>
                                        </div>
                                    </FadeIn>
                                </>
                            }

                            {checkIn && (
                                <div className="fixed inset-0 flex items-center justify-center z-[9998]">
                                    <div className="absolute inset-0 bg-[#f4f4f4] opacity-40"></div>
                                    <div className="bg-white p-8 rounded-2xl z-[9998] relative min-h-[600px] max-h-[600px] h-full min-w-[500px] w-[27%] border border-[#dedede] flex flex-col items-center">
                                        {step === 1 ?
                                            <>
                                                <button onClick={() => handleCloseClick()} className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 text-2xl">
                                                    X
                                                </button>
                                                <div className='w-full flex items-center justify-center gap-4 mb-4'>
                                                    <div className='w-[10px] h-[10px] rounded-full bg-[#28b7ea]'></div>
                                                    <div className='w-[10px] h-[10px] rounded-full bg-[#d9d9d9]'></div>
                                                    <div className='w-[10px] h-[10px] rounded-full bg-[#d9d9d9]'></div>
                                                </div>
                                                <span className='font-bold text-[#161d46] text-lg'>Hello, <span className='text-[#29b6e9]'>{userData?.user_info.firstname}</span></span>
                                                <span className='font-bold text-[#161d46] text-lg'>How are you feeling today?</span>
                                                <div className='w-full mt-4 flex justify-center'>
                                                    <div className='flex flex-col justify-center items-center gap-3'>
                                                        <div className={`w-fit p-7 rounded-full ${getBGColorFromEmotion(emotion!)}`}><img src={emotion!.icon} alt='emotion' className='w-36' /></div>
                                                        <span className={`font-bold ${getTextColorFromEmotion(emotion!)} text-lg`}>{emotion!.name}</span>
                                                    </div>
                                                </div>
                                                <div className='w-full flex justify-center items-center gap-2 mt-6'>
                                                    {emotionsData.map((emotion, index) => (
                                                        <button type='button' key={index} className='w-[17%] rounded-full' onClick={() => handleEmotionClick(emotion)}>
                                                            <img src={emotion.icon} key={emotion.id} alt={emotion.name} />
                                                        </button>
                                                    ))}
                                                </div>
                                                <div className='w-full h-full flex justify-between items-end px-4'>
                                                    <button className='bg-[#29b6e9] rounded-full flex justify-center items-center text-white font-bold px-4 py-1' onClick={() => handleCheckIn()} disabled={isDisabled}>
                                                        {isDisabled ? <Loader /> : 'Submit'}
                                                    </button>
                                                    <button className='w-10 h-10 bg-[#29b6e9] rounded-full flex justify-center items-center' onClick={() => handleNextClick()}>
                                                        <img src={arrow} alt='arrow' className='w-5' />
                                                    </button>
                                                </div>
                                            </>
                                            : step === 2 ?
                                                <>
                                                    <button onClick={() => handleCloseClick()} className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 text-2xl">
                                                        X
                                                    </button>
                                                    <button className='absolute top-0 left-0 m-4 flex items-center' onClick={handleBackClick}>
                                                        <img src={back} alt='back' className='mr-2' />
                                                        <span className='font-bold text-[#00ace5]'>Back</span>
                                                    </button>
                                                    <div className='w-full flex items-center justify-center gap-4 mb-8'>
                                                        <div className='w-[10px] h-[10px] rounded-full bg-[#28b7ea]'></div>
                                                        <div className='w-[10px] h-[10px] rounded-full bg-[#28b7ea]'></div>
                                                        <div className='w-[10px] h-[10px] rounded-full bg-[#d9d9d9]'></div>
                                                    </div>
                                                    <span className='font-bold text-[#161d46]'><span className='text-[#29b6e9]'>Who's</span> causing you to feel {emotion!.name}?</span>
                                                    <div className='flex flex-wrap gap-2 w-full h-full min-h-[350px] bg-[#f2f2f2] rounded-md mt-4 pl-2 pt-2 pb-2 overflow-scroll'>
                                                        {relationshipData.map((relationship, index) => (
                                                            <button key={relationship.id} onClick={() => handleRelationshipClick(relationship)} className={`w-[calc(50%-0.5rem)] bg-white rounded-md p-2 flex flex-col justify-center items-center h-20 ${selectedRelationship?.id === relationship.id ? 'outline outline-[#29b6e9]' : ''}`}>
                                                                <img src={relationship.icon_url} alt={relationship.name} className='w-6' />
                                                                <span className='text-sm'>{relationship.name}</span>
                                                            </button>
                                                        ))}

                                                        <input className={`${isCustomRelationship ? 'block' : 'hidden'} w-full mr-2 p-3 rounded-xl bg-[#DBDBDB] placeholder:text-[#757575] outline-[#29b6e9]`} placeholder='Type custom relationship' type="text" name="custom-relationship" ref={customRelationshipRef} onChange={handleCustomRelationship} required={isCustomRelationship} value={customRelationship} />
                                                    </div>
                                                    <div className='w-full h-full flex justify-between items-end px-4'>
                                                        <button className='w-9 h-9 bg-[#999999] rounded-full justify-center items-center bg-transparent cursor-default'>
                                                        </button>
                                                        <button className='bg-[#29b6e9] rounded-full flex justify-center items-center text-white font-bold px-4 py-1' onClick={() => handleCheckIn()} disabled={isDisabled}>
                                                            {isDisabled ? <Loader /> : 'Submit'}
                                                        </button>
                                                        <button className='w-9 h-9 bg-[#29b6e9] rounded-full flex justify-center items-center' onClick={() => handleNextClick()}>
                                                            <img src={arrow} alt='arrow' className='w-5' />
                                                        </button>
                                                    </div>
                                                </>
                                                : step === 3 ?
                                                    <>
                                                        <button onClick={() => handleCloseClick()} className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 text-2xl">
                                                            X
                                                        </button>
                                                        <button className='absolute top-0 left-0 m-4 flex items-center' onClick={handleBackClick}>
                                                            <img src={back} alt='back' className='mr-2' />
                                                            <span className='font-bold text-[#00ace5]'>Back</span>
                                                        </button>
                                                        <div className='w-full flex items-center justify-center gap-4 mb-8'>
                                                            <div className='w-[10px] h-[10px] rounded-full bg-[#28b7ea]'></div>
                                                            <div className='w-[10px] h-[10px] rounded-full bg-[#28b7ea]'></div>
                                                            <div className='w-[10px] h-[10px] rounded-full bg-[#28b7ea]'></div>
                                                        </div>
                                                        <span className='font-bold text-[#161d46]'><span className='text-[#29b6e9]'>What's</span> causing you to feel {emotion!.name}?</span>
                                                        <div className='flex flex-wrap gap-2 w-full h-full min-h-[350px] bg-[#f2f2f2] rounded-md mt-4 pl-2 pt-2 pb-2 overflow-scroll'>
                                                            {activityData.map((activities, index) => (
                                                                <button key={activities.id} onClick={() => handleActivityClick(activities)} className={`w-[calc(50%-0.5rem)] bg-white rounded-md p-2 flex flex-col justify-center items-center h-20 ${selectedActivity?.id === activities.id ? 'outline outline-[#29b6e9]' : ''}`}>
                                                                    <img src={activities.icon_url} alt={activities.name} className='w-6' />
                                                                    <span className='text-sm'>{activities.name}</span>
                                                                </button>
                                                            ))}
                                                            <input className={`${isCustomActivity ? 'block' : 'hidden'} w-full mr-2 p-3 rounded-xl bg-[#DBDBDB] placeholder:text-[#757575] outline-[#29b6e9]`} placeholder='Type custom activity' type="text" name="custom-activity" ref={customActivityRef} onChange={handleCustomActivity} required={isCustomActivity} value={customActivity} />
                                                        </div>
                                                        <div className='w-full h-full flex justify-center items-end px-8'>
                                                            <button className='w-fit px-4 py-1 bg-[#29b6e9] rounded-full flex justify-center items-center' onClick={() => handleCheckIn()} disabled={isDisabled}>
                                                                <span className='text-white font-bold'>{isDisabled ? <Loader /> : 'Submit'}</span>
                                                            </button>
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {children}
                </div>
            }
        </>
    )
}

export default DailyVibes