import { Button, Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import React from 'react'

interface PopUpModal {
    isOpen: boolean,
    close: () => void,
    text: string
}

export default function PopUpModal({ isOpen, close, text }: PopUpModal) {
    return (
        <Transition appear show={isOpen}>
            <Dialog as="div" className="relative z-[99999] focus:outline-none lexend-deca-normal" onClose={close}>
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <TransitionChild
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 transform-[scale(95%)]"
                            enterTo="opacity-100 transform-[scale(100%)]"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 transform-[scale(100%)]"
                            leaveTo="opacity-0 transform-[scale(95%)]"
                        >
                            <DialogPanel className="w-full max-w-[70%] rounded-xl bg-white p-6 border border-[#cccccc]">
                                <p dangerouslySetInnerHTML={{__html: text.replace(/\n/g, '<br/>')}}></p>
                                <div className="mt-4">
                                    <Button
                                        className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
                                        onClick={close}
                                    >
                                        Close
                                    </Button>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>

            </Dialog>
        </Transition>
    )
}
