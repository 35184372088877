import React, { CSSProperties, useState } from 'react'
import PopUpModal from './PopUpModal';

interface SurveyInsight {
    header: string;
    preScore: number | string;
    postScore: number | string;
    details: string;
    children: React.ReactNode
}

export default function SurveyInsight({ header, preScore, postScore, details, children }: SurveyInsight) {

    const [isOpen, setIsOpen] = useState(false)

    const handleViewMore = () => {
        setIsOpen(true)
    }
    const paraStyle: CSSProperties = { WebkitLineClamp: 3, overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical' }
    return (
        <div className='relative pt-4 w-full lg:w-[80%]'>
            <div className='rounded-2xl border border-[#cccccc] h-full w-full flex flex-col py-6 p-5'>
                <div className='flex items-center gap-2 mb-3'>
                    <h5 className='text-xl font-bold'>{header}</h5>

                </div>
                <div className='flex gap-20 justify-center items-center mb-8'>
                    <div>
                        <h5 className='text-[#26B8EB] font-bold'>Pre score</h5>
                        <div className='bg-[#26B8EB] p-2 rounded-full text-white text-center font-bold'>{preScore}</div>
                    </div>
                    <div>
                        <h5 className='text-[#26B8EB] font-bold'>Post score</h5>
                        <div className='bg-[#26B8EB] p-2 rounded-full text-white text-center font-bold'>{postScore}</div>
                    </div>
                </div>
                <p className='text-[#484747]' style={paraStyle}>{details ? details : "No description found"}</p>
                {details && <p className='text-[#26B8EB] cursor-pointer' onClick={handleViewMore}>View more</p>}
            </div>
            {children}
            <PopUpModal text={details} isOpen={isOpen} close={() => setIsOpen(false)}/>
        </div>
    )
}
