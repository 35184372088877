import React from 'react'
import { IconProps } from '../../types/icon'

const EmailIcon = ({ className }: IconProps) => {
    return (
        <svg className={className} width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2088_7531)">
                <path d="M27.6406 5.76306L19.2184 14.131L27.6406 22.499C27.7929 22.1808 27.8852 21.829 27.8852 21.4533V6.80874C27.8852 6.43302 27.7929 6.08128 27.6406 5.76306Z" fill="#161D46" />
                <path d="M25.4445 4.36804H2.55552C2.17981 4.36804 1.82806 4.46041 1.50984 4.61266L12.2743 15.3229C13.2261 16.2747 14.7739 16.2747 15.7257 15.3229L26.4902 4.61266C26.1719 4.46041 25.8202 4.36804 25.4445 4.36804Z" fill="#161D46" />
                <path d="M0.359373 5.76306C0.207123 6.08128 0.114754 6.43302 0.114754 6.80874V21.4533C0.114754 21.8291 0.207123 22.1808 0.359373 22.499L8.78159 14.131L0.359373 5.76306Z" fill="#161D46" />
                <path d="M18.0679 15.2816L16.8761 16.4734C15.2902 18.0593 12.7097 18.0593 11.1238 16.4734L9.93206 15.2816L1.50984 23.6496C1.82806 23.8018 2.17981 23.8942 2.55552 23.8942H25.4445C25.8202 23.8942 26.1719 23.8018 26.4902 23.6496L18.0679 15.2816Z" fill="#161D46" />
            </g>
            <defs>
                <clipPath id="clip0_2088_7531">
                    <rect width="27.7705" height="27.7705" fill="white" transform="translate(0.114754 0.24585)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default React.memo(EmailIcon)