import React, { useEffect, useState } from 'react';
import RightArrowIcon from '../asset/home/RightArrowIcon';
import WellnessRevampModal from './WellnessRevampModal';
import { Domains, SurveyProps, WellnessChartProps } from '../types/insights';
import WellnessChartV2 from './WellnessChartV2';

const defaultdata = [
    { name: 'This Week', value: "9.0" },
    { name: 'Last Week', value: "3.0" },
    { name: '2 Weeks Ago', value: "1.0" },
];


interface WellnessChartDetailProps {
    detail: {
        header: string,
        color: string,
        icon: any,
    }
    domain: WellnessChartProps
    wellnessSurvey? : SurveyProps
}



const WellnessRevampChart = ({ detail, domain, wellnessSurvey }: WellnessChartDetailProps) => {
    const [chartData, setChartData] = useState([
        { name: 'This Week', value: 0 },
        { name: 'Last Week', value: 0 },
        { name: '2 Weeks Ago', value: 0 },
    ])
    const transformValue = (data: WellnessChartProps) => {
        setChartData([
            { name: 'This Week', value: data.value[2] },
            { name: 'Last Week', value: data.value[1] },
            { name: '2 Weeks Ago', value: data.value[0] },
        ])

    };
    const [isOpen, setIsOpen] = useState(false)
    function open() {
        setIsOpen(true)
    }

    function close() {
        setIsOpen(false)
    }
    useEffect(() => {
        transformValue(domain)
    }, [])
    return (
        <div className='md:w-full xl:w-[calc(50%-1rem)]'>
            <div className='w-full flex items-center gap-4 p-4 bg-white rounded-t-3xl border border-[#cccccc] border-b-transparent cursor-pointer font-bold' onClick={open}>
                {detail.icon && <img src={detail.icon} />}
                <h3 style={{ color: `${detail.color}`, textAlign: 'left' }}>{detail.header}</h3>
                <RightArrowIcon className='ml-auto' />
            </div>
            <WellnessChartV2 chartData={chartData} color={detail.color} />
            <WellnessRevampModal isOpen={isOpen} close={close} header={detail.header} domain={domain} detail={detail} wellnessSurvey={wellnessSurvey}>
                <WellnessChartV2 chartData={chartData} color={detail.color} customStyle="rounded-lg" />
            </WellnessRevampModal>
        </div>
    );
};

export default WellnessRevampChart;
