import React from 'react';
import { PulseLoader } from 'react-spinners';

interface LoadingProps {
  fullScreen?: boolean;
}

const Loader: React.FC<LoadingProps> = ({ fullScreen = true }) => {
  return (
    <div className={`${fullScreen ? 'w-full h-screen' : 'w-full h-full'} flex justify-center items-center`}>
      <PulseLoader />
    </div>
  );
};

export default Loader;
