import { create } from "zustand"

interface PrivacyStore {
    isPrivacy: number
    setIsPrivacy: (value: number) => void
    isPrivacyModeCompleted: boolean
    setIsPrivacyModeCompleted: (value: boolean) => void
    isShowLocked: boolean
    setIsShowLocked: (value: boolean) => void
    autoLockDuration: number,
    setAutoLockDuration: (value: number) => void
}

export const usePrivacyStore = create<PrivacyStore>((set) => ({
    isPrivacy: 0,
    setIsPrivacy: (newValue) => set(() => ({ isPrivacy: newValue })),
    isPrivacyModeCompleted: false,
    setIsPrivacyModeCompleted: (newValue) => set(() => ({ isPrivacyModeCompleted: newValue })),
    isShowLocked: false,
    setIsShowLocked: (newValue) => set(() => ({ isShowLocked: newValue })),
    autoLockDuration: 0,
    setAutoLockDuration: (newValue) => set(() => ({ autoLockDuration: newValue }))
}))
