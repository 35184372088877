import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { TierLists } from '../types/tier';
import SidePanel from '../components/SidePanel';
import axios, { CancelTokenSource } from 'axios';
import cryptojs from 'crypto-js'
import learningspacactive from '../asset/sidebar/active/learningspace.svg';
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { back, bookmarkadd, chapters, defaultlike, duration, playbutton, zoalaplus, bookmarkactive } from '../asset';
import activelike from '../asset/lms/active-like.svg'
import ReactPlayer from 'react-player';
import { PulseLoader } from 'react-spinners';
import CircularProgress from '@mui/joy/CircularProgress';
import { useCountUp } from 'use-count-up';
import RightArrowIcon from '../asset/home/RightArrowIcon';
import Blur from '../components/Blur';
import cone from '../asset/Cones.svg'
import { UserDataProps } from '../types/common';
import { createWidget } from '@typeform/embed'
import '@typeform/embed/build/css/widget.css'
import download from '../asset/lms/download.svg'
import Error from '../components/Error';
import Loader from '../components/reusable/Loader';
import { track } from '@amplitude/analytics-browser';


interface LMSMods {
  module_id: number;
  category_id: number;
  is_bookmarked: boolean;
  is_like: boolean;
  progress: number;
  related_modules_id: number;
  image: string;
  activity_link: string;
  module_title: string;
  duration: number,
  module_description: string,
  units: LMSMod[]
}

interface LMSMod {
  assessment_link: string | null;
  is_complete: boolean,
  is_hidden: number,
  is_locked: number,
  link: string,
  module_id: number,
  unit_id: number,
  unit_title: string
  chapter?: number
}

interface LMSCategory {
  category: string,
  icon: string,
  iconSelected: string,
  id: number
}
interface Like {
  [key: number]: boolean
}
interface Bookmark {
  [key: number]: boolean
}

export default function LearningSpace() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserDataProps | null>(null);
  const [tierLists, setTierLists] = useState<TierLists>();
  const [isError, setIsError] = useState<boolean>(false)
  const [whereYouLeftOffMod, setWhereYouLeftOffMod] = useState<LMSMods[]>();
  const [recommendedMod, setRecommendedMod] = useState<LMSMods[]>();
  const [selectedMod, setSelectedMod] = useState<LMSMods | null>(null);
  const [updateModPercents, setUpdateModPercents] = useState<LMSMods>();
  const [selectedUnit, setSelectedUnit] = useState<LMSMod | null>(null);
  const [LMSMods, setLMSMods] = useState<LMSMods[]>();
  const [units, setUnits] = useState<LMSMod[]>();
  const [category, setCategory] = useState<LMSCategory[]>();
  const [selectedCategory, setSelectedCategory] = useState<LMSCategory>();
  const [loading, setLoading] = useState<boolean>(true);
  const [playVideo, setPlayVideo] = useState<boolean>(false);
  const [videoComplete, setVideoComplete] = useState<boolean>(false);
  const [backVideoClick, setBackVideoClick] = useState<boolean>(false);
  const [isCategoryLoading, setIsCategoryLoading] = useState<boolean>(false)
  const [likes, setLikes] = useState<Like[]>([]); // Initialize an empty object for likes
  const [bookmarks, setBookmarks] = useState<Bookmark[]>([]); // Initialize an empty object for likes
  const [formId, setFormId] = useState<string>("")
  const [isLastUnit, setIsLastUnit] = useState<boolean>(false)
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false)
  const formRef = useRef<HTMLDivElement>(null)

  const apiToken = sessionStorage.getItem('api_token');
  const { value, reset } = useCountUp({
    isCounting: true,
    duration: 1,
    start: 0,
    end: 100,
  });
  useEffect(() => {
    track('screenView', {screen_name: 'LearningSpaceScreen'})
    let source = axios.CancelToken.source();
    getWhereYouLeftOffMod(source);
    getRecommendedMod(source);
    getDiscoverMoreCat(source);
    const tier = localStorage.getItem('tier')
    if (tier && process.env.REACT_APP_TIER_SECRET) {
      try {
        const decryptData = cryptojs.AES.decrypt(tier, process.env.REACT_APP_TIER_SECRET)
        const tierData = JSON.parse(decryptData.toString(cryptojs.enc.Utf8))
        setTierLists(tierData)
      } catch (error) {
      }
    }
    return () => {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const storedUserData = sessionStorage.getItem('user');
    if (!apiToken || !storedUserData) {
      navigate('/login');
      return;
    }

    setUserData(JSON.parse(storedUserData));
  }, [apiToken, navigate]);

  const getWhereYouLeftOffMod = async (source: CancelTokenSource) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/get-where-you-left-off-modules`, null,
        { headers: { Authorization: `Bearer ${apiToken}` }, cancelToken: source.token });
      if (response.status === 200) {
        setWhereYouLeftOffMod(response.data);
      } else {
      }
    } catch (error) {
      if (axios.isCancel(error)) {

      } else {
        setLoading(false);
        setIsError(true);
      }
    }
  }

  const postUnitCompletion = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/post-unit-completion`, {
        unit_id: selectedUnit?.unit_id,
        progress: 100
      },
        { headers: { Authorization: `Bearer ${apiToken}` } });
      if (res.status === 200) {
        setVideoComplete(true);
        setPlayVideo(false)
      } else {
      }
    } catch (error) {
    }
  }

  const handleModsOnClick = (mod: LMSMods) => {
    if (category) {
      const data = category.filter(data => data.id === mod.category_id)
      if (data.length > 0)
        setSelectedCategory(data[0])
    }
    setSelectedMod(mod);
    setUpdateModPercents(mod)
    setUnits(mod.units)
  }

  const handleVideoOnClick = (unit: LMSMod, length: number, currentUnit: number) => {

    if (length === currentUnit + 1) {
      setIsLastUnit(true)
    } else {
      setIsLastUnit(false)
    }
    setSelectedUnit(unit);
    setPlayVideo(true);
  }

  const handleVideoBackClick = () => {
    setBackVideoClick(true);
  }

  const handleBackClick = () => {
    setSelectedMod(null);
  }

  const handleBackConfirmClick = () => {
    if (selectedCategory) {
      getCoursesByCatId(selectedCategory.id)
    }
    setBackVideoClick(false);
    setPlayVideo(false);
    setSelectedUnit(null);
  }
  const handleCloseChapter = () => {
    setVideoComplete(false)
    setPlayVideo(false);
    setSelectedUnit(null);
  }

  const handleCompleteVideoBack = () => {
    if (selectedCategory) {
      getCoursesByCatId(selectedCategory.id)
    }
    setBackVideoClick(false);
    setVideoComplete(false);
    setPlayVideo(false);
    setSelectedUnit(null);
  }

  const handleStayOnScreen = () => {
    setBackVideoClick(false);
  }

  const getNextElement = (array: LMSMod[], element: LMSMod): LMSMod | null => {
    const index = array.indexOf(element);
    if (index !== -1 && index < array.length - 1) {
      return array[index + 1];
    }
    return null;
  };

  const handleNextChapterClick = () => {
    setSelectedUnit(getNextElement(selectedMod!.units, selectedUnit!));
    setVideoComplete(false);
    setPlayVideo(true);
  }

  const getCoursesByCatId = async (category_id: number) => {
    try {
      setIsPageLoading(true)
      const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/get-courses-by-category-id`,
        {
          category_id: category_id
        },
        { headers: { Authorization: `Bearer ${apiToken}` } });
      if (response.status === 200) {
        setIsPageLoading(false)
        setLMSMods(response.data);
        if (selectedMod) {
          const transformedData: any = response.data.filter((data: any) => data.module_id === selectedMod.module_id);
          setUnits(transformedData[0].units)
          setUpdateModPercents(transformedData[0])
        }
      } else {
      }
      setIsCategoryLoading(false)
    } catch (error) {
      setIsPageLoading(false)
      setIsCategoryLoading(false)
    }
  }
  useEffect(() => {
    if (LMSMods && LMSMods.length > 0) {
      const filteredLikes = LMSMods.map((mod) => {
        return {
          [mod.module_id]: mod.is_like
        }
      })
      const filteredBookmarks = LMSMods.map((mod) => {
        return {
          [mod.module_id]: mod.is_bookmarked
        }
      })
      setLikes(filteredLikes)
      setBookmarks(filteredBookmarks)
    }
  }, [LMSMods])


  const convertMinutesToReadableFormat = (minutes: number) => {
    if (minutes <= 0) return '0 minutes';

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    let result = '';

    if (hours > 0) {
      result += `${hours} hour${hours > 1 ? 's' : ''}`;
    }

    if (remainingMinutes > 0) {
      if (hours > 0) {
        result += ' ';
      }
      result += `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
    }

    return result;
  }

  const getRecommendedMod = async (source: CancelTokenSource) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/get-recommended-module`, null,
        { headers: { Authorization: `Bearer ${apiToken}` }, cancelToken: source.token });
      if (response.status === 200) {
        setRecommendedMod(response.data);
      }
      setLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {

      } else {
        setLoading(false);
        setIsError(true);
      }
    }
  }
  const getDiscoverMoreCat = async (source: CancelTokenSource) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/get-categories`, null,
        { headers: { Authorization: `Bearer ${apiToken}` }, cancelToken: source.token });
      if (response.status === 200) {
        setCategory(response.data);
        setSelectedCategory(response.data[0]);
        getCoursesByCatId(response.data[0].id);
      } else {
      }
    } catch (error) {
      if (axios.isCancel(error)) {

      } else {
        setLoading(false);
        setIsError(true);
      }
    }
  }
  const categoryClick = (category: LMSCategory) => {
    setIsCategoryLoading(true)
    setSelectedCategory(category);
    if (selectedCategory?.id !== category.id)
      getCoursesByCatId(category.id);
  }

  const handleLike = async (module_id: number) => {
    const res = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/post-like-module`, {
      module_id: module_id
    },
      { headers: { Authorization: `Bearer ${apiToken}` } });
    if (res.status === 200) {
      setLikes(prev => {
        return prev.map(obj => {
          if (obj.hasOwnProperty(module_id)) {
            return { [module_id]: !obj[module_id] };
          }
          return obj;
        });
      });
    }
  }
  const handleBookmark = async (module_id: number) => {
    const res = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/post-bookmark-module`, {
      module_id: module_id
    },
      { headers: { Authorization: `Bearer ${apiToken}` } });
    if (res.status === 200) {
      setBookmarks(prev => {
        return prev.map(obj => {
          if (obj.hasOwnProperty(module_id)) {
            return { [module_id]: !obj[module_id] };
          }
          return obj;
        });
      });
    }
  }

  const handleOpenPdf = (fileUrl: string) => {
    window.open(fileUrl, '_blank')
  }
  const getLinkHostName = (url?: string) => {
    const urlObject = new URL(!url ? "" : url);
    const domain = urlObject.hostname;
    if (domain === 'streamable.com') {
      return 'streamable.com'
    }
    if (domain === 'teamed.typeform.com') {
      return 'teamed.typeform.com'
    }
    return ''
  }
  useEffect(() => {
    if (selectedUnit && selectedUnit.assessment_link) {
      const url: string = selectedUnit.assessment_link
      const formId = url.split('/').pop();
      if (formId)
        setFormId(formId)
    }
    if (selectedUnit && !selectedUnit.assessment_link) {
      const url: string = selectedUnit.link
      const hostName = getLinkHostName(url)
      if (hostName === 'teamed.typeform.com') {
        const formId = url.split('/').pop();
        if (formId) {
          setFormId(formId)
          setPlayVideo(false)
          setVideoComplete(true)
        }
      }
    }
  }, [selectedUnit])

  useEffect(() => {
    const formContainer = formRef.current
    if (formContainer && userData && process.env.REACT_APP_ENV && formId) {

      createWidget(formId, {
        onSubmit: ({ formId, responseId }) => {
          setFormId("")
        },
        container: formContainer,
        hidden: {
          user_id: userData.uuid,
          org_id: userData.current_org_id.toString(),
          env: process.env.REACT_APP_ENV,
        }
      })
    }
  }, [videoComplete, formRef])

  useEffect(() => {
    if (whereYouLeftOffMod || recommendedMod || category) {
      setLoading(false)
    }

  }, [whereYouLeftOffMod, recommendedMod, category])

  return (
    <div className='h-auto w-full'>
      {isError ? <div className='w-full h-full flex items-center justify-center gap-4'>
        <Error mainText="Something went wrong. Please try again later." />
      </div> : loading ? <Loader/> :
        <div className='pl-16 flex w-full h-full p-5'>

          {/* {userData ? <iframe id='my-form' className='w-full h-screen' src={`${selectedUnit?.assessment_link}?typeform-source=${process.env.REACT_APP_ENV_URL}#user_id=${userData.uuid}&org_id=${userData.current_org_id}&env=${process.env.REACT_APP_ENV}`} onLoad={handleLoad} style={{ display: videoComplete && selectedUnit?.assessment_link ? 'block' : 'none' }} ref={formRef}>
        </iframe> : ""} */}
          {formId && videoComplete && <div className='w-full h-screen' data-tf-widget={formId} ref={formRef}></div>}
          {
            videoComplete && !selectedUnit?.assessment_link && formId ? "" :
              videoComplete && selectedUnit?.assessment_link && formId ? "" :
                videoComplete && !formId ?
                  <>
                    <section className='bg-white h-full w-full px-8 flex flex-col'>
                      <div className='flex bg-[#caeaf7] w-fit px-3 py-1 rounded-full'>
                        <img src={learningspacactive} alt='icon' className='w-5 mr-2' />
                        <span className='font-bold text-[#26b8eb] text-lg'>Learning Space</span>
                      </div>
                      <div className='w-full h-full flex flex-col items-center mt-20'>

                        <CircularProgress size="lg" determinate value={value as number} sx={{
                          "--CircularProgress-size": "300px",
                          "--CircularProgress-trackThickness": "30px",
                          "--CircularProgress-progressThickness": "30px",
                          "--CircularProgress-progressColor": "#add038"
                        }}>
                          <div style={{ fontSize: '20px', color: '#add038', textAlign: 'center' }}>
                            <p className='font-bold text-2xl'>Progress</p>
                            <p className='font-semibold text-7xl'>{value}%</p>
                          </div>
                        </CircularProgress>
                        <span className='font-bold text-[#00ace5] text-4xl mt-8 mb-4'>Awesome!</span>
                        <span className='text-xl'>You've completed the chapter!</span>
                        <div className='w-full flex justify-center items-center gap-20 mt-20'>


                          {isLastUnit ? <button onClick={handleCloseChapter} className='bg-[#00ace5] px-4 py-2 rounded-full text-white font-bold'>Close</button> :
                            <><button onClick={handleCompleteVideoBack} className='flex items-center justify-center'>
                              <img src={back} alt='back' className='mr-2' />
                              <span className='font-bold text-[#00ace5]'>Back</span>
                            </button>
                              <button onClick={handleNextChapterClick} className='bg-[#00ace5] px-4 py-2 rounded-full text-white font-bold'>Proceed to next chapter</button></>}
                        </div>
                      </div>
                    </section>
                  </>
                  :
                  <>
                    {playVideo ?
                      <>
                        <section className='bg-white h-full w-full px-8 flex flex-col'>
                          <div className='flex bg-[#caeaf7] w-fit px-3 py-1 rounded-full'>
                            <img src={learningspacactive} alt='icon' className='w-5 mr-2' />
                            <span className='font-bold text-[#26b8eb] text-lg'>Learning Space</span>
                          </div>
                          <div className='w-full mt-3'>
                            <button onClick={() => handleVideoBackClick()} className='flex items-center justify-center'>
                              <img src={back} alt='back' className='mr-2' />
                              <span className='font-bold text-[#00ace5]'>Back</span>
                            </button>
                          </div>
                          <div className='w-full h-full flex justify-center items-center'>
                            {getLinkHostName(selectedUnit?.link) === "streamable.com" ? <ReactPlayer url={selectedUnit?.link} controls={true} width={700} onEnded={postUnitCompletion} /> : ""}
                          </div>
                          {backVideoClick && (
                            <div className="fixed inset-0 flex items-center justify-center z-[9998]">
                              <div className="absolute inset-0 bg-[#f4f4f4] opacity-40"></div>
                              <div className="bg-white rounded-2xl z-[9998] relative min-h-[200px] 
                    max-h-[200px] h-full min-w-[300px] border border-[#dedede] flex flex-col justify-center items-center p-10">
                                <span>Pending changes</span>
                                <span>Are you sure to leave this screen? Your progress will not be saved</span>
                                <button onClick={() => handleBackConfirmClick()} className='w-full py-4 bg-orange-400 text-white rounded-lg mt-7 mb-2'>Confirm</button>
                                <button onClick={() => handleStayOnScreen()}>Stay on this screen</button>
                              </div>
                            </div>
                          )}
                        </section>
                      </>
                      :
                      <>
                        {selectedMod && updateModPercents ?
                          <>
                            <section className='bg-white h-full w-full px-8 flex flex-col'>
                              <div className='flex bg-[#caeaf7] w-fit px-3 py-1 rounded-full'>
                                <img src={learningspacactive} alt='icon' className='w-5 mr-2' />
                                <span className='font-bold text-[#26b8eb] text-lg'>Learning Space</span>
                              </div>
                              <div className='w-full mt-3'>
                                <button onClick={() => handleBackClick()} className='flex items-center justify-center'>
                                  <img src={back} alt='back' className='mr-2' />
                                  <span className='font-bold text-[#00ace5]'>Back</span>
                                </button>
                              </div>
                              <div className='w-full h-full flex gap-10'>
                                <div className='w-[60%] h-[35%] flex flex-col mt-3'>
                                  <div className='w-full h-[35%]'>
                                    <img className='rounded-xl object-cover w-full h-[300px]' src={selectedMod.image} alt='image' />
                                  </div>
                                  <span className='font-bold text-[#161d46] text-2xl mt-4'>{selectedMod.module_title}</span>
                                  <div className='w-fit flex mt-3 gap-2 mb-2'>
                                    <div className='py-1 px-2 w-fit border border-[#cccccc] rounded-full flex justify-center items-center gap-2'>
                                      <img src={chapters} alt='chapters' />
                                      <span className='text-[#26b8eb]'>{selectedMod.units.length} Chapters</span>
                                    </div>
                                    <div className='py-1 px-2 w-fit border border-[#cccccc] rounded-full flex justify-center items-center gap-2'>
                                      <img src={duration} alt='duration' />
                                      <span className='text-[#26b8eb]'>{convertMinutesToReadableFormat(selectedMod.duration)}</span>
                                    </div>
                                  </div>
                                  <span>{selectedMod.module_description}</span>
                                  <div className='w-full h-[1px] bg-black mt-16'></div>
                                  <span className='font-bold text-[#161d46] mt-2'>Related Topics</span>
                                  <div className='w-full flex justify-center items-center'>
                                    <span className='text-gray-400'>No related module available</span>
                                  </div>
                                </div>
                                <div className='w-[40%] h-full bg-[#f5f5f5] rounded-xl p-3'>
                                  <div className='w-full h-[100px] flex justify-center items-center mt-10'>
                                    <CircularProgressbar
                                      className='h-full'
                                      value={Math.round(updateModPercents.progress)}
                                      text={`${Math.round(updateModPercents.progress)}%`}
                                      styles={buildStyles({
                                        textColor: "#26b8eb",
                                        pathColor: "#26b8eb",
                                        trailColor: "white",
                                        textSize: "30px"
                                      })}
                                    />
                                  </div>
                                  <div className='w-full h-[580px] flex flex-col overflow-y-auto mt-4 gap-4 px-[10%]'>
                                    {isPageLoading ? <div className='flex justify-center w-full mt-8'><PulseLoader /></div> :
                                      units && units.map((unit: any, index: number) => (
                                        <div onClick={() => handleVideoOnClick(unit, selectedMod.units.length, index)} key={index} className='w-full h-fit flex border-b-[1px] border-b-[#cccccc] py-2 cursor-pointer'>
                                          <div className='w-[80px]'>
                                            <CircularProgressbarWithChildren value={unit.is_complete ? 100 : 0}
                                              styles={buildStyles({
                                                pathColor: "#26b8eb",
                                                trailColor: "white"
                                              })}
                                            >
                                              <img src={playbutton} className='w-6' />
                                            </CircularProgressbarWithChildren>
                                          </div>
                                          <div className='w-full flex items-center ml-4'>
                                            <span>Chapter {index + 1}: {unit.unit_title}</span>
                                          </div>
                                        </div>
                                      ))}
                                    {selectedMod.activity_link && <div className='w-full h-fit flex gap-4 items-center border-b-[1px] border-b-[#cccccc] py-2 cursor-pointer' onClick={() => handleOpenPdf(selectedMod.activity_link)}>
                                      <img src={download} alt="" width={63} height={63} />
                                      <p>Resources</p>
                                    </div>}
                                  </div>
                                </div>
                              </div>

                            </section>
                          </>
                          :
                          <>
                            <section className='bg-white h-full w-[70%] md:w-[70%] xl:w-[75%] px-8 flex flex-col'>
                              <div className='flex bg-[#caeaf7] w-fit px-3 py-1 rounded-full'>
                                <img src={learningspacactive} alt='icon' className='w-5 mr-2' />
                                <span className='font-bold text-[#26b8eb] text-lg'>Learning Space</span>
                              </div>
                              <div className='flex flex-col w-full px-14 h-full mt-10'>

                                <span className='font-bold text-[#161d46]'>Where you left off:</span>
                                <div className='grid grid-cols-2 lg:grid-cols-4 gap-5 h-auto mt-5'>
                                  {whereYouLeftOffMod && whereYouLeftOffMod.length > 0 ?
                                    <>
                                      {whereYouLeftOffMod.map((modData, index) => (
                                        <div key={index} onClick={() => handleModsOnClick(modData)} className='bg-[#26b8eb] flex flex-col gap-4 justify-between rounded-xl w-full h-full p-4 cursor-pointer'>
                                          <span className='text-white font-bold text-sm'>{modData.module_title}</span>
                                          <div className='w-fit'>
                                            <CircularProgressbar
                                              className='h-14'
                                              value={Math.round(modData.progress)}
                                              text={`${Math.round(modData.progress)}%`}
                                              styles={buildStyles({
                                                textColor: "white",
                                                pathColor: "#161d46",
                                                trailColor: "white",
                                                textSize: "30px"
                                              })}
                                            />
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                    :
                                    <></>
                                  }
                                </div>
                                {recommendedMod && recommendedMod.length > 0 && (
                                  <div onClick={() => handleModsOnClick(recommendedMod[0])} className='w-full h-full lg:h-[50%] rounded-2xl border-[#cccccc] border mt-8 flex overflow-hidden cursor-pointer'>
                                    <div className='w-[50%] h-auto py-6 pl-6 flex flex-col'>
                                      <p className='font-bold text-[#26b8eb] text-base xl:text-xl'>Recommended For You</p>
                                      <div className='mt-auto'>
                                        <div className='w-fit flex flex-col xl:flex-row gap-2 mb-2'>
                                          <div className='py-1 px-2 w-fit border border-[#cccccc] rounded-full flex justify-center items-center gap-2'>
                                            <img src={chapters} alt='chapters' />
                                            <span className='text-[#26b8eb] text-xs lg:text-base'>{recommendedMod[0].units.length} Chapters</span>
                                          </div>
                                          <div className='py-1 px-2 w-fit border border-[#cccccc] rounded-full flex justify-center items-center gap-2'>
                                            <img src={duration} alt='duration' />
                                            <span className='text-[#26b8eb] text-xs lg:text-base'>{convertMinutesToReadableFormat(recommendedMod[0].duration)}</span>
                                          </div>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                          <span className='font-bold text-[#161d46] text-lg xl:text-xl'>{recommendedMod[0].module_title}</span>
                                          <RightArrowIcon />
                                        </div>
                                      </div>
                                    </div>
                                    <div className='w-[50%] relative flex justify-center items-center'>
                                      <img className='object-cover h-full w-full' src={recommendedMod[0].image} alt='image' />
                                      <div className='absolute inset-0 bg-gradient-to-r from-white to-transparent'></div>
                                    </div>
                                  </div>
                                )}
                                <span className='font-bold text-[#161d46] text-xl mt-10'>Discover More</span>
                                {category && category.length > 0 && (
                                  <div className='w-full flex mt-4 gap-3 flex-wrap'>
                                    {category.map((categoryData, index) => (
                                      <div key={index} onClick={() => categoryClick(categoryData)}
                                        className={`w-full lg:w-auto rounded-full py-1 px-3 ${selectedCategory === categoryData ? 'bg-[#26b8eb]' : ''} border border-[#cccccc] flex items-center gap-2 cursor-pointer`}>
                                        <div className={`flex w-[30px] justify-center rounded-full h-fit p-2 ${selectedCategory === categoryData ? 'bg-[#26b8eb]' : 'bg-white'}`}>
                                          <img className='h-4' src={selectedCategory === categoryData ? categoryData.icon : categoryData.iconSelected} alt='icon' />
                                        </div>
                                        <span className={`w-auto font-bold ${selectedCategory === categoryData ? 'text-white' : 'text-[#161d46]'}`}>{categoryData.category}</span>
                                      </div>
                                    ))}
                                  </div>
                                )}
                                <div className='w-full h-full mt-8 mb-4 overflow-x-auto'>
                                  {isCategoryLoading ? <div className='flex justify-center w-full mt-8'><PulseLoader /></div> : LMSMods && LMSMods.length > 0 && likes.length > 0 && (
                                    <div className='grid grid-flow-col auto-cols-[minmax(250px,1fr)] gap-3 h-full'>
                                      {LMSMods.map((lmsmodsData, index) => (
                                        <div onClick={() => handleModsOnClick(lmsmodsData)} key={index} className='w-full h-full rounded-2xl border border-[#cccccc] p-3 cursor-pointer'>
                                          <div className='w-full h-14 flex items-center gap-3'>
                                            <img src={zoalaplus} alt='zoala' />
                                            <span className='font-bold text-[#161d46]'>{lmsmodsData.module_title}</span>
                                          </div>
                                          <div className='w-full h-32 overflow-hidden rounded-xl mt-2'>
                                            <img className='object-cover h-full w-full' src={lmsmodsData.image} alt='mods' />
                                          </div>
                                          <div className='w-full flex items-center mt-3 gap-5'>
                                            {likes.length === LMSMods.length ? <><button className='w-6' onClick={() => handleLike(lmsmodsData.module_id)}><img src={likes[index][lmsmodsData.module_id] ? activelike : defaultlike} alt='like' /></button>
                                              <button className='' onClick={() => handleBookmark(lmsmodsData.module_id)}><img src={bookmarks[index][lmsmodsData.module_id] ? bookmarkactive : bookmarkadd} alt='bookmark' /></button></>
                                              :
                                              <><button className='w-6' onClick={() => handleLike(lmsmodsData.module_id)}><img src={lmsmodsData.is_like ? activelike : defaultlike} alt='like' /></button>
                                                <button className='' onClick={() => handleBookmark(lmsmodsData.module_id)}><img src={lmsmodsData.is_bookmarked ? bookmarkactive : bookmarkadd} alt='bookmark' /></button></>
                                            }
                                          </div>
                                          <div className='w-full mt-2'>
                                            <span>{lmsmodsData.module_description}</span>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>

                              </div>
                            </section>

                            <aside className='bg-white h-auto w-[25%] sm:w-[30%] xl:w-[25%]'>
                              {userData && tierLists ? <SidePanel userData={userData} tierLists={tierLists} /> : ""}
                            </aside>
                          </>
                        }
                      </>
                    }
                  </>
          }
        </div>}
      {/* <Blur><div className='flex gap-4 items-center'><p>Coming Soon</p><img src={cone}/></div></Blur> */}
    </div>
  )
}