import React from 'react'
import { IconProps } from '../../types/icon'

export default function CloseIcon({ className, color, onClick }: IconProps) {
    return (
        <svg onClick={onClick} className={className} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.02248 21C0.461765 21 0 20.5382 0 19.9775C0 19.7054 0.107195 19.4415 0.296849 19.2519L19.2457 0.303033C19.6497 -0.101011 20.2929 -0.101011 20.697 0.303033C21.101 0.707078 21.101 1.35025 20.697 1.75429L1.74811 20.7031C1.55846 20.8928 1.29459 21 1.02248 21Z" fill={color ? color : "#666666"} />
            <path d="M19.9713 21.0003C19.6992 21.0003 19.4354 20.8931 19.2457 20.7034L0.296849 1.75458C-0.0989496 1.35878 -0.0989496 0.707363 0.296849 0.303319C0.700893 -0.0924799 1.35231 -0.0924799 1.74811 0.303319L20.6887 19.2439C21.0928 19.648 21.0928 20.2911 20.6887 20.6952C20.4991 20.8848 20.2352 20.992 19.9631 20.992L19.9713 21.0003Z" fill={color ? color : "#666666"} />
        </svg>

    )
}
