import { create } from "zustand"
import { HotlineProps } from "../types/ineedhelp"

type ContactProps = {
    selectedContact: HotlineProps,
    setSelectedContact: (value: HotlineProps) => void
}


export const useContactStore = create<ContactProps>((set) => ({
    selectedContact: {
        email: '',
        hotline_name: '',
        hotline_number: '',
        hotlines_type: 0,
        hotlines_type_value: '',
        hotline_category: '',
        operating_hour_today: [],
        operating_hour_week: {
            "Monday": [],
            "Tuesday": [],
            "Wednesday": [],
            "Thursday": [],
            "Friday": [],
            "Saturday": [],
            "Sunday": [],
        },
        id: 0,
        initials: '',
        is_24h: 0,
        logo_url: '',
        remarks: '',
        status: 0,
        url: '',
        language: '',
        whatsapp_number: '',
    },
    setSelectedContact: (newValue) => set(() => ({ selectedContact: newValue })),

}))



