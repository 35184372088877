import React, { useEffect, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import DAS from '../components/DAS';
import Error from '../components/Error';
import { PulseLoader } from 'react-spinners';
import InsightsDailyVibes from '../components/InsightsDailyVibes';
import 'react-tooltip/dist/react-tooltip.css';
import { InsightsData, PersonalitiesProps, PositivityData, SurveyProps, ZoalaSurveyChart } from '../types/insights';
import Blur from '../components/Blur';
import { TierLists } from '../types/tier';
import cryptojs from 'crypto-js';
import WellnessRevamp from '../components/WellnessRevamp';
import insights from '../asset/sidebar/active/Insights.svg';
import PersonalityChart from '../components/PersonalityChart';
import SurveyInsight from '../components/SurveyInsight';
import { useDailyVibeStore } from '../store/vibeCheck';
import { track } from '@amplitude/analytics-browser';

const Insights: React.FC = () => {
  const token = sessionStorage.getItem('api_token');
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [personalities, setPersonalities] = useState<PersonalitiesProps['personalities']>([]);
  const [positivityData, setPositivityData] = useState<PositivityData[]>([]);
  const [tierLists, setTierLists] = useState<TierLists>();
  const [insightsData, setInsightsData] = useState<InsightsData>();
  const [surveys, setSurveys] = useState<SurveyProps[]>([]);
  const [positivitySurvey, setPositivitySurvey] = useState<SurveyProps>();
  const [wellnessSurvey, setWellnessSurvey] = useState<SurveyProps>();
  const [zmhlsSurvey, setZmhlsSurvey] = useState<SurveyProps>();
  const [zyrsSurvey, setZyrsSurvey] = useState<SurveyProps>();
  const [zmhlsChart, setZmhlsChart] = useState<ZoalaSurveyChart>()
  const [zyrsChart, setZyrsChart] = useState<ZoalaSurveyChart>()
  const setDailyVibe = useDailyVibeStore((state) => state.setDailyVibe)
  const getInsights = async (source: CancelTokenSource) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/get-latest-insight-sorted`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      });
      if (res.status === 200) {
        setIsLoading(false);
        setInsightsData(res.data);
        setPersonalities(res.data.personalityData);
        setPositivityData(res.data.positivityData);
        setZmhlsChart(res.data.zmhlsChart)
        setZyrsChart(res.data.zyrsChart)
      }
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        setIsError(true);
        setIsLoading(false);

      }
    }
  };

  const getSurveys = async (source: CancelTokenSource) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/get-surveys`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      });
      if (res.status === 200) {
        setSurveys(res.data);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error('Error fetching surveys', error);
      }
    }
  };

  useEffect(() => {
    track('screenView', {screen_name: 'InsightsScreen'})

    const source = axios.CancelToken.source();
    getInsights(source);
    getSurveys(source);

    const tier = localStorage.getItem('tier');
    if (tier && process.env.REACT_APP_TIER_SECRET) {
      try {
        const decryptData = cryptojs.AES.decrypt(tier, process.env.REACT_APP_TIER_SECRET);
        const tierData = JSON.parse(decryptData.toString(cryptojs.enc.Utf8));
        setTierLists(tierData);
      } catch (error) {
        console.error('Error decrypting tier data', error);
      }
    }

    return () => {
      source.cancel();
    };
  }, [token]);

  useEffect(() => {
    if (insightsData?.dailyVibesData)
      setDailyVibe(insightsData.dailyVibesData.d7)
  }, [insightsData])

  useEffect(() => {
    if (surveys.length > 0 && insightsData) {
      const positivitySurvey = surveys.find(survey => survey.survey_label === 4);
      const wellnessSurvey = surveys.find(survey => survey.survey_label === insightsData.wellness_survey_label);
      const zmhls = surveys.filter(survey => survey.survey_label === 8);
      const zyrs = surveys.filter(survey => survey.survey_label === 10);
      if (positivitySurvey) setPositivitySurvey(positivitySurvey);
      if (wellnessSurvey) setWellnessSurvey(wellnessSurvey);
      if (zmhls.length > 0) setZmhlsSurvey(zmhls[zmhls.length - 1]);
      if (zyrs.length > 0) setZyrsSurvey(zyrs[zyrs.length - 1]);
    }
  }, [surveys, insightsData]);

  const handleToSurvey = (survey: SurveyProps) => {
    window.open(`/survey/${survey.page_url}`, "_blank");
  };

  return (
    <div className='h-screen w-screen flex md:justify-center xl:block'>
      <section className='md:ml-8 lg:ml-0'>
        {isLoading ? (
          <div className='flex items-center justify-center h-screen'>
            <PulseLoader />
          </div>
        ) : isError ? (
          <Error />
        ) : (
          surveys.length > 0 && insightsData && (
            <>
              <div className='sm:pl-20 w-[98%] xl:w-full h-auto mt-4'>
                <div className='w-fit px-3 py-1 bg-[#FFEFD4] rounded-full flex sm:ml-20 xl:ml-24'>
                  <img src={insights} alt='insights' className='w-5 mr-2' />
                  <span className='font-bold text-[#FBA91F] text-lg'>Insights</span>
                </div>
                <div className='relative flex flex-col lg:items-center gap-4 pl-16 md:pl-20 xl:pl-24 sm:pt-8 sm:pr-8 lg:pr-24 w-[98%] sm:w-full'>
                  <PersonalityChart insightsData={insightsData} personalities={personalities}>
                    {!tierLists?.personality_chart && <Blur />}
                    {!insightsData?.showChart?.personality && <Blur text='No data to display for Personality Chart' />}
                  </PersonalityChart>
                  <div className='flex flex-col lg:flex-row gap-4 w-full lg:w-[80%]'>
                    <DAS positivityData={positivityData}>
                      {!tierLists?.surveys && <Blur />}
                      {!insightsData?.showChart?.positivity && positivitySurvey && (
                        <Blur text='Complete Positivity Survey to unlock Positivity Gauge' onClick={() => handleToSurvey(positivitySurvey)} />
                      )}
                    </DAS>
                    <InsightsDailyVibes>
                      {!tierLists?.daily_vibes && <Blur />}
                      {!insightsData?.showChart?.dailyVibes && <Blur text='No data to display for Vibe Check' />}
                    </InsightsDailyVibes>
                  </div>
                  {insightsData && wellnessSurvey && (
                    <WellnessRevamp wellnessChart={insightsData.wellnessChart} wellnessSurvey={wellnessSurvey}>
                      {!tierLists?.surveys && <Blur />}
                      {!insightsData.showChart?.wellness && wellnessSurvey && <Blur text='Complete Wellness Survey to unlock Wellness Index' onClick={() => handleToSurvey(wellnessSurvey)} />}
                    </WellnessRevamp>
                  )}
                  <div className='w-full lg:w-[80%] flex flex-col lg:flex-row gap-4'>
                    {zmhlsChart?.showCard && <SurveyInsight header='Zoala Mental Health Literacy Survey' preScore={!zmhlsChart?.pre_score || zmhlsChart?.pre_score === 0 ? 'N/A' : zmhlsChart?.pre_score} postScore={!zmhlsChart?.post_score || zmhlsChart?.post_score === 0 ? 'N/A' : zmhlsChart?.post_score} details={zmhlsChart?.description}>
                      {!insightsData?.showChart?.zmhls && zmhlsSurvey && <Blur text='Complete ZMHLS Survey to unlock Zoala Mental Health Literacy Survey' onClick={() => handleToSurvey(zmhlsSurvey)} />}
                    </SurveyInsight>}

                    {zyrsChart?.showCard && <SurveyInsight header='Zoala Youth Resilience Survey' preScore={!zyrsChart?.pre_score || zyrsChart?.pre_score === 0 ? 'N/A' : zyrsChart?.pre_score} postScore={!zyrsChart?.post_score || zyrsChart?.post_score === 0 ? 'N/A' : zyrsChart?.post_score} details={zyrsChart?.description}>
                      {!insightsData?.showChart?.zyrs && zyrsSurvey && <Blur text='Complete ZYRS Survey to unlock Zoala Youth Resilience Survey' onClick={() => handleToSurvey(zyrsSurvey)} />}
                    </SurveyInsight>}
                  </div>
                </div>
              </div>

            </>
          )
        )}
        <div className='w-full h-12'></div>
      </section>
    </div>
  );
};

export default Insights;
