import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { PlayerIcon } from './PlayerIcon';
import React from 'react';
import checkIsYoutube from '../../helpers/checkIsYoutube';
import ReactPlayer from 'react-player';
import Title from './Title';

export default function Player({ src, poster, feed }: { src: string; poster?: string, feed: WellnessStreamProps }) {
  const [url, setUrl] = useState('');
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const youtubeRef = useRef<ReactPlayer>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const [showPlayIcon, setShowPlayIcon] = useState(false);

  const [isYoutube, setIsYoutube] = useState(false)
  const [youtubePlayer, setYoutubePlayer] = useState<any>(null);

  // Using the react-intersection-observer hook to track visibility
  const { ref, inView } = useInView({
    threshold: 0.5, // Detect when at least 50% of the video is in view
    triggerOnce: false, // Keep checking when scrolling back into view
  });

  // Handle video play/pause
  const handlePlay = () => {
    if (!videoRef.current) return;
    setShowPlayIcon(false)
    videoRef.current.play()
    // setShowPlayIcon(playing)
  };

  // Update progress based on video time
  const handleTimeUpdate = () => {
    if (!videoRef.current) return;
    const progress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
    setProgress(progress);
  };

  // Handle video seek when dragging the progress bar
  const handleSeek = (event: MouseEvent | React.MouseEvent) => {
    if (!videoRef.current || !progressRef.current) return;

    const rect = progressRef.current.getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const newTime = (clickX / rect.width) * videoRef.current.duration;

    videoRef.current.currentTime = newTime;
    setProgress((clickX / rect.width) * 100); // Update progress bar based on click position
  };

  // Start dragging the progress bar
  const handleMouseDown = (event: React.MouseEvent) => {
    setIsDragging(true);
    handleSeek(event); // Seek immediately when mouse is clicked
  };

  // Update progress while dragging
  const handleMouseMove = (event: MouseEvent) => {
    if (!isDragging) return;
    handleSeek(event);
  };

  // Stop dragging
  const handleMouseUp = () => {
    setIsDragging(false);
  };



  // Autoplay based on user interaction
  useEffect(() => {

    function getYouTubeVideoId(url: string) {
      const regex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^&\n?]+)/;
      const match = url.match(regex);

      return match ? setUrl(match[1]) : null;
    }
    getYouTubeVideoId(src)
    // console.log("youtubeRef",youtubeRef.current?.getInternalPlayer())
    if (inView) {
      if (youtubeRef.current && youtubeRef.current.getInternalPlayer()) {
        youtubeRef.current.getInternalPlayer().mute()
        youtubeRef.current.getInternalPlayer().playVideo()

      }
      if (videoRef.current) {

        videoRef.current.muted = true;
        videoRef.current.play().then(() => {
        }).catch((error) => {
          console.log("Error playing video:", error);
        });

        videoRef.current.muted = false;
      }

      setPlaying(true);


    } else if (!inView) {
      // if (youtubePlayer) {

      //   youtubePlayer.pauseVideo();
      //   youtubePlayer.seekTo(0);
      //   setPlaying(false);
      // }
      if (youtubeRef.current && youtubeRef.current.getInternalPlayer()) {
        youtubeRef.current.getInternalPlayer().stopVideo()
      }
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
      setPlaying(false);
      if (videoRef.current) {
        videoRef.current.load(); // This will reload the video and show the poster
      }
    }
  }, [inView]); // Add src to the dependency array

  useEffect(() => {
    // Add global event listeners for mouse move and mouse up
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const onPlayerPlay = () => {
    if (youtubeRef.current) {
      // Get internal player reference
      const internalPlayer = youtubeRef.current.getInternalPlayer();

      // Check if the player is muted
      if (internalPlayer.isMuted()) {
        internalPlayer.unMute()
      } else {
      }
    }
  };


  return (
    <div className="player flex h-full aspect-[0.5625/1] justify-center items-center max-w-[300px] lg:max-w-[400px]" ref={ref}>
      {src ? (
        <div className='relative w-full'>
          {/* <div className='flex items-center w-full h-12 rounded-t-xl bg-black'>
            <img src={isMuted ? mute : unmute} alt="" className='w-6 h-6 ml-4 cursor-pointer' onClick={handleMute} />
          </div> */}
          {checkIsYoutube(src) ?

            <ReactPlayer
              playing={playing}
              style={{ cursor: 'pointer' }}
              width={'100%'}
              height={'calc(100dvh - 3rem)'}
              url={src}
              loop
              muted
              ref={youtubeRef}
              playsinline={true}
              config={{
                youtube: {
                  playerVars: {
                    playsinline: 1,
                    controls: 1,
                    fs: 1 // Enable fullscreen button
                  },
                }
              }}
              onPlay={onPlayerPlay}
            />
            :
            <video
              src={src}
              loop
              webkit-playsinline="true"
              playsInline
              poster={poster}
              ref={videoRef}
              preload="metadata"
              muted
              controls
              onTimeUpdate={handleTimeUpdate} // Track video progress
              onPlay={() =>setShowPlayIcon(false)}
              onPause={() =>setShowPlayIcon(true)}
            >
              <track kind="captions" />
            </video>
          }
          <Title feed={feed} className={`${checkIsYoutube(src) ? 'bottom-24' : 'bottom-16'} left-2`} />

        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-full bg-gray-200">
          <p>No video source provided</p>
        </div>
      )}
      {/* Custom progress bar */}
      {/* <div
        className="progress-bar"
        ref={progressRef}
        onMouseDown={handleMouseDown} // Start dragging
      >
        <div className="progress" style={{ width: `${progress}%` }} />
      </div> */}
      <div onClick={handlePlay}>
        {showPlayIcon && <PlayerIcon />}
      </div>

      <style>{`
      .player iframe{
        height: calc(100dvh - 5rem);
        width: 400px;
      }
        .player {
          position: relative;
          background: var(--black);
          overflow: hidden;
        }

        .player video {
          position: relative;
          // height: 100vh;
        }

        .player-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: grid;
          place-content: center;
          visibility: visible;
        }

        /* Progress bar container */
        .progress-bar {
          position: absolute;
          bottom: 10px;
          left: 0;
          width: 100%;
          height: 5px;
          background-color: rgba(255, 255, 255, 0.3);
          cursor: pointer;
        }

        /* Progress indicator */
        .progress {
          height: 100%;
          background-color: red;
          transition: width 0.1s linear;
        }

      `}</style>
    </div>
  );
}
