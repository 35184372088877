import React, { useState } from 'react'
import { UserDataProps } from '../../types/common'
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface AccountProps {
    userData: UserDataProps
}

export default function Account({ userData }: AccountProps) {
    const navigate = useNavigate();
    const [deleteAccount, setDeleteAccount] = useState<Boolean>(false);
    const [infoClicked, setInfoClicked] = useState(false)

    const handleDeleteClick = () => {
        setDeleteAccount(true);
    };

    const handleInfoClick = () => {
        setInfoClicked(true);
    };

    const convertGenderString = (gender?: string): string => {
        if (gender === 'm') {
            return 'Male';
        } else if (gender === 'f') {
            return 'Female';
        } else {
            return 'NA'
        }
    }

    const handleChangePassword = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/forgot-password/send-verification-code`, {
                email: userData?.login_email
            });
            if (response.status === 200) {
                navigate('/change-password')
            }
        } catch (error) {
            toast.error('Error please try again!');
        }
    };
    const handleCloseClick = () => {
        setDeleteAccount(false);
        setInfoClicked(false);
    };
    return (
        <div className='pt-10 pl-10 pr-10'>
            <span className='text-[#161d46] font-bold text-2xl'>Account</span>
            <div className='w-full h-fit p-8 border border-[#c8c8c8] rounded-2xl mt-5'>
                <div className='w-full flex justify-between'>
                    <span className='text-[#161d46] font-bold text-lg'>Personal Information</span>
                    <button onClick={() => handleInfoClick()} className='rounded-full w-6 h-6 bg-[#808080] flex justify-center items-center'>
                        <span className='text-white font-bold'>i</span>
                    </button>
                </div>
                <div className='w-full h-full flex mt-6'>
                    <div className='w-[50%] flex flex-col gap-4'>
                        <div className='flex flex-col'>
                            <span className='text-[#808080]'>First Name</span>
                            <span className='text-[#161d46]'>{userData?.user_info.firstname}</span>
                        </div>
                        <div className='flex flex-col'>
                            <span className='text-[#808080]'>Email Address</span>
                            <span className='text-[#161d46] w-1'>{userData?.login_email ? userData.login_email : '...'}</span>
                        </div>
                        <div className='flex flex-col'>
                            <span className='text-[#808080]'>Gender</span>
                            <span className='text-[#161d46]'>{convertGenderString(userData?.user_info.gender)}</span>
                        </div>
                    </div>
                    <div className='w-[50%] flex flex-col gap-4'>
                        <div className='flex flex-col'>
                            <span className='text-[#808080]'>Last Name</span>
                            <span className='text-[#161d46]'>{userData?.user_info.lastname ? userData.user_info.lastname : '...'}</span>
                        </div>
                        <div className='flex flex-col'>
                            <span className='text-[#808080]'>Birthday</span>
                            <span className='text-[#161d46]'>{userData?.user_info.birth_date ? userData.user_info.birth_date : '...'}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full h-fit p-8 border border-[#c8c8c8] rounded-2xl mt-4 flex flex-col mb-10'>
                <span className='text-[#161d46] font-bold text-lg'>Organization & Class</span>
                <div className='w-[50%] flex mt-6 justify-between'>
                    <div className='flex flex-col'>
                        <span className='text-[#808080]'>Organization</span>
                        <span className='text-[#161d46]'>{userData?.org_roles[0]?.org_name ? userData.org_roles[0].org_name : '...'}</span>
                    </div>
                    <div className='flex flex-col'>
                        <span className='text-[#808080]'>Class</span>
                        <span className='text-[#161d46]'>
                            {userData?.org_roles[0]?.class_name ? userData.org_roles[0].class_name : '...'}
                        </span>
                    </div>
                </div>
            </div>
            <span className='text-[#161d46] font-bold text-2xl'>Password</span>
            <div className='w-full h-fit p-8 border border-[#c8c8c8] rounded-2xl mt-3 flex justify-between items-center mb-10'>
                <span>********</span>
                <button onClick={() => handleChangePassword()} className='rounded-full border border-[#29b6e8] bg-white px-6 py-2'>
                    <span className='text-[#29b6e8] font-bold'>Change Password</span>
                </button>
            </div>
            <span className='text-[#ed3426] font-bold text-2xl'>Delete Account</span>
            <div className='w-full h-fit p-8 border border-[#ed3426] rounded-2xl mt-3 flex justify-between items-center mb-10'>
                <div className='flex flex-col'>
                    <span className='text-[#ed3426] font-bold'>Delete my account</span>
                    <span className='text-[#808080]'>Deleting your account will remove all of your information from our database.</span>
                </div>
                <button onClick={() => handleDeleteClick()} className='rounded-full bg-[#ed3426] px-6 py-2'>
                    <span className='text-white font-bold'>Delete account</span>
                </button>
            </div>
            {deleteAccount && (
                <div className="fixed inset-0 flex items-center justify-center z-[9998]">
                    <div className="absolute inset-0 bg-[#f4f4f4] opacity-40"></div>
                    <div className="bg-white rounded-2xl z-[9998] relative min-h-[200px] 
                    max-h-[200px] h-full min-w-[500px] w-[40%] border border-[#dedede] flex flex-col justify-center items-center p-10">
                        <button onClick={() => handleCloseClick()} className="absolute top-5 right-4 m-4 text-gray-600 hover:text-gray-800 text-2xl">
                            X
                        </button>
                        <span className='font-bold text-xl mt-4'>Delete account</span>
                        <span className='text-center text-sm mt-2'>Please reach out to your school to delete your account.</span>
                        <button onClick={() => handleCloseClick()} className='rounded-full bg-[#ed3426] px-6 py-2 mt-8'>
                            <span className='text-white font-bold'>OK</span>
                        </button>
                    </div>
                </div>
            )}
            {infoClicked && (
                <div className="fixed inset-0 flex items-center justify-center z-[9998]">
                    <div className="absolute inset-0 bg-[#f4f4f4] opacity-40"></div>
                    <div className="bg-white rounded-2xl z-[9998] relative min-h-[200px] 
                    max-h-[200px] h-full min-w-[500px] w-[40%] border border-[#dedede] flex flex-col justify-center items-center p-10 text-[#161d46] font-bold text-lg">
                        <button onClick={() => handleCloseClick()} className="absolute top-1 right-4 m-4 text-gray-600 hover:text-gray-800 text-2xl">
                            X
                        </button>
                        <span>Your school provided these information.</span>
                        <span>If anything needs to be updated,</span>
                        <span>Just let your school knows!</span>
                    </div>
                </div>
            )}
        </div>
    )
}
