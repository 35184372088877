import cryptojs from 'crypto-js'

type EncryptDataProps = {
    data: string,
    secret: string
}
export function encryptData({ data, secret }: EncryptDataProps) {
    const encryptedData = cryptojs.AES.encrypt(data, secret).toString();

    return encryptedData;
}

export function decryptData({ data, secret }: EncryptDataProps) {
    const decryptedData = cryptojs.AES.decrypt(data, secret)
    const res = JSON.parse(decryptedData.toString(cryptojs.enc.Utf8))
    return res;
}