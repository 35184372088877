import React, { Fragment, useState } from 'react'
import InfoIcon from '../asset/InfoIcon'
import { Tooltip } from 'react-tooltip'
import WellnessRevampChart from './WellnessRevampChart'
import emotion from '../asset/insights/wellness-emotional.svg'
import physical from '../asset/insights/wellness-physical.svg'
import learning from '../asset/insights/wellness-learning.svg'
import family from '../asset/insights/wellness-family.svg'
import peer from '../asset/insights/wellness-peer.svg'
import self from '../asset/insights/wellness-self.svg'
import { InsightsData } from '../types/insights'
export default function WellnessRevamp({ wellnessChart, wellnessSurvey, children }: InsightsData) {
    const details = [
        {
            header: 'Emotion Wellbeing',
            color: '#FBA91F',
            icon: emotion
        },
        {
            header: 'Physical Wellbeing',
            color: '#26B8EB',
            icon: physical
        },
        {
            header: 'Learning Progress',
            color: '#AED137',
            icon: learning
        },
        {
            header: 'Family Connectedness',
            color: '#2E79BE',
            icon: family
        },
        {
            header: 'Peer Relationship',
            color: '#A271B0',
            icon: peer
        },
        {
            header: 'Self-Esteem',
            color: '#FEC800',
            icon: self
        }
    ]
    const filteredKeys = Object.keys(wellnessChart).filter(key => key !== 'wellness_score');
    return (
        <div className='relative pt-4 w-full lg:w-[80%]'>
            <div className='rounded-2xl border border-[#cccccc] h-full w-full flex flex-col py-6 p-5'>
                <div className='flex items-center gap-2 mb-3'>
                    <h5 className='text-2xl font-bold'>Wellness Index</h5>
                    <a
                        data-tooltip-id="wellness-tooltip"
                        data-tooltip-content="A wellness index is a numerical representation of overall well-being derived from the Zoala's Wellbeing Survey."
                        data-tooltip-place="top"
                    >
                        <InfoIcon />
                    </a>
                    <Tooltip id="wellness-tooltip" style={{ maxWidth: "30%" }} />
                </div>
                <div className='flex justify-between items-center mb-8'>
                    <div>
                        <h5 className='text-[#26B8EB] font-bold'>Total Wellness Score</h5>
                        <p className='text-[#b7b9b8]'>For this week</p>
                    </div>
                    <div className='rounded-full bg-[#26B8EB] text-white px-4 py-1'>{wellnessChart.wellness_score}</div>
                </div>
                <div className='flex flex-wrap gap-4'>
                    {filteredKeys.map((domain, index) =>
                        <Fragment key={index}>
                            <WellnessRevampChart detail={details[index]} domain={wellnessChart[domain]} wellnessSurvey={wellnessSurvey} />
                        </Fragment>
                    )}

                </div>
                {/* <WellnessBarChart /> */}
            </div>
            {children}
        </div>
    )
}
