import { create } from "zustand"

type TierProps = {
    tier: string,
    setTier: (value: string) => void
}

export const useTierStore = create<TierProps>((set) => ({
    tier: "",
    setTier: (newValue) => set(() => ({ tier: newValue })),
}))
