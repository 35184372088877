import React, { Dispatch, SetStateAction, useState } from 'react';

interface ClickableListProps {
  safetyPlan: string[];
  handleBackClick: undefined | (() => void);
  handleNextClick: () => void;
  uniqueIdentifier: string;
  // Define the selectedItems property
  selectedItems: string[]; // Add this line
  setSelectedItems: Dispatch<SetStateAction<string[]>>;
  handleIAmOkayClick: () => Promise<void>;
}

function ClickableList({
  safetyPlan,
  handleBackClick,
  handleNextClick,
  uniqueIdentifier,
  handleIAmOkayClick
}: ClickableListProps) {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const handleItemClick = (index: number) => {
    // Check if the item is already selected
    const itemIdentifier = `${uniqueIdentifier}_${index}`;
    const selectedIndex = selectedItems.indexOf(itemIdentifier);
    if (selectedIndex === -1) {
      // If not selected, add it to the selected items
      setSelectedItems([...selectedItems, itemIdentifier]);
    } else {
      // If already selected, remove it from the selected items
      const updatedItems = [...selectedItems];
      updatedItems.splice(selectedIndex, 1);
      setSelectedItems(updatedItems);
    }
  };

  const isItemSelected = (index: number) => {
    // Generate a unique identifier for the item
    const itemIdentifier = `${uniqueIdentifier}_${index}`;
    return selectedItems.includes(itemIdentifier);
  };

  return (
    <main className="flex flex-col items-center mt-4 w-full px-[13%] h-full relative">
      <div className="overflow-y-auto max-h-52 h-52 w-full space-y-3 px-4">
        {safetyPlan.map((item, index) => (
          <div
            key={index}
            className={`relative border rounded p-2 w-full flex justify-between text-sm cursor-pointer`}
            onClick={() => handleItemClick(index)}
          >
            <p>{item}</p>
            {isItemSelected(index) ? (
              <div className="w-5 h-5 rounded-full bg-[#29b6e8] flex items-center justify-center">
                <span className="text-white">✓</span>
              </div>
            ) : (
              <div className="w-5 h-5 rounded-full bg-[#d9d9d9]"></div>
            )}
          </div>
        ))}
      </div>
      <div className='w-full flex justify-between mt-10'>
        <button className={`bg-[#29b6e8] text-white rounded-full py-1 px-4 ${handleBackClick ? '' : 'bg-transparent cursor-default text-transparent'}`} onClick={handleBackClick}>Back</button>
        <button className='bg-[#29b6e8] text-white rounded-full py-1 px-4' onClick={handleNextClick}>Next</button>
      </div>
      <div className='w-full rounded-xl h-32 bg-[#29b6e8] mt-10 flex flex-col items-center'>
        <span className='text-white font-bold text-xl mt-4'>How am I feeling now?</span>
        <div className='w-full flex mt-4 justify-center gap-4 px-8'>
          <button className={`rounded-full py-2 w-[100%] font-bold ${selectedItems.length > 0 ? 'bg-white text-[#29b6e8] cursor-pointer' : 'bg-[#989998] border-0 text-white cursor-default'}`} onClick={selectedItems.length > 0 ? handleIAmOkayClick : undefined}>I'm okay</button>
          <button className={`rounded-full py-2 w-[100%] font-bold ${selectedItems.length > 0 ? 'bg-red-400 text-white cursor-pointer' : 'text-white bg-[#989998] border-0 cursor-default'}`} onClick={selectedItems.length > 0 ? handleNextClick : undefined}>I'm still not okay</button>
        </div>
      </div>
    </main>
  );
}

export default ClickableList;
