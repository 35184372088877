import { Button, Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import PinField from 'react-pin-field'
import { toast } from 'react-toastify'
import axios from 'axios'
import Loader from '../icons/Loader'
import eyeOn from '../../asset/eyeOn.svg'
import eyeOff from '../../asset/eyeOff.svg'
import CloseIcon from '../../asset/ineedhelp/CloseIcon'
import { Link, useNavigate } from 'react-router-dom'
import { PulseLoader } from 'react-spinners'
import { useAuthStore } from '../../store/auth'
import { useInitApi } from '../../store/initApi'
import { usePrivacyStore } from '../../store/privacy'
import { SlArrowLeft } from 'react-icons/sl'

interface ChangePinPopUp {
    isOpenChangePin: boolean,
    closeIsOpenChangePin: () => void
    isForgetPin?: boolean
    closeIsForgetPin?: () => void
}
interface PasswordValidateProps {
    status?: boolean
    message: string,
    attempts?: number | string,
    remaining_time?: number
}

export default function ChangePinPopUp({ isOpenChangePin, closeIsOpenChangePin, isForgetPin, closeIsForgetPin }: ChangePinPopUp) {
    const apiToken = sessionStorage.getItem('api_token')
    const navigate = useNavigate();
    const toRestPin = localStorage.getItem('toResetPin')
    const passwordRef = useRef<HTMLInputElement>(null)
    const pinRef = useRef<HTMLInputElement[]>([])
    const pinReenterRef = useRef<HTMLInputElement[]>([])
    const [password, setPassword] = useState("")
    const [pinValue, setPinValue] = useState('')
    const [step, setStep] = useState<number>(1)
    const [passwordError, setPasswordError] = useState<PasswordValidateProps | null>(null)
    const [pinError, setPinError] = useState(false)
    const [pinReenterError, setPinReenterError] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [passwordVisible, setPasswordVisible] = useState(false)
    const setAuth = useAuthStore((state) => state.setAuth);
    const initApi = useInitApi(state => state.initApi)
    const setIsShowLocked = usePrivacyStore(state => state.setIsShowLocked)

    const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.trim() !== '') {
            setPasswordError(null)
        }
        setPassword(event.target.value.trim())
    }

    const handlePin = () => {
        const pinValue = pinRef.current?.map(input => input.value).join('');
        if (pinValue?.length === 4) {
            setPinValue(pinValue)
            pinRef.current?.map(input => (input.value = ""))
            setStep(3)
            setPinError(false)
        } else {
            setPinError(true)
        }
    }

    const handlePinReenter = async () => {
        try {
            const pinReenterValue = pinReenterRef.current?.map(input => input.value).join('');
            if (pinValue !== pinReenterValue) {
                setPinReenterError(true)
                pinReenterRef.current.forEach(input => (input.value = "")); // Reset input values to empty
                pinReenterRef.current[0].focus()
                return;
            }
            if (!initApi) return;
            setIsDisabled(true)

            const res = await axios.post(initApi.url.features.privacy_pin.post_privacy_mode, {
                type: 'change',
                privacy_pin: pinReenterValue
            }, { headers: { Authorization: `Bearer ${apiToken}` } })
            if (!res.data.session) {
                setPinReenterError(true)
            } else {
                closeIsOpenChangePin()
                toast.success('You have successfully set your PIN')
                setPinReenterError(false)
                setIsShowLocked(false)
                setStep(1)
                localStorage.removeItem('toResetPin')
            }
            setIsDisabled(false)

        } catch (error) {
            setIsDisabled(false)

        }
    }
    const handleLogout = (): void => {
        // Delete stored user data and API token
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('api_token');
        localStorage.removeItem('tier');
        localStorage.removeItem('zoala_version');
        localStorage.removeItem('localStreak')
        localStorage.removeItem('isActivated');
        localStorage.removeItem('storedTime');
        localStorage.removeItem('shownBoardingPrivacy');

        setAuth(false)
        navigate('/login');
    };
    const passwordValidate = async () => {
        try {
            if (passwordRef.current?.value.trim() === '') {
                setPasswordError({
                    status: false,
                    message: 'Password cannot be empty!'
                })
                return;
            }
            if (!initApi) return;
            setIsDisabled(true)

            const res = await axios.post(initApi.url.features.privacy_pin.post_password_validation, { password: password }, { headers: { Authorization: `Bearer ${apiToken}` } })
            if (res.data.status === false && res.data.attempts !== "3") {
                setPasswordError(res.data)
            } else if (res.data.status === false && res.data.attempts === "3") {
                handleLogout()
            } else {
                setPasswordError(null)
                setStep(2)
            }
            setIsDisabled(false)

        } catch (error) {
            setPasswordError({
                status: false,
                message: 'Error while validating password'
            })
            setIsDisabled(false)

        }
    }

    useEffect(() => {
        if (!isOpenChangePin) {
            setStep(1)
            setPassword('')
            setPinValue('')
            setPasswordVisible(false)
        }
        if (isOpenChangePin && step === 0 && !toRestPin) {
            setStep(1)
        }
        if (isOpenChangePin && step === 0 && toRestPin) {
            setStep(2)
        }
        if (step === 2) {
            pinRef.current[0].focus()
            setPinError(false)
        }
        if (step === 3) {
            pinReenterRef.current[0].focus()
            setPinReenterError(false)
        }
    }, [isOpenChangePin, step])

    return (
        <Transition appear show={isOpenChangePin}>
            <Dialog as="div" className="relative z-[99999] focus:outline-none lexend-deca-normal" onClose={isForgetPin ? () => null : closeIsOpenChangePin}>
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <TransitionChild
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 transform-[scale(95%)]"
                            enterTo="opacity-100 transform-[scale(100%)]"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 transform-[scale(100%)]"
                            leaveTo="opacity-0 transform-[scale(95%)]"
                        >
                            <DialogPanel className="relative flex justify-center w-full xl:max-w-[70%] h-[500px] rounded-xl bg-white p-6 border border-[#cccccc]">
                                {isForgetPin ? "" : <CloseIcon onClick={closeIsOpenChangePin} className='absolute top-5 right-5 cursor-pointer' />}
                                {step === 3 && <SlArrowLeft onClick={isDisabled ? () => null : () => setStep(2)} className='absolute top-5 left-5 cursor-pointer'/>}

                                <div className='flex flex-col justify-between h-full w-1/2'>
                                    {step === 1 ?
                                        <div className='flex flex-col justify-evenly h-full'>
                                            <h1 className='text-2xl font-bold text-center'>Enter your password</h1>
                                            <div>
                                                <div className='relative flex justify-center pb-8'>
                                                    <input type={passwordVisible ? 'text' : 'password'} placeholder='Password' ref={passwordRef} className='w-full border-gray-500 border-b-2 focus:outline-none text-2xl' onChange={handleChangePassword} disabled={isDisabled} />
                                                    <img src={passwordVisible ? eyeOff : eyeOn} alt="" width={25} height={25} className='absolute top-1 right-0 cursor-pointer' onClick={() => setPasswordVisible(!passwordVisible)} />
                                                    <Link to="/forget-password" state={{fromPinPopUpForgetPassword: true}} className='absolute bottom-0 right-0 text-[#00ace5] text-sm'>Forgot password?</Link>

                                                </div>
                                                {!passwordError?.status && <p className='text-red-600 text-center'>{passwordError?.message}</p>}
                                                {!passwordError?.status && passwordError?.attempts && <p className='text-red-600 text-center'>Attempt remaining: {4 - Number(passwordError?.attempts)}</p>}
                                            </div>
                                            <div className="mt-4 flex flex-col">
                                                <Button
                                                    className=" text-center rounded-md bg-[#29B3E6] text-white py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10 data-[hover]:bg-[#36819d]"
                                                    onClick={passwordValidate} disabled={isDisabled}
                                                >
                                                    {isDisabled ? <Loader className='m-auto' /> : 'Confirm'}

                                                </Button>
                                                {isForgetPin && !toRestPin ? <Button
                                                    className=" text-center rounded-md  py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10 data-[hover]:bg-[#dadada]"
                                                    onClick={closeIsForgetPin} disabled={isDisabled}
                                                >
                                                    Cancel

                                                </Button> : ""}
                                            </div>
                                        </div> :
                                        step === 2 ?
                                            <div className='flex flex-col justify-evenly h-full'>
                                                <h1 className='text-2xl font-bold text-center'>Set up a new PIN</h1>
                                                <div className='flex flex-col items-center'>
                                                    <div className='flex justify-center mb-4'>
                                                        <PinField ref={pinRef} validate={/^[0-9]$/} type='password' className='border w-20 h-20 text-center text-6xl' length={4} autoFocus disabled={isDisabled} onComplete={handlePin} />
                                                    </div>
                                                    {pinError && <p className='text-red-600 text-center'>4 digits PIN is required</p>}
                                                </div>

                                            </div> : step === 3 ?
                                                <div className='flex flex-col justify-evenly h-full'>
                                                    <h1 className='text-2xl font-bold text-center'>Enter new PIN again.</h1>
                                                    <div className='flex flex-col items-center'>
                                                        <div className='flex justify-center mb-4'>
                                                            <PinField ref={pinReenterRef} validate={/^[0-9]$/} type='password' className='border w-20 h-20 text-center text-6xl' length={4} autoFocus disabled={isDisabled} onComplete={handlePinReenter} />
                                                        </div>
                                                        {pinReenterError && <p className='text-red-600 text-center'>Incorrect PIN</p>}
                                                        {isDisabled ? <PulseLoader className='mt-4' /> : ''}
                                                    </div>
                                                    <div className="mt-4 flex flex-col">
                                                        <button
                                                            className="text-center rounded-md bg-[#dadada] hover:bg-[#a8a8a8] text-white py-1.5 px-3 text-sm/6 font-semibold shadow-inner shadow-white/10 "
                                                            onClick={()=> setStep(2)} disabled={isDisabled}
                                                        >
                                                             Back

                                                        </button>
                                                    </div>
                                                </div> : ""
                                    }
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>

            </Dialog>
        </Transition>
    )
}
