import React, { useEffect, useState } from 'react'
import { UserDataProps } from '../../types/common'
import fire from '../../asset/gamification/fire.svg'
import stars from '../../asset/gamification/stars.svg'
import globe from '../../asset/gamification/globe.svg'
import Achievements from './Profile/Achievements'
import { useProfileStore } from '../../store/profile'

interface ProfileProps {
  userData: UserDataProps
}
export default function Profile({ userData }: ProfileProps) {
  const {leaderboard} = useProfileStore()
  const [rank , setRank] = useState<string>("N/A")
  useEffect(() => {
    if (leaderboard?.leaderboard && userData) {
      const foundKey = Object.entries(leaderboard.leaderboard).find(
        ([key, entry]) => entry.uuid === userData.uuid
      )?.[0]; // The key will be the first element in the entry
      
      if (foundKey) {
        setRank(foundKey)
      }
    }
  }, [leaderboard, userData]);
  return (
    <div className='pt-10 pl-10 pr-10'>
      <div className='flex bg-gray-200 p-4 w-full justify-evenly rounded-xl'>
        <div className='flex flex-col gap-2 items-center'>
          <img src={stars} alt="" width={35} height={35} />
          <p className='uppercase text-gray-800 font-bold'>total xp</p>
          <span>{userData.gamification.total_xp}</span>
        </div>
        <div className='flex flex-col gap-2 items-center text-gray-800 font-bold'>
          <img src={globe} alt="" width={35} height={35} />
          <p className='uppercase'>Season Rank</p>
          <span>{rank}</span>
        </div>
      </div>
      <div className='w-full'>
        <div className='w-full flex items-center gap-4 p-4 mt-8 bg-[#e3d4e7] rounded-t-3xl border-2 border-[#F3EDF5] border-b-transparent'>
          <div className='font-bold'>Statistics</div>

        </div>
        <div className='flex gap-4 p-4 w-full rounded-b-3xl border-2 border-[#e3d4e7] border-t-transparent'>
          <img src={fire} alt="" width={35} height={35} />
          <div className='flex flex-col'>
            <p>Streak</p>
            <span className='font-bold'>{userData.gamification.current_streak}</span>
          </div>
        </div>
      </div>
      <Achievements/>
    </div>
  )
}
