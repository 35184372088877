import React from 'react'
import { IconProps } from '../../types/icon'

export default function SendIcon({ className, onClick, color }: IconProps) {
    return (
        <svg onClick={onClick} className={className} width="60" height="60" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24.5" cy="24.5" r="24.5" fill={color ? color : '#26B8EB'} />
            <svg x="15" y="15" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.1829 8.69236L1.59354 0.112145C1.44226 0.0383546 1.27615 0 1.10784 0C0.496 0 0 0.496 0 1.10784V1.13981C0 1.28846 0.0182273 1.43656 0.0542818 1.58078L1.74153 8.32973C1.78761 8.51409 1.94347 8.65027 2.13231 8.67127L9.54827 9.49527C9.80546 9.52382 10 9.74118 10 10C10 10.2588 9.80546 10.4762 9.54827 10.5047L2.13231 11.3287C1.94347 11.3497 1.78761 11.4859 1.74153 11.6703L0.0542818 18.4192C0.0182273 18.5635 0 18.7115 0 18.8602V18.8922C0 19.504 0.496 20 1.10784 20C1.27615 20 1.44226 19.9616 1.59354 19.8878L19.1829 11.3076C19.6828 11.0638 20 10.5563 20 10C20 9.44373 19.6828 8.93618 19.1829 8.69236Z" fill="white" />
            </svg>
        </svg>

    )
}