import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TextInput from '../components/TextInput';
import background from '../asset/BG.png';
import greentick from '../asset/login/passwordgreentick.svg';
import greycross from '../asset/login/passwordgreycross.svg';
import zoalalogo from '../asset/login/zoala-logo-standard.svg';
import zoalafaces from '../asset/login/zoalafaces.svg';
import bluetick from '../asset/login/bluetick.svg';
import pop from '../asset/login/pop.svg';
import emailsent from '../asset/login/emailsent.svg';
import Datepicker from 'react-tailwindcss-datepicker';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Loader from '../components/icons/Loader';
import { track } from '@amplitude/analytics-browser';

interface FormData {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}

function SignUp() {
  const navigate = useNavigate();
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&`~\\\-_+])[A-Za-z\d@$!%*#?&`~\\\-_+.]{8,}$/;
  const [code, setCode] = useState('');
  const [step, setStep] = useState(1);
  const [age, setAge] = useState(0);
  const [showSignUp, setShowSignUp] = useState(true);
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [isSpecialCharValid, setIsSpecialCharValid] = useState(false);
  const [isNumberValid, setIsNumberValid] = useState(false);
  const [isUppercaseValid, setIsUppercaseValid] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false)
  const [formData, setFormData] = useState<FormData>({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
  });

  const [birthdate, setBirthdate] = useState<{ startDate: Date | null; endDate: Date | null }>({
    startDate: null,
    endDate: null
  });

  const handleDateChange = (newValue: any) => {
    setBirthdate(newValue);
  };
  function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }
  useEffect(() => {
    if (birthdate.startDate) {
      // if (calculateAge() < 13) {
      //   toast.warning('Sorry, you must be at least 13 years old to register for an account on this website. If you have any questions, please contact our support team.');
      //   setAge(0);
      // } else {
      // }
      setAge(calculateAge());

    }
  }, [birthdate.startDate]);

  const checkPasswordRequirements = (password: string) => {
    setIsLengthValid(password.length >= 8);
    setIsSpecialCharValid(/[$&+,:;=?@#|'<>.^*()%!`~\\\-_]/.test(password));
    setIsNumberValid(/\d/.test(password));
    setIsUppercaseValid(/[A-Z]/.test(password));

    setFormData({
      ...formData,
      'password': password,
    });
  };

  const calculateAge = () => {
    const today = new Date();
    const birthDate = new Date(birthdate.startDate!);
    let calculatedAge = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      calculatedAge--;
    }

    return calculatedAge;
  };

  const getPasswordStatusIcon = (isValid: boolean) => {
    return isValid ? greentick : greycross;
  };

  const handleInputChange = (name: string, value: string) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNextClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setStep(step + 1);
    setIsLengthValid(false);
    setIsNumberValid(false);
    setIsSpecialCharValid(false);
    setIsUppercaseValid(false);
  };

  const handleBackClick = () => {
    setStep(step - 1);
  };

  const handleBackToLoginClick = () => {
    navigate(-1);
  };

  const handleBackToOrgClick = () => {
    setShowSignUp(true);
  };

  const handleRegister = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsDisabled(true)
    if (age <= -1) {
      toast.warning('Invalid age selected');
      setIsDisabled(false)
      return;
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/register-by-org-code`, {
        email: formData.email,
        password: formData.password,
        first_name: formData.first_name,
        last_name: formData.last_name,
        birth_date: birthdate.startDate ?? formatDate(new Date()),
        school_code: code,
      });
      if (response.status === 201) {
        setStep(step + 1);
      }
      setIsDisabled(false)
    } catch (error) {
      setIsDisabled(false)
      toast.error('Error registering. Please try again');
      setStep(1);
      setShowSignUp(true);
    }
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setIsDisabled(true)
      const formData = new FormData(e.currentTarget);
      const schoolCode = formData.get('code') as string;
      const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/validate-school-code`, {
        school_code: schoolCode,
      });
      if (response.status === 200) {
        setCode(schoolCode);
        setShowSignUp(false);
      }
      setIsDisabled(false)
    } catch (error) {
      setIsDisabled(false)
      toast.error('Wrong org code');
    }
  };

  const handleGoToMail = () => {
    const mailtoUrl = `mailto:`;
    window.location.href = mailtoUrl;
  };
  useEffect(() => {
    track('screenView', { screen_name: 'SignUpScreen' })
  },[])
  return (
    <>
      <div className='flex justify-center items-center flex-col h-screen overflow-hidden'
        style={{
          backgroundImage: `url(${background})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
      >
        <img src={zoalalogo} className='w-40 h-40' alt='zoala logo' />
        {showSignUp ? (
          <div className='flex justify-center items-center flex-col w-full'>
            <span className='font-bold text-xl'>Thanks for joining us!</span>
            <img src={zoalafaces} className='w-80 h-80 mt-2' alt='zoala face' />
            <span className='font-bold text-[#00ace5]'>Enter school code</span>
            <form onSubmit={handleFormSubmit} className='mb-40 mt-4 flex items-center justify-center w-full'>
              <button className='flex items-center outline-none font-bold text-[#00ace5]' onClick={() => handleBackToLoginClick()}>Back</button>
              <input
                name='code'
                className='bg-[#f2f3f2] pl-3 outline-none h-[40px] w-72 rounded-[5px] ml-8'
                type='text'
                placeholder='Code'
                required
              />
              <button disabled={isDisabled} type="submit" className='flex items-center outline-none'>
                {isDisabled ? <Loader className='ml-4' color='#00ace5' /> : <img src={bluetick} className='w-11 h-11 ml-4' alt='blue tick' />}
              </button>
            </form>
          </div>
        ) : (
          <div className='flex justify-center h-screen w-screen pb-10 relative'>
            <div className='bg-white h-fit w-96 rounded-3xl shadow-2xl shadow-black z-10 p-6'>
              <div className='w-full h-full flex flex-col items-center'>
                {step === 1 ? (
                  <>
                    <div className='w-full flex justify-center items-center gap-4 mt-4'>
                      <span className='font-bold text-[#00ade7] text-xl'>1</span>
                      <div className='bg-[#00ade7] w-3 h-[1px]'></div>
                      <span className='font-bold text-[#a4a4a5] text-xl'>2</span>
                      <div className='bg-[#a4a4a5] w-3 h-[1px]'></div>
                      <span className='font-bold text-[#a4a4a5] text-xl'>3</span>
                    </div>
                    <span className='text-[#00ade7] font-bold text-xl mt-7'>Sign Up</span>
                    <form onSubmit={handleNextClick} className='flex flex-col items-center w-full h-full mt-5 gap-3 relative'>
                      <TextInput type={'text'} placeholder={'First name'} name={'firstname'} onChange={(e) => handleInputChange('first_name', e.target.value)}></TextInput>
                      <TextInput type={'text'} placeholder={'Last name'} name={'lastname'} onChange={(e) => handleInputChange('last_name', e.target.value)}></TextInput>
                      <TextInput type={'email'} placeholder={'Email'} name={'email'} onChange={(e) => handleInputChange('email', e.target.value)}></TextInput>
                      <TextInput type={'password'} placeholder={'Password'} name={'password'} pattern={passwordRegex.source} onChange={(e) => checkPasswordRequirements(e.target.value)}></TextInput>
                      <div className='flex flex-col text-xs gap-1 mt-2 w-full px-12'>
                        <div className='flex w-full gap-2'>
                          <img src={getPasswordStatusIcon(isLengthValid)} alt='password checker' />
                          <span className={`${isLengthValid ? 'text-[#7d7d7d]' : 'text-red-600'} italic`}>Must be at least 8 characters long</span>
                        </div>
                        <div className='flex w-full gap-2'>
                          <img src={getPasswordStatusIcon(isSpecialCharValid)} alt='password checker' />
                          <span className={`${isSpecialCharValid ? 'text-[#7d7d7d]' : 'text-red-600'} italic`}>Include at least 1 special character</span>
                        </div>
                        <div className='flex w-full gap-2'>
                          <img src={getPasswordStatusIcon(isNumberValid)} alt='password checker' />
                          <span className={`${isNumberValid ? 'text-[#7d7d7d]' : 'text-red-600'} italic`}>Contain at least 1 number</span>
                        </div>
                        <div className='flex w-full gap-2'>
                          <img src={getPasswordStatusIcon(isUppercaseValid)} alt='password checker' />
                          <span className={`${isUppercaseValid ? 'text-[#7d7d7d]' : 'text-red-600'} italic`}>Have at least 1 uppercase letter</span>
                        </div>
                      </div>
                      <span className='mt-2 text-xs text-[#6d6d6d]'>By continuing, you agree to Zoala’s <span className='font-bold'>Conditions of use</span> and <span className='font-bold'>Privacy Pollicy</span>.</span>
                      <div className='w-full flex justify-between'>
                        <button className='text-[#00ace5]' onClick={() => handleBackToOrgClick()}>Back</button>
                        <button className='text-[#00ace5]' type='submit'>Next</button>
                      </div>
                    </form>
                  </>
                ) : step === 2 ? (
                  <>
                    <div className='w-full flex justify-center items-center gap-4 mt-4'>
                      <span className='font-bold text-[#00ade7] text-xl'>1</span>
                      <div className='bg-[#00ade7] w-3 h-[1px]'></div>
                      <span className='font-bold text-[#00ade7] text-xl'>2</span>
                      <div className='bg-[#a4a4a5] w-3 h-[1px]'></div>
                      <span className='font-bold text-[#a4a4a5] text-xl'>3</span>
                    </div>
                    <span className='text-[#00ade7] font-bold text-xl mt-7'>When's your birthday?</span>
                    <form onSubmit={handleRegister} className='w-full mt-4 px-7'>
                      <Datepicker useRange={false} asSingle={true} value={birthdate} onChange={handleDateChange} placeholder={"Select your birthday"} displayFormat={"DD/MM/YYYY"} maxDate={new Date()} popoverDirection='down'/>
                      <div className='flex flex-col items-center w-full mt-8 px-7 gap-3'>
                        <span className='text-[#00ade7] text-2xl font-bold'>You're</span>
                        <div className='w-full flex items-center justify-center gap-8 relative'>
                          <img src={pop} className='bottom-0 left-0 absolute' alt='pop' />
                          <span className='font-bold text-[80px] text-[#11163e]'>{age.toString()}</span>
                          <img src={pop} className='top-0 right-0 absolute' alt='pop' />
                        </div>
                        <span className='text-[#00ade7] text-2xl font-bold'>years old this year!</span>
                      </div>
                      <div className='w-full flex justify-between mt-10'>
                        <button className='text-[#00ade7]' onClick={handleBackClick}>Back</button>
                        <button disabled={isDisabled} className='text-[#00ade7]' type='submit'>{isDisabled ? <Loader color='#00ade7' /> : 'Register'}</button>
                      </div>
                    </form>
                  </>
                ) : (
                  <>
                    <div className='w-full flex justify-center items-center gap-4 mt-4'>
                      <span className='font-bold text-[#00ade7] text-xl'>1</span>
                      <div className='bg-[#00ade7] w-3 h-[1px]'></div>
                      <span className='font-bold text-[#00ade7] text-xl'>2</span>
                      <div className='bg-[#00ade7] w-3 h-[1px]'></div>
                      <span className='font-bold text-[#00ade7] text-xl'>3</span>
                    </div>
                    <img src={emailsent} alt='email sent' className='mt-8' />
                    <div className='flex flex-col w-full justify-center items-center'>
                      <span className='font-bold text-[#00ade7] text-xl mt-2'>Email Sent</span>
                      <span className='text-[#6d6d6d]'>We have sent a verification link to</span>
                      <span className='my-4 text-[#00ade7] font-bold'>{formData.email}</span>
                      <span className='text-[#6d6d6d] text-center px-10'>Click on the link to complete your sign up</span>
                    </div>
                    <button onClick={handleGoToMail} className='bg-[#00ace5] w-full rounded-full p-3 text-white mt-4'>Go to Mail</button>
                    <div className='flex w-full flex-col items-center mt-20'>
                      <span className='text-[#6d6d6d] text-sm'>Still can't find the email? No Problem</span>
                      <span className='text-[#00ade7] text-sm'>Resend Email</span>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className='bg-gradient-to-t from-[#04ACE8] to-[#5BC7EE] absolute h-[85%] mt-20 rounded-3xl top-0 w-[460px]'></div>
          </div>
        )}
      </div>
    </>
  );
}

export default SignUp;
