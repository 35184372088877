import { Button, Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import React, { useState } from 'react'
import lock from '../../asset/setting/lock.gif'
import { useNavigate } from 'react-router-dom'

interface PopUpModal {
    isOnBoarding: boolean,
    close: () => void,
    text?: string
}

export default function OnBoarding({ isOnBoarding, close }: PopUpModal) {
    const navigate = useNavigate()
    const [isSetupNow, setIsSetupNow] = useState(false)
    const [isSetupLater, setIsSetupLater] = useState(false)
    // const defaultOptions = {
    //     loop: true,
    //     autoplay: true,
    //     animationData: lock,
    //     rendererSettings: {
    //         preserveAspectRatio: "xMidYMid slice"
    //     }
    // };
    const handleSetupNow = () => {
        setIsSetupNow(true)
        setIsSetupLater(false)
        localStorage.setItem('onBoardingPrivacy', 'true');
        localStorage.setItem('shownBoardingPrivacy', 'true');
        navigate('/setting')
    }
    const handleSetupLater = () => {
        setIsSetupLater(true)
        setIsSetupNow(false)
    }
    const handleClose = () => {
        localStorage.setItem('shownBoardingPrivacy', 'true');
        close()
        setIsSetupLater(false)
    }
    return (
        <div>
            <Transition appear show={isOnBoarding && !isSetupNow && !isSetupLater}>
                <Dialog as="div" className="relative z-[99999] focus:outline-none lexend-deca-normal" onClose={() => null}>
                    <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4">
                            <TransitionChild
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 transform-[scale(95%)]"
                                enterTo="opacity-100 transform-[scale(100%)]"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 transform-[scale(100%)]"
                                leaveTo="opacity-0 transform-[scale(95%)]"
                            >
                                <DialogPanel className="flex justify-center w-full max-w-[70%] h-[500px] rounded-xl bg-white p-6 border border-[#cccccc]">
                                    {/* <p dangerouslySetInnerHTML={{__html: text.replace(/\n/g, '<br/>')}}></p> */}
                                    <div className='flex flex-col justify-between h-full w-1/2'>
                                        <div>
                                            <h1 className='text-2xl font-bold'>Safeguard Your Privacy</h1>
                                            <p>Keep your data safe and accessible only to you.</p>
                                        </div>
                                        <img src={lock} alt="" width={200} height={'auto'} className='block m-auto' />
                                        <div className="mt-4 flex flex-col">
                                            <Button
                                                className=" text-center rounded-md bg-[#29B3E6] text-white py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10 data-[hover]:bg-[#36819d]"
                                                onClick={handleSetupNow}
                                            >
                                                Setup Now
                                            </Button>
                                            <Button
                                                className="py-1.5 px-3 text-sm/6 font-semibold text-gray-700 shadow-inner shadow-white/10 data-[hover]:text-black"
                                                onClick={handleSetupLater}
                                            >
                                                Setup Later
                                            </Button>
                                        </div>
                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>

                </Dialog>
            </Transition>
            <Transition appear show={isSetupLater}>
                <Dialog as="div" className="relative z-[99999] focus:outline-none lexend-deca-normal" onClose={() => null}>
                    <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4">
                            <TransitionChild
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 transform-[scale(95%)]"
                                enterTo="opacity-100 transform-[scale(100%)]"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 transform-[scale(100%)]"
                                leaveTo="opacity-0 transform-[scale(95%)]"
                            >
                                <DialogPanel className="flex justify-center w-full max-w-[70%] h-[500px] rounded-xl bg-white p-6 border border-[#cccccc]">
                                    {/* <p dangerouslySetInnerHTML={{__html: text.replace(/\n/g, '<br/>')}}></p> */}
                                    <div className='flex flex-col justify-between h-full w-1/2'>
                                        <div>
                                            <h1 className='text-2xl font-bold'>You can always enable this later at</h1>
                                            <p>Setting {`->`} Preferences</p>
                                        </div>
                                        <img src={lock} alt="" width={200} height={'auto'} className='block m-auto' />
                                        <div className="mt-4 flex flex-col">
                                            <Button
                                                className=" text-center rounded-md bg-[#29B3E6] text-white py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10 data-[hover]:bg-[#36819d]"
                                                onClick={handleClose}
                                            >
                                                Okay
                                            </Button>

                                        </div>
                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>

                </Dialog>
            </Transition>
        </div>
    )
}
