import { Button, Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import React, { useEffect, useRef, useState } from 'react'
import PinField from 'react-pin-field'
import { usePrivacyStore } from '../../store/privacy'
import axios from 'axios'
import ChangePinPopUp from './ChangePinPopUp'
import { useNavigate } from 'react-router-dom'
import { PulseLoader } from 'react-spinners'
import { useAuthStore } from '../../store/auth'
import { useInitApi } from '../../store/initApi'

interface PinPop {
    isOpenPin: boolean,
}

interface PinValidateProps {
    status?: boolean
    message: string,
    attempts?: number | string,
    remaining_time?: number
}

export default function PinPopUp({ isOpenPin }: PinPop) {
    const apiToken = sessionStorage.getItem('api_token')
    const navigate = useNavigate();
    const pinRef = useRef<HTMLInputElement[]>([])
    const [isDisabled, setIsDisabled] = useState(false)
    const [isForgetPin, setIsForgetPin] = useState(false)
    const [isOpenChangePin, setIsOpenChangePin] = useState(false)
    const [pinError, setPinError] = useState<PinValidateProps | null>(null)
    const initApi = useInitApi(state => state.initApi)
    const setAuth = useAuthStore((state) => state.setAuth);
    const setIsShowLocked = usePrivacyStore(state => state.setIsShowLocked)

    const handleLogout = (): void => {
        // Delete stored user data and API token
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('api_token');
        localStorage.removeItem('tier');
        localStorage.removeItem('zoala_version');
        localStorage.removeItem('localStreak')
        localStorage.removeItem('isActivated');
        localStorage.removeItem('storedTime');
        localStorage.removeItem('shownBoardingPrivacy');

        setAuth(false)
        setIsShowLocked(false)
        navigate('/login');
    };

    const pinValidate = async () => {
        try {
            const pinValue = pinRef.current?.map(input => input.value).join('');
            if (pinValue?.length < 4) {
                setPinError({
                    status: false,
                    message: '4 digits PIN is required'
                })
            }
            if (!initApi) return;
            setIsDisabled(true)
            const res = await axios.post(initApi.url.features.privacy_pin.post_pin_validation, {
                privacy_pin: pinValue
            }, { headers: { Authorization: `Bearer ${apiToken}` } })
            if (res.data.status === false && res.data.attempts !== "3") {
                setPinError(res.data)
                setTimeout(() => {
                    pinRef.current[0]?.focus(); // Ensure focus is called after a short delay
                }, 0);
            } else if (res.data.status === false && res.data.attempts === "3") {
                handleLogout()
            } else {
                setPinError(res.data)
                setIsShowLocked(false)
            }
            pinRef.current.forEach(input => (input.value = "")); // Reset input values to empty
            setIsDisabled(false)

        } catch (error) {
            setPinError({
                status: false,
                message: 'Something went wrong. Please try again later.'
            })
            pinRef.current.forEach(input => (input.value = "")); // Reset input values to empty

            setIsDisabled(false)
            setTimeout(() => {
                pinRef.current[0]?.focus(); // Ensure focus is called after a short delay
            }, 0);

        }
    }

    const handleForgetPin = () => {
        setIsForgetPin(true)
        setIsOpenChangePin(true)
    }

    useEffect(() => {
        const toRestPin = localStorage.getItem('toResetPin')
        if (!isOpenPin) {
            setPinError(null)
        }
        if (toRestPin === '1') {
            setIsForgetPin(true)
            setIsOpenChangePin(true)
        }
        if (isOpenPin && pinRef.current.length > 0) {
            pinRef.current[0]?.focus(); // Ensure the first input exists before focusing
        }

    }, [isOpenPin, pinRef])

    return (
        <>
            <Transition appear show={isOpenPin && !isForgetPin}>
                <Dialog as="div" className="relative z-[99999] focus:outline-none lexend-deca-normal" onClose={() => null}>
                    <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4">
                            <TransitionChild
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 transform-[scale(95%)]"
                                enterTo="opacity-100 transform-[scale(100%)]"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 transform-[scale(100%)]"
                                leaveTo="opacity-0 transform-[scale(95%)]"
                            >
                                <DialogPanel className="relative flex justify-center w-full max-w-[70%] h-[500px] rounded-xl bg-white p-6 border border-[#cccccc]">

                                    <div className='flex flex-col justify-between h-full w-1/2'>

                                        <div className='flex flex-col justify-evenly h-full'>
                                            <div>
                                                <h1 className='text-2xl font-bold text-center'>Enter your PIN</h1>
                                                <h1 className='text-center'>Enter 4-digit PIN to access.</h1>
                                            </div>
                                            <div className='flex flex-col items-center'>
                                                <div className='flex justify-center mb-4'>
                                                    <PinField ref={pinRef} validate={/^[0-9]$/} type='password' className='border w-20 h-20 text-center text-6xl' length={4} disabled={isDisabled} onComplete={pinValidate} />
                                                </div>
                                                {!pinError?.status && <p className='text-red-600 text-center'>{pinError?.message}</p>}
                                                {!pinError?.status && pinError?.attempts && <p className='text-red-600 text-center'>Attempt remaining: {3 - Number(pinError?.attempts)}</p>}
                                                {isDisabled ? <PulseLoader className='mt-4' /> : ''}
                                            </div>

                                            <div className="mt-4 flex flex-col">
                                                <Button className='block mx-auto mt-4 py-1.5 px-3 border rounded-md hover:bg-[#dadada] text-center w-full cursor-pointer' onClick={handleForgetPin}>Forgot PIN</Button>
                                            </div>

                                        </div>

                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>

                </Dialog>
            </Transition>
            {isForgetPin ? <ChangePinPopUp isOpenChangePin={isOpenChangePin} closeIsOpenChangePin={() => setIsOpenChangePin(false)} isForgetPin={true} closeIsForgetPin={() => setIsForgetPin(false)} /> : ''}
        </>
    )
}
