import React, { useEffect, useState } from 'react';

interface CountdownTimerProps {
    startDate: string;
    endDate: string;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ startDate, endDate }) => {
    const calculateTimeLeft = () => {
        const start = new Date(`${startDate}T00:00:00`);
        const end = new Date(`${endDate}T23:59:59`);
        const difference = +end - +new Date();

        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="flex justify-center space-x-4 p-4 rounded-lg">
            {Object.entries(timeLeft).map(([unit, value]) => (
                <div key={unit} className='flex flex-col items-center'>
                    <div className="bg-white text-black p-4 rounded-lg">
                        <span className="text-4xl font-bold">{String(value).padStart(2, '0')}</span>
                    </div>
                    <span className="text-sm">
                        {unit === "days"
                            ? "Day"
                            : unit === "hours"
                                ? "Hour"
                                : unit === "minutes"
                                    ? "Min"
                                    : unit === "seconds"
                                        ? "Sec"
                                        : unit.charAt(0).toUpperCase() + unit.slice(1)}
                    </span>
                </div>
            ))}
        </div>
    );
};

export default CountdownTimer;
