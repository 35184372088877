import React, { useState } from 'react';
import SideBarItems from './SideBarItems';

export default function SideBar() {
    const [mini, setMini] = useState<boolean>(true);
    const minimizeSideBar = (value: boolean) => {
        setMini(value);
    };    
    return (
        <div onMouseOver={() => minimizeSideBar(false)} onMouseOut={() => minimizeSideBar(true)} className={`${mini ? 'w-16' : 'w-72'} bg-white h-full fixed top-0 left-0 p-4 shadow-xl flex flex-col items-center transition-all duration-300 z-[9999] overflow-auto`}>
            <SideBarItems mini={mini} minimizeSideBar={minimizeSideBar}/>
        </div>
    );
}