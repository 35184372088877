import React, { useState } from 'react';
import SearchIcon from '../../asset/SearchIcon';
import { useChatStore } from '../../store/chat';

type SearchBarProps = {
    chatPersons: ChatPersonProps[];
    onSearchResults: (results: ChatPersonProps[]) => void;
};

const SearchBar: React.FC<SearchBarProps> = ({ chatPersons }) => {
    const {setFilteredChat} = useChatStore()
    const [searchData, setSearchData] = useState<string>('');

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value.trim().toLowerCase();
        setSearchData(searchTerm);

        // If the search input is empty, display all surveys
        if (!searchTerm) {
            setFilteredChat(chatPersons);
            return;
        }

        // Filter the chatPersons array based on the title
        const filteredContacts = chatPersons.filter(person =>
            person.firstname.toLowerCase().includes(searchTerm) ||
            person.lastname.toLowerCase().includes(searchTerm)
        );
        // Return filtered data
        setFilteredChat(filteredContacts);
    };

    return (
        <div className='relative m-8'>
            <SearchIcon className='absolute left-6 top-3' />
            <input
                type="search"
                name="search-chat"
                placeholder='Search'
                className='bg-[#F0F0F0] w-full h-10 pl-12 pr-4 py-4 rounded-full'
                value={searchData}
                onChange={handleSearch}
            />
        </div>
    );
};

export default React.memo(SearchBar);
