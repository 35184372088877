import React, { useEffect, useState } from 'react'
import SidePanel from '../components/SidePanel'
import { useNavigate } from 'react-router-dom';
import axios, { CancelTokenSource } from 'axios';
import {
  mood1active, mood1inactive, mood2active, mood2inactive, mood3active, mood3inactive, mood4active, mood4inactive, mood5active, mood5inactive,
  journal
} from '../asset/index';
import arrow from '../asset/arrow.svg'
import congrats from '../asset/journal/Congrats-pop.svg'
import { FadeLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { back } from '../asset/index';
import "react-toastify/dist/ReactToastify.css";
import Blur from '../components/Blur';
import { TierLists } from '../types/tier';
import cryptojs from 'crypto-js'
import Loader from '../components/icons/Loader';
import { UserDataProps } from '../types/common';
import QuestProgressPopUp from '../components/gamification/QuestProgressPopUp';
import { track } from '@amplitude/analytics-browser';

interface GuidedJournalEntry {
  date: string;
  entry: {
    journal: string;
    category: string;
    mood: number | null;
  }[];
}

interface MiniJournal {
  date: string;
  entry: {
    journal: string;
    category: string;
    mood: number | null;
  }[];
}

interface Prompt {
  id: string;
  title: string;
  icon: string;
  literature: string;
}

function Journal() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserDataProps | null>(null);
  const [step, setStep] = useState<number>(1);
  const [emotion, setEmotion] = useState<number>(0);
  const [journalInput, setJournalInput] = useState<string>('');
  const [guidedJournalData, setGuidedJournalData] = useState<GuidedJournalEntry[]>([]);
  const [miniJournalData, setMiniJournalData] = useState<MiniJournal[]>([]);
  const [guidedJournalMiniJournalToggle, setGuidedJournalMiniJournalToggle] = useState<string>('journal');
  const [loading, setLoading] = useState<boolean>(true);
  const [promptLoading, setPromptLoading] = useState<boolean>(true);
  const [promptData, setPromptData] = useState<Prompt[]>([]);
  const [prompt, setPrompt] = useState<Prompt | null>(null);
  const [isDisabled, setIsDisabled] = useState(false)
  const [game, setGame] = useState<QuestProgressGameProps>()
  const [isOpen, setIsOpen] = useState(false)
  const [tierLists, setTierLists] = useState<TierLists>()
  const apiToken = sessionStorage.getItem('api_token');
  const handlePromptClick = (prompt: Prompt) => {
    setPrompt(prompt);
  }

  useEffect(() => {
    const storedUserData = sessionStorage.getItem('user');

    if (!apiToken || !storedUserData) {
      navigate('/login');
      return;
    }

    setUserData(JSON.parse(storedUserData));
  }, [apiToken, navigate]);

  useEffect(() => {
    track('screenView', {screen_name: 'JournalScreen'})

    let source = axios.CancelToken.source();

    fetchGuidedJournals(source);
    fetchPrompts(source);
    const tier = localStorage.getItem('tier')
    if (tier && process.env.REACT_APP_TIER_SECRET) {
      try {
        const decryptData = cryptojs.AES.decrypt(tier, process.env.REACT_APP_TIER_SECRET)
        const tierData = JSON.parse(decryptData.toString(cryptojs.enc.Utf8))
        setTierLists(tierData)
      } catch (error) {
      }
    }
    return () => {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear().toString();
    return `${day} ${month} ${year}`;
  }

  // function getDaySuffix(day) {
  //   if (day >= 11 && day <= 13) {
  //     return 'th';
  //   }
  //   switch (day % 10) {
  //     case 1: return 'st';
  //     case 2: return 'nd';
  //     case 3: return 'rd';
  //     default: return 'th';
  //   }
  // }
  const fetchGuidedJournals = async (source: CancelTokenSource) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/get-all-guidedjournals-v2`, { headers: { Authorization: `Bearer ${apiToken}` }, cancelToken: source.token });
      if (response.status === 200) {
        setGuidedJournalData(response.data.response.journal);
        setMiniJournalData(response.data.response.mini_journal);
        setLoading(false);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
      }
      else {
        toast.error('Error Journals');
      }
    }
  }
  const fetchPrompts = async (source: CancelTokenSource) => {
    try {
      const promptsResponse = await axios.get(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/get-prompts`, {
        cancelToken: source.token
      });
      if (promptsResponse.status === 200) {
        setPromptData(promptsResponse.data.response);
        setPromptLoading(false);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
      }
      else {
        toast.error('Error Prompts');
      }
    }
  }
  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setJournalInput(event.target.value);
  }
  const handleNextClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setStep(step + 1);
  }
  const handleBackClick = () => {
    setPrompt(null);
  }
  const submitJournal = async () => {
    let source = axios.CancelToken.source();
    try {
      setIsDisabled(true)
      const submitResponse = await axios.post(
        `${process.env.REACT_APP_ENV_URL}/api/app/mobile/create-guidedjournal`,
        {
          prompt_id: prompt!.id,
          journal: journalInput,
          mood: emotion
        },
        {
          headers: {
            Authorization: `Bearer ${apiToken}`
          }
        }
      );
      if (submitResponse.status === 200) {
        if (submitResponse.data.response.gamification) {
          setGame(submitResponse.data.response.gamification)
        }
        setPrompt(null);
        setStep(1);
        fetchGuidedJournals(source);
        toast.success('Journal submitted');
      }
      setIsDisabled(false)
    } catch (error) {

      setIsDisabled(false)
      toast.error('Error submitting');
    }
  }
  const handleEmotionClick = (emotion: number) => {
    setEmotion(emotion);
  };
  const getMoodId = (id: number) => {
    if (id === 1) {
      return mood1active;
    } else if (id === 2) {
      return mood2active;
    } else if (id === 3) {
      return mood3active;
    } else if (id === 4) {
      return mood4active;
    } else if (id === 5) {
      return mood5active;
    } else {
      return '';
    }
  }
  const getMood1Image = (): string => {
    if (emotion === 1) {
      return mood1active;
    } else {
      return mood1inactive;
    }
  }
  const getMood2Image = (): string => {
    if (emotion === 2) {
      return mood2active;
    } else {
      return mood2inactive;
    }
  }
  const getMood3Image = () => {
    if (emotion === 3) {
      return mood3active;
    } else {
      return mood3inactive;
    }
  }
  const getMood4Image = (): string => {
    if (emotion === 4) {
      return mood4active;
    } else {
      return mood4inactive;
    }
  }
  const getMood5Image = (): string => {
    if (emotion === 5) {
      return mood5active;
    } else {
      return mood5inactive;
    }
  }
  const handleJournalToggleClick = (name: string) => {
    setGuidedJournalMiniJournalToggle(name);
  }
  useEffect(() => {
    if (game) {
      setIsOpen(true);
    }
  }, [game])
  return (
    <>
      <div className='h-screen w-screen overflow-hidden'>
        <div className='pl-[65px] flex w-full h-full'>
          <div className={`relative h-full w-[20%] bg-[#f5f5f5] pt-4 px-6 flex flex-col items-center ${tierLists?.journal ? "overflow-scroll" : "overflow-hidden"}`}>
            <div className='w-fit h-auto rounded-full border border-[#e3e2e2] flex'>
              <button className={`py-1 px-2 rounded-full ${guidedJournalMiniJournalToggle === 'journal' ? 'text-white bg-[#a071af]' : 'text-[#929292]'}`} onClick={() => handleJournalToggleClick('journal')}>Past Journals</button>
              <button className={`py-1 px-2 rounded-full ${guidedJournalMiniJournalToggle === 'reflection' ? 'text-white bg-[#a071af]' : 'text-[#929292]'}`} onClick={() => handleJournalToggleClick('reflection')}>Reflections</button>
            </div>
            {loading ?
              <>
                <FadeLoader color='gray' loading={loading} height={12} width={5} radius={5} />
              </>
              :
              <>
                {guidedJournalMiniJournalToggle === 'journal' ?
                  <>
                    {guidedJournalData && guidedJournalData.length > 0 ?
                      (guidedJournalData.map((journal, index) => (
                        <div className='w-full' key={index}>
                          <div className='w-full mt-5 text-center'>
                            <span className='font-bold text-lg'>{formatDate(journal.date)}</span>
                          </div>
                          {journal.entry.map((journalEntry, index) => (
                            <div className='w-full flex flex-col mt-2' key={index}>
                              <div className='w-full h-fit bg-white rounded-md p-4 flex border border-[#cccccc]'>
                                <div className='flex flex-col w-full'>
                                  <span className='text-lg'>{journalEntry.journal}</span>
                                  <div className='w-full flex justify-between'>
                                    <span className='mt-4 text-[#999999] text-sm'>{journalEntry.category}</span>
                                    {journalEntry.mood !== null && journalEntry.mood !== 0 ? <img src={getMoodId(journalEntry.mood)} alt='mood' /> : <></>}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))) :
                      <>
                        <span className='text-[#999999] text-lg'>No Journal data</span>
                      </>
                    }
                  </>
                  :
                  <>
                    {miniJournalData && miniJournalData.length > 0 ?
                      (miniJournalData.map((journal, index) => (
                        <div className='w-full' key={index}>
                          <div className='w-full mt-5 text-center'>
                            <span className='font-bold text-lg'>{formatDate(journal.date)}</span>
                          </div>
                          {journal.entry.map((journalEntry, index) => (
                            <div className='w-full flex flex-col mt-2' key={index}>
                              <div className='w-full h-fit bg-white rounded-md p-4 flex border border-[#cccccc]'>
                                <div className='flex flex-col w-full'>
                                  <span className='text-lg'>{journalEntry.journal}</span>
                                  <div className='w-full flex justify-between'>
                                    <span className='mt-4 text-[#999999] text-sm'>{journalEntry.category}</span>
                                    {journalEntry.mood !== null && journalEntry.mood !== 0 ? <img src={getMoodId(journalEntry.mood)} alt='mood' /> : <></>}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))) :
                      <>
                        <span className='text-[#999999] text-lg'>No Journal data</span>
                      </>
                    }
                  </>
                }
              </>
            }
            {tierLists?.journal ? <></> : <Blur />}

          </div>
          <div className='relative bg-white h-full w-[55%] px-10 mt-5'>
            <div className='px-4 py-1 bg-[#dce0f0] w-fit h-fit rounded-full ml-2 flex justify-center items-center'>
              <img src={journal} alt='journal' className='w-5 mr-2' />
              <span className='font-bold text-[#a171b0]'>Journal</span>
            </div>
            <div className='w-full h-[80%] items-center flex flex-col mt-16 relative'>
              {!prompt ?
                <>
                  <span className='font-bold text-lg'>What do you feel like writing today?</span>
                  {promptLoading ?
                    <>
                      <FadeLoader className='mt-4' color='gray' loading={loading} height={12} width={5} radius={5} />
                    </> : <></>}
                  <div className='grid grid-cols-3 w-[80%] h-fit gap-4 mt-4'>
                    {promptData && promptData.map((prompt, index) => (
                      <div key={index} className='bg-[#a171b0] w-full h-28 items-center hover:cursor-pointer rounded-xl relative p-2 overflow-hidden' onClick={() => handlePromptClick(prompt)}>
                        <span className='font-bold text-white z-20 relative'>{prompt.title}</span>
                        <img src={prompt.icon} alt={prompt.title} className='absolute bottom-0 right-0 w-24' />
                      </div>
                    ))}
                  </div>
                </>
                : step === 1 ?
                  <>
                    <button className='w-full absolute -top-9 left-0 flex items-center' onClick={() => handleBackClick()}>
                      <img src={back} alt='back' className='mr-2' />
                      <span className='font-bold text-[#00ace5]'>Back</span>
                    </button>
                    <div className='w-full flex items-center mt-2'>
                      <img src={prompt.icon} alt={prompt.title} className='w-20 mr-4' />
                      <div className='flex flex-col w-full'>
                        <span className='font-bold text-[#a071af] text-3xl'>{prompt.title}</span>
                        <span className='text-sm text-[#999999]'>{prompt.literature}</span>
                      </div>
                    </div>
                    <form onSubmit={handleNextClick} className='w-full flex flex-col relative h-full'>
                      <textarea className='w-full h-60 bg-[#e9e9e9] rounded-xl mt-10 p-4 outline-none placeholder:text-[#999999]'
                        placeholder='Type here.....'
                        required
                        onChange={handleInputChange}
                        maxLength={1000}
                      ></textarea>
                      <button className='absolute bottom-10 right-0 bg-[#a071af] p-3 text-white rounded-full' type='submit'>
                        <img src={arrow} alt='arrow' className='w-7' />
                      </button>
                    </form>
                  </>
                  :
                  <>
                    <div className='w-full h-full flex flex-col items-center relative'>
                      <img src={congrats} alt='congrats' className='w-[60%]' />
                      <span className='font-bold text-xl mt-5'>Congrats for taking the step towards building self-care</span>
                      <span className='font-bold text-[#00a8dd] text-xl mt-20'>How do you feel after journaling?</span>
                      <div className='w-full flex justify-center mt-8'>
                        <div className='w-full grid grid-cols-5 ml-16'>
                          <div className='flex flex-col items-center justify-center w-14'>
                            <img src={getMood1Image()} alt='mood-1' className='w-14 cursor-pointer' onClick={() => handleEmotionClick(1)} />
                            <span className='mt-4 text-[#e83223]'>Worse</span>
                          </div>
                          <img src={getMood2Image()} alt='mood-2' className='w-14 cursor-pointer col-span-1' onClick={() => handleEmotionClick(2)} />
                          <img src={getMood3Image()} alt='mood-3' className='w-14 cursor-pointer col-span-1' onClick={() => handleEmotionClick(3)} />
                          <img src={getMood4Image()} alt='mood-4' className='w-14 cursor-pointer col-span-1' onClick={() => handleEmotionClick(4)} />
                          <div className='flex flex-col items-center w-14'>
                            <img src={getMood5Image()} alt='mood-5' className='w-14 cursor-pointer' onClick={() => handleEmotionClick(5)} />
                            <span className='mt-4 text-[#a9cb35]'>Better</span>
                          </div>
                        </div>
                      </div>
                      <button disabled={isDisabled} className='absolute bottom-10 right-0 bg-[#a071af] px-2 py-1 text-white rounded-full' onClick={() => submitJournal()}>
                        {isDisabled ? <Loader /> : 'Submit'}
                      </button>
                    </div>
                  </>
              }
            </div>
            {game && <QuestProgressPopUp gamification={game} isOpen={isOpen} close={() => setIsOpen(false)} />}

            {tierLists?.journal ? <></> : <Blur />}
          </div>
          <aside className='bg-white h-full w-[25%] mt-5 mr-5'>
            {userData && tierLists ? <SidePanel userData={userData} tierLists={tierLists} /> : ""}
          </aside>
        </div>
      </div>
    </>
  )
}

export default Journal