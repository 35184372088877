import { create } from "zustand"
import { D7Vibes } from "../types/insights"

interface DailyVibe {
    dailyVibe: D7Vibes | null
    setDailyVibe: (value: D7Vibes) => void
}

export const useDailyVibeStore = create<DailyVibe>((set) => ({
    dailyVibe: null,
    setDailyVibe: (newValue) => set(() => ({ dailyVibe: newValue })),
}))