import React, { useEffect, useState } from 'react';
import ApiProvider from '../provider/ApiProvider';
import QuestList from './gamification/QuestList';
import { useInitApi } from '../store/initApi';

interface Quest {
    handlePassQuest: (value: QuestProps) => void
    handleOpenStreak: (value: boolean) => void;
}


export default function Quest({ handlePassQuest, handleOpenStreak }: Quest) {
    const apiToken = sessionStorage.getItem('api_token')
    const initApi = useInitApi((state) => state.initApi);
    const [quests, setQuests] = useState<QuestProps>();
    // const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchQuests = async () => {
            if (!initApi?.url?.features?.gamification?.get_quests) {
                setError('Something went wrong');
                // setLoading(false);
                return;
            }

            try {
                const data = await ApiProvider({
                    method: 'get',
                    url: initApi.url.features.gamification.get_quests,
                    apiToken: apiToken
                });
                setQuests(data.response);
                handlePassQuest(data.response)
                // setLoading(false);
            } catch (err) {
                setError('Failed to get quests');
                // setLoading(false);
            }
        };
        if (initApi)
            fetchQuests();
    }, [initApi]);

    useEffect(() => {
        const localStreak = localStorage.getItem('localStreak');
        if (localStreak === quests?.current_streaks.toString())
            return;
        if (localStreak && quests && localStreak !== quests.current_streaks.toString()) {
            handleOpenStreak(true)
            localStorage.setItem('localStreak', quests.current_streaks.toString())
        }
        if (!localStreak && quests && quests.current_streaks)
            localStorage.setItem('localStreak', quests.current_streaks.toString())
    }, [quests])


    // if (loading) return <div>Loading quests...</div>;
    if (error) return <div>{error}</div>;
    return (
        <div className='w-[70%] mt-8'>
            {quests &&
                <>
                    <h5 className='font-bold mb-4'>Quests</h5>
                    <QuestList title="Daily" quests={quests.quest.daily} />
                    <QuestList title="Weekly" quests={quests.quest.weekly} />
                </>
            }

        </div>
    );
}
