import { create } from "zustand"
import { UserProfile } from "../types/common"

interface GameStore {
    userProfile: UserProfile | null
    setUserProfile: (value: UserProfile) => void
}

export const useGameStore = create<GameStore>((set) => ({
    userProfile: null,
    setUserProfile: (newValue) => set(() => ({ userProfile: newValue }))
}))