import React from 'react';

interface SafetyPlansNavProps {
  stressor: string;
  redflags: string;
  coping: string;
  motivation: string;
  distraction: string;
  contact: string;
  navigateClick: (index: number) => void;
}

const SafetyPlansNav: React.FC<SafetyPlansNavProps> = ({ stressor, redflags, coping, motivation, distraction, contact, navigateClick }) => {
  return (
    <div className='w-full flex justify-center gap-12'>
      <img src={stressor} alt='stress' className='w-8 h-8 cursor-pointer' onClick={() => navigateClick(0)} />
      <img src={redflags} alt='red flag ' className='w-8 h-8 cursor-pointer' onClick={() => navigateClick(1)} />
      <img src={coping} alt='coping' className='w-8 h-8 cursor-pointer' onClick={() => navigateClick(2)} />
      <img src={motivation} alt='motivation' className='w-8 h-8 cursor-pointer' onClick={() => navigateClick(3)} />
      <img src={distraction} alt='distraction' className='w-8 h-8 cursor-pointer' onClick={() => navigateClick(4)} />
      <img src={contact} alt='contact' className='w-8 h-8 cursor-pointer' onClick={() => navigateClick(5)} />
    </div>
  );
}

export default SafetyPlansNav;
