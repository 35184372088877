import React, { useState } from 'react'
import PrivacySwitcher from './PrivacySwitcher';
import ChangePinPopUp from './ChangePinPopUp';
import AutoLockDropDown from './AutoLockDropDown';
import { usePrivacyStore } from '../../store/privacy'
import OnlyPinPopUp from './OnlyPinPopUp';
import PasswordPopUp from './PasswordPopUp';

export default function Preferences() {
  const [isOpenPin, setIsOpenPin] = useState(false)
  const [isOpenChangePin, setIsOpenChangePin] = useState(false)
  const [isOpenPassword, setIsOpenPassword] = useState(false)
  const isPrivacy = usePrivacyStore(state => state.isPrivacy)
  const handleChangePin = () => {
    setIsOpenChangePin(true)
  }

  const handlePrivacySwitch = (privacyTo: boolean) => {
    if (privacyTo === true) {
      setIsOpenPin(true)
    }
    if (privacyTo === false) {
      setIsOpenPassword(true)
    }
  }
  return (
    <div className='h-full pt-10 pl-10 pr-10'>
      <div className='flex flex-col h-full gap-20 xl:w-1/2'>
        <div>
          <div className='flex w-full justify-between mb-4'>
            <h1>Privacy Mode</h1>
            <PrivacySwitcher handlePrivacySwitch={handlePrivacySwitch} />
          </div>
          <span>Privacy mode hides sensitive information and setups additional PIN to prevent other people accessing your account</span>
        </div>
        {
          isPrivacy ?
            <div>
              <div className='flex items-center justify-between mb-8'>
                <p className='font-bold'>Auto Lock</p>
                <AutoLockDropDown />
              </div>
              <p className='font-bold'>Pin Setting</p>

              <div className='flex gap-4 mt-4'>
                <button
                  className="text-center rounded-md bg-[#29B3E6] text-white py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10 data-[hover]:bg-[#36819d]" onClick={handleChangePin}
                >
                  Change Pin
                </button>
                {/* <button
                  className="text-center rounded-md bg-gray-700 text-white py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10 data-[hover]:bg-[#36819d]"
                >
                  Reset Pin
                </button> */}
              </div>
            </div> : ""
        }
      </div>
      {isOpenChangePin && <ChangePinPopUp isOpenChangePin={isOpenChangePin} closeIsOpenChangePin={() => setIsOpenChangePin(false)} />}
      {isOpenPin && <OnlyPinPopUp isOpenPin={isOpenPin} closeIsOpenPin={() => setIsOpenPin(false)} />}
      {isOpenPassword && <PasswordPopUp isOpenPassword={isOpenPassword} closeIsOpenPassword={() => setIsOpenPassword(false)} />}
    </div>
  )
}
