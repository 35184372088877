import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useSafetyStore } from '../store/safety';
import { getActiveChatBeta, getActiveChatImage, getActiveFeedsImage, getActiveHomeImage, getActiveInsightImage, getActiveJournalImage, getActiveLearningImage, getActiveSMARTImage, getActiveSettingImage, getActiveSurveyImage, getActiveWellnessStreamImage,getActiveWellnessStreamBeta } from '../helpers/activeImageHelpers'
import { PulseLoader } from 'react-spinners';
import ineedhelp from '../asset/sidebar/i need help.svg';
import zoalalogo from '../asset/login/zoala-logo-standard.svg';
import smallzoalalogo from '../asset/sidebar/zoala logo.svg';
import SafetyPlanFirstTimerRoundedIcon from '../asset/home/SafetyPlanFirstTimerRoundedIcon';
import SafetyPlanFirstTimerIcon from '../asset/home/SafetyPlanFirstTimerIcon';
import SafetyPlanRoundedIcon from '../asset/home/SafetyPlanRoundedIcon';
import SafetyPlanIcon from '../asset/home/SafetyPlanIcon';
import RightArrowIcon from '../asset/sidebar/RightArrowIcon';
import logout from '../asset/sidebar/default/logout.svg';
import QuestionIcon from '../asset/sidebar/QuestionIcon';
import { Tooltip } from 'react-tooltip';
import safetyplanactivated from '../asset/sidebar/safety plan activated.svg';
import cryptojs from 'crypto-js'
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { toast } from 'react-toastify';
import useSidebar from '../hooks/useSidebar';
import { useAuthStore } from '../store/auth';
import { useTierStore } from '../store/tier';
import { reset } from '@amplitude/analytics-browser';

export default function SideBarItems({ mini, minimizeSideBar }: any) {
    const location = useLocation();
    const navigate = useNavigate();
    const activePage = location.pathname.substring(1);
    const apiToken = sessionStorage.getItem('api_token');
    const {
        user: { userData, setUserData },
        setup: { setUpStatus, setSetUpStatus },
        activation: { isActivated, setIsActivated },
        loading: { isLoading, setIsLoading },
        image: { imageLoaded, setImageLoaded },
        dropdown: { isDropdown, setIsDropdown },
        tiers: { tierLists, setTierLists }
    } = useSidebar();

    const setSafetyActivated = useSafetyStore((state) => state.setSafetyActivated);
    const handleImageLoad = () => {
        setImageLoaded(true);
    };
    const handleMiniSidebar = (clickedPage: string) => {
        if (clickedPage !== activePage)
            minimizeSideBar(true)
    }
    const handleIsDropdown = () => {
        setIsDropdown(!isDropdown)
    }
    const handleActivateSafetyPlan = async () => {
        try {
            setIsLoading(true)
            const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/safety-plan-activation`,
                {
                    activate_status: 1
                },
                { headers: { Authorization: `Bearer ${apiToken}` } });
            if (response.status === 200) {
                setIsLoading(false)
                setSafetyActivated(1)

                toast.success(response.data.message);
                navigate("/safety-plan")

            } else {
                setIsLoading(false)
            }
        } catch (error) {

        }

    }
    const setAuth = useAuthStore((state) => state.setAuth);

    const handleLogout = (): void => {
        // Delete stored user data and API token
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('api_token');
        localStorage.removeItem('tier');
        localStorage.removeItem('zoala_version');
        localStorage.removeItem('localStreak')
        localStorage.removeItem('isActivated');
        localStorage.removeItem('storedTime');
        localStorage.removeItem('shownBoardingPrivacy');

        reset()
        setAuth(false)
        navigate('/login');
        
    };

    const fetchApi = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/init`, {}, { headers: { Authorization: `Bearer ${apiToken}` } });
            if (response.status === 200) {
                sessionStorage.setItem('user', JSON.stringify(response.data.response.user));
                localStorage.setItem('zoala_version', process.env.REACT_APP_VERSION ?? '1.0')
            }
        } catch (error) {
            // toast.error('Error fetching data');
        }
    }

    useEffect(() => {
        const storedUserData = sessionStorage.getItem('user');
        const version = localStorage.getItem('zoala_version')
        if (version !== process.env.REACT_APP_VERSION) {
            fetchApi();
        }
        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
        }
        let source = axios.CancelToken.source();
        fetchSafetyPlan(source);

        return () => {
            source.cancel();
        }
    }, []);

    const fetchSafetyPlan = async (source: CancelTokenSource): Promise<void> => {
        try {
            const response: AxiosResponse<any> = await axios.get(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/safety-plan`, { headers: { Authorization: `Bearer ${apiToken}` }, cancelToken: source.token });
            if (response.status === 200) {
                setIsLoading(false)
                setSetUpStatus(response.data.response.setup_status);
                setIsActivated(response.data.response.activate_status)
            }
        } catch (error) {
            if (axios.isCancel(error)) {
            }
            else {
                setIsLoading(false)
            }

        }
    };
    useEffect(() => {
        const localTier = localStorage.getItem('tier')
        if (localTier && process.env.REACT_APP_TIER_SECRET) {
            const decryptData = cryptojs.AES.decrypt(localTier, process.env.REACT_APP_TIER_SECRET)
            const tierData = JSON.parse(decryptData.toString(cryptojs.enc.Utf8))
            setTierLists(tierData)
        }
        const tierSubscription = useTierStore.subscribe((state) => {
            if (state.tier && process.env.REACT_APP_TIER_SECRET) {
                try {
                    const decryptData = cryptojs.AES.decrypt(state.tier, process.env.REACT_APP_TIER_SECRET)
                    const tierData = JSON.parse(decryptData.toString(cryptojs.enc.Utf8))
                    setTierLists(tierData)
                } catch (error) {
                    navigate("/home")
                }
            }
        })
        const safetySubscription = useSafetyStore.subscribe((state) => {
            setIsActivated(state.safetyActivated)
        })
        // Cleanup subscriptions on component unmount
        return () => {
            tierSubscription();
            safetySubscription();
        };
    }, [])
    useEffect(() => {
        if (mini)
            setIsDropdown(false)

    }, [mini])
    const links = [
        {
            title: "Home",
            pathName: "home",
            func: getActiveHomeImage('home', activePage),
            activeColor: '#29b6e8',
            isShow: 1
        },
        {
            title: "Chat",
            pathName: "chat",
            func: getActiveChatImage('chat', activePage),
            activeColor: '#29b6e8',
            isShow: 1
        },
        {
            title: "Learning Space",
            pathName: "learning-space",
            func: getActiveLearningImage('learning-space', activePage),
            activeColor: '#29b6e8',
            isShow: 1 && userData?.org_roles[0].role === 'Student'
        },
        {
            title: "Surveys",
            pathName: "surveys",
            func: getActiveSurveyImage('surveys', activePage),
            activeColor: '#ed3426',
            isShow: 1 && userData?.org_roles[0].role === 'Student'
        },
        {
            title: "Smart Goals",
            pathName: "smart-goals",
            func: getActiveSMARTImage('smart-goals', activePage),
            activeColor: '#add038',
            isShow: 1 && userData?.org_roles[0].role === 'Student'
        },
        {
            title: "Journal",
            pathName: "journal",
            func: getActiveJournalImage('journal', activePage),
            activeColor: '#a071af',
            isShow: 1 && userData?.org_roles[0].role === 'Student'
        },
        {
            title: "Insights",
            pathName: "insights",
            func: getActiveInsightImage('insights', activePage),
            activeColor: '#faa81d',
            isShow: tierLists?.insights && userData?.org_roles[0].role === 'Student'
        },
        {
            title: "Feeds",
            pathName: "feeds",
            func: getActiveFeedsImage('feeds', activePage),
            activeColor: '#faa81d',
            isShow: 1 && userData?.org_roles[0].role === 'Student'
        },
        {
            title: "Wellness Streams",
            pathName: "wellness-streams",
            func: getActiveWellnessStreamImage('wellness-streams', activePage),
            activeColor: '#29b6e8',
            isShow: 1 && userData?.org_roles[0].role === 'Student'
        },
    ]
    return (
        <>
            <img src={mini ? smallzoalalogo : zoalalogo} alt='zoala logo' className='w-40 mt-2' />

            <div className='flex flex-col w-full h-full mt-4 relative'>
                <div className='w-full h-auto'>
                    {mini ? (
                        <div className='h-[60px]'></div>
                    ) : (
                        <>
                            {userData?.org_roles[0].class_name !== null && (
                                <div className='m-auto bg-[#29b6e8] py-1 rounded-full w-fit px-4'>
                                    <span className='text-white font-bold'>{userData?.org_roles[0].class_name}</span>
                                </div>
                            )}
                            <div className='flex justify-center w-full mt-2'>
                                <div className='border border-[#fbad2e] rounded-full w-fit py-1 px-4'>
                                    <span className='text-[#fbad2e] font-bold'>{userData?.current_org_name}</span>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className={`w-full flex flex-col mt-4 ${mini ? '' : 'p-4'} gap-6 mb-6 xl:mb-0`}>
                    {links.map((link, index) => (
                        userData && tierLists && link.isShow ? <Link key={index} onClick={() => handleMiniSidebar(link.pathName)} to={`/${link.pathName}`} className={`${'relative'} flex w-full items-center ${mini ? 'justify-center' : ''}`}>
                            <img src={link.func} alt={link.pathName} className='w-6' />
                            {link.pathName === 'chat' && process.env.REACT_APP_CHAT_BETA && <img src={getActiveChatBeta('chat', activePage)} className={`absolute ${mini ? 'top-[-6px] left-[15px]' : 'top-[2px] left-[90px]'}`} width={50} height={50} />}
                            {mini ? (<></>) : (<><span className={`ml-4 font-bold ${activePage === link.pathName ? `text-[${link.activeColor}]` : 'text-[#cccccc]'}`}>{link.title}{link.pathName === 'wellness-streams' && process.env.REACT_APP_WELLNESS_STREAM_BETA && <img src={getActiveWellnessStreamBeta('wellness-streams', activePage)} className={`absolute ${mini ? 'top-[-6px] left-[15px]' : 'top-[2px] left-[84%]'}`} width={50} height={50} />}</span></>)}
                            

                        </Link> : ""
                    )
                    )}
                </div>
                {userData?.org_roles[0].role === 'Student' &&
                    <div className={`w-full xl:mt-6 flex flex-col justify-center items-center ${mini ? "gap-4 md:mb-6 xl:mb-0" : "gap-6"}`}>
                        <Link onClick={() => handleMiniSidebar('i-need-help')} to='/i-need-help' className={`w-full ${imageLoaded ? 'bg-[#fde0de]' : ''} rounded-full flex justify-center items-center ${mini ? 'p-[6px]' : 'py-3 px-4'}`}>
                            <img src={ineedhelp} alt='i need help' className='w-6 h-6' onLoad={handleImageLoad} />
                            {mini ? (<></>) : (<><span className='font-bold ml-5 text-[#e23225]'>I need help</span></>)}
                        </Link>
                        {
                            isLoading ?
                                <PulseLoader className='mt-4' size={5} /> :
                                !setUpStatus ?
                                    <Link onClick={() => handleMiniSidebar('safety-plan')} to='/safety-plan' className={`${mini ? "flex justify-center items-center p-[6px]" : " "}`}>
                                        {/* <img src={safetyplan} alt='i need help' className='w-6' /> */}
                                        {mini ? <SafetyPlanFirstTimerRoundedIcon /> :
                                            <div className={`w-full border-2 border-[#29B6E8] font-bold rounded-full flex justify-center items-center ${mini ? 'p-[6px]' : 'px-4'}`} onClick={handleIsDropdown}>
                                                <div className='flex gap-4 items-center justify-center  cursor-pointer'>
                                                    {/* <img src={safetyplanactivated} alt='i need help' className='w-6' /> */}
                                                    <SafetyPlanFirstTimerIcon />
                                                    {mini ? "" :
                                                        <>
                                                            <p className='text-[#29B6E8]'>Set-up Safety Plan</p>
                                                            {/* <RightArrowIcon color='#29B6E8' /> */}
                                                        </>
                                                    }
                                                </div>
                                            </div>}
                                    </Link>
                                    :

                                    !isActivated && setUpStatus && isDropdown ?
                                        <>

                                            {mini ? <SafetyPlanRoundedIcon /> :
                                                <div className='w-full border-2 border-[#29B6E8] font-bold flex flex-col items-center rounded-3xl'>
                                                    <div className='flex gap-2 items-center justify-center border-b-[0.5px] border-black w-full px-6 py-2 cursor-pointer' onClick={handleIsDropdown}>
                                                        <SafetyPlanIcon />
                                                        <p className='text-[#29B6E8]'>Safety Plan</p>
                                                        <RightArrowIcon color='#29B6E8' />
                                                    </div>
                                                    <div className={`text-red-500 w-full text-center px-4 py-2 ${tierLists?.safety_plan ? "cursor-pointer" : "cursor-not-allowed"}`} onClick={tierLists?.safety_plan ? handleActivateSafetyPlan : undefined}>Activate Safety Plan</div>
                                                    <Link onClick={() => handleMiniSidebar('safety-plan-revised')} to='/safety-plan-revised' className='px-4 py-2'>Revise Safety Plan</Link>
                                                </div>
                                            }
                                        </>
                                        :
                                        !isActivated && setUpStatus && !isDropdown ?
                                            <div className={`${!mini ? "w-full border-2 border-[#29B6E8]" : "w-fit"} font-bold rounded-full flex justify-center items-center gap-2 cursor-pointer ${mini ? 'p-[6px]' : 'px-6 py-2'}`} onClick={handleIsDropdown}>
                                                {mini ? <SafetyPlanRoundedIcon /> :
                                                    <>
                                                        <SafetyPlanIcon />
                                                        <p className='text-[#29B6E8]'>Safety Plan</p>
                                                        <RightArrowIcon color='#29B6E8' />
                                                    </>
                                                }
                                            </div>
                                            :
                                            isActivated ?
                                                <Link onClick={() => handleMiniSidebar('safety-plan')} to='/safety-plan'>
                                                    <div className={`w-full bg-[#fde0de] font-bold rounded-full flex justify-center items-center ${mini ? 'p-[6px]' : 'px-4 py-3'}`} onClick={handleIsDropdown}>
                                                        <div className='flex gap-2 items-center justify-center  cursor-pointer'>
                                                            <img src={safetyplanactivated} alt='i need help' className='w-6' />
                                                            {mini ? "" :
                                                                <>
                                                                    <p className='text-black'>Safety Plan Activated</p>
                                                                    <RightArrowIcon />
                                                                </>
                                                            }
                                                        </div></div>
                                                </Link> : ""



                        }
                    </div>
                }
                <div className={`${mini && 'hidden'} w-full flex flex-1 items-end gap-4 ml-4 mt-4`}>
                    {userData?.org_roles[0].role === 'Student' &&
                        <Link onClick={() => handleMiniSidebar('setting')} to='/setting' className='w-fit'>
                            <img src={getActiveSettingImage('setting', activePage)} alt='setting' className='w-6' />
                            {/* {mini ? (<></>) : (<><span className={`ml-4 font-bold ${activePage === 'setting' ? 'text-[#161d45]' : 'text-[#cccccc]'}`}>Settings</span></>)} */}
                        </Link>
                    }
                    <button className='' onClick={() => handleLogout()}>
                        <img src={logout} className='w-6' alt='logout' />
                        {/* {mini ? (<></>) : (<><span className={`ml-4 font-bold text-[#cccccc]`}>Logout</span></>)} */}
                    </button>
                    {/* <a
                        data-tooltip-id="questions-tooltip"
                        data-tooltip-content="Help and resources"
                        data-tooltip-place="top-start"
                        className='ml-auto mr-4'
                    >
                        <QuestionIcon />
                    </a>
                    <Tooltip id="questions-tooltip" className=' rounded-l-full' style={{ backgroundColor: "#12143E", color: "#ffffff", borderTopLeftRadius: "100px", borderBottomLeftRadius: "100px", borderTopRightRadius: "100px", }} /> */}
                </div>

            </div>
        </>
    )
}
