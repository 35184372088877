import { create } from "zustand";

interface ChatStore {
    chatHistory: ChatProps[]
    setChatHistory: (updater: (prev: ChatProps[]) => ChatProps[]) => void;
    filteredChat: ChatPersonProps[]
    setFilteredChat: (value: ChatPersonProps[]) => void
    chatSelected: ChatPersonProps | null,
    setChatSelected: (value: ChatPersonProps | null) => void
    showDisableZo: boolean
    setShowDisableZo: (value: boolean) => void
    isSelectedZo: boolean
    setIsSelectedZo: (value: boolean) => void
    isLoggedInAi: boolean
    setIsLoggedInAi: (value: boolean) => void
    zoChat: ZoChatProps[] | null
    setZoChat: (value: ZoChatProps[]) => void
    zoTempMsgs: ZoTempMsgs[]
    setZoTempMsgs: (updater: (prev: ZoTempMsgs[]) => ZoTempMsgs[]) => void;
    tempMsgs: TempMsgProps[]
    setTempMsgs: (updater: (prev: TempMsgProps[]) => TempMsgProps[]) => void;
    fromVibeCheckState: string | null
    setFromVibeCheckState(value: string | null): void
    isLoading: boolean
    setIsLoading: (value: boolean) => void
    chatPage: number,
    setChatPage: (updater: (prev: number) => number) => void;
    isNewChat: boolean
    setIsNewChat: (value: boolean) => void
    zoLoading: boolean,
    setZoLoading: (value: boolean) => void
    chatPersons: ChatPersonProps[]
    setChatPersons: (value: ChatPersonProps[]) => void
}

export const useChatStore = create<ChatStore>((set) => ({
    chatHistory: [],
    setChatHistory: (updater) => set((state) => ({
        chatHistory: updater(state.chatHistory)
    })),
    filteredChat: [],
    setFilteredChat: (newValue) => set(() => ({ filteredChat: newValue })),
    chatSelected: null,
    setChatSelected: (newValue) => set(() => ({ chatSelected: newValue })),
    showDisableZo: false,
    setShowDisableZo: (newValue) => set(() => ({ showDisableZo: newValue })),
    isSelectedZo: false,
    setIsSelectedZo: (newValue) => set(() => ({ isSelectedZo: newValue })),
    isLoggedInAi: false,
    setIsLoggedInAi: (newValue) => set(() => ({ isLoggedInAi: newValue })),
    zoTempMsgs: [],
    setZoTempMsgs: (updater) => set((state) => ({
        zoTempMsgs: updater(state.zoTempMsgs)
    })),
    tempMsgs: [],
    setTempMsgs: (updater) => set((state) => ({
        tempMsgs: updater(state.tempMsgs)
    })),
    zoChat: null,
    setZoChat: (newValue) => set(() => ({ zoChat: newValue })),
    fromVibeCheckState: null,
    setFromVibeCheckState: (newValue) => set(() => ({ fromVibeCheckState: newValue })),
    isLoading: false,
    setIsLoading: (newValue) => set(() => ({ isLoading: newValue })),
    chatPage: 1,
    setChatPage: (updater) => set((state) => ({
        chatPage: updater(state.chatPage)
    })),
    isNewChat: false,
    setIsNewChat: (newValue) => set(() => ({ isNewChat: newValue })),
    zoLoading: false,
    setZoLoading: (newValue) => set(() => ({ zoLoading: newValue })),
    chatPersons: [],
    setChatPersons: (newValue) => set(() => ({ chatPersons: newValue })),

}))