import React from 'react'

const OperatingHourIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 1.5C9.92329 1.5 7.89323 2.11581 6.16651 3.26957C4.43979 4.42332 3.09398 6.0632 2.29926 7.98182C1.50454 9.90045 1.29661 12.0116 1.70175 14.0484C2.1069 16.0852 3.10692 17.9562 4.57538 19.4246C6.04383 20.8931 7.91475 21.8931 9.95155 22.2982C11.9883 22.7034 14.0995 22.4955 16.0182 21.7007C17.9368 20.906 19.5767 19.5602 20.7304 17.8335C21.8842 16.1068 22.5 14.0767 22.5 12C22.497 9.21614 21.3898 6.54715 19.4213 4.57867C17.4528 2.61018 14.7839 1.50298 12 1.5ZM12 21C10.22 21 8.47991 20.4722 6.99987 19.4832C5.51982 18.4943 4.36627 17.0887 3.68508 15.4442C3.00389 13.7996 2.82566 11.99 3.17293 10.2442C3.5202 8.49836 4.37736 6.89471 5.63604 5.63604C6.89471 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4441 3.68508C17.0887 4.36627 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C20.9974 14.3862 20.0484 16.6738 18.3611 18.3611C16.6738 20.0484 14.3862 20.9974 12 21Z" fill="#12133D" />
            <path d="M13.1895 12.129C12.9082 11.8478 12.7501 11.4663 12.75 11.0685V5.25C12.75 5.05109 12.671 4.86032 12.5303 4.71967C12.3897 4.57902 12.1989 4.5 12 4.5C11.8011 4.5 11.6103 4.57902 11.4697 4.71967C11.329 4.86032 11.25 5.05109 11.25 5.25V11.6895C11.2501 12.0873 11.4082 12.4688 11.6895 12.75L15.2197 16.2803C15.3612 16.4169 15.5507 16.4925 15.7473 16.4908C15.9439 16.489 16.1321 16.4102 16.2711 16.2711C16.4102 16.1321 16.489 15.9439 16.4908 15.7473C16.4925 15.5507 16.4169 15.3612 16.2803 15.2197L13.1895 12.129Z" fill="#12133D" />
        </svg>
    )
}
export default React.memo(OperatingHourIcon)
