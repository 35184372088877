import React from 'react'

const DangerIcon: React.FC = () => {
    return (
        <svg width="30" height="20" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.2072 9.64469L9.06143 1.07914C8.92294 0.847131 8.72318 0.654328 8.48234 0.520268C8.24149 0.386207 7.96814 0.315613 7.68982 0.315613C7.41149 0.315613 7.13814 0.386207 6.89729 0.520268C6.65645 0.654328 6.45669 0.847131 6.3182 1.07914L1.17285 9.64469C1.03346 9.87579 0.959933 10.1381 0.959793 10.4052C0.959653 10.6723 1.03295 10.9347 1.17209 11.1659C1.31123 11.3972 1.51134 11.5891 1.75232 11.7222C1.99329 11.8553 2.26651 11.9249 2.54446 11.9241H12.8408C13.1183 11.924 13.3909 11.8538 13.6311 11.7203C13.8713 11.5868 14.0706 11.3948 14.2091 11.1638C14.3476 10.9327 14.4202 10.6706 14.4198 10.404C14.4195 10.1373 14.3463 9.87541 14.2072 9.64469ZM13.5238 10.7874C13.455 10.903 13.3556 10.999 13.2356 11.0656C13.1156 11.1322 12.9794 11.1671 12.8408 11.1666H2.54446C2.40496 11.1671 2.26768 11.1323 2.14669 11.0656C2.0257 10.9988 1.92531 10.9026 1.85544 10.7866C1.78557 10.6706 1.74883 10.5388 1.74892 10.4048C1.74901 10.2707 1.7858 10.139 1.85582 10.0231L7.00117 1.45832C7.0702 1.3413 7.17032 1.24391 7.29129 1.17625C7.41226 1.10859 7.54985 1.07303 7.68982 1.07315C7.83014 1.07129 7.96857 1.10614 8.08985 1.17398C8.21114 1.24182 8.31083 1.34015 8.37846 1.45832L13.5238 10.0231C13.5958 10.1385 13.6337 10.2706 13.6337 10.4052C13.6337 10.5398 13.5958 10.6719 13.5238 10.7874Z" fill="white" />
            <path d="M13.5239 10.0253L8.37856 1.46047C8.31094 1.34229 8.21125 1.24406 8.08996 1.17622C7.96867 1.10838 7.83025 1.07353 7.68992 1.07539C7.54996 1.07527 7.41236 1.11082 7.29139 1.17849C7.17042 1.24615 7.07031 1.34345 7.00128 1.46047L1.85593 10.0253C1.7859 10.1412 1.74911 10.2729 1.74902 10.4069C1.74893 10.541 1.78568 10.6727 1.85555 10.7887C1.92542 10.9048 2.0258 11.001 2.1468 11.0677C2.26779 11.1344 2.40507 11.1693 2.54457 11.1687H12.8409C12.9795 11.1692 13.1157 11.1344 13.2357 11.0678C13.3557 11.0012 13.4551 10.9051 13.5239 10.7895C13.5959 10.6741 13.6338 10.5421 13.6338 10.4075C13.6338 10.2729 13.5959 10.1408 13.5239 10.0253ZM7.29555 4.10201H8.08467V7.8938H7.29555V4.10201ZM7.68992 9.66317C7.58596 9.66259 7.48448 9.63248 7.3983 9.57659C7.31212 9.5207 7.24511 9.44159 7.20565 9.34916C7.16618 9.25672 7.15607 9.15506 7.17656 9.05711C7.19705 8.95915 7.24729 8.86923 7.32086 8.79863C7.39443 8.72803 7.48824 8.67996 7.5902 8.66041C7.69216 8.64086 7.79778 8.65075 7.89391 8.68882C7.99004 8.72688 8.07235 8.79142 8.13038 8.87432C8.18841 8.95722 8.21945 9.05475 8.21991 9.15467C8.21909 9.28922 8.16281 9.41802 8.06352 9.51288C7.96422 9.60775 7.82994 9.661 7.68992 9.661V9.66317Z" fill="white" />
            <path d="M8.08455 4.09985H7.29544V7.89008H8.08455V4.09985Z" fill="#ED3425" />
            <path d="M8.21984 9.15251C8.21984 9.28679 8.16452 9.4155 8.06572 9.51045C7.96691 9.6054 7.8326 9.65874 7.69288 9.65874C7.55315 9.65874 7.41922 9.6054 7.32041 9.51045C7.22161 9.4155 7.16629 9.28679 7.16629 9.15251C7.16629 9.01823 7.22161 8.88943 7.32041 8.79447C7.41922 8.69952 7.55315 8.64618 7.69288 8.64618C7.8326 8.64618 7.96691 8.69952 8.06572 8.79447C8.16452 8.88943 8.21984 9.01823 8.21984 9.15251Z" fill="#ED3425" />
        </svg>

    )
}

export default React.memo(DangerIcon)
