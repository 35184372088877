import home from '../asset/sidebar/default/home.svg';
import homeactive from '../asset/sidebar/active/home.svg';
import chat from '../asset/sidebar/default/chat.svg';
import chatactive from '../asset/sidebar/active/chat.svg';
import journal from '../asset/sidebar/default/journal.svg';
import journalactive from '../asset/sidebar/active/journal.svg';
import learningspace from '../asset/sidebar/default/learningspace.svg';
import learningspacactive from '../asset/sidebar/active/learningspace.svg';
import insight from '../asset/sidebar/default/Insights.svg';
import insightactive from '../asset/sidebar/active/Insights.svg';
import survey from '../asset/sidebar/default/survey.svg';
import surveyactive from '../asset/sidebar/active/survey.svg';
import smartgoal from '../asset/sidebar/default/SMART goals.svg';
import smartgoalactive from '../asset/sidebar/active/SMART goals.svg';
import setting from '../asset/sidebar/default/Settings.svg';
import settingactive from '../asset/sidebar/active/Settings.svg';
import feeds from '../asset/sidebar/default/feeds.svg';
import feedsactive from '../asset/sidebar/active/feeds.svg';
import wellnessStreams from '../asset/sidebar/default/wellnessStream.svg';
import wellnessStreamsActive from '../asset/sidebar/active/wellnessStream.svg';
import betaActive from '../asset/chat/beta-active.png';
import betaInactive from '../asset/chat/beta-inactive.png';

const isActive = (page: string, activePage: string): boolean => {
    return page === activePage;
};

export const getActiveHomeImage = (page: string, activePage: string): string => {
    return isActive(page, activePage) ? homeactive : home;
};

export const getActiveChatImage = (page: string, activePage: string): string => {
    return isActive(page, activePage) ? chatactive : chat;
};

export const getActiveChatBeta = (page: string, activePage: string): string => {
    return isActive(page, activePage) ? betaActive : betaInactive;
};

export const getActiveJournalImage = (page: string, activePage: string): string => {
    return isActive(page, activePage) ? journalactive : journal;
};

export const getActiveLearningImage = (page: string, activePage: string): string => {
    return isActive(page, activePage) ? learningspacactive : learningspace;
};

export const getActiveInsightImage = (page: string, activePage: string): string => {
    return isActive(page, activePage) ? insightactive : insight;
};

export const getActiveSurveyImage = (page: string, activePage: string): string => {
    return isActive(page, activePage) ? surveyactive : survey;
};

export const getActiveSMARTImage = (page: string, activePage: string): string => {
    return isActive(page, activePage) ? smartgoalactive : smartgoal;
};

export const getActiveFeedsImage = (page: string, activePage: string): string => {
    return isActive(page, activePage) ? feedsactive : feeds;
};
export const getActiveWellnessStreamImage = (page: string, activePage: string): string => {
    return isActive(page, activePage) ? wellnessStreamsActive : wellnessStreams;
};
export const getActiveWellnessStreamBeta = (page: string, activePage: string): string => {
    return isActive(page, activePage) ? betaActive : betaInactive;
};

export const getActiveSettingImage = (page: string, activePage: string): string => {
    return isActive(page, activePage) ? settingactive : setting;
};
