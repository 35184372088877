import React, { useEffect, useState } from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { D7Vibes, DailyVibeData } from '../types/insights';
import { useDailyVibeStore } from '../store/vibeCheck';

type EmotionPointImages = {
  [key: string]: string
}

type WeekdayMapping = {
  [key: string]: number
}

// Custom shape component to render an image
const CustomImageShape = ({ cx, cy, payload }: any) => {
  return (
    <image
      x={cx - 25} // Adjust image position as needed
      y={cy - 25} // Adjust image position as needed
      width={50} // Adjust image size as needed
      height={50} // Adjust image size as needed
      xlinkHref={payload.imageSrc} // Image source
    />
  );
};
const emotionPointImages: EmotionPointImages = {
  happy: 'https://staging.zoala.co/images/avatar/selected/happy.png#26022024',
  sad: 'https://staging.zoala.co/images/avatar/selected/sad.png#26022024',
  angry: 'https://staging.zoala.co/images/avatar/selected/angry.png#26022024',
  surprised: 'https://staging.zoala.co/images/avatar/selected/surprised.png#26022024',
  fearful: 'https://staging.zoala.co/images/avatar/selected/fearful.png#26022024',
  disgust: 'https://staging.zoala.co/images/avatar/selected/disgust.png#26022024'
};

const weekdayMapping: WeekdayMapping = {
  "Mon": 1,
  "Tue": 2,
  "Wed": 3,
  "Thu": 4,
  "Fri": 5,
  "Sat": 6,
  "Sun": 7
};


const customXAxisTicks = [
  { value: 1, label: 'Mon' },
  { value: 2, label: 'Tue' },
  { value: 3, label: 'Wed' },
  { value: 4, label: 'Thu' },
  { value: 5, label: 'Fri' },
  { value: 6, label: 'Sat' },
  { value: 7, label: 'Sun' },
];

// Custom y-axis ticks
const customYAxisTicks = [
  { value: 4, label: '4am' },
  { value: 8, label: '8am' },
  { value: 12, label: '12pm' },
  { value: 16, label: '4pm' },
  { value: 20, label: '8pm' },
  { value: 24, label: 'Midnight' },
];

const DailyVibesChart = () => {
  const token = sessionStorage.getItem('api_token');
  const [dailyVibes, setDailyVibes] = useState<D7Vibes>()
  const [dailyVibesData, setDailyVibesData] = useState<DailyVibeData[]>()
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // Custom x-axis ticks
  function getTodayDayNumber() {
    const today = new Date();
    const day = today.getDay();
    // Adjust day value so that Monday is 1 and Sunday is 7
    return day === 0 ? 7 : day; // Adjust so Sunday is 7
  }
  const today = getTodayDayNumber();
  const rearrangedTicks = [
    ...customXAxisTicks.slice(today), // Days after today
    ...customXAxisTicks.slice(0, today) // Days up to and including today
  ];
  const dayMapper = (value: number) => {
    const index = (value - 1) % 7;
    return rearrangedTicks[index].label;
  };
  const storeDailyVibes = (dailyVibes: D7Vibes) => {
    let data: DailyVibeData[] = [];
    const vibes = Object.keys(dailyVibes)
    vibes.forEach((vibe) => {
      dailyVibes[vibe].forEach((eachVibe) => {
        if (weekdayMapping.hasOwnProperty(eachVibe.day)) {
          const dailyValue = weekdayMapping[eachVibe.day];
          const splitTime = eachVibe.time.split(":");
          const diff = (dailyValue - today)
          const x = (diff <= 0 ? diff + 7 : diff).toString();
          const y = splitTime[0]; // y should be a string
          const imageSrc = emotionPointImages[vibe];
          data.push({ x, y, imageSrc });
        }
      });
    });
    setDailyVibesData(data);
  }
  useEffect(() => {
    useDailyVibeStore.subscribe((state) => {
      if(state.dailyVibe){
        setDailyVibes(state.dailyVibe)
      }
    })
  }, [])

  useEffect(() => {
    if (dailyVibes) {
      setIsLoading(false)
      storeDailyVibes(dailyVibes)
    }

  }, [dailyVibes])
  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const CustomTooltip = (props: { emoji: any }) => {
    const { emoji } = props;
    const capitalizedString = capitalizeFirstLetter(emoji);

    return (
      <div className="custom-tooltip min-w-[80px] bg-gray-100 text-center p-2">
        {capitalizedString}
      </div>
    );
  };
  const findKeyByValue = (value: string, obj: EmotionPointImages) => {
    for (const key in obj) {
      if (obj[key] === value) {
        return key;
      }
    }
    return null; // If the value is not found, return null
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      {isLoading ? <></> : dailyVibes ? (
        <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }} >
          <XAxis
            type="number"
            dataKey="x"
            tick={{ fontSize: 10, fontWeight: "bold" }}
            tickCount={7}
            tickFormatter={dayMapper}
            domain={[1, 7]}
            tickLine={false}
            padding={{ left: 30, right: 30 }}
          />
          <YAxis
            type="number"
            dataKey="y"
            tick={{ fontSize: 12, fontWeight: "bold" }}
            tickCount={7}
            tickFormatter={(value) => {
              const tick = customYAxisTicks.find(tick => tick.value === value);
              return tick ? tick.label : "";
            }}
            domain={[0, 24]}
            tickLine={false}
            padding={{ top: 0, bottom: 50 }}
            reversed
          />
          {dailyVibesData ? (
            <Tooltip
              cursor={{ stroke: "#FAA81E" }}
              content={({ payload }) => {
                if (payload && payload.length >= 2) {
                  return <CustomTooltip emoji={findKeyByValue(payload[0].payload.imageSrc, emotionPointImages)} />;
                }
                return null;
              }}
            />
          ) : null}
          <Scatter name="Data" data={dailyVibesData} shape={<CustomImageShape />} />
        </ScatterChart>
      ) : (<h1 className='flex justify-center items-center h-full'>No Vibe Check data</h1>)}
    </ResponsiveContainer>
  );
};

export default DailyVibesChart;
