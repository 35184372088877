import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import React, { useEffect, useRef, useState } from 'react'
import PinField from 'react-pin-field'
import { toast } from 'react-toastify'
import axios from 'axios'

import CloseIcon from '../../asset/ineedhelp/CloseIcon'
import { PulseLoader } from 'react-spinners'
import { useInitApi } from '../../store/initApi'
import { usePrivacyStore } from '../../store/privacy'
import { SlArrowLeft } from 'react-icons/sl'

interface PinPop {
    isOpenPin: boolean,
    closeIsOpenPin: () => void
}

export default function OnlyPinPopUp({ isOpenPin, closeIsOpenPin }: PinPop) {
    const apiToken = sessionStorage.getItem('api_token')
    const pinRef = useRef<HTMLInputElement[]>([])
    const pinReenterRef = useRef<HTMLInputElement[]>([])
    const [pinValue, setPinValue] = useState('')
    const [step, setStep] = useState<number>(0)
    const [pinError, setPinError] = useState(false)
    const [pinReenterError, setPinReenterError] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const initApi = useInitApi(state => state.initApi)
    const setIsPrivacyModeCompleted = usePrivacyStore(state => state.setIsPrivacyModeCompleted)
    const isPrivacy = usePrivacyStore(state => state.isPrivacy)
    const setIsPrivacy = usePrivacyStore(state => state.setIsPrivacy)
    const setAutoLockDuration = usePrivacyStore(state => state.setAutoLockDuration)
    const handlePin = () => {
        const pinValue = pinRef.current?.map(input => input.value).join('');
        if (pinValue?.length === 4) {
            setPinValue(pinValue)
            pinRef.current?.map(input => (input.value = ""))
            setStep(2)
            setPinError(false)
        } else {
            setPinError(true)
        }
    }
    const handlePinReenter = async () => {
        try {
            const pinReenterValue = pinReenterRef.current?.map(input => input.value).join('');
            if (pinValue !== pinReenterValue) {
                setPinReenterError(true)
                pinReenterRef.current.forEach(input => (input.value = "")); // Reset input values to empty
                pinReenterRef.current[0].focus()
                return;
            }
            if (!initApi || isPrivacy === 1) return;
            setIsDisabled(true)
            const privacyMode = isPrivacy === 1 ? 'disable' : 'enable'
            const res = await axios.post(initApi.url.features.privacy_pin.post_privacy_mode, {
                type: privacyMode,
                privacy_pin: pinReenterValue
            }, { headers: { Authorization: `Bearer ${apiToken}` } })
            if (!res.data.session) {
                setPinReenterError(true)
            } else {
                setIsPrivacyModeCompleted(true)
                setIsPrivacy(1)
                const currentTime = new Date().getTime(); // Get the current time in milliseconds
                localStorage.setItem('storedTime', currentTime.toString()); // Store the current time in localStorage
                setAutoLockDuration(0)
                closeIsOpenPin()
                toast.success('You have successfully set your PIN')
                setPinReenterError(false)
            }
            setIsDisabled(false)

        } catch (error) {
            setIsDisabled(false)

        }
    }

    useEffect(() => {
        if (!isOpenPin) {
            setPinValue('')
            setStep(0)
            setPinReenterError(false)
        }
        if (isOpenPin && step === 0) {
            setStep(1)
        }
        if (step === 1) {
            pinRef.current[0].focus()
        }
        if (step === 2) {
            pinReenterRef.current[0].focus()
            setPinReenterError(false)
        }
    }, [isOpenPin, step])

    return (
        <Transition appear show={isOpenPin}>
            <Dialog as="div" className="relative z-[99999] focus:outline-none lexend-deca-normal" onClose={closeIsOpenPin}>
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <TransitionChild
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 transform-[scale(95%)]"
                            enterTo="opacity-100 transform-[scale(100%)]"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 transform-[scale(100%)]"
                            leaveTo="opacity-0 transform-[scale(95%)]"
                        >
                            <DialogPanel className="relative flex justify-center w-full max-w-[70%] h-[500px] rounded-xl bg-white p-6 border border-[#cccccc]">
                                <CloseIcon onClick={closeIsOpenPin} className='absolute top-5 right-5 cursor-pointer' />
                                {step === 2 && <SlArrowLeft onClick={isDisabled ? () => null : () => setStep(1)} className='absolute top-5 left-5 cursor-pointer' />}
                                <div className='flex flex-col justify-between h-full w-1/2'>
                                    {
                                        step === 1 ?
                                            <div className='flex flex-col justify-evenly h-full'>
                                                <h1 className='text-2xl font-bold text-center'>Set PIN</h1>
                                                <div className='flex flex-col items-center'>
                                                    <div className='flex justify-center mb-4'>
                                                        <PinField ref={pinRef} validate={/^[0-9]$/} type='password' className='border w-20 h-20 text-center text-6xl' length={4} autoFocus disabled={isDisabled} onComplete={handlePin} />
                                                    </div>
                                                    {pinError && <p className='text-red-600 text-center'>4 digits PIN is required</p>}
                                                    {isDisabled ? <PulseLoader className='mt-4' /> : ''}
                                                </div>

                                            </div> : step === 2 ?
                                                <div className='flex flex-col justify-evenly h-full'>
                                                    <h1 className='text-2xl font-bold text-center'>Enter new PIN again.</h1>
                                                    <div className='flex flex-col items-center'>
                                                        <div className='flex justify-center mb-4'>
                                                            <PinField ref={pinReenterRef} validate={/^[0-9]$/} type='password' className='border w-20 h-20 text-center text-6xl' length={4} autoFocus disabled={isDisabled} onComplete={handlePinReenter} />
                                                        </div>
                                                        {pinReenterError && <p className='text-red-600 text-center'>Incorrect PIN</p>}
                                                        {isDisabled ? <PulseLoader className='mt-4' /> : ''}
                                                    </div>
                                                    <div className="mt-4 flex flex-col">
                                                        <button
                                                            className="text-center text-white rounded-md bg-[#dadada] hover:bg-[#a8a8a8] py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10"
                                                            onClick={() => setStep(1)} disabled={isDisabled}
                                                        >
                                                            Back

                                                        </button>
                                                    </div>
                                                </div> : ""
                                    }
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>

            </Dialog>
        </Transition>
    )
}
