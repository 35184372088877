import React from 'react'
import { IconProps } from '../../types/icon'

const AddIcon = ({ onClick }: IconProps) => {
    return (
        <svg onClick={onClick} className='cursor-pointer' width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.224 11.2016C22.224 17.3882 17.249 22.4033 11.112 22.4033C4.97502 22.4033 0 17.3882 0 11.2016C0 5.01515 4.97502 0 11.112 0C17.249 0 22.224 5.01515 22.224 11.2016Z" fill="#29B3E6" />
            <g clipPath="url(#clip0_2335_8522)">
                <path d="M15.9453 9.94531H12.0547V6.05469C12.0547 5.4722 11.5825 5 11 5C10.4175 5 9.94531 5.4722 9.94531 6.05469V9.94531H6.05469C5.4722 9.94531 5 10.4175 5 11C5 11.5825 5.4722 12.0547 6.05469 12.0547H9.94531V15.9453C9.94531 16.5278 10.4175 17 11 17C11.5825 17 12.0547 16.5278 12.0547 15.9453V12.0547H15.9453C16.5278 12.0547 17 11.5825 17 11C17 10.4175 16.5278 9.94531 15.9453 9.94531Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_2335_8522">
                    <rect width="12" height="12" fill="white" transform="translate(5 5)" />
                </clipPath>
            </defs>
        </svg>

    )
}
export default React.memo(AddIcon)