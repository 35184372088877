import React from 'react'
import { Tooltip } from 'react-tooltip'
import InfoIcon from '../asset/InfoIcon'
import { InsightsData, PersonalityProps } from '../types/insights'
import generatedByAi from '../asset/insights/generated-by-ai.svg'
type PersonalityComponentProps = {
    insightsData: InsightsData
    personalities: PersonalityProps[],
    children: React.ReactNode
}

export default function PersonalityChart({ insightsData, personalities, children }: PersonalityComponentProps) {
    const modifiedColors = [
        {
            "title": "#FAA81D",
            "left": "#F37A22",
            "right": "#FAA81D"
        },
        {
            "title": "#27B0E3",
            "left": "#418AC9",
            "right": "#27B0E3"
        },
        {
            "title": "#A071B0",
            "left": "#944498",
            "right": "#A071B0"
        },
        {
            "title": "#ADD037",
            "left": "#75BF43",
            "right": "#ADD037"
        },
        {
            "title": "#ED3425",
            "left": "#9C3624",
            "right": "#ED3425"
        },
    ]

    return (
        <div className='flex flex-col lg:w-[80%] h-full'>
            <div className='relative pb-20 h-full rounded-xl border px-4 md:px-10'>
                <div className='flex justify-between mt-6 mb-3'>
                    <div className='flex items-center gap-2'>
                        <h5 className='text-2xl font-bold'>Personality Chart</h5>
                        <a
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="The Personality Chart, based on the big 5 model, represents your personality traits as assessed from your conversation with Zo."
                            data-tooltip-place="top"
                        >
                            <InfoIcon />
                        </a>
                        <Tooltip id="my-tooltip" style={{ maxWidth: "30%" }} />
                    </div>
                    <div className='self-end'>
                        {insightsData.showChart?.personality_from_survey ?
                            <>
                                <a
                                    data-tooltip-id="generated-survey-tooltip"
                                    data-tooltip-content="This data is curated from your recent survey submission."
                                    data-tooltip-place="top"
                                >
                                    <p className='font-bold text-[#27B0E3]'>Survey Results</p>
                                </a>
                                <Tooltip id="generated-survey-tooltip" style={{ maxWidth: "30%" }} />

                            </> :
                            <>
                                <a
                                    data-tooltip-id="generated-ai-tooltip"
                                    data-tooltip-content="This data is curated from your conversation with Zo."
                                    data-tooltip-place="top"
                                >
                                    <div className='flex items-center gap-4'>
                                        <img src={generatedByAi} width={35} height={35} />
                                        <p className='font-bold text-[#27B0E3]'>AI Generated</p>
                                    </div>
                                </a>
                                <Tooltip id="generated-ai-tooltip" style={{ maxWidth: "30%" }} />

                            </>
                        }
                    </div>

                </div>
                <div className='px-4'>
                    {
                        personalities?.map((personality: PersonalityProps, index: number) =>
                            <div className='flex flex-col gap-4 items-center pb-8 mt-4 md:pb-12 md:mt-0 relative' key={index}>
                                <span className=" bg-white text-center text-xs font-extrabold me-2 px-2.5 py-0.5 rounded-xl dark:bg-gray-700 dark:text-blue-400 border" style={{ color: modifiedColors[index].title, borderColor: modifiedColors[index].title }}>{personality.title}</span>
                                <div className='flex w-full px-8'>
                                    <div className="w-full rounded-l-md dark:bg-gray-700" style={{ background: "rgb(229 231 235)" }}>
                                        <div className="text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-l-md float-right" style={{ width: `${(10 - personality.value) * 10}%`, background: index % 2 !== 0 ? modifiedColors[index].left : modifiedColors[index].left }}> {(10 - personality.value) * 10}%</div>
                                    </div>
                                    <div className="w-full rounded-r-md dark:bg-gray-700" style={{ background: "rgb(229 231 235)" }}>
                                        <div className="text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-r-md" style={{ width: `${personality.value * 10}%`, background: index % 2 === 0 ? modifiedColors[index].right : modifiedColors[index].title }}> {personality.value * 10}%</div>
                                    </div>

                                </div>
                                <div className='absolute h-[80px] border-[0.5px] border-black top-[22px]'></div>
                                <p className='absolute left-0 bottom-0 text-xs' style={{ color: modifiedColors[index].title }}>{personality.subtitle}</p>
                                <p className='absolute right-0 bottom-0 text-xs' style={{ color: modifiedColors[index].title }}>{personality.caption}</p>
                            </div>
                        )
                    }
                </div>
                {children}
            </div>


        </div>
    )
}
