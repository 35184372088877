import React, { useEffect, useState } from 'react';
import help from '../asset/sidebar/i need help.svg';
import axios from 'axios';
import SearchIcon from '../asset/SearchIcon';
import SafetyContactIcon from '../asset/ineedhelp/SafetyContactIcon';
import CounsellorsIcon from '../asset/ineedhelp/CounsellorsIcon';
import HotlinesIcon from '../asset/ineedhelp/HotlinesIcon';
import Tabs from '../components/INeedHelp/Tabs';
import { CounsellorProps, HotlineProps, WeekDays } from '../types/ineedhelp';
import CloseIcon from '../asset/ineedhelp/CloseIcon';
import { useContactStore } from '../store/contact';
import { PulseLoader } from 'react-spinners'
import { motion } from "framer-motion";
import WhatsAppIcon from '../asset/ineedhelp/WhatsAppIcon'
import EmailIcon from '../asset/ineedhelp/EmailIcon'
import PhoneIcon from '../asset/ineedhelp/PhoneIcon'
import BrowserIcon from '../asset/ineedhelp/BrowserIcon'
import DownArrowIcon from '../components/icons/DownArrowIcon';
import RightArrowIcon2 from '../components/icons/RightArrowIcon';
import Country from '../components/INeedHelp/Country';
import FloatingActivateSafety from '../components/INeedHelp/FloatingActivateSafety';
import MentalHealthSupport from '../components/INeedHelp/MentalHealthSupport';
import SafetyContacts from '../components/INeedHelp/SafetyContacts';
import Counsellors from '../components/INeedHelp/Counsellors';
import { useInitApi } from '../store/initApi';
import { track } from '@amplitude/analytics-browser';

interface SelectedCountry {
  name: string;
  code: string;
  flag: string;
}

export interface SafetyContact {
  contact1: string
  contact2: string | null
  name: string
  relationship: string
}

function INeedHelp() {
  const [teacherContact, setTeacherContact] = useState<CounsellorProps[]>([]);
  const [counsellorContacts, setCounsellorContacts] = useState<CounsellorProps[]>([]);
  const [hotlineContacts, setHotLineContacts] = useState<HotlineProps[]>([]);
  // const [selectedContact, setSelectedContact] = useState<string | null>(null);

  const apiToken = sessionStorage.getItem('api_token');
  const [isHotline, setIsHotline] = useState<boolean>(false);
  const [isSafetyContacts, setIsSafetyContacts] = useState<boolean>(false);
  const [isCounsellor, setIsCounsellor] = useState<boolean>(false);


  const [selectedContact, setSelectedContact] = useState<HotlineProps | null>();
  const [selectedCountry, setSelectedCountry] = useState<SelectedCountry>()
  const initApi = useInitApi(state => state.initApi)

  const [helperlistLoading, setHelperListLoading] = useState(false)
  const [extendHour, setExtendHour] = useState(false)
  const [safetyContacts, setSafetyContacts] = useState<SafetyContact[]>()
  const [filteredCounsellor, setFilteredCounsellor] = useState<CounsellorProps[]>([])
  const [searchSafety, setSearchSafety] = useState<SafetyContact[]>([]);
  const [searchCounsellor, setSearchCounsellor] = useState<CounsellorProps[]>([]);
  const [searchTeacher, setSearchTeacher] = useState<CounsellorProps[]>([]);

  const [searchHotline, setSearchHotline] = useState<HotlineProps[]>([]);

  const [setUpStatus, setSetUpStatus] = useState<number>(0);
  const [isActivated, setIsActivated] = useState<number>(0)
  const [isError, setIsError] = useState(false)
  const handleExtendHour = () => {
    setExtendHour((prev) => !prev)
  }

  const closeSelectedModal = () => setSelectedContact(null)

  const contactIconStyle = "bg-[#E9ECED] border-[10px] lg:border-[20px] border-[#E9ECED] rounded-full w-auto h-auto lg:h-[80px]"

  useEffect(() => {
    track('screenView', { screen_name: 'INeedHelpScreen' })

    fetchSafetyContacts()
    fetchHelpList();
  }, []);

  useEffect(() => {
    useContactStore.subscribe((state) => {
      if (state.selectedContact) {
        setSelectedContact(state.selectedContact)
        console.log(state.selectedContact)
      }
    })
  }, []);

  const handleSelectedCountry = (data: any) => {
    setSelectedCountry(data)
  }
  const fetchHelpList = async () => {
    try {
      setHelperListLoading(true)
      if (initApi) {
        const response = await axios.get(initApi?.url.features.panic_button.get_help_list, { headers: { Authorization: `Bearer ${apiToken}` } });
        if (response.status === 200) {
          setHelperListLoading(false)
          setCounsellorContacts(response.data.counsellors);
          setSearchCounsellor(response.data.counsellors)
          setTeacherContact(response.data.teachers);
          setSearchTeacher(response.data.teachers)
          setHotLineContacts(response.data.hotlines)
          setSearchHotline(response.data.hotlines)
          setSelectedCountry({ name: "", code: response.data.user_selected_country, flag: "" })
        }
      }

    } catch (error) {
      setHelperListLoading(false)
      // toast.error('Error fetching data');
    }
  };

  const fetchSafetyContacts = async () => {
    if (initApi) {
      const res = await axios.get(initApi.url.features.safety_plan.display_plan, { headers: { Authorization: `Bearer ${apiToken}` } })
      if (res.status === 200) {
        setHelperListLoading(false)
        setSetUpStatus(res.data.response.setup_status);
        setIsActivated(res.data.response.activate_status)
        setSafetyContacts(res.data.response.contacts)
        setSearchSafety(res.data.response.contacts)
      }
    }
  }

  useEffect(() => {
    if (safetyContacts && safetyContacts.length > 0 && hotlineContacts.length > 0) {
      setHelperListLoading(false)
    }
    if (safetyContacts && safetyContacts.length < 1 && hotlineContacts.length < 1) {
      setIsError(true)
    } else {
      setIsError(false)
    }

  }, [safetyContacts, hotlineContacts])
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value.trim().toLowerCase();
    // If the search input is empty, display all surveys
    if (safetyContacts && safetyContacts.length > 0) {
      if (!searchTerm) {
        setSearchSafety(safetyContacts);
      }

      // Filter the array based on the title
      const filteredContacts = safetyContacts?.filter(contact =>
        contact.name.toLowerCase().includes(searchTerm) ||
        contact.contact1.toLowerCase().includes(searchTerm)
      );

      // Update the state with the filtered data
      setSearchSafety(filteredContacts);
    }
    if (counsellorContacts && counsellorContacts.length > 0) {
      if (!searchTerm) {
        setSearchCounsellor(counsellorContacts);
      }

      // Filter the array based on the title
      const filteredContacts = counsellorContacts?.filter(contact =>
        contact.first_name.toLowerCase().includes(searchTerm) ||
        contact.phone_number?.toLowerCase().includes(searchTerm)
      );

      // Update the state with the filtered data
      setSearchCounsellor(filteredContacts);
    }
    if (teacherContact && teacherContact.length > 0) {
      if (!searchTerm) {
        setSearchTeacher(teacherContact);
      }

      // Filter the array based on the title
      const filteredContacts = teacherContact?.filter(contact =>
        contact.first_name.toLowerCase().includes(searchTerm) ||
        contact.phone_number?.toLowerCase().includes(searchTerm)
      );

      // Update the state with the filtered data
      setSearchTeacher(filteredContacts);
    }
    if (hotlineContacts && hotlineContacts?.length > 0) {
      if (!searchTerm) {
        setSearchHotline(hotlineContacts);
      }

      // Filter the array based on the title
      const filteredContacts = hotlineContacts?.filter(contact =>
        contact.hotline_name.toLowerCase().includes(searchTerm) ||
        contact.hotline_number.toLowerCase().includes(searchTerm)
      );
      // Update the state with the filtered data
      setSearchHotline(filteredContacts);
    }
  };
  const handleOnClick = (contact: string) => {
    if (contact === 'counsellor') {
      setIsSafetyContacts(false)
      setIsCounsellor(true)
      setIsHotline(false)
    } else if (contact === 'hotline') {
      setIsSafetyContacts(false)
      setIsCounsellor(false)
      setIsHotline(true);
    } else if (contact === 'safety') {
      setIsSafetyContacts(true)
      setIsCounsellor(false)
      setIsHotline(false)
    }
  };
  const handleSetHotlines = (data: HotlineProps[]) => {
    setHotLineContacts(data)
    setSearchHotline(data)
  }
  const handleClearFilter = () => {
    setIsSafetyContacts(false)
    setIsCounsellor(false)
    setIsHotline(false)
  }
  const handleSetActivate = (data: number) => {
    setIsActivated(data)
  }

  useEffect(() => {
    if (counsellorContacts && counsellorContacts.length > 0) {
      const filteredContacts = counsellorContacts.filter(contact => contact.phone_number !== null)
      setFilteredCounsellor(filteredContacts)
    }
  }, [counsellorContacts])
  return (
    <main className='relative h-screen w-full p-6 pl-16'>
      <div className='absolute w-fit px-2 py-1 rounded-full border border-[#ED3425] flex items-center justify-center left-16 md:left-20 lg:left-16 xl:left-24'>
        <img src={help} alt='help' className='w-5 mr-2' />
        <span className='text-[#ED3425] font-bold'>I Need Help</span>
      </div>
      <div className='absolute md:top-4 lg:top-14 right-4 xl:right-40 flex gap-6'>
        <Country selectedCountry={selectedCountry} onSelectedCountry={handleSelectedCountry} onSetHotlines={handleSetHotlines} />
      </div>
      {setUpStatus === 1 && <FloatingActivateSafety isActivated={isActivated} onSetAcivate={handleSetActivate} />}

      <div className='flex mt-4 ml-4'>
        <section className='flex flex-col gap-4 justify-center items-center w-full mt-8'>
          <div className='flex gap-8 w-full lg:w-1/2 mt-4'>
            <div className='relative w-full'>
              <input type="text" className="bg-[#F1F1F1] pl-12 py-2 w-full rounded-full" onChange={handleSearch} placeholder="Search for contacts..." />
              <SearchIcon className='absolute top-3 left-4 w-4 h-4' color='#B3B3B3' />
            </div>

          </div>
          <div className={`${selectedContact ? 'w-[80%]' : 'w-[50%]'} h-full flex flex-col items-center mt-4 mb-8`}>
            <div className={`flex ${filteredCounsellor.length < 1 ? 'justify-around' : 'justify-between'} w-full mb-8`}>
              <div className='flex flex-col items-center cursor-pointer' onClick={() => handleOnClick('safety')}>
                <SafetyContactIcon className="hover:scale-110" />
                <p>Safety Contacts</p>
              </div>
              {filteredCounsellor.length < 1 ? "" : <div className='flex flex-col items-center cursor-pointer' onClick={() => handleOnClick('counsellor')}>
                <CounsellorsIcon className="hover:scale-110" />
                <p>Counsellors / Teachers</p>
              </div>}
              <div className='flex flex-col items-center cursor-pointer' onClick={() => handleOnClick('hotline')}>
                <HotlinesIcon className="hover:scale-110" />
                <p>Hotlines</p>
              </div>
            </div>
            {isSafetyContacts || isCounsellor || isHotline ? <div className='flex justify-between w-full mb-8'>
              <p className='text-[#484747] md:text-xs lg:text-base'>Showing results for Mental health Support {isSafetyContacts ? '(Safety Contacts)' : isCounsellor ? '(Counsellors & Teachers)' : isHotline ? '(Hotlines)' : ''}</p>
              <div className='flex gap-4 items-center text-[#29B6E8] md:text-xs lg:text-base cursor-pointer' onClick={handleClearFilter}>
                <CloseIcon color='#29B6E8' />
                <p>Clear Filter</p>
              </div>
            </div> : ""}
            {helperlistLoading ? <PulseLoader /> :
              isError ? <div className='text-[#999999]'>No contact found</div> :
                <>
                  {searchSafety && isSafetyContacts ? <SafetyContacts safetyContacts={searchSafety} /> : safetyContacts && !isSafetyContacts && !isHotline && !isCounsellor ? <SafetyContacts safetyContacts={searchSafety} /> : ""}

                  {filteredCounsellor.length < 1 ? "" : searchCounsellor && searchCounsellor.length > 0 && filteredCounsellor.length > 0 && isCounsellor ? <Counsellors counsellors={searchCounsellor} teachers={searchTeacher} /> : filteredCounsellor.length > 0 && !isCounsellor && !isHotline && !isSafetyContacts ? <Counsellors counsellors={searchCounsellor} teachers={searchTeacher} /> : ""}

                  {!isHotline && !isSafetyContacts && !isCounsellor && <MentalHealthSupport hotlineContacts={searchHotline} {...(selectedContact?.hotlines_type === 1 ? { selectedContact } : {})} />}
                  {isHotline && <Tabs {...(selectedContact ? { selectedContact } : {})} hotlineContacts={searchHotline} />}
                </>
            }
          </div>

        </section>
        {selectedContact ?
          <aside className='mt-28 w-[40%]'>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className='relative'
            >
              <CloseIcon onClick={closeSelectedModal} className='absolute top-5 right-5 cursor-pointer' />
              <div className='flex flex-col items-center p-12 bg-[#F5F5F5] rounded-xl'>
                <img src={selectedContact.logo_url} alt={selectedContact.hotline_name} className='w-32 mb-4' />
                <h5 className='font-bold text-center'>{selectedContact.hotline_name}</h5>
                <p className='text-[#767575] mb-8'>{selectedContact.hotline_category}</p>
                <p className='text-[#767575]'>Opening Hours:</p>
                <div className='flex gap-4 items-center text-[#12133D]' onClick={handleExtendHour}>
                  <p>{selectedContact.operating_hour_today}</p>
                  {extendHour ? <DownArrowIcon /> : <RightArrowIcon2 />}

                </div>
                <div className='flex flex-col w-full mt-4 lg:px-20 2xl:px-40'>
                  {extendHour && Object.keys(selectedContact.operating_hour_week).map((day, index) => (
                    <div key={day} className={`${index === 0 ? 'font-bold' : ""} flex justify-between`}>
                      <p className=' flex-1'>{day}</p>
                      <p className={`${selectedContact.operating_hour_week[day as WeekDays][0] === 'Closed' ? 'text-[#767575]' : 'text-[#F6A41E]'} flex-1 lg:text-start`}>{selectedContact.operating_hour_week[day as WeekDays][0]}</p>
                    </div>
                  ))}
                </div>
                <div className='flex items-center w-auto lg:w-full justify-evenly 2xl:justify-center md:gap-2 mt-8'>

                  {selectedContact.whatsapp_number && <WhatsAppIcon className={`${contactIconStyle} cursor-pointer`} />}
                  {selectedContact.hotline_number && <a href={`tel:${selectedContact.hotline_number}`}><PhoneIcon className={`${contactIconStyle} cursor-pointer`} /></a>}
                  {selectedContact.email && <EmailIcon className={`${contactIconStyle} cursor-pointer `} />}
                  {selectedContact.url && <BrowserIcon className={`${contactIconStyle} cursor-pointer `} onClick={() => !selectedContact.url ? null : window.open(selectedContact.url, '_blank')} />}
                </div>
                <div className='bg-white rounded-3xl md:p-4 lg:p-8 w-full mt-8'>
                  <p className='text-[#767575]'>Phone number</p>
                  <p className='text-[#0E113C]'>{selectedContact.hotline_number}</p>
                </div>
                <div className='bg-white rounded-3xl md:p-4 lg:p-8 w-full mt-8'>
                  <p className='text-[#767575]'>Languages</p>
                  <div className="flex gap-4">
                    <span className="w-fit text-[#29B7EA] text-xs font-medium  px-2.5 py-0.5 rounded-full border border-[#29B7EA] mt-2">{selectedContact.language.length > 1 ? selectedContact.language : 'ENG'}</span>
                  </div>

                </div>
                <div className='bg-white rounded-3xl md:p-4 lg:p-8 w-full mt-12'>
                  <p className='text-[#767575]'>Description</p>
                  <p className='text-[#0E113C]'>{selectedContact.remarks}</p>
                </div>

              </div>
            </motion.div>
          </aside> : ""}
      </div>



    </main>
  );
}

export default INeedHelp;