import React, { useRef, useState } from 'react';
import addicon from '../asset/safetyplan/Add icon.svg';
import phone from '../asset/safetyplan/active/safety contacts active.svg';
import contact from '../asset/safetyplan/contact.svg';

interface EmergencyContact {
    id: number;
    name: string;
    relationship: string;
    contact1: string;
    contact2: string;
}

interface EmergencyDragandDropProps {
    safetyPlan: EmergencyContact[];
    setSafetyPlan: React.Dispatch<React.SetStateAction<EmergencyContact[]>>;
}

const EmergencyDragandDrop: React.FC<EmergencyDragandDropProps> = ({ safetyPlan, setSafetyPlan }) => {
    const dragSafetyPlan = useRef<number>(0);
    const draggedOverSafetyPlan = useRef<number>(0);
    const [newContactName, setNewContactName] = useState<string>('');
    const [newContactRelationship, setNewContactRelationship] = useState<string>('');
    const [newContactNumber, setNewContactNumber] = useState<string>('');
    const [triggerAddOrEdit, setTriggerAddOrEdit] = useState<boolean>(false);

    const handleSort = (): void => {
        const safetyPlanClone = [...safetyPlan];
        const temp = safetyPlanClone[dragSafetyPlan.current];
        safetyPlanClone[dragSafetyPlan.current] = safetyPlanClone[draggedOverSafetyPlan.current];
        safetyPlanClone[draggedOverSafetyPlan.current] = temp;
        setSafetyPlan(safetyPlanClone);
    };

    const handleDeleteSafetyPlanItem = (contact: string): void => {
        const updatedSafetyPlan = safetyPlan.filter(item => item.contact1 !== contact);
        setSafetyPlan(updatedSafetyPlan);
    };

    const handleAddEmergencyContact = (): void => {
        setTriggerAddOrEdit(true);
    };

    const handleSubmitEmergencyContact = (): void => {
        if (!newContactName.trim() || !newContactRelationship.trim() || !newContactNumber.trim()) {
            // Ensure all fields are filled
            return;
        }

        const newEmergencyContact: EmergencyContact = {
            id: safetyPlan.length + 1,
            name: newContactName,
            relationship: newContactRelationship,
            contact1: newContactNumber,
            contact2: ''
        };

        setSafetyPlan([...safetyPlan, newEmergencyContact]);
        setTriggerAddOrEdit(false);
    };

    return (
        <main className="flex flex-col items-center space-y-4 mt-4 w-full">
            {!triggerAddOrEdit ? (
                <>
                    <div className="flex w-full justify-between items-center rounded-md bg-[#f2f2f2] px-4 py-5">
                        <span className='text-[#999999]'>Add new contact</span>
                        <button className="ml-2 bg-[#29b6e8] text-white rounded-full" onClick={handleAddEmergencyContact}><img src={addicon} alt='add icon' className='w-8 h-8'/></button>
                    </div>
                    <div className="overflow-y-auto max-h-72 w-full space-y-3">
                        {safetyPlan.map((item, index) => (
                            <div
                                key={index}
                                className="relative border rounded p-2 w-full flex items-center"
                                draggable
                                onDragStart={() => (dragSafetyPlan.current = index)}
                                onDragEnter={() => (draggedOverSafetyPlan.current = index)}
                                onDragEnd={handleSort}
                                onDragOver={(e) => e.preventDefault()}
                            >
                                <div className='rounded-full w-16 h-16 flex items-center justify-center bg-[#161d44] mr-4'>
                                    <span className='text-2xl'>{item.name[0]}</span>
                                </div>
                                <div className='flex justify-between w-[82%]'>
                                    <div className='flex flex-col text-[#999999]'>
                                        <p className='text-black'>{item.name}</p>
                                        <span>{item.relationship}</span>
                                        <span>{item.contact1}</span>
                                    </div>
                                    <button
                                        onClick={() => handleDeleteSafetyPlanItem(item.contact1)}
                                        className="text-[#29b6e8] text-xl"
                                    >
                                        X
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <>
                    <div className='w-full flex flex-col items-center px-32 relative'>
                        <div className='mt-10 w-full flex items-center'>
                            <img src={contact} alt='contact' className='w-8 h-8 mr-4'/>
                            <input placeholder='Name' className='bg-[#f2f2f2] rounded-md outline-none p-2 w-full' value={newContactName} onChange={(e) => setNewContactName(e.target.value)}/>
                        </div>
                        <div className='w-full mt-4 flex items-center'>
                            <img src={phone} alt='phone' className='w-8 h-8 mr-4'/>
                            <input placeholder='Contact' className='bg-[#f2f2f2] rounded-md outline-none p-2 w-full' value={newContactNumber} onChange={(e) => setNewContactNumber(e.target.value)}/>
                        </div>
                        <span className='mt-4'>What is your relationship with this person?</span>
                        <input placeholder='*Friend' className='bg-[#f2f2f2] rounded-md outline-none p-2 w-full mt-2 mb-16' value={newContactRelationship} onChange={(e) => setNewContactRelationship(e.target.value)}/>
                        <button className='absolute bottom-0 right-0 rounded-full bg-[#29b6e8] text-white p-2' onClick={handleSubmitEmergencyContact}>Submit</button>
                    </div>
                </>
            )}
        </main>
    );
};

export default EmergencyDragandDrop;
