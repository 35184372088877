interface DateItem {
    datetime: string;
}

const calculateTimeDifference = (updatedAt: string) => {
    const updatedTime = new Date(updatedAt);
    const currentTime = new Date();
    const differenceInSeconds = (currentTime.getTime() - updatedTime.getTime()) / 1000;

    const days = Math.floor(differenceInSeconds / (3600 * 24));
    const hours = Math.floor((differenceInSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((differenceInSeconds % 3600) / 60);
    // const remainingSeconds = Math.floor(differenceInSeconds % 60);
    if (days > 0) {
        return `${days} days ago`;
    } else if (hours > 0) {
        return `${hours} hours ago`;
    } else if (minutes > 0) {
        return `${minutes} minutes ago`;
    } else {
        return `a few seconds ago`;
    }
};

const isSameDay = (date1: Date, date2: Date) => {
    const d1 = new Date(date1).toDateString();
    const d2 = new Date(date2).toDateString();
    return d1 === d2;
};
const formatDate = (dateString: string) => {
    const options: any = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
};

const formatTime = (oldDate: Date) => {
    const date = new Date(oldDate);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;

    const strTime = `${hours}:${minutesStr} ${ampm}`;
    return strTime;
};

const sortLatestDate = (dateList: DateItem[]): DateItem[] => {
    return dateList.sort((a, b) => {
        return new Date(b.datetime).getTime() - new Date(a.datetime).getTime();
    });
};

export { calculateTimeDifference, isSameDay, formatDate, formatTime, sortLatestDate }