import CircularProgress from '@mui/joy/CircularProgress';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useCountUp } from 'use-count-up';
import blink from '../../asset/gamification/bling.svg'
import tick from '../../asset/tick.svg'

interface QuestData {
    category: string;
    description: string;
    icon: string;
    is_completed: boolean;
    name: string;
    progress: number;
    required_count: number;
    reward: {
        xp: number;
        credits: number;
    };
}

interface QuestListProps {
    title: string;
    quests: QuestData[];
}
const progressColors: {
    Journal: string;
    Chat: string;
    "SMART Goal": string;
    Others: string;
} = {
    Journal: "#A271B1",
    Chat: "#1EB8EB",
    "SMART Goal": "#AED137",
    Others: "#FDEEC1"
};
const QuestList: React.FC<QuestListProps> = ({ title, quests }) => {
    const navigate = useNavigate()
    let color: any;
    const { value: value2, reset } = useCountUp({
        isCounting: true,
        duration: 1,
        start: 0,
        end: 75,
    });
    const handleQuest = (cat: string) => {
        if (cat === 'Journal') {
            navigate('/journal')
        }
        if (cat === 'Chat') {
            localStorage.setItem('stateFromQuest', 'stateFromQuest');
            navigate('/chat')
        }
        if (cat === 'SMART Goal') {
            navigate('/smart-goals')
        }
    }
    return (
        <div>
            {quests && <p>{title}</p>}
            {quests && quests.map((quest, index) => {
                const data = (Object.keys(progressColors) as (keyof typeof progressColors)[]).find(
                    (cat) => cat === quest.category
                );
                if (data && data.length > 0) {
                    color = progressColors[data]
                }
                else {
                    color = progressColors['Others']
                }
                return (
                    <ul className='mt-4' key={index}>
                        <>
                            <li key={index} className='flex gap-8 justify-between my-4' onClick={() => handleQuest(quest.category)}>
                                <div className='flex gap-4'>

                                    <CircularProgress size="lg" determinate value={(quest.progress / quest.required_count) * 100 as number} sx={{
                                        "--CircularProgress-size": "70px",
                                        "--CircularProgress-trackThickness": "10px",
                                        "--CircularProgress-progressThickness": "10px",
                                        "--CircularProgress-trackColor": `${color}4a`,
                                        "--CircularProgress-progressColor": color
                                    }}>
                                        <img src={quest.icon} width={35} height={35} />

                                    </CircularProgress>
                                    <div className='flex flex-col'>
                                        <strong>{quest.name}</strong>
                                        <span>{quest.description}</span>
                                    </div>

                                </div>
                                {quest.is_completed ? <button className='bg-[#a071af] rounded-full text-white font-bold flex justify-center items-center w-10 h-10' type='button'><img src={tick} alt='tick' /></button>
                                    : <div className='flex gap-2 items-center font-bold'>
                                        <img src={blink} width={35} height={35} />
                                        <p>{quest.reward.xp} XP</p>
                                    </div>
                                }
                            </li>
                            {index === 0 && <hr />}
                        </>
                    </ul>
                )
            })}
        </div>
    )
}
export default QuestList