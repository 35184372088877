import PostItem from '../wellnessStream/PostItem';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { PulseLoader, ClipLoader } from 'react-spinners';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import PostItemSidebar from './PostItemSidebar';
import { useInitApi } from '../../store/initApi';
import { useWellnessStreamStore } from '../../store/wellnessStream';
import { sortLatestDate } from '../../helpers/dateTime';
import mutedBlack from '../../asset/wellness_stream/muted_black.svg'
import notfound from '../../asset/notfound.svg'
import cones from '../../asset/Cones.svg'
import { isMobile } from 'react-device-detect';
import InfiniteScroll from 'react-infinite-scroll-component'
import Title from './Title';
import { useInView } from 'react-intersection-observer';

interface WellnessStream {
  imageGroup: {
    src: string;
  }[] | null;
  photo_album_id: number | null;
  content: string | null;
  image: string | null;
}



export default function PostList() {
  const apiToken = sessionStorage.getItem('api_token');

  const [contents, setContents] = useState<WellnessStream[]>([]);
  const [feeds, setFeeds] = useState<WellnessStreamProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [mappedData, setMappedData] = useState<WellnessStreamProps[]>([]);
  const [displayedContents, setDisplayedContents] = useState<WellnessStream[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [showLoadMoreText, setShowLoadMoreText] = useState(true)
  const [isError, setIsError] = useState(false)
  // const [hasUserInteracted, setHasUserInteracted] = useState(false);
  const userInteractedRef = useRef(false);
  const { initApi } = useInitApi();
  const { setScroll, setLikeCounts, setGlobalMute, hasUserInteracted, setIsUserInteracted, isInputFocused, isCommentOpen, setPrevLength } = useWellnessStreamStore();
  const { ref, inView } = useInView({
    threshold: 0.8, // Detect when at least 50% of the video is in view
    triggerOnce: false, // Keep checking when scrolling back into view
  });
  const { ref: lastItemRef, inView: isLastItemInView } = useInView({
    threshold: 0.5, // Adjust this value as needed
    triggerOnce: false,
  });
  const observer = useRef<IntersectionObserver | null>(null);
  const lastPostElementRef = useCallback((node: HTMLDivElement | null) => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        loadMoreItems();
      }
    });
    if (node) observer.current.observe(node);
  }, [isLoading, hasMore, contents, displayedContents]);

  const fetchFeeds = async () => {
    if (!initApi) return;
    try {
      const res = await axios.post(
        `${initApi.url.features.feed.get_feed}`,
        {
          event_type: 'microlearningcontent',
          isMLC: true
        },
        { headers: { Authorization: `Bearer ${apiToken}` } }
      );
      if (res.status === 200) {
          const idLikesObject = res.data.response.reduce((acc: any, item: any) => {
            acc[item.id] = {
              likes: item.number_of_likes,
              isLiked: item.like
            }
            return acc
          }, {});
          setLikeCounts((prev) => {
            return {
              ...prev,
              ...idLikesObject, // Example of updating the likeCounts object
            };
          });
        
        const nonMobileLength = res.data.response.length > 0 && res.data.response.length < 20 ? 50 : 2
        const repeatedData = Array.from({ length: isMobile ? 1 : nonMobileLength }, () => res.data.response).flatMap(x => x);

        setFeeds(res.data.response);
        return repeatedData
      }

    } catch (error) {
      setIsError(true)
      console.log(error);
    }
  };

  const fetchWellnessStream = async () => {
    if (!initApi) return;
    try {
      const res = await axios.post(
        `${initApi.url.features.wellness_stream}`,
        {},
        { headers: { Authorization: `Bearer ${apiToken}` } }
      );
      if (res.status === 200) {
        const sortedData: any = sortLatestDate(res.data.response);
        // Repeat the sortedData 50 times
        const nonMobileLength = res.data.response.length > 0 && res.data.response.length < 20 ? 50 : 2

        const repeatedData = Array.from({ length: isMobile ? 1 : nonMobileLength }, () => sortedData).flatMap(x => x);
        setContents(repeatedData);
        return repeatedData;
      }
    } catch (error) {
      setIsError(true)
      console.log(error);
    }
  };

  const loadMoreItems = () => {
    if (displayedContents.length < contents.length) {
      const nextItems = contents.slice(displayedContents.length, displayedContents.length + 2);
      setDisplayedContents(prevContents => [...prevContents, ...nextItems]);
    } else {
      setHasMore(false);
    }
  };

  useEffect(() => {
    if (apiToken) {
      Promise.all([fetchFeeds(), fetchWellnessStream()])
        .then((values) => {
          const mapPostToId = (feeds: any[], streams: any[]) => {
            return streams.map((stream, index) => {
              const matchingData = feeds.find(feed => stream.id === feed.post_id);
              const matchAuthor = streams.find(stream => matchingData.post_id === stream.id)
              return {
                ...matchingData,
                author: matchAuthor.author,
                author_id: matchAuthor.author_id,
                mapped_id: matchingData ? matchingData.id : null,
                brief: matchingData ? stream.brief : null
              };
            });
          };
          const mappedData: any = mapPostToId(values[0] || [], values[1] || []);
          setMappedData(mappedData)
          setIsLoading(false);
        })
        .catch((error) => {
          console.log('Error fetching data:', error);
          setIsError(true)
          setIsLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (contents.length > 0) {
      setDisplayedContents(contents.slice(0, 2));
    }
  }, [contents]);


  const handleInitUnmute = () => {
    setGlobalMute(false);
    setIsUserInteracted(true);
    userInteractedRef.current = true;
  }
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const fetchData = async () => {
    const scrollContainer = document.querySelector('.post-section'); // Scroll container

    if (!scrollContainer) return;


    // Add a buffer of 300px to ensure we're detecting when the user is close to the bottom
    if (!isCommentOpen && !isInputFocused && scrollContainer.scrollTop > 0) {
      setShowLoadMoreText(false);
      setLoadMore(true);
      window.scrollTo(0, 0);
      // Simulate fetching data
      setPrevLength(contents.length)
      setTimeout(() => {
        setContents([...contents, ...contents]); // Append new contents
        setMappedData([...mappedData, ...mappedData]); // Append mapped data
        setLoadMore(false);
        setShowLoadMoreText(true);
        // setGlobalMute(true)
      }, 500);
    }
  };

  // useEffect(() => {
  //   if (!isMobile) {
  //     const handleScroll = () => {
  //       if (
  //         window.innerHeight + document.documentElement.scrollTop >=
  //         document.documentElement.offsetHeight - 100
  //       ) {
  //         fetchData();
  //       }
  //     };

  //     window.addEventListener('scroll', handleScroll);
  //     return () => window.removeEventListener('scroll', handleScroll);
  //   }
  // }, [isMobile]);
  // useEffect(() => {
  //   if(inView && contents.length ){
  //     fetchData()
  //   }
  // },[inView])
  useEffect(() => {
    if (isLastItemInView && contents.length) {
      fetchData();
    }

  }, [isLastItemInView]);

  // useEffect(() => {
  //   const handleInteraction = () => {
  //     // setIsUserInteracted(true);
  //     window.removeEventListener('touchstart', handleInteraction);
  //   };

  //   if (!hasUserInteracted) {
  //     window.addEventListener('touchstart', handleInteraction);
  //   }

  //   return () => {
  //     window.removeEventListener('touchstart', handleInteraction);
  //   };
  // }, [hasUserInteracted]);
  const handleInteraction = () => {
    setGlobalMute(true)

    // setIsUserInteracted(true);
    // window.removeEventListener('touchstart', handleInteraction);
  };

  const handleScroll = () => {
    setScroll(true)
  }

  return (
    <section className={`${isMobile ? 'ml-0 h-auto overflow-hidden' : 'ml-16 snap-y snap-mandatory scroll-smooth h-[100vh] overflow-y-auto overflow-x-hidden'} flex flex-col gap-1 `} style={{ scrollbarWidth: 'none' }} onScroll={handleScroll} onTouchStart={handleInteraction}>
      {isError ? <div className='h-screen flex justify-center items-center font-bold'><img src={notfound} className='mr-4' /><p>Wellness Stream not found</p></div> : isLoading ? (
        <div className='h-[100dvh] flex justify-center items-center'>
          <PulseLoader />
        </div>
      ) : !isLoading && displayedContents.length > 0 && feeds.length > 0 ? (

        <div className='relative'>
          {!hasUserInteracted && isMobile && <div className='absolute left-4 top-8 z-[99999] flex gap-2 bg-gray-200 p-2 rounded-xl' onClick={handleInitUnmute}>
            <img src={mutedBlack} className='w-6 h-6' />
            <p>Unmute</p>
          </div>
          }
          {isMobile ? <InfiniteScroll
            style={{ height: 'calc(100vh)' }}
            className='post-section snap-y snap-mandatory scroll-smooth h-[100vh] overflow-y-auto overflow-x-hidden'
            dataLength={contents.length}
            next={() => null}
            hasMore={isCommentOpen && !inView ? false : true}
            loader={<h4></h4>}
            scrollThreshold={-9} // Adjust this value as needed
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {contents.map((content, index) => (
              <div className='h-screen snap-center' key={index} ref={index === contents.length - 2 ? lastItemRef : null}>
                {content.content ? (
                  <PostItem item={content} feed={mappedData[index % contents.length]} className={`${isMobile ? 'w-full' : 'w-[calc(100%+4rem)]'}`} />
                ) : content.photo_album_id ? (
                  <div className={`relative flex items-center justify-center ${isMobile ? 'w-full h-[100dvh]' : 'w-[calc(100%+4rem)] h-[80dvh]'} `}>
                    <div className='relative'>
                      {/* <div className='flex items-center w-full h-12 rounded-t-xl bg-black'>
                          <img src={mute} alt="" className='w-6 h-6 ml-4 cursor-pointer' />
                        </div> */}

                      <Carousel
                        swipeable
                        emulateTouch
                        showStatus={false}
                        showThumbs={false}
                        className='relative flex aspect-[2/3] lg:aspect-[1/2] items-center'
                      >
                        {content.imageGroup?.map((image, imgIndex) => (
                          <img src={image.src} key={imgIndex} alt={`carousel-img-${imgIndex}`} />
                        ))}
                      </Carousel>
                      <Title className='bottom-0 left-8' feed={mappedData[index % contents.length]} />
                    </div>
                    <PostItemSidebar className="absolute right-4" feed={mappedData[index % contents.length]} />
                  </div>
                ) : null}
              </div>
            ))}
          </InfiniteScroll> :
            displayedContents.map((content, index) => (
              <div key={index} ref={index === displayedContents.length - 1 ? lastPostElementRef : null}>
                {content.content ? (
                  <PostItem item={content} feed={mappedData[index % contents.length]} className={`snap-center ${isMobile ? 'w-full' : 'w-[calc(100%+4rem)]'}`} />
                ) : content.photo_album_id ? (
                  <div className='snap-center flex items-center justify-center w-[calc(100%+4rem)] h-[100dvh]'>
                    <div className='relative'>
                      <Carousel
                        swipeable
                        emulateTouch
                        showStatus={false}
                        showThumbs={false}
                        className='relative flex h-full aspect-[2/5] lg:aspect-[1/2] items-center max-w-[300px] lg:max-w-[400px]'
                      >
                        {content.imageGroup?.map((image, imgIndex) => (
                          <img src={image.src} key={imgIndex} alt={`carousel-img-${imgIndex}`} />
                        ))}
                      </Carousel>
                      <Title className='bottom-20 left-2' feed={mappedData[index % contents.length]} />

                    </div>
                    <PostItemSidebar feed={mappedData[index % contents.length]} />
                  </div>
                ) : null}
              </div>
            ))
          }
        </div>
      ) : <div className='h-screen flex justify-center items-center font-bold'><img src={cones} className='mr-4' /><p>Coming soon </p></div>}
      {/* { !isCommentOpen && isMobile && <div ref={ref}>
        {!isLoading && !isError && isMobile && showLoadMoreText && <p className='block w-full text-center'>Scroll up to load more </p>}
      </div>} */}
      {loadMore && !showLoadMoreText && <div className='flex justify-center w-full'><ClipLoader speedMultiplier={0.5} /></div>}

      <style>{`
        .post-section {
          scrollbar-gutter: stable;
          
        }

          .loadMore {
          color:white;
          position: fixed;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999
          }
      `}</style>
    </section>
  );
}