import React from 'react'

export default function SafetyPlanFirstTimerIcon() {
    return (
        <svg width="40" height="40" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.4311 24.5031C14.5073 24.5031 14.5835 24.5406 14.6216 24.5406C14.6597 24.5406 14.7741 24.5406 14.8122 24.5031C15.117 24.4281 22.2432 22.4406 22.2432 17.7906V9.16563C22.2432 8.86563 22.0527 8.60313 21.7859 8.49063C17.213 6.50312 11.9922 6.50312 7.4573 8.49063C7.19054 8.60313 7 8.86563 7 9.16563V17.7906C7 22.4406 14.1262 24.4281 14.4311 24.5031ZM11.0395 14.6031C11.3443 14.3031 11.8016 14.3031 12.1065 14.6031L13.8976 16.3656L17.1368 13.1781C17.4416 12.8781 17.8989 12.8781 18.2038 13.1781C18.5086 13.4781 18.5086 13.9281 18.2038 14.2281L14.4311 17.9406C14.2786 18.0906 14.0881 18.1656 13.8976 18.1656C13.707 18.1656 13.5165 18.0906 13.3641 17.9406L11.0395 15.6531C10.7346 15.3531 10.7346 14.8656 11.0395 14.6031Z" fill="#29B6E8" />
            <path d="M24.7244 21.0825C24.7244 23.0155 23.1574 24.5825 21.2244 24.5825C19.2914 24.5825 17.7244 23.0155 17.7244 21.0825C17.7244 19.1495 19.2914 17.5825 21.2244 17.5825C23.1574 17.5825 24.7244 19.1495 24.7244 21.0825Z" fill="#141D45" />
            <g clipPath="url(#clip0_2278_6062)">
                <path d="M23.2849 20.6431H21.6638V19.022C21.6638 18.7793 21.4671 18.5825 21.2244 18.5825C20.9817 18.5825 20.7849 18.7793 20.7849 19.022V20.6431H19.1638C18.9211 20.6431 18.7244 20.8398 18.7244 21.0825C18.7244 21.3252 18.9211 21.522 19.1638 21.522H20.7849V23.1431C20.7849 23.3858 20.9817 23.5825 21.2244 23.5825C21.4671 23.5825 21.6638 23.3858 21.6638 23.1431V21.522H23.2849C23.5276 21.522 23.7244 21.3252 23.7244 21.0825C23.7244 20.8398 23.5276 20.6431 23.2849 20.6431Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_2278_6062">
                    <rect width="5" height="5" fill="white" transform="translate(18.7244 18.5825)" />
                </clipPath>
            </defs>
        </svg>
    )
}
