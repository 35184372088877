import React from 'react';

interface ContactItem {
  name: string;
  relationship: string;
  contact1: string;
}

interface ContactListProps {
  safetyPlan: ContactItem[];
  handleBackClick?: () => void;
  handleNextClick?: () => void;
  handleIAmOkayClick: () => void;
}

function ContactList({ 
  safetyPlan, 
  handleBackClick, 
  handleNextClick, 
  handleIAmOkayClick 
}: ContactListProps) {

  return (
    <main className="flex flex-col items-center mt-4 w-full px-[15%] h-full relative">
      <div className="overflow-y-auto max-h-52 h-52 w-full space-y-3">
        {safetyPlan && safetyPlan.map((item, index) => (
          <div
            key={index}
            className={`relative border rounded p-2 w-full flex justify-between text-sm cursor-pointer items-center`}
          >
            <div className='flex flex-col'>
                <span>{item.name}</span>
                <span>{item.relationship}</span>
            </div>
            <span>{item.contact1}</span>
          </div>
        ))}
      </div>
      <div className='w-full flex justify-between mt-10'>
        <button className={`bg-[#29b6e8] text-white rounded-full py-1 px-4 ${handleBackClick ? '' : 'bg-transparent cursor-default text-transparent'}`} onClick={handleBackClick}>Back</button>
        <button className={`bg-[#29b6e8] text-white rounded-full py-1 px-4 ${handleNextClick ? '' : 'bg-transparent cursor-default text-transparent'}`} onClick={handleNextClick}>Next</button>
      </div>
      <div className='w-full rounded-xl h-32 bg-[#29b6e8] mt-10 flex flex-col items-center'>
        <span className='text-white font-bold text-xl mt-4'>How am I feeling now?</span>
        <div className='w-full flex mt-4 justify-center gap-4'>
            <button className={`rounded-full py-2 w-[20%] font-bold bg-white text-[#29b6e8]`} onClick={handleIAmOkayClick}>I'm okay</button>
        </div>
      </div>
    </main>
  );
}

export default ContactList;
