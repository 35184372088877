import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import yellowBling from '../../asset/gamification/xp-yellow.svg'
import level from '../../asset/gamification/level.svg'

import Lottie from 'react-lottie';

import * as levelUpEffect from '../../asset/gamification/level-light.json'

interface LevelUpPopUpProps {
    isOpenLevel: boolean,
    closeLvl: () => void
    gamification: QuestProgressGameProps
}

export default function LevelUpPopUp({ isOpenLevel, closeLvl, gamification }: LevelUpPopUpProps) {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: levelUpEffect,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };


    return (
        <Transition appear show={isOpenLevel}>
            <Dialog as="div" className="relative z-[99998] focus:outline-none lexend-deca-normal" onClose={() => null}>
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <TransitionChild
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 transform-[scale(95%)]"
                            enterTo="opacity-100 transform-[scale(100%)]"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 transform-[scale(100%)]"
                            leaveTo="opacity-0 transform-[scale(95%)]"
                        >
                            <DialogPanel className="w-full max-w-[40%] rounded-xl bg-white p-6 border border-[#cccccc]">
                            <h5 className='uppercase text-center font-bold text-2xl'>Congratulations!</h5>
                                <div className='relative'>
                                    <Lottie
                                        options={defaultOptions}

                                    />
                                    <span className='absolute transform translate-x-1/2 -translate-y-1/2 top-[45%] right-[50%] z-[999] text-7xl text-[#feda32]'>{gamification.current_level + 1}</span>

                                    <img src={level}  className='w-[300px] absolute transform translate-x-1/2 -translate-y-1/2 top-[50%] right-[50%]' />
                                </div>
                                <div className="mt-4">
                                    <Button
                                        className="bg-[#29B3E6] text-white rounded-full p-4 w-full text-center" onClick={closeLvl}
                                    >
                                        Close
                                    </Button>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>

            </Dialog>
        </Transition>
    )
}
