import React, { useEffect, useState } from 'react';
import feeds from '../asset/sidebar/active/feeds.svg';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios, { CancelTokenSource } from 'axios';
import { activelike, defaultlike, defaualtshare, defaultcomment, sendIcon } from '../asset';
import { UserDataProps } from '../types/common';
import { FadeLoader } from 'react-spinners';
import { track } from '@amplitude/analytics-browser';

interface FeedData {
  author: string;
  author_profile: string;
  content: string;
  like: boolean;
  number_of_comments: number;
  number_of_likes: number;
  time: string;
  text: string;
  title: string;
  id: number;
}

interface CommentData {
  comment: string;
  datetime: string;
  time: string;
  user_id: number;
  user_profile: string;
  username: string;
}

function Feeds() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserDataProps | null>(null);
  const [feedsData, setFeedsData] = useState<FeedData[] | null>(null);
  const [selectedFeedData, setSelectedFeedData] = useState<FeedData | null>(null);
  const [CommentsData, setCommentsData] = useState<CommentData[] | null>(null);
  const [comment, setComment] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingFeeds, setLoadingFeeds] = useState<boolean>(true);
  const [commentInput, setCommentInput] = useState<string>('');
  const apiToken = sessionStorage.getItem('api_token');

  useEffect(() => {
    const storedUserData = sessionStorage.getItem('user');
    if (!apiToken || !storedUserData) {
      navigate('/login');
      return;
    }

    setUserData(JSON.parse(storedUserData));
  }, [apiToken, navigate]);

  useEffect(() => {
    track('screenView', { screen_name: 'FeedsScreen' })
    let source = axios.CancelToken.source();
    fetchFeeds(source);
    return () => {
      source.cancel();
    }
  }, []);

  const postComment = async (event_id: number, source: CancelTokenSource) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/timeline/postComment`,
        { event_id: event_id, content: commentInput },
        { headers: { Authorization: `Bearer ${apiToken}` }, cancelToken: source.token }
      );
      if (response.status === 200) {
        fetchTimelineComments(source, event_id);
        fetchFeeds(source);
        setCommentInput(''); // Clear the comment input after successful post
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        toast.error('Error');
      }
    }
  };

  const fetchFeeds = async (source: CancelTokenSource) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/timeline`, {},
        { headers: { Authorization: `Bearer ${apiToken}` }, cancelToken: source.token }
      );
      if (response.status === 200) {
        setLoadingFeeds(false);
        setFeedsData(response.data.response);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        toast.error('Error');
      }
    }
  };

  const fetchTimelineComments = async (source: CancelTokenSource, id: number) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/timeline/comment`,
        { event_id: id },
        { headers: { Authorization: `Bearer ${apiToken}` }, cancelToken: source.token }
      );
      if (response.status === 200) {
        setLoading(true);
        setCommentsData(response.data.response.comments);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        toast.error('Error');
      }
    }
  };

  const handleCommentClick = (feedData: FeedData) => {
    let source = axios.CancelToken.source();
    fetchTimelineComments(source, feedData.id);
    setSelectedFeedData(feedData);
    setComment(true);
  };

  const handleCloseClick = () => {
    setComment(false);
    setCommentsData(null);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommentInput(event.target.value);
  };

  const handleLikeClick = async (event_id: number, isLiked: boolean) => {
    let source = axios.CancelToken.source();
    try {
      const submitResponse = await axios.post(
        `${process.env.REACT_APP_ENV_URL}/api/app/mobile/timeline/postLike`,
        {
          user_id: userData?.uuid,
          event_id: event_id,
          on: !isLiked
        },
        { headers: { Authorization: `Bearer ${apiToken}` } }
      );
      if (submitResponse.status === 200) {
        fetchFeeds(source);
      }
    } catch (error) {
      toast.error('Error submitting');
    }
  };

  const handleCommentSubmit = () => {
    let source = axios.CancelToken.source();
    if (selectedFeedData) {
      postComment(selectedFeedData.id, source);
    }
  };

  return (
    <div className='w-screen flex md:justify-center xl:block'>
      <div className='pl-20 flex w-full h-full p-5'>
        <div className='flex flex-col mt-4 w-full h-full'>
          <div className='w-fit px-3 py-1 border-[#faa81d] border rounded-full flex'>
            <img src={feeds} alt='surveys' className='w-5 mr-2' />
            <span className='font-bold text-[#faa81d] text-lg'>Feeds</span>
          </div>
          <div className='w-full h-fit items-center flex flex-col mt-5 gap-4 overflow-y-scroll'>
            {feedsData && feedsData.length > 0 ?
              <>
                {feedsData.map((feedData, index) => (
                  <div key={index} className='w-[45%] h-fit rounded-2xl overflow-hidden border border-[#cccccc]'>
                    <div className='w-full p-4'>
                      <div className='w-full flex justify-between items-center'>
                        <div className='flex items-center'>
                          <img src={feedData.author_profile} alt='profile' className='w-12 rounded-full' />
                          <span className='ml-3'>{feedData.author}</span>
                        </div>
                        <span>{feedData.time}</span>
                      </div>
                      <div className='w-full flex justify-center'>
                        <span dangerouslySetInnerHTML={{ __html: feedData.content }}></span>
                      </div>
                    </div>
                    <div className='w-full h-12 bg-[#efefef] flex items-center'>
                      <div className='flex items-center justify-center w-fit ml-8'>
                        <button onClick={() => handleLikeClick(feedData.id, feedData.like)}>
                          <img src={feedData.like ? activelike : defaultlike} alt='like' className='w-6' />
                        </button>
                        <span className='ml-3'>{feedData.number_of_likes}</span>
                      </div>
                      <div className='flex items-center justify-center w-fit ml-8'>
                        <button onClick={() => handleCommentClick(feedData)}>
                          <img src={defaultcomment} alt='like' className='w-6' />
                        </button>
                        <span className='ml-3'>{feedData.number_of_comments}</span>
                      </div>
                      {/* <div className='flex items-center justify-center w-fit ml-8'>
                          <button>
                            <img src={defaualtshare} alt='like' className='w-6'/>
                          </button>
                        </div> */}
                    </div>
                  </div>
                ))}
                {comment && selectedFeedData !== null && (
                  <div className="fixed inset-0 flex items-center justify-center z-[9998]">
                    <div className="absolute inset-0 bg-[#f4f4f4] opacity-40"></div>
                    <div className="bg-white rounded-2xl z-[9998] relative min-h-[600px] 
                          max-h-[600px] h-full min-w-[500px] w-[50%] border border-[#dedede] flex flex-col items-center">
                      <button onClick={() => handleCloseClick()} className="absolute top-5 right-4 m-4 text-gray-600 hover:text-gray-800 text-2xl">
                        X
                      </button>
                      <div className='w-full mt-20'>
                        <div className='w-full px-8'>
                          <div className='w-full flex justify-between items-center'>
                            <div className='flex items-center'>
                              <img src={selectedFeedData?.author_profile} alt='profile' className='w-12 rounded-full' />
                              <span className='ml-3'>{selectedFeedData?.author}</span>
                            </div>
                            <span>{selectedFeedData?.time}</span>
                          </div>
                          <div className='w-full flex justify-center'>
                            <span dangerouslySetInnerHTML={{ __html: selectedFeedData?.content }}></span>
                          </div>
                        </div>
                        <div className='w-full h-12 bg-[#efefef] flex items-center mt-12'>
                          <div className='flex items-center justify-center w-fit ml-8'>
                            <button>
                              <img src={selectedFeedData.like ? activelike : defaultlike} alt='like' className='w-6' />
                            </button>
                            <span className='ml-3'>{selectedFeedData.number_of_likes}</span>
                          </div>
                          <div className='flex items-center justify-center w-fit ml-8'>
                            <button>
                              <img src={defaultcomment} alt='like' className='w-6' />
                            </button>
                            <span className='ml-3'>{selectedFeedData.number_of_comments}</span>
                          </div>
                          {/* <div className='flex items-center justify-center w-fit ml-8'>
                                    <button>
                                      <img src={defaualtshare} alt='like' className='w-6'/>
                                    </button>
                                  </div> */}
                        </div>
                        <div className='w-full mt-10 px-8'>
                          <div className='w-full rounded-full bg-[#f3f3f3] flex items-center py-4'>
                            <input
                              className='ml-6 bg-transparent text-sm w-full outline-none'
                              placeholder='Write a comment...'
                              onChange={handleInputChange}
                              value={commentInput}
                            />
                            <button onClick={handleCommentSubmit} className='w-fit h-fit rounded-full bg-[#1bb9eb] p-2 flex justify-center items-center mr-5'>
                              <img src={sendIcon} />
                            </button>
                          </div>
                          <div className='w-full h-52 mt-6 px-6 gap-2 flex flex-col overflow-y-auto'>
                            {CommentsData && CommentsData.length > 0 ?
                              <>
                                {CommentsData.map((CommentData, index) => (
                                  <div key={index} className='w-full h-fit rounded-2xl bg-[#efefef] flex p-3'>
                                    <img className='w-14 rounded-full' src={CommentData.user_profile} alt='user' />
                                    <div className='w-full flex flex-col ml-4'>
                                      <span className='text-[#12143e] font-bold'>{CommentData.username}</span>
                                      <span className='mt-2'>{CommentData.comment}</span>
                                    </div>
                                  </div>
                                ))}
                              </>
                              :
                              <>
                                <div className='w-full flex justify-center items-center'>
                                  <FadeLoader color='gray' loading={loading} height={12} width={5} radius={5} />
                                </div>
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
              :
              <>
                <div className='w-full flex justify-center items-center'>
                  <FadeLoader color='gray' loading={loadingFeeds} height={12} width={5} radius={5} />
                </div>
                {feedsData && feedsData.length === 0 && (
                  <span>No Feed Data</span>
                )}
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feeds;
