import React from 'react'

const DownArrowIcon = () => {
    return (
        <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2948_2582)">
                <path d="M5.49767 6.57673L9.61293 2.46166C9.72597 2.34835 9.87711 2.28601 10.0383 2.28601C10.1995 2.28601 10.3506 2.34835 10.4638 2.46166L10.8242 2.82224C10.9376 2.93537 11 3.0866 11 3.24775C11 3.40891 10.9376 3.55996 10.8242 3.67317L5.92462 8.57283C5.81104 8.68649 5.65928 8.74874 5.49794 8.74829C5.33589 8.74874 5.18431 8.68658 5.07064 8.57283L0.175821 3.67774C0.0624225 3.56452 -1.18583e-06 3.41347 -1.19285e-06 3.25222C-1.19986e-06 3.09107 0.0624225 2.94002 0.175821 2.82671L0.536227 2.46622C0.770804 2.23164 1.15267 2.23164 1.38716 2.46622L5.49767 6.57673Z" fill="#F6A41E" />
            </g>
            <defs>
                <clipPath id="clip0_2948_2582">
                    <rect width="11" height="11" fill="white" transform="matrix(-4.35175e-08 -1 -1 4.35175e-08 11 11.0171)" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default React.memo(DownArrowIcon)
