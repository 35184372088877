import React from 'react'
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import Lottie from 'react-lottie';
import * as streakFire from '../../asset/gamification/streak.json'
import tick from '../../asset/tick.svg'

interface QuestPopUpProps {
    isOpen: boolean,
    close: () => void,
    gamification: QuestProps
}

export default function StreaksPopUp({ isOpen, close, gamification }: QuestPopUpProps) {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: streakFire,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const today = new Date();
    const dayOfWeek = today.getDay();
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    return (
        <>
            <Transition appear show={isOpen}>
                <Dialog as="div" className="relative z-[99999] focus:outline-none lexend-deca-normal" onClose={() => null}>
                    <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4">
                            <TransitionChild
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 transform-[scale(95%)]"
                                enterTo="opacity-100 transform-[scale(100%)]"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 transform-[scale(100%)]"
                                leaveTo="opacity-0 transform-[scale(95%)]"
                            >
                                <DialogPanel className="w-full max-w-[60%] xl:max-w-[40%] rounded-xl bg-white p-6 border border-[#cccccc]">
                                    <div className='block m-auto mb-8' >
                                        <Lottie
                                            options={defaultOptions}
                                            width={100}
                                        />
                                    </div>
                                    <div className='text-center mb-8'>
                                        <h5 className='font-bold'>{gamification.current_streaks} day streak!</h5>
                                        <p>Congratulations on your {gamification.current_streaks}-day quest streak!</p>
                                        <p>You're off to a great start!</p>
                                    </div>
                                    <div className='font-bold flex justify-around text-center mb-20'>
                                        <div className='flex flex-col justify-center'>
                                            <p>M</p>
                                            {gamification.streak.mon && days[dayOfWeek] === 'Monday' ? <button className='bg-[#29B3E6] rounded-full text-white font-bold flex justify-center items-center w-10 h-10' type='button'><img src={tick} alt='tick' /></button> : gamification.streak.mon ? <button className='bg-gray-500 rounded-full text-white font-bold flex justify-center items-center w-10 h-10' type='button'><img src={tick} alt='tick' /></button> : <div className='rounded-full bg-gray-500 w-10 h-10'></div>}
                                        </div>
                                        <div className='flex flex-col justify-center'>
                                            <p>T</p>
                                            {gamification.streak.tue && days[dayOfWeek] === 'Tuesday' ? <button className='bg-[#29B3E6] rounded-full text-white font-bold flex justify-center items-center w-10 h-10' type='button'><img src={tick} alt='tick' /></button> : gamification.streak.tue ? <button className='bg-gray-500 rounded-full text-white font-bold flex justify-center items-center w-10 h-10' type='button'><img src={tick} alt='tick' /></button> : <div className='rounded-full bg-gray-500 w-10 h-10'></div>}
                                        </div>
                                        <div className='flex flex-col justify-center'>
                                            <p>W</p>
                                            {gamification.streak.wed && days[dayOfWeek] === 'Wednesday' ? <button className='bg-[#29B3E6] rounded-full text-white font-bold flex justify-center items-center w-10 h-10' type='button'><img src={tick} alt='tick' /></button> : gamification.streak.wed ? <button className='bg-gray-500 rounded-full text-white font-bold flex justify-center items-center w-10 h-10' type='button'><img src={tick} alt='tick' /></button> : <div className='rounded-full bg-gray-500 w-10 h-10'></div>}
                                        </div>
                                        <div className='flex flex-col justify-center'>
                                            <p>T</p>
                                            {gamification.streak.thu && days[dayOfWeek] === 'Thursday' ? <button className='bg-[#29B3E6] rounded-full text-white font-bold flex justify-center items-center w-10 h-10' type='button'><img src={tick} alt='tick' /></button> : gamification.streak.thu ? <button className='bg-gray-500 rounded-full text-white font-bold flex justify-center items-center w-10 h-10' type='button'><img src={tick} alt='tick' /></button> : <div className='rounded-full bg-gray-500 w-10 h-10'></div>}
                                        </div>
                                        <div className='flex flex-col justify-center'>
                                            <p>F</p>
                                            {gamification.streak.fri && days[dayOfWeek] === 'Friday' ? <button className='bg-[#29B3E6] rounded-full text-white font-bold flex justify-center items-center w-10 h-10' type='button'><img src={tick} alt='tick' /></button> : gamification.streak.fri ? <button className='bg-gray-500 rounded-full text-white font-bold flex justify-center items-center w-10 h-10' type='button'><img src={tick} alt='tick' /></button> : <div className='rounded-full bg-gray-500 w-10 h-10'></div>}
                                        </div>
                                        <div className='flex flex-col justify-center'>
                                            <p>S</p>
                                            {gamification.streak.sat && days[dayOfWeek] === 'Saturday' ? <button className='bg-[#29B3E6] rounded-full text-white font-bold flex justify-center items-center w-10 h-10' type='button'><img src={tick} alt='tick' /></button> : gamification.streak.sat ? <button className='bg-gray-500 rounded-full text-white font-bold flex justify-center items-center w-10 h-10' type='button'><img src={tick} alt='tick' /></button> : <div className='rounded-full bg-gray-500 w-10 h-10'></div>}
                                        </div>
                                        <div className='flex flex-col justify-center'>
                                            <p>S</p>
                                            {gamification.streak.sun && days[dayOfWeek] === 'Sunday' ? <button className='bg-[#29B3E6] rounded-full text-white font-bold flex justify-center items-center w-10 h-10' type='button'><img src={tick} alt='tick' /></button> : gamification.streak.sun ? <button className='bg-gray-500 rounded-full text-white font-bold flex justify-center items-center w-10 h-10' type='button'><img src={tick} alt='tick' /></button> : <div className='rounded-full bg-gray-500 w-10 h-10'></div>}
                                        </div>
                                    </div>
                                    <button className='bg-[#29B3E6] text-white rounded-full p-4 w-full text-center' onClick={close}>
                                        <span>Continue</span>
                                    </button>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>

                </Dialog>
            </Transition >
        </>
    )
}
