import React from 'react'
import { HotlineProps } from '../../types/ineedhelp'
import OperatingHourIcon from '../../asset/ineedhelp/OperatingHourIcon'
import WhatsAppIcon from '../../asset/ineedhelp/WhatsAppIcon'
import EmailIcon from '../../asset/ineedhelp/EmailIcon'
import { useContactStore } from '../../store/contact'
import PhoneIcon from '../../asset/ineedhelp/PhoneIcon'
import BrowserIcon from '../../asset/ineedhelp/BrowserIcon'


export default function MentalHealthSupport({ hotlineContacts }: { hotlineContacts: HotlineProps[]; selectedContact?: HotlineProps; }) {
  const contactIconStyle = "bg-[#E9ECED] border-[10px] lg:border-[20px] border-[#E9ECED] rounded-full w-auto h-auto lg:h-[80px]"
  const setSelectedContact = useContactStore((state) => state.setSelectedContact)
  const handleSelected = (contact: HotlineProps) => {
    setSelectedContact(contact)
  }
  return (
    <>
      {hotlineContacts.length > 0 ?
        <section className='max-h-[1000px] overflow-auto w-full'>
          <h5 className='text-[#999999] font-bold mb-4 text-3xl'>Mental health Support</h5>
          {hotlineContacts.map((contact: HotlineProps) => (
            <div className='p-8 border border-[#E9E9E9] rounded-xl mb-8' onClick={() => handleSelected(contact)} key={contact.id}>
              <div className='flex gap-8 md:flex-col md:items-center lg:flex-row lg:items-start'>
                <img src={contact.logo_url} alt={contact.hotline_name} className='md:w-32 md:h-32 lg:w-32' />
                <div className='flex flex-col'>
                  <h5 className='font-bold'>{contact.hotline_name}</h5>
                  <span className='text-sm text-[#999999] font-bold'>{contact.is_24h ? '24-hour' : ''}</span>
                  <span className='font-bold text-[#29b6e8] mt-2'>{contact.hotline_number}</span>
                  <span className="w-fit text-[#29B7EA] text-xs font-medium  px-2.5 py-0.5 rounded-full border border-[#29B7EA] mt-2">{contact.language ?? 'ENG'}</span>
                  {contact.operating_hour_today ?
                    <div className='flex items-center gap-4 mt-2'>
                      <OperatingHourIcon />
                      <div className='border border-[#F6A41E] rounded-full w-fit text-[#F6A41E] md:text-xs text-sm px-2 py-1 font-medium'>
                        <span>{contact.operating_hour_today}</span>
                      </div>
                    </div>
                    : ""}
                </div>
              </div>
              <div className='flex items-center w-full md:gap-4 lg:gap-8 2xl:gap-20 mt-8'>

                {contact.whatsapp_number && <WhatsAppIcon className={`lg:w-auto ${contactIconStyle} cursor-pointer`} />}
                {contact.hotline_number && <a className='lg:w-auto' href={`tel:${contact.hotline_number}`}><PhoneIcon className={`lg:w-full ${contactIconStyle} cursor-pointer`} /></a>}
                {contact.email && <EmailIcon className={`lg:w-auto ${contactIconStyle} cursor-pointer`} />}
                {contact.url && <BrowserIcon className={`lg:w-auto ${contactIconStyle} cursor-pointer`} onClick={() => !contact.url ? null : window.open(contact.url, '_blank')} />}
              </div>
            </div>
          )
          )}
        </section>
        : ""}

    </>
  )
}
