import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify';
import { Dialog, Transition, DialogPanel, TransitionChild, DialogTitle } from '@headlessui/react'
import Loader from '../icons/Loader';
import { useInitApi } from '../../store/initApi';

interface CountryProps {
    countries: CountryDetail[]
}

interface CountryDetail {
    code: string;
    flag: string;
    id: number;
    name: string;
    timezone: string;
}

interface SelectedCountry {
    name: string;
    code: string;
    flag: string;
}
export default function Country({ selectedCountry, onSelectedCountry, onSetHotlines }: any) {
    const apiToken = sessionStorage.getItem('api_token');
    const [countries, setCountries] = useState<CountryProps>()
    const [selectCountry, setSelectCountry] = useState<SelectedCountry>()
    const [isCountryOpen, setIsCountryOpen] = useState(false)
    const [isOpenSelectCountry, setIsOpenSelectCountry] = useState(false)
    const [loadingCountryCode, setLoadingCountryCode] = useState("");
    const [isDisableCountry, setIsDisabledCountry] = useState(false)
    const initApi = useInitApi(state => state.initApi)
    const openCountryModal = () => {
        setIsCountryOpen(true)
        setIsOpenSelectCountry(false)
    }
    const closeCountryModal = () => setIsCountryOpen(false)
    const handleSelectCountry = () => {
        setIsOpenSelectCountry(true)
    }
    const handleSelected = () => {

    }
    const fetchHelpList = async () => {
        try {
            if (initApi) {
                const response = await axios.get(initApi?.url.features.panic_button.get_help_list, { headers: { Authorization: `Bearer ${apiToken}` } });
                if (response.status === 200) {
                    onSetHotlines(response.data.hotlines)
                }
            }

        } catch (error) {

            // toast.error('Error fetching data');
        }
    };
    const getCountryList = async () => {
        try {
            if (initApi) {
                const res = await axios.get(initApi?.url.features.panic_button.get_country_list, { headers: { Authorization: `Bearer ${apiToken}` } })
                if (res.status === 200)
                    setCountries(res.data)
            }

        } catch (error) {
            console.log(error)
        }
    }
    const updateCountry = async (countryCode: string, countryName: string, countryFlag: string) => {
        try {
            setIsDisabledCountry(true)
            setLoadingCountryCode(countryCode)
            if (initApi) {
                const res = await axios.post(initApi?.url.features.panic_button.update_preferred_country, { country_code: countryCode }, { headers: { Authorization: `Bearer ${apiToken}` } })

                if (res.status === 200) {
                    setLoadingCountryCode("")
                    setIsDisabledCountry(false)

                    // fetchHelpList();
                    toast.success(res.data.message)
                    setSelectCountry({ name: countryName, code: countryCode, flag: countryFlag })
                    // setSelectedContact(null)
                    setIsOpenSelectCountry(false)
                }
                closeCountryModal()
            }
        } catch (error) {
            setIsDisabledCountry(false)

            setLoadingCountryCode("")
        }
    }
    useEffect(() => {
        if (selectCountry && selectCountry.name !== "" && countries) {
            fetchHelpList()
            setSelectCountry(selectCountry)
        }
        //Set country flag img on load
        if (selectCountry && selectCountry.name === "" && countries) {
            const filterCountry = countries.countries.filter(country => country.code === selectCountry.code)
            if (filterCountry)
                setSelectCountry({ name: filterCountry[0].name, code: filterCountry[0].code, flag: filterCountry[0].flag })
        }

    }, [selectCountry, countries])
    useEffect(() => {
        setSelectCountry(selectedCountry)
    }, [selectedCountry])
    useEffect(() => {
        getCountryList();
    }, []);
    return (
        <div>
            {selectCountry && <img src={selectCountry.flag} width={50} className='w-10 h-10 border-4 border-black rounded-full cursor-pointer' onClick={openCountryModal} />}
            <Transition appear show={isCountryOpen} as={Fragment}>
                <Dialog as="div" className="relative z-[9999] lexend-deca-normal" onClose={closeCountryModal}>
                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </TransitionChild>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <TransitionChild
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <DialogPanel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all">
                                    <DialogTitle
                                        as="h3"
                                        className="text-3xl text-[#29B3E6] font-bold text-center"
                                    >
                                        What country are you in?
                                    </DialogTitle>
                                    {!isOpenSelectCountry ? <div className="flex justify-center gap-8 w-full items-center mt-8 text-xl">
                                        <div>
                                            <p className=" w-full text-[#13143E] font-semibold">
                                                I am in
                                            </p>
                                        </div>
                                        <div className='flex items-center gap-4 px-4 py-2 rounded-2xl bg-[#F3F3F3] font-semibold cursor-pointer' onClick={handleSelectCountry}>
                                            {selectCountry && <img src={selectCountry.flag} width={30} />}
                                            <p>{selectCountry?.name}</p>
                                        </div>
                                    </div> : <div className='flex flex-wrap gap-4 mt-8'>
                                        {countries && countries?.countries.map(country =>
                                            <div key={country.id} className={`${selectCountry?.code === country.code && !isDisableCountry ? 'border border-[#29B3E6] text-[#29B3E6] bg-[#29b3e661]' : "bg-[#F3F3F3]"} w-[calc(50%-1rem)] flex items-center gap-4 px-4 py-2 rounded-2xl  font-semibold ${isDisableCountry ? 'cursor-not-allowed' : 'cursor-pointer'} ${loadingCountryCode === country.code ? 'border border-[#29B3E6] text-[#29B3E6] bg-[#29b3e661]' : ''}`} onClick={!isDisableCountry ? () => updateCountry(country.code, country.name, country.flag) : () => null}>
                                                <img src={country.flag} width={50} />
                                                <p className='text-[14px]'>{country.name}</p>
                                                {loadingCountryCode === country.code && <Loader className='w-20' color='black' />}
                                            </div>
                                        )}
                                    </div>
                                    }
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    )
}
