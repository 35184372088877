import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { useInitApi } from '../../store/initApi';
import { usePrivacyStore } from '../../store/privacy';

const duration = [
    { id: 0, name: 'Immediately' },
    { id: 1, name: '1 Minute' },
    { id: 2, name: '2 Minutes' },
    { id: 3, name: '3 Minutes' },
    { id: 4, name: '4 Minutes' },
    { id: 5, name: '5 Minutes' },
]

export default function AutoLockDropDown() {
    const apiToken = sessionStorage.getItem('api_token')
    const initApi = useInitApi(state => state.initApi)
    const [selected, setSelected] = useState(duration[0])
    const [isDisabled, setIsDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const setAutoLockDuration = usePrivacyStore(state => state.setAutoLockDuration)

    const fetchApi = async () => {
        try {
            setIsLoading(true)
          const response = await axios.post(`${process.env.REACT_APP_ENV_URL}/api/app/mobile/init`, {}, { headers: { Authorization: `Bearer ${apiToken}` } });
          if (response.status === 200 && process.env.REACT_APP_ENCRYPT_SECRET) {
            setAutoLockDuration(response.data.response.user.user_info.auto_lock_duration)
            setSelected(duration[response.data.response.user.user_info.auto_lock_duration])
          }
          setIsLoading(false)
        }catch (error) {
            setIsLoading(false)
        }
    }
    const updateAutoLock = async (selectedOption: any) => {
        try {
            setIsLoading(true)
            setIsDisabled(true)
            if (!initApi || !apiToken) return;
            const res = await axios.post(initApi.url.features.privacy_pin.post_auto_lock_duration, {
                duration: selectedOption.id
            }, { headers: { Authorization: `Bearer ${apiToken}` } })
            if(res.status === 200){
                toast.success(res.data.message)
                const currentTime = new Date().getTime(); // Get the current time in milliseconds
                localStorage.setItem('storedTime', currentTime.toString()); // Store the current time in localStorage
                setAutoLockDuration(selectedOption.id)
            }
            setIsLoading(false)
            setIsDisabled(false)
        } catch (error) {
            toast.error('Unable to update auto lock duration. Try again later')
            setIsLoading(false)
            setIsDisabled(false)
        }
    }

    const handleSelectChange = (option: any) => {
        setSelected(option);
        updateAutoLock(option); // Trigger the API call after selection
    }

    useEffect(() => {
        fetchApi()            
    },[])
    if(isLoading) return <PulseLoader/>
    return (
        <div className="w-52">
            <Listbox value={selected} onChange={handleSelectChange} disabled={isDisabled}>
                <ListboxButton
                    className={clsx(
                        'relative block w-full rounded-lg bg-black/5 py-1.5 pr-8 pl-3 text-left text-sm/6 text-black',
                        'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-black/25'
                    )}
                >
                    {selected.name}
                    <ChevronDownIcon
                        className="w-5 h-5 group pointer-events-none absolute top-2.5 right-2.5 size-4 fill-black/60"
                        aria-hidden="true"
                    />
                </ListboxButton>
                <ListboxOptions
                    anchor="bottom"
                    transition
                    className={clsx(
                        'w-[var(--button-width)] rounded-xl border border-black/5 bg-black/5 p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none',
                        'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
                    )}
                >
                    {duration.map((option) => (
                        <ListboxOption
                            key={option.name}
                            value={option}
                            className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-black/10"
                        >
                            <CheckIcon className="w-5 h-5 invisible fill-black group-data-[selected]:visible" />
                            <div className="text-sm/6 text-black">{option.name}</div>
                        </ListboxOption>
                    ))}
                </ListboxOptions>
            </Listbox>
        </div>
    )
}
