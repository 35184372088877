import { create } from "zustand"

type AuthProps = {
    auth: boolean,
    setAuth: (value: boolean) => void
    genderPage: boolean,
    setGenderPage: (value: boolean) => void
}
export const useAuthStore = create<AuthProps>((set) => ({
    auth: false,
    setAuth: (newValue) => set(() => ({ auth: newValue })),
    genderPage: false,
    setGenderPage: (newValue) => set(() => ({ genderPage: newValue })),
}))