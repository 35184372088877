import React, { Fragment } from 'react'
import InfoIcon from '../asset/InfoIcon'
import { Tooltip } from 'react-tooltip'
import DASTable from './DASTable'
import ReactDOMServer from 'react-dom/server';
import DepressionIcon from '../asset/home/DepressionIcon'
import { DASProps } from '../types/insights'
import AnxietyIcon from '../asset/home/AnxietyIcon'
import StressIcon from '../asset/home/StressIcon'

function DAS({ positivityData, children }: DASProps) {
  const valueLength = [
    {
      value: 5,
    },
    {
      value: 4,
    },
    {
      value: 3,
    },
    {
      value: 2,
    },
    {
      value: 1,
    },
  ]
  return (
    <div className='relative rounded-2xl border border-[#cccccc] h-auto w-full lg:w-1/2 flex flex-col py-6 p-5'>
      <div className='w-full flex justify-between'>
        <div className='flex gap-2 items-center'>
          <h5 className='text-2xl font-bold text-[#161d45]'>Positivity Chart</h5>
          {/* <a
            data-tooltip-id="das-tooltip"
            data-tooltip-place="top"
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(<DASTable />)}
          >
            <InfoIcon />
          </a>
          <Tooltip id="das-tooltip" /> */}
        </div>
        {/* <button className='text-xs font-bold text-[#666666]'>View all &gt;</button> */}
      </div>
      {positivityData.length > 0 ?
        <div className='w-full mt-4'>
          <div className='w-full flex items-center mb-4'>
            <DepressionIcon width={"50"} height={"50"} />

            <div className='flex flex-col ml-2 text-sm'>
              <span className='font-bold text-[#00a4df]'>Happiness</span>
              {positivityData.length > 0 ? <span className='text-[#6D6F72]'>{positivityData[0].label}</span> : ""}
            </div>
          </div>
          <div className='w-full flex items-center mt-2'>
            <span className='text-xs mr-1 text-[#6D6F72]'>Low</span>
            {valueLength.map((item, index) =>
              <Fragment key={index}>
                <div className={`w-8 md:w-16 xl:w-8 h-8 2xl:w-10 2xl:h-10 rounded-full 
                ${positivityData[0].value === item.value ? 'bg-[#00a4df] text-white' : 'bg-[#edeeef] text-[#6D6F72]'} 
                flex items-center justify-center`}>
                  <span>{index+1}</span>
                </div>
                {index >= 4 ? "" : <div className='bg-[#edeeef] h-2 w-3 md:w-11 2xl:w-6'></div>}
              </Fragment>
            )}
            <span className='text-xs ml-1 text-[#6D6F72]'>High</span>
          </div>
          <div className='w-full flex items-center my-4'>
            <AnxietyIcon width={"50"} height={"50"} />
            <div className='flex flex-col ml-2 text-sm'>
              <span className='font-bold text-[#00a4df]'>Confidence</span>
              {positivityData.length > 0 ? <span className='text-[#6D6F72]'>{positivityData[1].label}</span> : ""}
            </div>
          </div>
          <div className='w-full flex items-center mt-2'>
            <span className='text-xs mr-1 text-[#6D6F72]'>Low</span>
            {valueLength.map((item, index) =>
              <Fragment key={index}>
                <div className={`w-8 md:w-16 xl:w-8 h-8 2xl:w-10 2xl:h-10 rounded-full ${positivityData[1].value === item.value ? 'bg-[#00a4df] text-white' : 'bg-[#edeeef] text-[#6D6F72]'} flex items-center justify-center`}>
                  <span>{index+1}</span>
                </div>
                {index >= 4 ? "" : <div className='bg-[#edeeef] h-2 w-3 md:w-11 2xl:w-6'></div>}
              </Fragment>
            )}
            <span className='text-xs ml-1 text-[#6D6F72]'>High</span>
          </div>
          <div className='w-full flex items-center my-4'>
            <StressIcon width={"50"} height={"50"} />

            <div className='flex flex-col ml-2 text-sm'>
              <span className='font-bold text-[#00a4df]'>Calmness</span>
              {positivityData.length > 0 ? <span className='text-[#6D6F72]'>{positivityData[2].label}</span> : ""}
            </div>
          </div>
          <div className='w-full flex items-center mt-2'>
            <span className='text-xs mr-1 text-[#6D6F72]'>Low</span>
            {valueLength.map((item, index) =>
              <Fragment key={index}>
                <div className={`w-8 md:w-16 xl:w-8 h-8 2xl:w-10 2xl:h-10 rounded-full ${positivityData[2].value === item.value ? 'bg-[#00a4df] text-white' : 'bg-[#edeeef] text-[#6D6F72]'} flex items-center justify-center`}>
                  <span>{index+1}</span>
                </div>
                {index >= 4 ? "" : <div className='bg-[#edeeef] h-2 w-3 md:w-11 2xl:w-6'></div>}
              </Fragment>
            )}
            <span className='text-xs ml-1 text-[#6D6F72]'>High</span>
          </div>
        </div>
        : ""}
      {children}
    </div>
  )
}

export default DAS