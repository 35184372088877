import React from 'react'

export default function SafetyPlanRoundedIcon() {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.3855 14.9428C29.3855 22.9193 22.9193 29.3855 14.9428 29.3855C6.96624 29.3855 0.5 22.9193 0.5 14.9428C0.5 6.96624 6.96624 0.5 14.9428 0.5C22.9193 0.5 29.3855 6.96624 29.3855 14.9428Z" stroke="#29B6E8" />
            <path d="M14.4311 24.5031C14.5073 24.5031 14.5835 24.5406 14.6216 24.5406C14.6597 24.5406 14.7741 24.5406 14.8122 24.5031C15.117 24.4281 22.2432 22.4406 22.2432 17.7906V9.16563C22.2432 8.86563 22.0527 8.60313 21.7859 8.49063C17.213 6.50312 11.9922 6.50312 7.4573 8.49063C7.19054 8.60313 7 8.86563 7 9.16563V17.7906C7 22.4406 14.1262 24.4281 14.4311 24.5031ZM11.0395 14.6031C11.3443 14.3031 11.8016 14.3031 12.1065 14.6031L13.8976 16.3656L17.1368 13.1781C17.4416 12.8781 17.8989 12.8781 18.2038 13.1781C18.5086 13.4781 18.5086 13.9281 18.2038 14.2281L14.4311 17.9406C14.2786 18.0906 14.0881 18.1656 13.8976 18.1656C13.707 18.1656 13.5165 18.0906 13.3641 17.9406L11.0395 15.6531C10.7346 15.3531 10.7346 14.8656 11.0395 14.6031Z" fill="#29B6E8" />
        </svg>
    )
}
