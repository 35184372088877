import React, { useState } from 'react'
import { UserDataProps } from '../types/common';
import { TierLists } from '../types/tier';

export default function useSidebar() {
    const [userData, setUserData] = useState<UserDataProps>();
    const [setUpStatus, setSetUpStatus] = useState<number>(0);
    const [isActivated, setIsActivated] = useState<number>(0)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [imageLoaded, setImageLoaded] = useState(false);
    const [isDropdown, setIsDropdown] = useState(false)
    const [tierLists, setTierLists] = useState<TierLists>();

    return {
        user: { userData, setUserData },
        setup: { setUpStatus, setSetUpStatus },
        activation: { isActivated, setIsActivated },
        loading: { isLoading, setIsLoading },
        image: { imageLoaded, setImageLoaded },
        dropdown: { isDropdown, setIsDropdown },
        tiers: { tierLists, setTierLists }
    };
}
