import React from 'react'
import survey from '../asset/sidebar/active/survey.svg';
import surveyBanner from '../asset/home/surveyBanner.png'
import newPersonalBanner from '../asset/home/newPersonalBanner.png'

import RightArrowIcon from '../asset/home/RightArrowIcon';
import { useNavigate } from 'react-router-dom';

export default function SurveyBanner() {
    const navigate = useNavigate()

    return (
        <div className='flex flex-col xl:flex-row xl:gap-32 xl:justify-between rounded-xl border mt-12 w-[80%] cursor-pointer' onClick={() => navigate(`/survey/wellness-index`)}>
            <img className='xl:hidden w-full rounded-[2rem] p-4' src={newPersonalBanner} height={250} width={250} />

            <div className='flex flex-col pt-8 pl-8 pr-8 lg:pr-0'>
                <div className='w-fit px-3 py-1 bg-[#fde0de] rounded-full flex'>
                    <img src={survey} alt='surveys' className='w-5 mr-2' />
                    <span className='font-bold text-[#ed3426] text-lg'>Surveys</span>
                </div>
                <div className='flex flex-col md:gap-4 xl:gap-0 h-full justify-between'>
                    <p className='font-bold text-xl pt-2'>Recommended For You</p>
                    <div className='flex gap-4 items-center pb-8'>
                        <h5 className='font-medium text-xl tracking-wide'>Wellness Index</h5>
                        <RightArrowIcon />
                    </div>
                </div>
            </div>
            {/* <img className='rounded-r-xl' src={surveyBanner} height={250} width={250} /> */}
            <img className='hidden md:hidden xl:block rounded-r-xl' src={newPersonalBanner} height={250} width={250} />
        </div>
    )
}
