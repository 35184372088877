import React from 'react';
import safetycontact from '../asset/ineedhelp/Safety Contacts.svg';
import teacher from '../asset/ineedhelp/Teachers.svg';
import counsellors from '../asset/ineedhelp/School Counsellor.svg';
import { Link } from 'react-router-dom';

const DoYouNeedHelpWidget: React.FC = () => {
  return (
    <>
      <span className='text-[#29b6e8] font-bold text-2xl mt-4'>Do you need help?</span>
      <div className='w-full h-fit grid grid-cols-1 mt-3 gap-4 px-10'>
        <div className='border border-[#cccccc] w-full h-fit rounded-2xl flex flex-col justify-center items-center py-3 cursor-not-allowed'>
          <span className='font-bold text-sm text-[#666666]'>Safety contacts</span>
          <img src={safetycontact} alt='safety contacts' className='mt-4 w-[28%] h-[28%]' />
          <span className='font-bold text-center text-lg mt-4 text-[#29b6e8]'>I want to talk to someone</span>
        </div>
        <Link to='/i-need-help?teacher=1' className='border border-[#cccccc] w-full h-fit rounded-2xl flex flex-col justify-center items-center py-3 cursor-pointer'>
          <span className='font-bold text-sm text-[#666666]'>School Teachers</span>
          <img src={teacher} alt='safety contacts' className='mt-4 w-[28%] h-[28%]' />
          <span className='font-bold text-center text-lg mt-4 text-[#29b6e8]'>I need to talk it out</span>
        </Link>
        <Link to='/i-need-help?counsellor=1' className='border border-[#cccccc] w-full h-fit rounded-2xl flex flex-col justify-center items-center py-3 cursor-pointer'>
          <span className='font-bold text-sm text-[#666666]'>School Counsellor</span>
          <img src={counsellors} alt='safety contacts' className='mt-4 w-[28%] h-[28%]' />
          <span className='font-bold text-center text-lg mt-4 text-[#29b6e8]'>I need some guidance</span>
        </Link>
      </div>
      <span className='font-bold text-sm mt-4'>Need immediate assistance?</span>
      <Link to='/i-need-help?hotline=1' className='w-fit py-2 px-10 rounded-full bg-[#faa81d] mb-4 mt-2'>
        <span className='font-bold text-white'>Check hotline number</span>
      </Link>
    </>
  );
}

export default DoYouNeedHelpWidget;
