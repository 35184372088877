import React from 'react'

type BlurProps = {
    className?: string
    text?: string
    onClick?: () => void
    children?: React.ReactNode
}

export default function Blur({ className, text, onClick, children }: BlurProps) {
    return (
        <>
            <div onClick={onClick} className={`single-blur left-0 rounded-2xl relative ${onClick && 'cursor-pointer'} ${className}`}>
                <p className={`lexend-deca-bold absolute flex justify-center items-center text-center h-full w-full z-[999] text-base  px-4 font-semibold ${onClick && 'hover:text-[#696969]'}`}>{text}</p>
                <div className={`lexend-deca-bold absolute flex justify-center items-center text-center h-full w-full z-[999] text-base font-semibold ${onClick && 'hover:text-[#696969]'}`}>{children}</div>
            </div>
        </>
    )
}
